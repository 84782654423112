import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonIcon,
  IonLabel,
  IonModal,
  IonList,
  IonItem,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonThumbnail,
  IonImg,
  IonRow,
  IonCol,
  IonGrid,
  IonSearchbar,
  IonReorderGroup,
  IonReorder,
  IonInput,
  IonToggle,
  IonTextarea,
  IonText,
  IonPopover,
  IonChip,
  IonCheckbox,
  IonAlert,
  IonLoading,
} from '@ionic/react';
import {
  addCircleOutline,
  addOutline,
  closeOutline,
  checkmarkOutline,
  copyOutline,
  ellipsisHorizontalOutline,
  removeCircleOutline,
  trashOutline,
} from 'ionicons/icons';
import Parse from 'parse/dist/parse.min.js';
import * as moment from 'moment';
import { nanoid } from 'nanoid';
import AppContext from '../../../context/AppContext';
import MenuEditorModal from '../../../components/MenuEditorModal';
import EditSectionCategoryModal from '../../../components/EditSectionCategoryModal';

import './MenuSectionPage.css';

const MenuSectionPage = (props) => {
  const { deviceWidth, user, updateUser, updateIsOnboarding } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();

  const [categoryIndex, setCategoryIndex] = useState(null);
  const [categoryObj, setCategoryObj] = useState({
    section: '',
    title: '',
    description: '',
    menuUuids: [],
  });
  const [categorySegment, setCategorySegment] = useState({ section: null, category: null });
  const [copiedRestaurantHours, setCopiedRestaurantHours] = useState([]);
  const [filteredModItems, setFilteredModItems] = useState([]);
  const [flag, setFlag] = useState('');
  const [isFilledTitle, setIsFilledTitle] = useState(false);
  const [menu, setMenu] = useState();
  const [menus, setMenus] = useState(null);
  const [menuIndex, setMenuIndex] = useState();
  const [menuStockArray, setMenuStockArray] = useState([]);
  const [modCategories, setModCategories] = useState('Additional');
  const [modFlag, setModFlag] = useState('');
  const [modifierItems, setModifierItems] = useState([]);
  const [modifierItemSearchWord, setModifierSearchWord] = useState('');
  const [modifierGroups, setModifierGroups] = useState([]);
  const [modifierStockArray, setModifierStockArray] = useState([]);
  const [modId, setModId] = useState('');
  const [modIndex, setModIndex] = useState();
  const [modItemsTag, setModItemsTag] = useState('Additional');
  const [modMin, setModMin] = useState(0);
  const [modMax, setModMax] = useState(100);
  const [modOptions, setModOptions] = useState([]);
  const [modifierItemsCheckedIdArray, setModifierItemsCheckedIdArray] = useState([]);
  const [modRequired, setModRequired] = useState(false);
  const [modTitle, setModTitle] = useState('');
  const [modType, setModType] = useState(false);
  const [newBusinessHour, setNewBusinessHour] = useState({ start: '', end: '', is_overnight: false });
  const [newSection, setNewSection] = useState({
    title: null,
    categories: [
      {
        title: '',
        description: '',
        menuUuids: [],
      },
    ],
    sectionIndex: null,
    menuUuids: [],
  });
  const [newSectionCategory, setNewSectionCategory] = useState('');
  const [newModifierItemName, setNewModifierItemName] = useState('');
  const [newModifierItemPrice, setNewModifierItemPrice] = useState();
  const [originalMenus, setOriginalMenus] = useState([]);
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [restaurantHours, setRestaurantHours] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [sections, setSections] = useState([]);
  const [sectionFlag, setSectionFlag] = useState();
  const [sectionIndex, setSectionIndex] = useState(null);
  const [sectionTitle, setSectionTitle] = useState('');
  const [segment, setSegment] = useState('0');
  const [select, setSelect] = useState('All');
  const [showAddSectionModal, setShowAddSectionModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [showEditSectionModal, setShowEditSectionModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showMenuEditModal, setShowMenuEditModal] = useState(false);
  const [showModifierModal, setShowModifierModal] = useState(false);
  const [showModifierItemSelectModal, setShowModifierItemSelectModal] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [sectionNumArray, setSectionNumArray] = useState();
  const [title, setTitle] = useState('');
  const [uuids, setUuids] = useState([]);
  const [shownAddHourObj, setShownAddHourObj] = useState({});

  const swiper = React.useRef();
  const newCategoryInputRef = React.useRef([]);

  const slideOption = {
    allowTouchMove: true,
    scrollbars: true,
    ionViewDidEnter: () => {
      swiper.current.update();
    },
  };

  function onChangeSlide(value) {
    setSegment(value);
    // const val = Number(value);
    // slide.slideTo(val);
  }

  const rId = props.match.params.id;

  useEffect(() => {
    console.log('menuPage rendered');
    const currentUser = Parse.User.current();
    if (!currentUser) {
      updateIsOnboarding(true);
      return history.replace('/');
    }
    const getRestaurant = async () => {
      await Parse.Cloud.run('getRestaurantFromApp', { rId: rId ? rId : user.restaurant_id }).then((result) => {
        if (!result) {
          alert('Please check internet connection');
        } else {
          updateUser({ ...user, restaurant_id: rId ? rId : user.restaurant_id, restaurant_name: result.name });
        }
      });
    };
    if (!user.restaurant_name) {
      getRestaurant();
    }

    const getMenuData = async () => {
      await Parse.Cloud.run('getMenuPageData', { rId: rId ? rId : user.restaurant_id })
        .then((results) => {
          if (results) {
            console.log(results.sections);
            setRestaurantHours(results.restaurantHours);
            setSections(results.sections);
            setSectionNumArray(results.sectionNumArray);
          } else {
            console.log('No restaurant data');
          }
        })
        .catch((error) => {
          alert(error);
        });
    };
    getMenuData();
  }, []);

  const handleSectionChange = (index, event) => {
    let copy = [...sections];
    const template = copy[index].categories[event.detail.from];
    copy[index].categories[event.detail.from] = copy[index].categories[event.detail.to];
    copy[index].categories[event.detail.to] = template;
    event.detail.complete();
    setSections([...copy]);
  };

  const handleUpdateCategory = (index, val) => {
    setCategoryIndex(index);
    setCategoryObj(val);
    setShowEditCategoryModal(true);
  };

  const onSaveSections = () => {
    if (!newSection.title.length) {
      alert('Please input new section title');
      return;
    }
    setShowLoading(true);
    let copiedSection = [...sections];
    if (sectionFlag === 'edit') {
      copiedSection[sectionIndex] = newSection;
      Parse.Cloud.run('saveSections', { id: user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id, data: copiedSection }).then((result) => {
        if (result) {
          setSections([...copiedSection]);
          setShowLoading(false);
          setShowAddSectionModal(false);
        } else {
          setShowLoading(false);
          alert(result);
        }
      });
    } else {
      copiedSection.push(newSection);
      Parse.Cloud.run('saveSections', { id: user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id, data: copiedSection }).then((result) => {
        if (result) {
          setSections([...sections, newSection]);
          setShowLoading(false);
          setShowAddSectionModal(false);
        } else {
          setShowLoading(false);
          alert(result);
        }
      });
    }
  };

  const onDeleteSection = () => {
    let copiedSection = sections;
    copiedSection.splice(sectionIndex, 1);
    Parse.Cloud.run('saveSections', { id: user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id, data: copiedSection }).then((result) => {
      if (result) {
        setSections(copiedSection);
        setShowAddSectionModal(false);
      } else {
        alert(result);
      }
    });
  };

  const onSelectChange = (val) => {
    const copiedMenus = [...originalMenus];
    if (val !== 'All') {
      setSelect(val[1]);
      let menuUuids = sections[val[0]].menuUuids;
      let filteredArray = copiedMenus.filter(function (value) {
        return menuUuids.includes(value.objectId);
      });
      setMenus(filteredArray);
    } else {
      setSelect('All');
      setMenus(copiedMenus);
    }
  };

  const onSaveCategory = async () => {
    let copiedSection = sections;
    let checkboxes = document.getElementsByClassName('categoryModalCheckBox');
    let uuids = [];
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        uuids.push(checkboxes[i].value);
        let index = copiedSection[sectionIndex].menuUuids.indexOf(checkboxes[i].value);
        if (index === -1) {
          copiedSection[sectionIndex].menuUuids.push(checkboxes[i].value);
        }
      } else {
        let index = copiedSection[sectionIndex].menuUuids.indexOf(checkboxes[i].value);
        if (index > -1) {
          copiedSection[sectionIndex].menuUuids.splice(index, 1);
        }
      }
    }
    let targetObj = categoryObj;
    targetObj.menuUuids = uuids;
    copiedSection[sectionIndex].categories[categoryIndex] = targetObj;
    //copiedSection[sectionIndex].menuUuids = [...new Set([...copiedSection[sectionIndex].menuUuids, ...uuids])];
    Parse.Cloud.run('saveSections', { id: user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id, data: copiedSection }).then((result) => {
      if (result) {
        setSections([...copiedSection]);
        setShowEditCategoryModal(false);
      } else {
        alert(result);
      }
    });
  };

  const onDeleteCategory = async (event, index, i) => {
    event.stopPropagation();
    let copy = [...sections];
    copy[index].categories.splice(i, 1);
    setSections([...copy]);
    let query = new Parse.Query('Restaurants');
    try {
      let result = await query.get(user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id);
      result.set('sections', copy);
      result.save();
    } catch (error) {
      alert(error);
    }
  };

  const handleUpdateSections = (value) => {
    setSections([...value]);
  };

  const onSaveSectionInHours = async (index, value) => {
    let copiedRestaurantHour = restaurantHours;
    copiedRestaurantHour[0].open[index].section = value;
    setRestaurantHours(copiedRestaurantHour);
    await Parse.Cloud.run('onSaveHours', { data: copiedRestaurantHour, id: user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id })
      .then((result) => {
        return true;
      })
      .catch((error) => alert(error));
  };

  const handleNewSectionAddCategory = () => {
    if (!newSectionCategory.length) return;
    let copiedNewSectionCategory = newSection.categories;
    copiedNewSectionCategory.push({ title: newSectionCategory, menuUuids: [] });
    setNewSection({ ...newSection, categories: copiedNewSectionCategory });
    setNewSectionCategory('');
  };

  const handleEditSection = (index) => {
    setSectionIndex(index);
    setNewSection({ ...newSection, title: sections[index].title, categories: sections[index].categories });
    setSectionFlag('EDIT');
    setShowAddSectionModal(true);
  };

  const showEditBusinessHourModal = () => {
    setCopiedRestaurantHours([...restaurantHours]);
    setShowEditSectionModal(true);
  };

  const toggleAddHour = (index, val) => {
    setNewBusinessHour({ ...newBusinessHour, start: val });
    setShownAddHourObj({ [index]: true });
  };

  const handleAddBusinessHours = (index, day) => {
    if (Number(newBusinessHour.start) >= Number(newBusinessHour.end)) {
      alert('Start-time must be earlier than end-time');
      return false;
    }
    if (Number(newBusinessHour.start) < Number(copiedRestaurantHours[0].open[index].end)) {
      alert('New start-time must be earlier than previous end-time');
      return false;
    }
    if (
      copiedRestaurantHours[0].open[index + 1].day === day &&
      Number(newBusinessHour.end) > Number(copiedRestaurantHours[0].open[index + 1].start)
    ) {
      alert('New end-time must be earlier than next start-time');
      return false;
    }
    let copy = copiedRestaurantHours;
    const obj = {
      day,
      start: newBusinessHour.start,
      end: newBusinessHour.end,
      is_overnight: newBusinessHour.is_overnight,
      is_open: true,
      section: 0,
    };
    copy[0].open.splice(index + 1, 0, obj);
    setShownAddHourObj({ [index]: false });
    setCopiedRestaurantHours([...copy]);
  };

  const onSaveBusinessHour = async () => {
    await Parse.Cloud.run('onSaveHours', {
      id: user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id,
      data: copiedRestaurantHours,
    }).then((result) => {
      if (result) {
        setRestaurantHours([...copiedRestaurantHours]);
        setShowEditSectionModal(false);
      } else {
        alert(result);
      }
    });
  };

  const handleChangeBusinessHourCheck = (dayNumber, checked) => {
    let copiedBusinessHour = restaurantHours;
    let open = copiedBusinessHour[0].open;
    for (let i = 0; i < open.length; i++) {
      if (open[i].day === Number(dayNumber)) open[i].is_open = checked;
    }
    copiedBusinessHour[0].open = open;
    setRestaurantHours([...copiedBusinessHour]);
  };

  const handleRemoveBusinessHour = (index) => {
    let copy = [...copiedRestaurantHours];
    copy[0].open.splice(index, 1);
    setCopiedRestaurantHours([...copy]);
  };

  const handleShowAddSectionModal = (flag, title) => {
    setSectionFlag(flag);
    setSectionTitle(title);
    setShowAddSectionModal(true);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode='ios' style={{ width: '100%' }}></IonToolbar>
      </IonHeader>

      <IonContent scrollX='true' className='ion-padding'>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' size-lg='8'>
              <>
                <h1>Restaurant business hours</h1>
                <h1>and menu section</h1>
                <IonButton onClick={showEditBusinessHourModal} style={{ fontFamily: 'BrandonBold', margin: '24px 0', width: '50%' }}>
                  Edit Operation Hour
                </IonButton>
                <IonButton onClick={showEditBusinessHourModal} style={{ fontFamily: 'BrandonBold', margin: '24px 0', width: '50%' }}>
                  Edit Menu Sections
                </IonButton>
                <IonGrid className='ion-padding-bottom' style={{ borderBottom: '1px solid grey' }}>
                  <IonRow>
                    <IonCol size-xs='12' size-sm='3' size-md='3' size-lg='3'>
                      <div style={{ width: '7em', fontSize: '1rem' }}>Days</div>
                    </IonCol>
                    <IonCol size-xs='6' size-sm='3' size-md='5' size-lg='5'>
                      <div style={{ width: '10em', fontSize: '1rem' }}>Hours</div>
                    </IonCol>
                    <IonCol size-xs='6' size-sm='3' size-md='4' size-lg='4'>
                      <IonLabel style={{ fontSize: '1rem' }}>Applied Menu</IonLabel>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonGrid className='ion-margin-top'>
                  {!restaurantHours.length && !sections.length
                    ? null
                    : restaurantHours[0].open.map((hour, index) => {
                        const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
                        let start = String(hour.start);
                        let end = String(hour.end);
                        let startHour;
                        let startMin = start.substr(-2);
                        let endHour;
                        let endMin = end.substr(-2);
                        if (start.length === 4) {
                          startHour = start.substring(0, 2);
                        } else {
                          startHour = start.substring(0, 1);
                        }
                        if (end.length === 4) {
                          endHour = end.substring(0, 2);
                        } else {
                          endHour = end.substring(0, 1);
                        }
                        let prevState = 0;
                        if (index > 0) {
                          prevState = restaurantHours[0].open[index - 1];
                        }

                        return (
                          <IonRow key={nanoid()} className='ion-align-items-center' style={{ height: '50px' }}>
                            <IonCol size-xs='12' size-sm='3' size-md='3' size-lg='3'>
                              {!prevState !== 0 && prevState.day === hour.day ? (
                                <div style={{ fontFamily: 'Brandon', fontSize: '1.1em' }}></div>
                              ) : (
                                <div style={{ fontFamily: 'Brandon', fontSize: '1.1em' }}>{days[hour.day]}</div>
                              )}
                            </IonCol>
                            {hour.is_open ? (
                              <>
                                <IonCol size-xs='6' size-sm='5' size-md='5' size-lg='5'>
                                  <div style={{ width: '10em', fontSize: '1rem' }}>
                                    {startHour}:{startMin} - {endHour}:{endMin}
                                  </div>
                                </IonCol>
                                <IonCol size-xs='6' size-sm='4' size-md='4' size-lg='4'>
                                  {/* <IonChip> */}
                                  <IonSelect
                                    id='select'
                                    style={{ backgroundColor: '#333', fontSize: '1rem' }}
                                    value={hour.section}
                                    onIonInput={(e) => onSaveSectionInHours(index, e.detail.value)}>
                                    {sections.map((item, i) => (
                                      <IonSelectOption key={nanoid()} value={i}>
                                        {item.title}
                                      </IonSelectOption>
                                    ))}
                                  </IonSelect>
                                  {/* </IonChip> */}
                                </IonCol>
                              </>
                            ) : (
                              <>
                                <IonCol>{!prevState !== 0 && prevState.day === hour.day && !hour.is_open ? null : <IonLabel>Close</IonLabel>}</IonCol>
                                <IonCol></IonCol>
                              </>
                            )}
                          </IonRow>
                        );
                      })}
                </IonGrid>
                <div className='ion-margin-top ion-float-end' style={{ width: '30%' }}>
                  <IonButton fill='outline' expand='block' onClick={() => handleShowAddSectionModal('ADD')}>
                    Add Section
                  </IonButton>
                  {!sections
                    ? null
                    : sections.map((item, index) => (
                        <IonItem
                          key={index}
                          className='ion-margin-top'
                          type='button'
                          style={{ borderRadius: '5px' }}
                          onClick={() => handleEditSection(index)}>
                          <h3 slot='start'>{item.title}</h3>
                          <div className='ion-wrap'>
                            {item.categories.length ? (
                              item.categories.map((category) => (
                                <IonText key={nanoid()} className='ion-margin-end'>
                                  {category.title}
                                </IonText>
                              ))
                            ) : (
                              <IonChip>No Category yet</IonChip>
                            )}
                          </div>
                        </IonItem>
                      ))}
                </div>
              </>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonModal
          id='businessHourModal'
          isOpen={showEditSectionModal}
          onDidDismiss={() => {
            setShowEditSectionModal(false);
            setCopiedRestaurantHours([]);
            setShownAddHourObj({});
            setNewBusinessHour({ start: '', end: '', is_overnight: false });
          }}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonButton onClick={() => setShowEditSectionModal(false)}>
                  <IonIcon icon={closeOutline} />
                </IonButton>
              </IonButtons>
              <IonTitle>Add or Edit Business Hours</IonTitle>
              <IonButtons slot='end'>
                <IonButton fill='solid' expand='block' color='primary' onClick={onSaveBusinessHour}>
                  <IonLabel>SAVE</IonLabel>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className='ion-padding'>
            <IonGrid>
              <IonRow>
                <IonCol className='ion-no-padding' size-xs='12' size-sm='10' offset-sm='1' size-md='8' offset-md='2' size-lg='6' offset-lg='3'>
                  <h3 style={{ fontFamily: 'BrandonBold' }}>If you edit exist hours, type as 24h and three of four letters.</h3>
                  <h3 className='ion-margin-top' style={{ fontFamily: 'BrandonBold' }}>
                    Ex: 100 = 1:00 am, 1300 = 1:00 pm
                  </h3>
                  <IonGrid>
                    {!copiedRestaurantHours.length
                      ? null
                      : copiedRestaurantHours[0].open.map((val, index) => {
                          const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
                          let prevState = 0;
                          if (index > 0) {
                            prevState = copiedRestaurantHours[0].open[index - 1];
                          }
                          return (
                            <React.Fragment key={nanoid()}>
                              <IonRow className='ion-align-items-center'>
                                <IonCol size='auto'>
                                  {prevState !== 0 && prevState.day === val.day ? (
                                    <div style={{ width: '1em' }}></div>
                                  ) : (
                                    <div style={{ width: '1em' }}>
                                      <IonCheckbox
                                        checked={val.is_open}
                                        value={val.day}
                                        onIonInput={(e) => handleChangeBusinessHourCheck(e.target.value, e.target.checked)}
                                      />
                                    </div>
                                  )}
                                </IonCol>
                                <IonCol size='auto'>
                                  {prevState && prevState.day === val.day ? (
                                    <div style={{ width: '6em' }}></div>
                                  ) : (
                                    <div style={{ fontFamily: 'BrandonBold', fontSize: '0.9em', width: '6em' }}>{days[val.day]}</div>
                                  )}
                                </IonCol>
                                <IonCol size='auto' className='ion-padding-end'>
                                  <div>
                                    <IonInput
                                      className='ion-float-start ion-text-center'
                                      type='tel'
                                      style={{ border: '1px solid grey', borderRadius: '5px', width: '5em' }}
                                      placeholder={val.start}
                                      value={val.start}
                                      disabled={!val.is_open}
                                      onIonInput={(e) => (val.start = e.target.value)}
                                    />
                                    <span className='ion-float-start ion-margin-horizontal'>to</span>
                                    <IonInput
                                      className='ion-float-start ion-text-center'
                                      type='tel'
                                      style={{ border: '1px solid grey', borderRadius: '5px', width: '5em' }}
                                      placeholder={val.end}
                                      value={val.end}
                                      disabled={!val.is_open}
                                      onIonInput={(e) => (val.end = e.target.value)}
                                    />
                                  </div>
                                </IonCol>
                                <IonCol size='auto'>
                                  <IonButton fill='clear' color='secondary' disabled={!val.is_open} onClick={() => toggleAddHour(index, val.end)}>
                                    <IonIcon icon={addCircleOutline} />
                                  </IonButton>
                                </IonCol>
                                {prevState !== 0 && prevState.day === val.day ? (
                                  <IonCol>
                                    <IonButton fill='clear' color='tertiary' disabled={!val.is_open} onClick={() => handleRemoveBusinessHour(index)}>
                                      <IonIcon icon={removeCircleOutline} />
                                    </IonButton>
                                  </IonCol>
                                ) : null}
                              </IonRow>
                              {shownAddHourObj[[index]] && val.is_open ? (
                                <IonRow className='ion-align-items-center'>
                                  <IonCol size='auto'>
                                    <div style={{ width: '1em' }}></div>
                                  </IonCol>
                                  <IonCol size='auto'>
                                    <div style={{ width: '6em' }}></div>
                                  </IonCol>
                                  <IonCol size='auto' className='ion-padding-end'>
                                    <div>
                                      <IonInput
                                        type='tel'
                                        className='ion-float-start ion-text-center'
                                        style={{ border: '1px solid grey', borderRadius: '5px', width: '5em' }}
                                        placeholder={val.end}
                                        value={newBusinessHour.start}
                                        onIonInput={(e) => setNewBusinessHour({ ...newBusinessHour, start: e.target.value })}
                                      />
                                      <span className='ion-float-start ion-margin-horizontal'>to</span>
                                      <IonInput
                                        type='tel'
                                        className='ion-float-start ion-text-center'
                                        style={{ border: '1px solid grey', borderRadius: '5px', width: '5em' }}
                                        placeholder='end'
                                        value={newBusinessHour.end}
                                        onIonInput={(e) => setNewBusinessHour({ ...newBusinessHour, end: e.target.value })}
                                      />
                                    </div>
                                  </IonCol>
                                  <IonCol>
                                    <IonButton onClick={() => handleAddBusinessHours(index, val.day)}>
                                      <IonLabel>Submit</IonLabel>
                                    </IonButton>
                                  </IonCol>
                                </IonRow>
                              ) : null}
                            </React.Fragment>
                          );
                        })}
                  </IonGrid>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>

        <IonModal
          isOpen={showAddSectionModal}
          onDidDismiss={() => {
            setNewSection({ ...newSection, title: '', description: '', categories: [], menuUuids: [] });
            setIsFilledTitle(false);
            setShowAddSectionModal(false);
            setSectionFlag('');
            setSectionIndex(null);
          }}>
          {/* <EditSectionCategoryModal handleShowAddSectionModal={handleShowAddSectionModal} /> */}
          <IonHeader>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonButton onClick={() => setShowAddSectionModal(false)}>
                  <IonIcon icon={closeOutline} />
                </IonButton>
              </IonButtons>
              <IonTitle>{sectionFlag === 'EDIT' ? 'Edit Section' : 'Add Section'}</IonTitle>

              <IonButtons slot='end'>
                {sectionFlag === 'EDIT' ? (
                  <IonButton fill='solid' expand='block' color='primary' onClick={onSaveSections}>
                    Save
                  </IonButton>
                ) : (
                  <IonButton fill='solid' expand='block' color='primary' onClick={onSaveSections}>
                    Save
                  </IonButton>
                )}
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className='ion-padding'>
            <IonGrid>
              <IonRow className='ion-align-items-center'>
                <IonCol size='auto' sizeSm className='ion-text-end'>
                  <div style={{ width: '6em', fontSize: '1rem' }}>Title: </div>
                </IonCol>
                <IonCol className='ion-padding-end'>
                  <IonInput
                    className='ion-padding-start'
                    style={{ border: '1px solid grey', borderRadius: '5px' }}
                    value={sectionTitle && sectionTitle}
                    onIonInput={(e) => setNewSection({ ...newSection, title: e.target.value })}
                    disabled
                  />
                </IonCol>
                <IonCol size='auto'>
                  <IonButton
                    fill={!isFilledTitle ? 'solid' : 'clear'}
                    onClick={() => {
                      if (!newSection.title.length) return alert('Please fill title');
                      setIsFilledTitle(true);
                    }}>
                    <IonIcon icon={checkmarkOutline} />
                  </IonButton>
                </IonCol>
              </IonRow>
              {isFilledTitle || sectionFlag === 'edit' ? (
                <>
                  <IonRow className='ion-align-items-center ion-padding-top'>
                    <IonCol size='auto' className='ion-text-end'>
                      <div style={{ width: '6em', fontSize: '1rem' }}>Categories: </div>
                    </IonCol>
                    <IonCol className='ion-padding-end'>
                      <IonInput
                        className='ion-padding-start'
                        style={{ border: '1px solid grey', borderRadius: '5px' }}
                        placeholder={' Soup & Salad'}
                        value={newSectionCategory}
                        onIonInput={(e) => setNewSectionCategory(e.target.value)}
                      />
                    </IonCol>
                    <IonCol size='auto'>
                      <IonButton fill='solid' onClick={handleNewSectionAddCategory}>
                        <IonIcon icon={addOutline} />
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-align-items-center ion-padding-top'>
                    <IonCol size='auto' className='ion-text-end'>
                      <div style={{ width: '6em', fontSize: '1rem' }}>Categories: </div>
                    </IonCol>
                    <IonCol className='ion-padding-end'>
                      <IonInput
                        className='ion-padding-start'
                        style={{ border: '1px solid grey', borderRadius: '5px' }}
                        placeholder={'Mosyly local & organic ingredients'}
                        value={newSectionCategory}
                        onIonInput={(e) => setNewSectionCategory(e.target.value)}
                      />
                    </IonCol>
                    <IonCol size='auto'>
                      <IonButton fill='solid' onClick={handleNewSectionAddCategory}>
                        <IonIcon icon={addOutline} />
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-align-items-center ion-padding-top'>
                    <IonCol size='auto' className='ion-text-end'>
                      <div style={{ width: '6em' }}>Applied:</div>
                    </IonCol>
                    <IonCol>
                      <IonReorderGroup disabled={false} onIonItemReorder={(e) => handleSectionChange(sectionIndex, e)}>
                        {newSection.categories.map((val, index) => (
                          <IonItem
                            key={nanoid()}
                            color='light'
                            style={{ borderRadius: '5px', marginBottom: '8px' }}
                            onClick={() => handleUpdateCategory(index, val)}>
                            <IonReorder slot='start' />
                            <IonLabel>{val.title}</IonLabel>
                            <IonLabel>
                              {val.menuUuids.length} {val.menuUuids.length > 1 ? 'items' : 'item'}
                            </IonLabel>
                            <IonButton slot='end' fill='solid' color='danger'>
                              <IonIcon icon={trashOutline} />
                            </IonButton>
                          </IonItem>
                        ))}
                      </IonReorderGroup>
                    </IonCol>
                  </IonRow>
                </>
              ) : null}
            </IonGrid>
          </IonContent>
        </IonModal>

        <IonModal className='categoryModal' isOpen={showEditCategoryModal}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonButton onClick={() => setShowEditCategoryModal(false)}>
                  <IonIcon icon={closeOutline} />
                </IonButton>
              </IonButtons>
              <IonTitle>Edit Category in "{categoryObj.section}"</IonTitle>
              <IonButtons slot='end'>
                <IonButton fill='solid' expand='block' color='primary' onClick={onSaveCategory}>
                  Save
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className='ion-padding'>
            <IonItem>
              <IonLabel position='stacked'>Title</IonLabel>
              <IonInput fill='outline' value={categoryObj.title} onIonInput={(e) => setCategoryObj({ ...categoryObj, title: e.target.value })} />
            </IonItem>
            <IonItem>
              <IonLabel position='stacked'>Description</IonLabel>
              <IonTextarea
                fill='outline'
                value={categoryObj.description}
                onIonInput={(e) => setCategoryObj({ ...categoryObj, description: e.target.value })}
              />
            </IonItem>
            <IonSearchbar />
            <IonList>
              {originalMenus.length ? (
                originalMenus.map((item, index) => (
                  <IonItem key={index}>
                    <IonCheckbox
                      slot='start'
                      className='categoryModalCheckBox'
                      checked={categoryObj.menuUuids.includes(item.objectId)}
                      value={item.objectId}
                    />
                    {!item.images.length > 0 ? null : (
                      <IonThumbnail slot='start'>
                        <IonImg src={item.images[0].uri} />
                      </IonThumbnail>
                    )}
                    <IonLabel className='ion-text-wrap'>
                      <h3>{item.name}</h3>
                      <p>{item.description}</p>
                      <p>${item.price}</p>
                    </IonLabel>
                  </IonItem>
                ))
              ) : (
                <IonLabel>No Item Yet</IonLabel>
              )}
            </IonList>
          </IonContent>
        </IonModal>

        <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />
      </IonContent>
    </IonPage>
  );
};

export default MenuSectionPage;
