import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  IonButton,
  IonHeader,
  IonContent,
  IonPage,
  IonAvatar,
  IonLabel,
  IonToolbar,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonMenuButton,
  IonTitle,
  IonItem,
  IonButtons,
  IonText,
  IonInput,
  IonTextarea,
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonLoading,
  IonModal,
  IonReorderGroup,
  IonReorder,
  IonList,
  IonListHeader,
  IonSpinner,
  IonRefresher,
  IonRefresherContent,
  IonFooter,
} from '@ionic/react';
import { closeOutline, createOutline, imageOutline, images, receipt } from 'ionicons/icons';
import Parse from 'parse/dist/parse.min.js';
import { nanoid } from 'nanoid';
import { Browser } from '@capacitor/browser';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { FilePicker } from '@capawesome/capacitor-file-picker';

import MenuHourComponent from '../../components/MenuHourComponent/MenuHourComponent';
import EditBusinessHourModal from '../../components/EditBusinessHourModal/EditBusinessHourModal';

import './AccountPage.css';

import AppContext from '../../context/AppContext';

const AccountPage = (props) => {
  const { platform, deviceWidth, user, updateUser, updateIsOnboarding } = useContext(AppContext);
  const history = useHistory();

  const [restaurant, setRestaurant] = useState(null);
  const [copiedRestaurant, setCopiedRestaurant] = useState();
  const [restaurantHours, setRestaurantHours] = useState([]);
  const [copiedRestaurantHours, setCopiedRestaurantHours] = useState([]);
  const [menus, setMenus] = useState([]);
  const [categories, setCategories] = useState(null);
  const [sections, setSections] = useState([]);
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [showBusinessHourModal, setShowBusinessHourModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showImageUploadLoading, setShowImageUploadLoading] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showStoreCategoryModal, setShowStoreCategoryModal] = useState(false);
  const [showImageEditModal, setShowImageEditModal] = useState(false);
  const [igData, setIgData] = useState([]);

  const storeCategoryInput = useRef([]);

  const rId = props.match.params.id;

  console.log(platform);

  const initAccountPage = async () => {
    setShowLoading(false);
    await Parse.Cloud.run('getMenuPageData', { rId: rId ? rId : user.restaurant_id })
      .then(async (result) => {
        updateIsOnboarding(false);
        setRestaurant(result.restaurant);
        setCopiedRestaurant(result.restaurant);
        setRestaurantHours(result.restaurantHours);
        setSections(result.sections);
        setMenus(result.data);
        setCategories(result.categories);
        setShowLoading(false);
        if (result.restaurant.linked_services.instagram.username) {
          const accessToken = result.restaurant.linked_services.instagram.access_token;
          const results = await Parse.Cloud.run('getMyIgPostForPlaceDetail', {
            accessToken,
          });
          if (results.length) {
            setIgData(results);
          } else {
            setIgData([]);
          }
        }
      })
      .catch((error) => {
        setShowLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (!restaurant) {
      setShowLoading(true);
    }
    const checkOnBoardingStatus = async () => {
      await Parse.Cloud.run('checkOnBoardingStatus', { rId: rId ? rId : user.restaurant_id }).then((result) => {
        console.log(result.onboarding_status.is_onboarding);
        if (result.onboarding_status.is_onboarding) {
          updateIsOnboarding(true);
          return history.replace('/');
        } else {
          updateIsOnboarding(false);
        }
      });
    };
    const currentUser = Parse.User.current();
    if (!currentUser) {
      checkOnBoardingStatus();
    }
    initAccountPage();
  }, []);

  useEffect(() => {
    if (!platform.includes('desktop') || !platform.includes('mobileweb')) Browser.addListener('browserFinished', initAccountPage);
  }, []);

  const handleRefresh = (event) => {
    initAccountPage();
    setTimeout(() => {
      event.detail.complete();
    }, 2000);
  };

  const handleUpdateLogo = async (uri) => {
    await Parse.Cloud.run('updateRestaurantLogo', { uri, rId: rId ? rId : user.restaurant_id }).then(() => {
      return;
    });
  };

  const onSaveSectionInHours = async (index, value) => {
    let copiedRestaurantHour = restaurantHours;
    copiedRestaurantHour[0].open[index].section = value;
    setRestaurantHours(copiedRestaurantHour);
    await Parse.Cloud.run('onSaveHours', { data: copiedRestaurantHour, rId: user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id })
      .then((result) => {
        console.log(result);
      })
      .catch((error) => alert(error));
  };

  const showEditBusinessHourModal = () => {
    setCopiedRestaurantHours([...restaurantHours]);
    setShowBusinessHourModal(true);
  };

  const handleSaveDescription = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('saveRestaurantDescription', { rId: rId ? rId : user.restaurant_id, data: restaurant.description }).then(() => {
      setShowLoading(false);
    });
  };

  const handleSaveMessage = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('saveRestaurantDairyMessage', { rId: rId ? rId : user.restaurant_id, data: restaurant.dairy_message }).then(() => {
      setShowLoading(false);
    });
  };

  const closeCategoryModal = () => {
    setShowStoreCategoryModal(false);
  };

  const onSaveStoreCategories = async () => {
    setShowLoading(true);
    const array = storeCategoryInput.current.map((item) => {
      return { alias: item.value.toLowerCase(), title: item.value };
    });
    setRestaurant({ ...restaurant, categories: [...array] });
    await Parse.Cloud.run('saveStoreCategories', { rId: rId ? rId : user.restaurant_id, data: array })
      .then((result) => {
        console.log(result);
        closeCategoryModal();
        setShowLoading(false);
      })
      .catch((error) => {
        setShowLoading(false);
        alert(error);
      });
  };

  const onSaveWebSite = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('updateWebSite', { rId, url: restaurant.web }).then((result) => {
      setShowLoading(false);
      if (!result) {
        console.log(result);
      }
    });
  };

  const onSaveReservation = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('updateReservationUrl', { rId, url: restaurant.reservation_url }).then((result) => {
      setShowLoading(false);
      if (!result) {
        console.log(result);
      }
    });
  };

  const takePicture = async () => {
    const result = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Camera,
    });
    setShowSpinner(true);
    const base64 = `${result.dataUrl}`;
    setRestaurant({ ...restaurant, logo: base64 });
    try {
      await Parse.Cloud.run('uploadRestaurantLogo', { base64, rId })
        .then((uploadedFile) => {
          updateUser({
            ...user,
            restaurant_logo: uploadedFile,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
    setShowSpinner(false);
  };

  const pickImage = async () => {
    const result = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Photos,
    });
    setShowSpinner(true);
    const base64 = `${result.dataUrl}`;
    setRestaurant({ ...restaurant, logo: base64 });
    try {
      await Parse.Cloud.run('uploadRestaurantLogo', { base64, rId })
        .then((uploadedFile) => {
          updateUser({
            ...user,
            restaurant_logo: uploadedFile,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
    setShowSpinner(false);
  };

  const pickLogo = async () => {
    const result = await FilePicker.pickFiles({
      types: ['image/png', 'image/jpg', 'image/JPG', 'image/heic'],
      multiple: false,
      readData: true,
    });
    const file = result.files[0];
    setShowSpinner(true);
    try {
      const base64 = `data:${file.mimeType};base64,${file.data}`;
      setRestaurant({ ...restaurant, logo: base64 });
      await Parse.Cloud.run('uploadRestaurantLogo', { base64, rId })
        .then((uploadedFile) => {
          updateUser({
            ...user,
            restaurant_logo: uploadedFile,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
    setShowSpinner(false);
  };

  const pickImages = async () => {
    const result = await FilePicker.pickImages({
      // types: ['image/png', 'image/jpg', 'image/JPG', 'image/heic'],
      multiple: true,
      readData: true,
    });

    if (result.files.length === 1) {
      const file = result.files[0];
      const base64 = `data:${file.mimeType};base64,${file.data}`;
      let prevImages = restaurant.images;
      prevImages.unshift({ uri: base64, media_type: 'IMAGE' });
      setRestaurant({ ...restaurant, images: [...prevImages] });
    } else {
      let data = [];
      for (let i = 0; i < result.files.length; i++) {
        if (result.files[i].mimeType.includes('video') || result.files[i].mimeType.includes('mp')) {
          alert('You can not upload video at this time. Please wait for future update');
          continue;
        }
        const base64 = `data:${result.files[i].mimeType};base64,${result.files[i].data}`;
        data.push({ uri: base64, media_type: 'IMAGE', name: result.files[i].name });
      }
      let prevImages = restaurant.images;
      prevImages = [...data, ...prevImages];
      setRestaurant({ ...restaurant, images: [...prevImages] });
    }
  };

  const handleReorderImages = async (event) => {
    let data = restaurant?.images;
    let copiedData = restaurant?.images[event.detail.from];
    data.splice(event.detail.from, 1);
    data.splice(event.detail.to, 0, copiedData);
    event.detail.complete();
    setRestaurant({ ...restaurant, images: [...data] });
    // await Parse.Cloud.run('saveRestaurantImages', { rId, images: data }).then(() => {
    //   setRestaurant({ ...restaurant, images: [...data] });
    // });
  };

  const onSaveImages = async () => {
    setShowImageUploadLoading(true);
    await Parse.Cloud.run('saveRestaurantImages', { rId, images: restaurant?.images, action: 'SAVE' })
      .then((results) => {
        console.log(results);
        setRestaurant({ ...restaurant, images: results });
        setCopiedRestaurant({ ...copiedRestaurant, images: restaurant.images });
      })
      .catch((error) => {
        console.log(error);
      });
    setShowImageUploadLoading(false);
  };

  const deleteImage = async (index) => {
    setShowLoading(true);
    let copiedImages = restaurant?.images;
    copiedImages.splice(index, 1);
    setRestaurant({ ...restaurant, images: [...copiedImages] });
    setShowLoading(false);
    await Parse.Cloud.run('saveRestaurantImages', { rId, images: copiedImages, action: 'DELETE' })
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ImageModalComponent = (props) => {
    const { images, setShowImageEditModal, setRestaurant } = props;
    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonButton fill='clear' onClick={() => setShowImageEditModal(false)}>
                <IonIcon slot='icon-only' icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className='ion-padding'>
          <IonListHeader>
            <h1>Please drag and drop images</h1>
          </IonListHeader>
          <IonList>
            <IonReorderGroup disabled={false} onIonItemReorder={(e) => handleReorderImages(e)}>
              {images?.map((image, index) => {
                return (
                  <IonItem key={index} style={{ height: '10vh', marginBottom: '1em' }}>
                    <IonReorder slot='start'></IonReorder>
                    <img src={image.uri} alt={'1'} style={{ width: 'auto', height: '10vh' }} />
                    <IonButton slot='end' size='small' onClick={() => deleteImage(index)}>
                      Delete
                    </IonButton>
                  </IonItem>
                );
              })}
            </IonReorderGroup>
          </IonList>
        </IonContent>
      </>
    );
  };

  const handleConnectInstagram = async () => {
    // history.replace('/dashboard/store/instagram_auth/');
    const clientId = process.env.REACT_APP_INSTAGRAM_CLIENT_ID;
    const redirectUri = 'https://join.onoonline.app/dashboard/store/instagram_auth/';
    console.log(platform);
    const result = await Browser.open({
      url: `https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=user_profile,user_media&response_type=code&state=${rId}`,
      windowName: platform.includes('desktop') || platform.includes('mobileweb') ? '_self' : '_blank',
    });
  };

  const unlinkInstagram = async (accessToken) => {
    setShowLoading(true);
    await Parse.Cloud.run('unlinkInstagram', {
      rId,
      accessToken,
    })
      .then(() => {
        setIgData([]);
        setShowLoading(false);
        setRestaurant({ ...restaurant, instagram: '', linked_services: { username: '', user_id: '', access_token: '', created_at: '' } });
      })
      .catch((error) => {
        setShowLoading(false);
        console.log(error);
      });
  };

  if (showLoading) {
    return <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={null} />;
  }

  if (showImageUploadLoading) {
    return (
      <IonLoading
        isOpen={showImageUploadLoading}
        message='Compressing your files. Please wait...'
        onDidDismiss={() => setShowImageUploadLoading(false)}
      />
    );
  }

  return (
    <IonPage id='menu'>
      <IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' offset-md='1' size-lg='8' offset-lg='2'>
              <IonToolbar>
                <IonButtons slot='start'>
                  <IonMenuButton size='large' />
                </IonButtons>
                <IonButtons slot='end'>
                  <IonButton>
                    <IonIcon slot='icon-only' icon={receipt} />
                  </IonButton>
                </IonButtons>
                <IonTitle>Store</IonTitle>
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>

      <IonPopover
        cssClass='my-custom-class'
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}>
        <IonItem
          onClick={(e) => {
            setShowPopover({ showPopover: false, event: e });
            history.push(`/dashboard/account/bank/${user.restaurant_id}`);
          }}>
          Bank
        </IonItem>
      </IonPopover>
      <IonContent>
        <IonRefresher slot='fixed' pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={(e) => handleRefresh(e)}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' offset-md='1' size-lg='8' offset-lg='2'>
              <div className='ion-padding ion-text-center'>
                <IonRow className='ion-justify-content-center'>
                  <IonCol size='auto'>
                    <div style={{ margin: 'auto', position: 'relative', width: '12vh', height: '12vh' }}>
                      {!restaurant ? null : (
                        <>
                          <IonAvatar
                            id='popover-button1'
                            style={{ width: '12vh', height: '12vh' }}
                            onClick={platform.includes('desktop') || platform.includes('mobileweb') ? pickLogo : null}>
                            <IonImg src={restaurant?.logo ? restaurant?.logo : 'https://fakeimg.pl/150x150/?retina=1&text=Logo&font=Montserrat'} />
                          </IonAvatar>
                          {platform.includes('desktop') || platform.includes('mobileweb') ? null : (
                            <IonPopover trigger='popover-button1' dismissOnSelect={true}>
                              <IonContent>
                                <IonList className='ion-no-padding'>
                                  <IonItem button={true} detail={false} onClick={takePicture}>
                                    Take photo
                                  </IonItem>
                                  <IonItem button={true} detail={false} onClick={pickImage}>
                                    Choose from library
                                  </IonItem>
                                  <IonItem button={true} detail={false} onClick={pickLogo}>
                                    Browse files
                                  </IonItem>
                                </IonList>
                              </IonContent>
                            </IonPopover>
                          )}
                        </>
                      )}
                      {showSpinner ? (
                        <IonSpinner style={{ position: 'absolute', top: 0, right: '-5vh' }} name='crescent' color='danger'></IonSpinner>
                      ) : (
                        <>
                          <IonButton
                            id='popover-button3'
                            style={{ position: 'absolute', top: 0, right: '-5vh' }}
                            size='small'
                            shape='round'
                            fill='clear'
                            onClick={platform.includes('desktop') || platform.includes('mobileweb') ? pickLogo : null}>
                            <IonIcon slot='icon-only' icon={imageOutline} color='dark' />
                          </IonButton>
                          {platform.includes('desktop') || platform.includes('mobileweb') ? null : (
                            <IonPopover trigger='popover-button3' dismissOnSelect={true}>
                              <IonContent>
                                <IonList className='ion-no-padding'>
                                  <IonItem button={true} detail={false} onClick={takePicture}>
                                    Take photo
                                  </IonItem>
                                  <IonItem button={true} detail={false} onClick={pickImage}>
                                    Choose from library
                                  </IonItem>
                                  <IonItem button={true} detail={false} onClick={pickLogo}>
                                    Browse files
                                  </IonItem>
                                </IonList>
                              </IonContent>
                            </IonPopover>
                          )}
                        </>
                      )}
                    </div>
                  </IonCol>
                </IonRow>
                <div style={{ margin: '2rem 0' }}>
                  <div style={{ fontFamily: 'BrandonBold', fontSize: '1.3rem' }}>{user.restaurant_name}</div>
                  {!restaurant ? null : (
                    <>
                      <div style={{ fontSize: '0.9rem', marginTop: '8px' }}>
                        {restaurant.categories.map((item, index) => (
                          <IonLabel key={index} style={{ marginRight: '7px' }}>
                            {item.title}
                          </IonLabel>
                        ))}
                      </div>
                      <div style={{ fontSize: '0.9rem', marginTop: '4px' }}>
                        <IonLabel>{restaurant.location.address1} </IonLabel>
                        {!restaurant.location.address2 ? null : <IonLabel>{restaurant.location.address2} </IonLabel>}
                        <IonLabel>{restaurant.location.address3}, </IonLabel>
                        <IonLabel>{restaurant.location.city}, </IonLabel>
                        <IonLabel>{restaurant.location.state} </IonLabel>
                        <IonLabel>{restaurant.location.zip_code}</IonLabel>
                        <div style={{ fontSize: '0.9rem', marginTop: '8px' }}>{restaurant.display_phone}</div>
                      </div>
                    </>
                  )}
                  <IonButton className='ion-margin-top' onClick={() => setShowStoreCategoryModal(true)}>
                    <IonIcon style={{ marginRight: '5px' }} size='small' icon={createOutline} /> Edit categories
                  </IonButton>
                </div>
              </div>

              <section style={{ borderRadius: '10px' }}>
                <IonRow className='ion-align-items-center'>
                  <IonCol>
                    <IonText style={{ fontFamily: 'BrandonBold', fontSize: '3vh' }}>Dairy message</IonText>
                  </IonCol>
                  <IonCol size='auto'>
                    <IonButton
                      disabled={restaurant?.dairy_message === copiedRestaurant?.dairy_message}
                      size='small'
                      style={{ width: '45px' }}
                      onClick={handleSaveMessage}>
                      Save
                    </IonButton>
                  </IonCol>
                </IonRow>

                <IonTextarea
                  style={{ border: '1px solid grey', borderRadius: '5px', padding: '5px' }}
                  placeholder='What you want to tell?'
                  autoGrow={true}
                  value={restaurant?.dairy_message}
                  onIonInput={(e) => setRestaurant({ ...restaurant, dairy_message: e.target.value })}
                />
              </section>

              <section style={{ borderRadius: '10px', marginTop: '50px' }}>
                <IonRow className='ion-align-items-center'>
                  <IonCol>
                    <IonText style={{ fontFamily: 'BrandonBold', fontSize: '3vh' }}>Images</IonText>
                  </IonCol>
                  <IonCol size='auto'>
                    {/* {platform.includes('ios') || platform.includes('android') ? ( */}
                    <IonButton size='small' onClick={pickImages}>
                      Upload
                    </IonButton>
                  </IonCol>
                </IonRow>
                <IonRow>
                  {restaurant?.images.map((image, index) => {
                    return (
                      <IonCol size='4' key={image.uri}>
                        <IonImg src={image.uri} style={{ aspectRatio: 1, width: '100%' }} />
                      </IonCol>
                    );
                  })}
                </IonRow>
                <div className='ion-margin-top'>
                  <IonButton
                    expand='block'
                    color='light'
                    onClick={() => {
                      setShowImageEditModal(true);
                    }}>
                    Edit
                  </IonButton>
                  <IonButton
                    className='ion-margin-top'
                    expand='block'
                    disabled={restaurant?.images === copiedRestaurant?.images}
                    onClick={onSaveImages}>
                    Save
                  </IonButton>
                </div>
                {igData.length > 0 ? (
                  <section style={{ marginTop: '4em' }}>
                    <IonRow className='ion-align-items-center'>
                      <IonCol>
                        <IonText style={{ fontFamily: 'BrandonBold', fontSize: '3vh' }}>From Instagram (not editable)</IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      {igData?.map((image, index) => {
                        console.log(image);
                        return (
                          <IonCol size='4' key={index}>
                            {image.media_type === 'IMAGE' ? (
                              <img src={image.media_url} style={{ width: '100%', aspectRatio: 1 }} />
                            ) : image.media_type === 'VIDEO' ? (
                              <video style={{ aspectRatio: 1 }} autoPlay={'autoplay'} muted={true} loop={true} playsInLine='playsInline' width='100%'>
                                <source src={image.media_url} type='video/mp4' />
                              </video>
                            ) : image.media_type === 'CAROUSEL_ALBUM' && image.children.data[0].media_type === 'IMAGE' ? (
                              <div style={{ position: 'relative' }}>
                                <img src={image.children.data[0].media_url} style={{ width: '100%', aspectRatio: 1 }} />
                                <IonIcon icon={images} size='20px' style={{ position: 'absolute', top: '5px', right: '5px' }} />
                              </div>
                            ) : (
                              <video style={{ aspectRatio: 1 }} autoPlay={'autoplay'} muted={true} loop={true} playsInLine='playsInline' width='100%'>
                                <source src={image.image.children.data[0].media_url} type='video/mp4' />
                              </video>
                            )}
                          </IonCol>
                        );
                      })}
                    </IonRow>
                  </section>
                ) : null}
              </section>

              {/* <section style={{ borderRadius: '10px', marginTop: '50px' }}>
                <IonRow className='ion-align-items-center'>
                  <IonCol>
                    <IonText style={{ fontFamily: 'BrandonBold', fontSize: '3vh' }}>Restaurant Bio</IonText>
                  </IonCol>
                  <IonCol size='auto'>
                    <IonButton
                      disabled={
                        restaurant?.description.title === copiedRestaurant?.description.title &&
                        restaurant?.description.content === copiedRestaurant?.description.content
                      }
                      size='small'
                      style={{ width: '45px' }}
                      onClick={handleSaveDescription}>
                      Save
                    </IonButton>
                  </IonCol>
                </IonRow>
                <IonLabel style={{ fontSize: '0.7rem', marginTop: '12px' }}>Title</IonLabel>
                <IonInput
                  placeholder='Title'
                  value={restaurant?.description.title}
                  style={{ border: '1px solid grey', borderRadius: '5px', paddingHorizontal: '10px' }}
                  onIonInput={(e) => setRestaurant({ ...restaurant, description: { ...restaurant.description, title: e.target.value } })}
                />
                <IonLabel style={{ fontSize: '0.7rem', marginTop: '12px' }}>Content</IonLabel>
                <IonTextarea
                  style={{ border: '1px solid grey', borderRadius: '5px', paddingHorizontal: '10px' }}
                  placeholder='Describe restaurant'
                  rows={2}
                  autoGrow={true}
                  value={restaurant?.description.content}
                  onIonInput={(e) => setRestaurant({ ...restaurant, description: { ...restaurant.description, content: e.target.value } })}
                />
              </section> */}

              <section style={{ borderRadius: '10px', marginTop: '50px' }}>
                {!restaurant || !categories || !sections || !restaurantHours ? null : (
                  <MenuHourComponent
                    from={'DASHBOARD'}
                    rId={restaurant.objectId}
                    menus={menus}
                    categories={categories}
                    sections={sections}
                    setSections={setSections}
                    restaurantHours={restaurantHours ? restaurantHours : []}
                  />
                )}
              </section>

              {!restaurant ? null : (
                <div style={{ marginTop: '4em' }}>
                  <IonText style={{ fontFamily: 'BrandonBold', fontSize: '3vh' }}>Restaurant business hours</IonText>
                  <br />
                  <IonText style={{ fontFamily: 'BrandonBold', fontSize: '3vh' }}>and menu section</IonText>

                  <IonGrid className='ion-padding-bottom' style={{ borderBottom: '1px solid grey' }}>
                    <IonRow>
                      <IonCol size-xs='auto' size-sm='3' size-md='3' size-lg='3'>
                        <div style={{ width: '12vh', fontSize: '1rem' }}>Days</div>
                      </IonCol>
                      <IonCol size-xs='auto' size-sm='3' size-md='5' size-lg='5'>
                        <div style={{ width: '15vh', fontSize: '1rem' }}>Hours</div>
                      </IonCol>
                      <IonCol size-xs='auto' size-sm='3' size-md='4' size-lg='4'>
                        <IonLabel style={{ fontSize: '1rem' }}>Applied Menu</IonLabel>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  <IonGrid className='ion-margin-top'>
                    {!restaurantHours.length && !sections.length
                      ? null
                      : restaurantHours[0].open.map((hour, index) => {
                          const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
                          let start = String(hour.start);
                          if (start === '0000') {
                            start = '000';
                          }
                          let end = String(hour.end);
                          if (end === '0000') {
                            end = '000';
                          }
                          let startHour;
                          let startMin = start.substr(-2);
                          let endHour;
                          let endMin = end.substr(-2);
                          if (start.length === 4) {
                            startHour = start.substring(0, 2);
                          } else {
                            startHour = start.substring(0, 1);
                          }
                          if (end.length === 4) {
                            endHour = end.substring(0, 2);
                          } else {
                            endHour = end.substring(0, 1);
                          }
                          let prevState = 0;
                          if (index > 0) {
                            prevState = restaurantHours[0].open[index - 1];
                          }

                          return (
                            <IonRow key={nanoid()} className='ion-align-items-center' style={{ paddingBottom: '6px' }}>
                              <IonCol size-xs='auto' size-sm='3' size-md='3' size-lg='3'>
                                {!prevState !== 0 && prevState.day === hour.day ? (
                                  <div style={{ fontFamily: 'Brandon', fontSize: '2.2vh', width: '12vh' }}></div>
                                ) : (
                                  <div style={{ fontFamily: 'Brandon', fontSize: '2.2vh', width: '12vh' }}>{days[hour.day]}</div>
                                )}
                              </IonCol>
                              {hour.is_open ? (
                                <>
                                  <IonCol size-xs='auto' size-sm='5' size-md='5' size-lg='5'>
                                    <div style={{ width: '15vh', fontSize: '2vh' }}>
                                      {Number(startHour)}:{startMin} - {Number(endHour)}:{endMin}
                                    </div>
                                  </IonCol>
                                  <IonCol size-sm='4' size-md='4' size-lg='4'>
                                    {/* <IonChip> */}
                                    <IonSelect
                                      id='select'
                                      style={{ fontSize: '2vh' }}
                                      value={hour.section}
                                      onIonInput={(e) => onSaveSectionInHours(index, e.detail.value)}>
                                      {sections.map((item, i) => (
                                        <IonSelectOption key={nanoid()} value={i}>
                                          {item.title}
                                        </IonSelectOption>
                                      ))}
                                    </IonSelect>
                                    {/* </IonChip> */}
                                  </IonCol>
                                </>
                              ) : (
                                <>
                                  <IonCol>
                                    {!prevState !== 0 && prevState.day === hour.day && !hour.is_open ? null : <IonLabel>Close</IonLabel>}
                                  </IonCol>
                                  <IonCol></IonCol>
                                </>
                              )}
                            </IonRow>
                          );
                        })}
                  </IonGrid>
                  <IonButton onClick={showEditBusinessHourModal} style={{ fontFamily: 'BrandonBold', margin: '24px 0', width: '50%' }}>
                    Edit Operation Hour
                  </IonButton>
                  <section style={{ marginTop: '5em' }}>
                    <h1>Links</h1>
                    <div className='ion-padding-horizontal ion-margin-top' style={{ border: '0.5px solid grey', borderRadius: '5px' }}>
                      <IonRow className='ion-margin-top'>
                        <div style={{ fontSize: '1.25em', fontFamily: 'BrandonBold' }}>Business url</div>
                      </IonRow>
                      <IonItem className='ion-no-padding'>
                        <IonInput
                          placeholder='https://your-restaurant-url.com'
                          value={restaurant?.web}
                          onIonInput={(e) => setRestaurant({ ...restaurant, web: e.target.value })}
                        />
                      </IonItem>
                      <IonButton className='ion-margin-vertical' disabled={restaurant?.web === copiedRestaurant?.web} onClick={onSaveWebSite}>
                        Save
                      </IonButton>
                      <div style={{ marginTop: '2em' }}>
                        <IonText style={{ fontSize: '1.25em', fontFamily: 'BrandonBold' }}>Instagram username</IonText>
                      </div>
                      {restaurant?.instagram ? (
                        <>
                          <IonItem className='ion-no-padding'>
                            <IonInput
                              placeholder='your-instagram-account'
                              value={restaurant.instagram}
                              inputMode='text'
                              disabled
                              onIonInput={(e) => setRestaurant({ ...restaurant, instagram: e.target.value })}
                            />
                          </IonItem>

                          <IonButton
                            className='ion-margin-vertical'
                            onClick={() => unlinkInstagram(restaurant?.linked_services.instagram.access_token)}>
                            Unlink
                          </IonButton>
                        </>
                      ) : (
                        <IonButton onClick={handleConnectInstagram}>Connect Instagram Account</IonButton>
                        // <IonButton
                        //   className='ion-margin-vertical'
                        //   disabled={restaurant.instagram === copiedRestaurant?.instagram}
                        //   onClick={onSaveInstagram}>
                        //   Connect your Instagram account
                        // </IonButton>
                      )}
                      <div style={{ marginTop: '2em' }}>
                        <IonText style={{ fontSize: '1.25em', fontFamily: 'BrandonBold' }}>3rd party reservation service URL</IonText>
                      </div>
                      <IonItem className='ion-no-padding'>
                        <IonInput
                          placeholder='https://opentable.com/??? or Resy?'
                          value={restaurant.reservation_url}
                          inputMode='text'
                          onIonInput={(e) => setRestaurant({ ...restaurant, reservation_url: e.target.value })}
                        />
                      </IonItem>
                      <IonButton
                        className='ion-margin-vertical'
                        disabled={restaurant.reservation_url === copiedRestaurant?.reservation_url}
                        onClick={onSaveReservation}>
                        Save
                      </IonButton>
                      <div style={{ marginTop: '2em' }}>
                        <IonText style={{ fontSize: '1.25em', fontFamily: 'BrandonBold' }}>yelp url - not editable</IonText>
                      </div>
                      <IonItem className='ion-no-padding ion-margin-bottom'>
                        <IonInput disabled value={restaurant?.yelp_url} />
                      </IonItem>
                      {/* <div style={{ marginTop: '2em' }}>
                        <IonText style={{ fontSize: '1.25em', fontFamily: 'BrandonBold' }}>Delivery services</IonText>
                        <br />
                        <IonText style={{ fontSize: '0.8em', fontFamily: 'Brandon' }}>
                          How to get URL: Browse delivery service's website , and search your page. Then, copy URL and paste below{' '}
                        </IonText>
                      </div> */}
                      {/* <DeliveryServicesComponent
                        deliveryServices={restaurant?.delivery}
                        updateDeliveryServices={updateDeliveryServices}
                        rId={rId ? rId : user.restaurant_id}
                      /> */}
                    </div>
                  </section>
                </div>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />
      </IonContent>
      <EditBusinessHourModal
        rId={rId ? rId : user.restaurant_id}
        restaurantHours={restaurantHours}
        setRestaurantHours={setRestaurantHours}
        showBusinessHourModal={showBusinessHourModal}
        setShowBusinessHourModal={setShowBusinessHourModal}
      />
      <IonModal
        isOpen={showStoreCategoryModal}
        onDidDismiss={() => setShowStoreCategoryModal(false)}
        onIonModalDidDismiss={() => setShowStoreCategoryModal(false)}>
        <IonHeader>
          <IonGrid>
            <IonRow>
              <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' offset-md='1' size-lg='6' offset-lg='3'>
                <IonToolbar>
                  <IonButtons slot='start'>
                    <IonButton onClick={() => setShowStoreCategoryModal(false)}>
                      <IonIcon slot='icon-only' icon={closeOutline} />
                    </IonButton>
                  </IonButtons>
                  <IonTitle>Edit restaurant categories</IonTitle>
                </IonToolbar>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonHeader>
        <IonContent className='ion-padding'>
          <h3 className='ion-text-center ion-margin-top' style={{ fontFamily: 'BrandonBold' }}>
            Please register restaurant categories
          </h3>
          <div style={{ margin: '24px 0' }}>
            {restaurant?.categories.map((item, index) => (
              <IonItem key={index}>
                <IonLabel slot='start'>{index + 1}.</IonLabel>
                <IonInput value={item.title} ref={(ref) => (storeCategoryInput.current[index] = ref)} />
              </IonItem>
            ))}
          </div>
          <div className='ion-text-center' style={{ marginTop: '36px' }}>
            <IonButton style={{ width: '70%' }} onClick={onSaveStoreCategories}>
              Save
            </IonButton>
          </div>
        </IonContent>
      </IonModal>
      <IonModal isOpen={showImageEditModal} onIonModalDidDismiss={() => setShowImageEditModal(false)}>
        <ImageModalComponent images={restaurant?.images} setShowImageEditModal={setShowImageEditModal} setRestaurant={setRestaurant} />
      </IonModal>
      {/* <IonFooter>
        <IonToolbar color='light'>
          <IonButtons slot='start'>
            <IonMenuButton size='large' />
          </IonButtons>
          <IonButtons slot='end'>
            <IonButton>
              <IonIcon slot='icon-only' icon={receipt} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter> */}
    </IonPage>
  );
};
export default AccountPage;
