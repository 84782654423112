import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';

export default function TermsofUsePage() {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton />
          </IonButtons>
          <IonTitle>Terms of use</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className='ion-padding'>
          <h1>ono online for Restaurants - Terms of Use</h1>
          <h3>Effective Date: January 15, 2023</h3>
          <h5>
            Please read these Restaurant Terms and Conditions (“Terms”) carefully before using the services offered by ono online Technologies, Inc.
            (ono online). If you accept these terms on behalf of an organization, you represent and warrant that you are authorized to bind that
            organization. If these Terms are considered an offer, acceptance is expressly limited to such terms. These Terms contain, among other
            things, warranty disclaimers, liability limitations and use limitations. There shall be no force or effect to any different terms of any
            related purchase order or similar form even if signed by the parties after the date hereof.
          </h5>
          <h1>Restaurant Terms and Conditions</h1>
          <h1>1. ono online’s Services</h1>

          <h5>
            ono online will provide an app and website that allows Customers to make Orders from Restaurant. If Restaurant alerts ono online to
            changes in its menu or prices, ono online will work with Restaurant to update these changes on its app and website. For each Order that
            Customer places, ono online will charge and collect from Customer the price for each Order and all applicable fees and taxes. ono online
            will pay these amounts to Restaurant, withholding the appropriate Commission and Card Processing Fee, as outlined in our Order Form.
            Notwithstanding anything to the contrary in these Terms or otherwise, ono online shall be an independent contractor of Restaurant during
            the term of the Agreement. ono online has the discretion (but not the obligation) to edit any content that Restaurant provides, including
            editing typos, removing inappropriate content, or removing Menu Offerings.
          </h5>
          <h1>2. Restaurant’s Services</h1>
          <h5>
            Restaurant will (a) provide the address(es) of its location(s), the food and drinks that it provides (its “Menu Offerings”) and
            corresponding price of each Menu Offering (the “Prices”); and (b) provide photos of Menu Offering if requested by ono online. For each
            Order, Restaurant will promptly: (c) accept the Order; (d) prepare the Order, (e) if the Order is Dine In, (f) reserve a table for
            Customer, or, (g) if space is not permitting, alert ono online that the Order will be packaged for Takeout, and (h) if the Order is
            Takeout, properly package the Order. Restaurant will ensure the quality, condition, availability, packaging and freshness of each Order
            before picked up by Customer. Restaurant will ensure that all Menu Offerings are of similar quality and taste as the same items Restaurant
            serves to other diners not using ono online. All relevant Restaurant employees, subcontractors, and agents, including, but not limited to,
            waiters, will comply with the applicable terms of these Terms. Restaurant will be liable for the work performed by, and for the acts and
            omissions of, all its employees, contractors, and agents including following food safety regulations. Restaurant will be liable for
            additional charges that ono online may impose, including order errors or delays caused by Restaurant, missing items, equipment damage, or
            if Restaurant closures without notice. If Customer has any queries or issues with the total of the Order, Restaurant (not ono online) will
            be responsible for handling them. ono online’s obligation is only to facilitate the payment of the amount shown on the Order. Further,
            Restaurant takes full responsibility for the following scenarios (and ono online disclaims responsibility): - If Restaurant does not
            confirm an Order but begins working on it. Customers can cancel unconfirmed Orders in their discretion; - If Customer cancels the Order
            after Restaurant has started preparing an Order; - If Restaurant lists different prices on its menu than it has shared with ono online; -
            If Customer arrives late and Restaurant must remake an Order; - If Restaurant refuses to accept any Order. Restaurant agrees the price of
            each Menu Offering listed on ono online’s app or website will not be greater than the price it regularly charges for that Menu Offering
            absent any food ordering platforms. ono online may update the prices, or deduct the difference in future payments to Restaurant, to ensure
            the foregoing.
          </h5>
          <h1>3. Mutual Representations and Warranties</h1>
          <h5>
            ono online and Restaurant each represent and warrant that they: (a) have full power and authority to enter into the Agreement and perform
            its obligations hereunder; and (b) will comply with all laws and regulations applicable to their performance obligations herein, including
            for Restaurant any applicable federal, state, and local laws related to Restaurant’s sale of Menu Offerings consisting of alcoholic
            beverages or other non-perishable non-food items.
          </h5>
          <h1>4. Restaurant’s Representations and Warranties</h1>
          <h5>
            Restaurant hereby represents and warrants that: (a) ono online’s use of Restaurant’s trademark does not and will not infringe or violate
            any trademark or other intellectual property of any third party; (b) the Prices of each Menu Offering listed on ono online’s app or
            website shall not be greater than the price Restaurant regularly charges for that Menu Offering absent any food ordering platform(s); and
            (c) sales tax information provided by the Restaurant is accurate. In the event that Restaurant sells Menu Offerings consisting of
            alcoholic beverages, Restaurant represents and warrants that it has obtained all necessary and applicable local state permits and/or
            licenses for selling and serving alcohol off premises for all Restaurant locations selling such Menu Offerings on ono online’s app or
            website.
          </h5>
          <h1>5. Trademark</h1>
          <h5>
            Restaurant may use ono online’s name, logo, promo code (if applicable) and link to the ono online website and services in its advertising
            to promote the fact that their food is available for pre-ordering through ono online. ono online shall have the right to use Restaurant
            name, trademark, photos (if provided) and logo in the platform and in connection with all reasonable marketing and advertising. If
            Restaurant uses ono online’s name, logo or any other intellectual property in a manner that ono online reasonably considers to negatively
            affect its business or brand, upon notification to Restaurant, Restaurant will promptly remove any and all uses of ono online’s name, logo
            and intellectual property in accordance with ono online’s notification. For the avoidance of doubt, all names, logos, designs, trademarks
            and other intellectual property remain solely the property of the owning party. Nothing in these Terms provides for the transfer of
            ownership of any such intellectual property.
          </h5>
          <h1>6. Communication</h1>
          <h5>
            Restaurant consents to accept and receive communications from ono online, including e-mail, text messages, calls, and push notifications
            to the cellular telephone number Restaurant provides to ono online. Standard message and data rates applied by Restaurant’s cell phone
            carrier may apply to the text messages ono online sends Restaurant. Restaurant may opt-out of receiving communications by following the
            unsubscribe options provided by ono online. Restaurant acknowledges that opting out of receiving communications may impact Restaurant’s
            use of the Services. Carriers are not responsible or liable for undelivered or delayed messages.
          </h5>
          <h1>7. Confidentiality</h1>
          <h5>
            The terms of the Agreement and any and all information disclosed by ono online to Restaurant pursuant to these Terms are confidential
            (including the Commission amount), and Restaurant agrees not to disclose the terms of the Agreement or any such confidential information
            to any third party.
          </h5>
          <h1>8. Payment</h1>
          <h5>
            ono online shall charge Restaurant the percentage Commission stated in the Sign Up Sheet on the Net Revenue less Taxes for any order. “Net
            Revenue” means the gross amount actually received by ono online for Orders, less promotional discounts, tips and taxes. ono online will
            determine the sales, use, privilege, gross receipts, restaurant, tariff, excise or other tax due in connection with the sale of food or
            beverages (and delivery fee, if applicable) by Restaurant through ono online (“Taxes”). ono online shall collect Taxes from Customers on
            behalf of Restaurant, shall tender collected Taxes to the Restaurant (as discussed above) and Restaurant shall be solely responsible for
            (a) verifying that the collected, tendered Tax amount is correct, (b) filing all Tax returns and (c) remitting all required Taxes to the
            appropriate taxing authorities. ono online uses a third-party payment processor (the “Payment Processor”) to charge Customers for Orders.
            The processing of payments will be subject to the terms, conditions and privacy policies of the Payment Processor in addition to these
            Terms. Currently, we use Stripe, Inc. as our Payment Processor. Restaurant is subject to the Stripe Connected Account Agreement, which
            includes the Stripe Services Agreement. By agreeing to these Terms, Merchant agrees to be bound by the Stripe Connected Account Agreement
            available here: https://stripe.com/connect-account/legal and the Stripe Services Agreement available here: https://stripe.com/legal, as
            the same may be modified by Stripe from time to time. As a condition of ono online enabling payment processing services through Stripe,
            Restaurant agrees to provide ono online accurate and complete information about Restaurant’s representative and its business, and
            Restaurant authorizes ono online to share it and transaction information related to Restaurant’s use of the payment processing services
            provided by Stripe.
          </h5>
          <h1>9. Term and Termination</h1>
          <h5>
            The term of the Agreement shall begin on the Effective Date. Either party may terminate the Agreement for any reason or no reason upon
            fourteen days’ notice to the other party. Section 8 (Confidentiality), Section 10 (Term and Termination), Section 11 (Indemnification),
            Section 12 (Data), Section 15 (Misuse of the App), Section 17 (Limitation of Liability), and Section 18 (Miscellaneous) shall survive
            termination.
          </h5>
          <h1>10. Indemnification</h1>
          <h5>
            Restaurant, at its expense, will defend, indemnify and hold harmless ono online against all loss, cost, liability, damages and expense,
            including attorneys’ fees, arising out of all third party claims arising out of Restaurant’s obligations or performance under the
            Agreement, including claims related to (i) the death of or injury to any person, (ii) Restaurant’s failure to redeem any consumer’s
            purchase, (iii) any allegation that any item provided by Restaurant infringes or misappropriates any intellectual property right of any
            third party, or (iv) computation and payment of local sales taxes to the appropriate taxing authority, and (v) all matters concerning the
            quality and condition of the food and beverages and the preparation of the food and beverages
          </h5>
          <h1>11. Data</h1>
          <h5>
            ono online’s Privacy Policy, available at ono online.now/privacy/ is incorporated into these Terms by reference, as ono online updates
            from time to time. Restaurant will only collect, use, and share personal information of Customer (“Customer Data”) in compliance with ono
            online’s Privacy Policy as well as applicable privacy laws and only for the purpose of preparing Orders to Customers. Restaurant will not
            use Customer Data for any other purpose other than as contemplated by the Agreement or as agreed to in writing by the parties.
          </h5>
          <h1>12. ono online URL in Google Search and Google Maps</h1>
          <h5>
            The link to ono online will appear automatically with restaurant's listings in Google Search and Google Maps. This link leads to the
            restaurant page inside ono online app or website. From the ono online app or website, a user can make a reservation to Dine In at a
            Restaurant. This link is updated automatically via ono online. There's no way to add, edit, or remove this link in Google My Business. If
            you want to remove or fix the link in your listing, please contact the ono online support team.
          </h5>
          <h1>13. Use of the App</h1>
          <h5>
            ono online may supply the App to Restaurant after the Effective Date which Restaurant must install on its device or device provided by ono
            online. ono online does not provide any hardware, and is strictly a software mobile application provider. ono online will use best efforts
            to ensure the App is operational 24 hours a day, 7 days a week. However, there may be issues or delays due to events outside ono online’s
            control. Similarly, ono online may have to suspend the App if it has to deal with technical problems, or to make improvements. When
            possible, ono online will contact Restaurant to let it know in advance where this occurs, unless the problem is urgent or an emergency. If
            you encounter any defect with the App: please contact ono online as soon as reasonably possible, and give ono online a reasonable
            opportunity to repair or fix any defect. Restaurant’s staff is responsible for reservations and payment at Restaurant, and must go through
            ono online’s training, and must accept Orders from Customers in accordance with the rules set forth at: https://join.ono
            online.app/help/restaurants
          </h5>
          <h1>14. Misuse of the App</h1>
          <h5>
            You represent, warrant, and agree that you will not use the Services or App, interact with the Services or App, in a manner that: (a)
            Infringes or violates the intellectual property rights or any other rights of anyone else (including ono online); (b) Violates any law or
            regulation, including, without limitation, any applicable export control laws; (c) Is harmful, fraudulent, deceptive, threatening,
            harassing, defamatory, obscene, or otherwise objectionable; (d) Jeopardizes the security of your ono online account or anyone else’s (such
            as allowing someone else to log in to the Services as you); (e) Attempts, in any manner, to obtain the password, account, or other
            security information from any other user; (f) Violates the security of any computer network, or cracks any passwords or security
            encryption codes; (g) Runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or any processes that run or are
            activated while you are not logged into the Services, or that otherwise interfere with the proper working of the Services (including by
            placing an unreasonable load on the Services’ infrastructure); (h) “Crawls,” “scrapes,” or “spiders” any page, data, or portion of or
            relating to the Services or content (through use of manual or automated means); (i) Copies or stores any significant portion of the
            content; or (j) Decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or
            relating to the Services. A violation of any of the foregoing is grounds for termination of your right to use or access the Services.
          </h5>
          <h1>15. Disclaimer</h1>
          <h5>
            EXCEPT FOR THE EXPRESS WARRANTIES ABOVE, ono online MAKES NO OTHER WARRANTIES, EXPRESS OR IMPLIED, CONCERNING THE SUBJECT MATTER OF THE
            AGREEMENT, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF MERCHANTABILITY, NONINFRINGEMENT, OR FITNESS FOR A PARTICULAR PURPOSE.
            WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, ono online DOES NOT REPRESENT OR WARRANT THAT OPERATION OF THE WEBSITE OR ANY OTHER ono
            online SYSTEMS WILL BE UNINTERRUPTED OR ERROR-FREE.
          </h5>
          <h1>16. Limitation of Liability</h1>
          <h5>
            ono online WILL NOT BE LIABLE TO RESTAURANT FOR INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF THIS AGREEMENT,
            EVEN IF RESTAURANT HAS BEEN INFORMED OF THE POSSIBILITY OF THOSE DAMAGES. ono online’S LIABILITY FOR DIRECT DAMAGES HEREUNDER WILL NOT
            EXCEED $100.
          </h5>
          <h1>17. Miscellaneous</h1>
          <h5>
            The Agreement contains the entire agreement between the parties regarding its subject matter. The Agreement will be governed by the laws
            of the State of Hawaii. The parties consent to the exclusive jurisdiction of state and federal courts Honolulu, Hawaii and expressly waive
            any objection or defense based upon lack of personal jurisdiction or venue in connection with any dispute arising out of or relating to
            the Agreement. Restaurant may not assign any of its rights or obligations under the Agreement without the prior written consent of ono
            online. The Agreement will benefit and bind permitted successors and assigns, if any, of the parties. If a court or other tribunal of
            competent jurisdiction holds that any of the provisions of these Terms are illegal, invalid, or unenforceable, those provisions will be
            limited or eliminated to the minimum extent necessary so that the Agreement will otherwise remain in full force and effect.
          </h5>
        </div>
      </IonContent>
    </IonPage>
  );
}
