import { IonLabel, IonRow, IonCol, IonCheckbox } from '@ionic/react';
import { nanoid } from 'nanoid';

export default function OrderRefundChildComponent(props) {
  const { option, index, refundAmount, updateRefundAmount } = props;

  const handleChildItemCheckbox = (checked, val) => {
    console.log(val);
    console.log(checked);
    let topCheckbox = document.getElementsByClassName('refundTopCheckbox');
    let originalNumber = refundAmount;
    let number = Number(val);
    setTimeout(() => {
      if (checked) {
        let sum = originalNumber + number;
        updateRefundAmount(sum);
      } else {
        topCheckbox[0].checked = false;
        let sum = originalNumber - number;
        updateRefundAmount(sum);
      }
    });
  };

  return (
    <>
      {option?.choice &&
        option.choice.map((value) => (
          <IonRow key={nanoid()} className='ion-align-items-center'>
            <IonCol size='0.5'></IonCol>
            <IonCol className='ion-text-start'>
              <IonCheckbox
                className={value.price ? `refundItemCheckbox${index} refundCheckbox` : ''}
                value={`${value.price / 100}`}
                onIonInput={(e) => {
                  handleChildItemCheckbox(e.detail.checked, e.detail.value);
                }}
                disabled={!value.price ? true : false}
              />
              <IonLabel>{value.name}</IonLabel>
            </IonCol>
            <IonCol size='1' className='ion-text-center'>
              <IonLabel>1</IonLabel>
            </IonCol>
            <IonCol size='1.5' className='ion-text-end'>
              <IonLabel>${value.price / 100}</IonLabel>
            </IonCol>
          </IonRow>
        ))}
    </>
  );
}
