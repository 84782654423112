import { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonAlert,
  IonButton,
  IonCheckbox,
  IonContent,
  IonImg,
  IonPage,
  IonIcon,
  IonInput,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonCard,
  IonLabel,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonRadioGroup,
  IonRadio,
  IonTextarea,
} from '@ionic/react';
import { Camera, CameraResultType } from '@capacitor/camera';
import Parse from 'parse/dist/parse.min.js';
import { Browser } from '@capacitor/browser';
import { nanoid } from 'nanoid';
import { AuthContext, AppContext } from '../../context/AppContext';
import { errorAlert } from '../../components/errorAlert';
import { arrowBackOutline, mailOutline, storefrontOutline, alertCircleOutline } from 'ionicons/icons';
import ReactInputVerificationCode from 'react-input-verification-code';

import './SignupPage.css';

const SignupPage = ({ location }) => {
  const history = useHistory();

  const { user, updateUser, updateIsOnboarding } = useContext(AppContext);

  const [avatar, setAvatar] = useState();
  const [code, setCode] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [emailVerificationCode, setEmailVerificationCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userRegistrationFlag, setUserRegistrationFlag] = useState(true);
  const [restaurant, setRestaurant] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showExistingMediaUserAlert, setShowExistingMediaUserAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [termCheckbox1, setTermCheckbox1] = useState(false);
  const [termCheckbox2, setTermCheckbox2] = useState(false);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [otherRestaurants, setOtherRestaurants] = useState([]);
  const [userData, setUserData] = useState(null);
  const [loginAlertMessage, setLoginAlertMessage] = useState('');
  const [restaurantSearchWord, setRestaurantSearchWord] = useState('');
  const [website, setWebsite] = useState('');
  const [yelpSearchedRestaurants, setYelpSearchedRestaurants] = useState([]);
  const [comment, setComment] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const handleSwiperIndex = useCallback((num) => {
    setSwiperIndex(num);
  }, []);

  const handleCheckCurrentUser = useCallback(() => {
    const currentUser = Parse.User.current();
    if (currentUser) {
      console.log('currentuser', currentUser.get('status'));
      if (currentUser.get('type') === 'RESTAURANT') {
        if (currentUser.get('status') === 'SIGNUP' || currentUser.get('status') === 'YELP') {
          console.log(currentUser.id);
          updateUser({
            id: currentUser.id,
            first_name: currentUser.get('first_name'),
            last_name: currentUser.get('last_name'),
            email: currentUser.get('email'),
            avatar: currentUser.get('avatar'),
            role: currentUser.get('role'),
          });
          handleSwiperIndex(5);
        } else {
          handleSwiperIndex(1);
        }
      } else {
        console.log('only restaurant');
      }
    } else {
      handleSwiperIndex(1);
    }
  }, []);

  const handleAvatar = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Base64,
      width: 100,
      height: 100,
    });
    if (image) {
      let imageUrl = `data:image/heic;base64,${image.base64String}`;
      setAvatar(imageUrl);
    } else return;
  };

  const sendEmailVerification = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('checkIfEmailExist', { email })
      .then(async (result) => {
        if (result.status === 'MEDIA') {
          setShowLoading(false);
          setShowExistingMediaUserAlert(true);
          setShowLoading(false);
          return false;
        }
        if (result.status === 'MULTIPLE') {
          setUserData(result.userData);
          setOtherRestaurants(result.rData);
          handleSwiperIndex(2.5);
          setShowLoading(false);
          return true;
        }
        if (result.status === 'PROCEED') {
          if (result.action === 'LOGIN') {
            setUserData(result.userData);
          }
          await Parse.Cloud.run('sendEmailVerification', { email })
            .then(() => {
              handleSwiperIndex(3);
              setShowLoading(false);
            })
            .catch((error) => {
              setShowLoading(false);
              console.log(error);
            });
        }
      })
      .catch((error) => {
        setShowLoading(false);
        console.log(error);
      });
  };

  const handleMultipleStores = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('sendEmailVerification', { email })
      .then(() => {
        handleSwiperIndex(3);
        setShowLoading(false);
      })
      .catch((error) => {
        setShowLoading(false);
        console.log(error);
      });
  };

  const checkEmailVerificationCode = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('checkEmailVerificationCode', { email, code: emailVerificationCode }).then((result) => {
      if (result) {
        setShowLoading(false);
        handleSwiperIndex(4);
      } else {
        setShowLoading(false);
        setErrorText('Verification code is incorrect');
        setShowErrorAlert(true);
      }
    });
  };

  useMemo(() => {
    if (firstName.length > 1 && lastName.length > 1 && role.length > 1 && email.length > 4 && password.length > 7 && password === confirmPassword) {
      setUserRegistrationFlag(false);
    }
  }, [firstName, lastName, role, email, password, confirmPassword]);

  useEffect(() => {
    if (user.id) {
      handleSwiperIndex(5);
    }
  }, [updateUser]);

  const signUp = async () => {
    if (password.toString().length < 8) {
      setErrorText('Error', 'Password Error');
      setShowErrorAlert(true);
      return;
    }
    if (password.toString() !== confirmPassword.toString()) {
      setErrorText('Error', 'Password does not match');
      setShowErrorAlert(true);
      return;
    }
    setShowLoading(true);
    await Parse.Cloud.run('restaurantUserSignUp', { email, password, avatar, firstName, lastName, role }).then(async (result) => {
      setShowLoading(false);
      if (result.code) {
        if (result.code === 202) {
          setErrorText('Account already exists for this email');
          return setShowErrorAlert(true);
        } else if (result.code === 400) {
          setErrorText('Please try again');
          return setShowErrorAlert(true);
        } else if (result.code === 142) {
          setErrorText('Password cannot contain your username');
          return setShowErrorAlert(true);
        } else if (result.code === 125) {
          setErrorText('Email address format is invalid');
          return setShowErrorAlert(true);
        } else {
          setErrorText('Please refresh browser and try again');
          return setShowErrorAlert(true);
        }
      } else {
        await Parse.User.logIn(email, password).then((USER) => {
          updateUser({
            id: USER.id,
            first_name: firstName,
            last_name: lastName,
            email: email,
            role: role,
            restaurant_id: '',
            restaurant_name: '',
            restaurant_logo: '',
          });
          handleSwiperIndex(5);
        });
      }
    });
  };

  const loginUser = async () => {
    setShowLoading(true);
    await Parse.User.logIn(email, password)
      .then(async (User) => {
        updateUser({
          id: User.id,
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: email,
          role: role,
          restaurant_id: '',
          restaurant_name: '',
          restaurant_logo: '',
        });
        handleSwiperIndex(5);
      })
      .catch((error) => {
        setShowLoading(false);
        if (error.code === 100) {
          setLoginAlertMessage('Please check your internet connection');
        }
        if (error.code === 101) {
          setLoginAlertMessage('Invalid email or Password');
          return setShowAlert(true);
        }
        if (error.code === 400 || error.code === 404) {
          setLoginAlertMessage('Invalid email or Password');
        }
      });
  };

  const yelpSearch = async () => {
    setShowLoading(true);
    if (!phoneNumber || phoneNumber.length !== 10) {
      setShowLoading(false);
      setErrorText('Phone Number must be 10 numbers');
      setShowErrorAlert(true);
      return false;
    }
    await Parse.Cloud.run('yelpSearch', { phone: String(phoneNumber), userId: user.id })
      .then((result) => {
        console.log(result);
        if (!result) {
          setErrorText('It seems the restaurant already has an account. Please ask the account holder to add you to the team.');
          setShowLoading(false);
          setShowErrorAlert(true);
          return false;
        } else if (result === 'no restaurant') {
          setErrorText('It seems the restaurant does not exist on yelp.');
          setShowLoading(false);
          setShowErrorAlert(true);
          handleSwiperIndex(5.25);
          return false;
        } else if (result === 'closed') {
          setErrorText('According to yelp information, this restaurant is closed. Please try to contact yelp.');
          setShowLoading(false);
          setShowErrorAlert(true);
        } else {
          setShowLoading(false);
          setRestaurant(result);
          handleSwiperIndex(6);
        }
      })
      .catch((error) => {
        setShowLoading(false);
        errorAlert('Error', error);
      });
  };

  const searchYelpRestaurantByName = async () => {
    await Parse.Cloud.run('searchYelpByTerms', { text: restaurantSearchWord }).then((results) => {
      if (!results) {
        console.log('No data');
        return false;
      }
      setYelpSearchedRestaurants(results);
      handleSwiperIndex(5.5);
    });
  };

  const openUrl = async (url) => {
    await Browser.open({ url });
  };

  const handleSelectYelpRestaurant = async (item) => {
    setRestaurant(item);
    handleSwiperIndex(6);
  };

  const sendUrl = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('handleManualRegistration', {
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      comment,
      website,
    })
      .then((result) => {
        setShowLoading(false);
        if (result) {
          setShowSuccessAlert(true);
        }
      })
      .catch((error) => {
        setShowLoading(false);
        console.log(error);
      });
  };

  const verifyPhoneNumber = async () => {
    await Parse.Cloud.run('verifyRestaurantNumber', { number: restaurant.phone }).then(() => {
      handleSwiperIndex(8);
    });
  };

  const verifyResendPhoneNumber = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('verifyRestaurantNumber', { number: restaurant.phone }).then(() => {
      setShowLoading(false);
    });
  };

  const verifyCode = async () => {
    if (code.length !== 5) {
      alert('Please input 5-digit number');
      return false;
    }
    setShowLoading(true);
    await Parse.Cloud.run('verifyCode', { code: code, userId: user.id, number: restaurant.phone })
      .then((result) => {
        if (result) {
          setShowLoading(true);
          onSaveRestaurant();
        } else {
          setShowLoading(false);
          return alert('Looks like you typed invalid code');
        }
      })
      .catch((error) => {
        setShowLoading(false);
        return alert(error);
      });
  };

  const handleLogOut = async () => {
    await Parse.User.logOut().then(() => {
      history.goBack();
    });
  };

  const onSaveRestaurant = async () => {
    await Parse.Cloud.run('saveRestaurant', { userId: user.id, userEmail: user.email, role, data: restaurant }).then((result) => {
      setShowLoading(false);
      if (result.rId) {
        updateUser({
          ...user,
          restaurant_id: result.rId,
          restaurant_name: result.rName,
          restaurant_logo: '',
        });
        updateIsOnboarding(false);
        history.push(`/dashboard/store/${result.rId}`);
        return;
      } else {
        return errorAlert('Error', 'Something went wrong');
      }
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonRow>
          <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='8' offset-md='2' size-lg='6' offset-lg='3'>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonBackButton text='HOME'></IonBackButton>
              </IonButtons>
              <IonTitle>Welcome to ono online</IonTitle>
            </IonToolbar>
          </IonCol>
        </IonRow>
      </IonHeader>
      <IonContent className='ion-padding'>
        {swiperIndex === 0 && (
          <div style={{ width: '100%', height: '100%' }}>
            <IonGrid>
              <IonRow className='ion-align-items-center'>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='5' offset-lg='3.5' className='ion-no-padding'>
                  <div className='ion-text-start' style={{ marginBottom: '2em' }}>
                    <h1 className='ion-margin-bottom' style={{ fontFamily: 'BrandonBold', fontSize: '10vh' }}>
                      <IonImg src={'assets/images/ono.png'} style={{ width: '10vh', height: '10vh', borderRadius: '1.5em', overflow: 'hidden' }} />
                    </h1>
                    <h1 className='ion-margin-bottom' style={{ fontFamily: 'BrandonBold', fontSize: '2em' }}>
                      Mahalo for your interests
                    </h1>
                    <p className='subtitle' style={{ marginTop: '2em', marginBottom: '1em' }}>
                      Only three steps to register your restaurant in our marketplace! <br />
                      <br />
                      <span style={{ fontSize: '1.25em', fontWeight: '800' }}>1.</span> Enter your information. <br />
                      <span style={{ fontSize: '1.25em', fontWeight: '800' }}>2.</span> Confirm the restaurant, shop, or eatery with a phone call.
                      <br />
                      <span style={{ fontSize: '1.25em', fontWeight: '800' }}>3.</span> Upload your verification code.
                      <br />
                      <br />
                      After completing registration, please give us at least five business days to input your restaurant info.
                    </p>
                    <p>
                      <IonButton
                        expand={'full'}
                        style={{ fontFamily: 'BrandonBold', marginTop: '3em', color: 'white', width: '60%' }}
                        strong
                        size={'large'}
                        onClick={handleCheckCurrentUser}>
                        Next
                      </IonButton>
                    </p>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>

            {/* </IonContent> */}
          </div>
        )}
        {swiperIndex === 1 && (
          <div style={{ width: '100%', height: '100%' }}>
            <IonGrid>
              <IonRow className='ion-align-items-center'>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4' className='ion-no-padding'>
                  <h1>
                    <IonIcon icon={storefrontOutline} size='large' />
                  </h1>
                  <h1 className='ion-margin-bottom' style={{ fontFamily: 'BrandonBold', fontSize: '2em', lineHeight: '1.5em' }}>
                    Does your restaurant already have an ono online account?
                  </h1>

                  <div style={{ marginTop: '2em' }}>
                    <p>
                      <IonButton size='large' style={{ fontFamily: 'BrandonBold' }} onClick={() => handleSwiperIndex(2)}>
                        Create account
                      </IonButton>
                    </p>
                    <p style={{ marginTop: '2em' }}>
                      <IonButton fill='clear' color='dark' onClick={() => setShowAlert(true)}>
                        Yes, we already have an account
                      </IonButton>
                    </p>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonAlert
              isOpen={showAlert}
              onDidDismiss={() => setShowAlert(false)}
              cssClass='alert'
              header={'Thank you for joining us'}
              subHeader={''}
              message={'Please ask an account owner to add your account to your restaurant. Then try to log in again.'}
              buttons={['OK']}
            />
          </div>
        )}
        {swiperIndex === 2 && (
          <div style={{ width: '100%', height: '100%' }}>
            <IonGrid>
              <IonRow className='ion-align-items-center'>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4' className='ion-no-padding'>
                  <h1>
                    <IonIcon icon={mailOutline} size='large' />
                  </h1>
                  <h1 className='ion-margin-bottom' style={{ fontFamily: 'BrandonBold', fontSize: '2em' }}>
                    Please enter your email
                  </h1>
                  <p style={{ marginTop: '2em' }}>We will send you a six-digit verification code when you submit your email.</p>
                  <IonItem className='ion-margin-top ion-no-padding inputItem' lines='none' style={{ width: '100%' }}>
                    <IonLabel className='text-white' position='stacked'>
                      Email
                    </IonLabel>
                    <IonInput
                      className='register-input'
                      inputmode='email'
                      pattern='^([a-zA-Z0-9]{3,})$'
                      placeholder=''
                      min='3'
                      value={email}
                      onIonInput={(e) => setEmail(e.target.value)}
                      required
                      style={{ width: '100%' }}
                    />
                  </IonItem>
                  <p>
                    <IonLabel style={{ fontFamily: 'BrandonBold', fontSize: '10px' }}>
                      This email address will be used for logging in and receiving details about orders when you accept orders online.
                    </IonLabel>
                  </p>
                  <IonButton
                    style={{ fontFamily: 'BrandonBold', marginTop: '1em' }}
                    size='large'
                    disabled={!email.length || email.indexOf('@') === -1}
                    onClick={sendEmailVerification}>
                    Next
                  </IonButton>
                  <p style={{ marginTop: '2em' }}>
                    <IonButton fill='clear' style={{ color: 'white' }} onClick={() => handleSwiperIndex(1)}>
                      <IonIcon slot='start' icon={arrowBackOutline} color='white' />
                      BACK
                    </IonButton>
                  </p>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonAlert
              isOpen={showExistingMediaUserAlert}
              onDidDismiss={() => setShowExistingMediaUserAlert(false)}
              cssClass='alert'
              header={'Woops'}
              subHeader={''}
              message={'You are registered as a media entity. If you wish to register as a restaurant, please use a different email address.'}
              buttons={['OK']}
            />
          </div>
        )}
        {swiperIndex === 2.5 && (
          <div style={{ width: '100%', height: '100%' }}>
            <IonGrid>
              <IonRow className='ion-align-items-center'>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4' className='ion-no-padding'>
                  <h1>
                    <IonIcon icon={alertCircleOutline} style={{ width: '7vh', height: '7vh' }} />
                  </h1>
                  <h1 className='ion-margin-bottom'>
                    It appears that the following restaurants are associated with your email. Would you like to add another new restaurant?
                  </h1>
                  {otherRestaurants.length === 0
                    ? null
                    : otherRestaurants.map((item, index) => (
                        <IonItem key={index}>
                          <IonLabel style={{ fontFamily: 'BrandonBold', marginTop: '1em' }}>{item.name}</IonLabel>
                        </IonItem>
                      ))}
                  <IonRow className='ion-margin-top'>
                    <IonCol>
                      <IonButton
                        fill='outline'
                        style={{ fontFamily: 'BrandonBold', marginTop: '1em' }}
                        size='large'
                        onClick={() => handleSwiperIndex(2)}>
                        No
                      </IonButton>
                    </IonCol>
                    <IonCol>
                      <IonButton style={{ fontFamily: 'BrandonBold', marginTop: '1em' }} size='large' onClick={handleMultipleStores}>
                        Yes
                      </IonButton>
                    </IonCol>
                  </IonRow>

                  <p style={{ marginTop: '2em' }}>
                    <IonButton fill='clear' style={{ color: 'white' }} onClick={() => handleSwiperIndex(1)}>
                      <IonIcon slot='start' icon={arrowBackOutline} color='white' />
                      BACK
                    </IonButton>
                  </p>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonAlert
              isOpen={showExistingMediaUserAlert}
              onDidDismiss={() => setShowExistingMediaUserAlert(false)}
              cssClass='alert'
              header={'Woops'}
              subHeader={''}
              message={'You are registered as a media entity. If you wish to register as a restaurant, please use a different email address.'}
              buttons={['OK']}
            />
          </div>
        )}
        {swiperIndex === 3 ? (
          <div style={{ width: '100%', height: '100%' }}>
            <IonGrid>
              <IonRow className='ion-align-items-center ion-padding'>
                <IonCol size-xs='12' size-sm='8' size-md='6' size-lg='4' className='ion-align-self-center'>
                  <h5>We have sent a code to your email address</h5>
                  <h1>Please enter 6 digit number</h1>
                  <p style={{ marginTop: '2em' }}>Enter Code below</p>
                  <IonToolbar className='customStyles'>
                    <ReactInputVerificationCode
                      autoFocus={true}
                      length={6}
                      value={emailVerificationCode}
                      onChange={(e) => setEmailVerificationCode(e)}
                    />
                  </IonToolbar>
                  <IonRow className='ion-no-padding' style={{ marginTop: '2em' }}>
                    <IonCol className='ion-no-padding'>
                      <IonButton
                        style={{ fontFamily: 'BrandonBold' }}
                        size='large'
                        disabled={emailVerificationCode.length !== 6}
                        fill='solid'
                        onClick={checkEmailVerificationCode}>
                        Submit
                      </IonButton>
                    </IonCol>
                    <IonCol className='ion-align-self-center'>
                      <IonLabel
                        color='danger'
                        onClick={() => {
                          handleMultipleStores();
                          setEmailVerificationCode('');
                        }}>
                        Resend Code
                      </IonLabel>
                    </IonCol>
                  </IonRow>

                  {/* <IonButton fill='clear' style={{ color: 'white' }} onClick={() => setSwiperIndex(4)}>
                      <IonIcon slot='start' icon={arrowBackOutline} color='white' />
                      BACK
                    </IonButton> */}
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        ) : null}
        {swiperIndex === 4 ? (
          <div style={{ width: '100%' }}>
            {/* <IonContent className='ion-padding'> */}
            <IonGrid style={{ marginBottom: '120px' }}>
              <IonRow className='ion-align-items-center ion-padding'>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4' className='ion-no-padding'>
                  <h1 className='ion-text-start'>{!userData ? `Let's create new account` : `Thank you again!`}</h1>
                  <h5 className='ion-text-start'>
                    <IonText className='subtitle'>
                      {!userData ? `Please let us know who you are` : `Please choose your role for the restaurant you are about to register`}
                    </IonText>
                  </h5>
                  <IonItem className='ion-margin-vertical ion-no-padding inputItem' lines='none' style={{ width: '100%' }}>
                    <IonLabel className='text-white' position='stacked'>
                      First Name
                    </IonLabel>
                    <IonInput
                      className='register-input'
                      pattern='^([a-zA-Z0-9]{3,})$'
                      placeholder=''
                      value={userData ? userData.first_name : firstName}
                      onIonInput={(e) => setFirstName(e.target.value)}
                      disabled={userData}
                      required
                    />
                  </IonItem>
                  <IonItem className='ion-margin-vertical ion-no-padding inputItem' lines='none' style={{ width: '100%' }}>
                    <IonLabel className='text-white' position='stacked'>
                      Last Name
                    </IonLabel>
                    <IonInput
                      className='register-input'
                      pattern='^([a-zA-Z0-9]{3,})$'
                      placeholder=''
                      value={userData ? userData.last_name : lastName}
                      onIonInput={(e) => setLastName(e.target.value)}
                      required
                      disabled={userData}
                      style={{ width: '100%' }}
                    />
                  </IonItem>

                  <IonItem className='ion-margin-vertical ion-no-padding inputItem' lines='none' style={{ width: '100%' }}>
                    <IonLabel className='text-white' position='stacked'>
                      Role
                    </IonLabel>
                    <IonSelect className='register-input' interface='action-sheet' value={role} onIonInput={(e) => setRole(e.target.value)}>
                      <IonSelectOption className='text select-option' style={{ color: 'white' }} value='Owner'>
                        Owner
                      </IonSelectOption>
                      <IonSelectOption value='Chef' style={{ color: 'white' }}>
                        Chef
                      </IonSelectOption>
                      <IonSelectOption value='Kitchen Manager' style={{ color: 'white' }}>
                        Kitchen Manager
                      </IonSelectOption>
                      <IonSelectOption value='Manager' style={{ color: 'white' }}>
                        Manager
                      </IonSelectOption>
                      <IonSelectOption value='Marketing' style={{ color: 'white' }}>
                        Marketing
                      </IonSelectOption>
                      <IonSelectOption value='IT' style={{ color: 'white' }}>
                        IT
                      </IonSelectOption>
                      <IonSelectOption value='Cashier' style={{ color: 'white' }}>
                        Cashier
                      </IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  <IonItem className='ion-margin-top ion-no-padding inputItem' lines='none' style={{ width: '100%' }}>
                    <IonLabel className='text-white' position='stacked'>
                      Email
                    </IonLabel>
                    <IonInput
                      className='register-input'
                      inputmode='email'
                      pattern='^([a-zA-Z0-9]{3,})$'
                      placeholder=''
                      min='3'
                      value={email}
                      onIonInput={(e) => setEmail(e.target.value)}
                      disabled={true}
                      style={{ width: '100%' }}
                    />
                  </IonItem>

                  <IonItem className='ion-margin-top ion-no-padding inputItem' lines='none' style={{ width: '100%' }}>
                    <IonLabel className='text-white' position='stacked'>
                      Password - At least 8 characters
                    </IonLabel>
                    <IonInput
                      className='register-input'
                      type='password'
                      minlength={8}
                      placeholder=''
                      value={password}
                      onIonInput={(e) => setPassword(e.target.value)}
                      required
                    />
                  </IonItem>
                  {loginAlertMessage.length ? <IonLabel style={{ color: 'red', fontSize: '1.5vh' }}>{loginAlertMessage}</IonLabel> : null}
                  <br />
                  <IonLabel className='ion-margin-top' style={{ fontSize: '1.5vh' }}>
                    At lease 8 characters which contain at least one numeric digit, one uppercase and one lowercase letter
                  </IonLabel>
                  {!userData ? (
                    <IonItem className='ion-margin-vertical ion-no-padding inputItem' lines='none' style={{ width: '100%' }}>
                      <IonLabel position='stacked'>Confirm password</IonLabel>
                      <IonInput
                        className='register-input'
                        type='password'
                        minlength={8}
                        placeholder=''
                        value={confirmPassword}
                        onIonInput={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                    </IonItem>
                  ) : null}
                  {!userData ? (
                    <IonButton
                      disabled={userRegistrationFlag}
                      size='large'
                      fill='solid'
                      expand='block'
                      style={{ fontFamily: 'BrandonBold', height: '4em', marginTop: '2em' }}
                      onClick={signUp}>
                      Submit
                    </IonButton>
                  ) : (
                    <IonButton
                      disabled={role.length === 0 ? true : password.length < 8 ? true : false}
                      size='large'
                      fill='solid'
                      expand='block'
                      style={{ fontFamily: 'BrandonBold', height: '4em', marginTop: '2em' }}
                      onClick={loginUser}>
                      Submit
                    </IonButton>
                  )}
                  <IonButton fill='clear' onClick={() => handleSwiperIndex(3)}>
                    <IonIcon slot='start' icon={arrowBackOutline} color='white' />
                    BACK
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
            {/* </IonContent> */}
          </div>
        ) : null}
        {swiperIndex === 5 ? (
          <div style={{ width: '100%', height: '100%' }}>
            <IonGrid>
              <IonRow className='ion-align-items-center'>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4'>
                  <h3 className='vh300 bold' style={{ color: '#FC0FC0' }}>
                    Aloha {user.first_name}
                  </h3>
                  <h3 className='vh200'>Thank you for joining us.</h3>
                  <h1 className='vh400 bold'>Please enter your restaurant phone number</h1>
                  <p>Please note that only 10-digit numbers are acceptable.</p>
                  <p style={{ marginTop: '2em' }}>
                    <IonInput
                      style={{ borderColor: 'grey', borderWidth: '1px', fontSize: '5vh' }}
                      value={phoneNumber}
                      placeholder='808xxxxxxx'
                      onIonInput={(e) => setPhoneNumber(e.target.value)}
                      type='tel'
                    />
                  </p>
                  <p style={{ marginTop: '2em' }}>
                    <IonButton
                      style={{ fontFamily: 'BrandonBold' }}
                      size='large'
                      disabled={phoneNumber.length !== 10}
                      fill='solid'
                      expand='block'
                      onClick={yelpSearch}>
                      Submit
                    </IonButton>
                  </p>
                  <p style={{ marginTop: '2em', textAlign: 'center' }}>
                    <IonLabel className='vh200'>
                      Phone Number Search by{'  '}
                      <span style={{ backgroundColor: 'red', paddingLeft: '4px', paddingRight: '4px' }}>
                        <img style={{ width: '6vh' }} src={'assets/images/yelp_logo_dark_bg.png'} alt='yelp' />
                      </span>
                    </IonLabel>
                  </p>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        ) : null}
        {swiperIndex === 5.25 ? (
          <div style={{ width: '100%', height: '100%' }}>
            <IonGrid>
              <IonRow className='ion-align-items-center'>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4'>
                  <h3 className='vh300 bold' style={{ color: '#FC0FC0' }}>
                    Let's try different way to search your restaurant
                  </h3>
                  <h3 className='vh300 bold'>1. Search by restaurant name</h3>
                  <IonLabel>New establishments not listed on Yelp will not be displayed. We will assist you with the input using method 2.</IonLabel>
                  <IonItem className='ion-no-padding' style={{ marginTop: '1em' }}>
                    <IonLabel position='stacked' className='bold'>
                      Please enter your restaurant name
                    </IonLabel>
                    <IonInput
                      style={{ borderColor: 'grey', borderWidth: '1px', fontSize: '3vh' }}
                      value={restaurantSearchWord}
                      placeholder='Restaurant name'
                      onIonInput={(e) => setRestaurantSearchWord(e.target.value)}
                      type='text'
                    />
                  </IonItem>
                  <IonButton
                    className='ion-margin-vertical'
                    style={{ fontFamily: 'BrandonBold' }}
                    disabled={!restaurantSearchWord.length}
                    fill='solid'
                    onClick={searchYelpRestaurantByName}>
                    Submit
                  </IonButton>

                  <h3 className='vh300 bold'>2. We will manually register your restaurant info</h3>
                  <IonItem className='ion-no-padding' style={{ marginTop: '1em' }}>
                    <IonLabel position='stacked' className='bold'>
                      Please enter website URL
                    </IonLabel>
                    <IonInput
                      style={{ borderColor: 'grey', borderWidth: '1px', fontSize: '3vh' }}
                      value={website}
                      placeholder='Your website URL'
                      onIonInput={(e) => setWebsite(e.target.value)}
                      type='url'
                    />
                  </IonItem>
                  <IonButton
                    className='ion-margin-vertical'
                    style={{ fontFamily: 'BrandonBold' }}
                    disabled={!website.length}
                    fill='solid'
                    onClick={() => handleSwiperIndex(5.75)}>
                    Submit
                  </IonButton>
                  <br />
                  <IonButton fill='clear' onClick={() => handleSwiperIndex(5)}>
                    <IonIcon slot='start' icon={arrowBackOutline} />
                    BACK
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        ) : null}
        {swiperIndex === 5.5 ? (
          <div style={{ width: '100%', height: '100%' }}>
            <IonGrid>
              <IonRow className='ion-align-items-center'>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4'>
                  <h3 className='vh300 bold' style={{ color: '#FC0FC0' }}>
                    Is your restaurant on the list?
                  </h3>
                  {!yelpSearchedRestaurants.length ? (
                    <h1>Unfortunately, there is no restaurant</h1>
                  ) : (
                    <IonRadioGroup>
                      {yelpSearchedRestaurants.map((item, index) => (
                        <IonItem key={index} className='ion-no-padding' onClick={() => handleSelectYelpRestaurant(item)}>
                          <IonRadio slot='start' />
                          <IonLabel>
                            <h3>{item.name}</h3>
                            <p>{item.location.display_address}</p>
                          </IonLabel>
                        </IonItem>
                      ))}
                    </IonRadioGroup>
                  )}
                  <IonButton className='ion-margin-vertical' style={{ fontFamily: 'Brandon' }} fill='solid' onClick={() => handleSwiperIndex(5.25)}>
                    Can not find my venue
                  </IonButton>
                  <br />
                  <IonButton fill='clear' onClick={() => handleSwiperIndex(5.25)}>
                    <IonIcon slot='start' icon={arrowBackOutline} />
                    BACK
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        ) : null}
        {swiperIndex === 5.75 ? (
          <div style={{ width: '100%', height: '100%' }}>
            <IonGrid>
              <IonRow className='ion-align-items-center'>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4'>
                  <h3 className='vh300 bold' style={{ color: '#FC0FC0' }}>
                    Thank you for allowing us to assist you with the registration.
                  </h3>
                  <IonLabel>
                    We will reference the URL you provided to register your restaurant's information. Please allow us approximately 7 business days to
                    complete the registration. We will contact you via email after the registration is done.
                  </IonLabel>
                  <IonItem className='ion-no-padding' style={{ marginTop: '1em' }}>
                    <IonLabel position='stacked' className='bold'>
                      Contact email
                    </IonLabel>
                    <h1 style={{ borderColor: 'grey', borderButtomWidth: '1px', fontSize: '2.5vh' }}>{user.email}</h1>
                  </IonItem>
                  <IonItem className='ion-no-padding' style={{ marginTop: '1em' }}>
                    <IonLabel position='stacked' className='bold'>
                      URL
                    </IonLabel>
                    <h1 style={{ borderColor: 'grey', borderButtomWidth: '1px', fontSize: '2.5vh' }}>{website}</h1>
                  </IonItem>
                  <IonItem className='ion-no-padding' style={{ marginTop: '1em' }}>
                    <IonTextarea
                      labelPlacement='fixed'
                      placeholder='This is a new restaurant in Honolulu'
                      autoGrow
                      fill='outline'
                      value={comment}
                      onIonInput={(e) => setComment(e.target.value)}>
                      <div slot='label'>Something we need to know?</div>
                    </IonTextarea>
                  </IonItem>
                  <br />
                  <IonButton className='ion-margin-vertical' style={{ fontFamily: 'BrandonBold' }} fill='solid' onClick={sendUrl}>
                    Confirm
                  </IonButton>

                  <br />
                  <IonButton fill='clear' onClick={() => handleSwiperIndex(5)}>
                    <IonIcon slot='start' icon={arrowBackOutline} />
                    BACK
                  </IonButton>
                  <IonAlert
                    isOpen={showSuccessAlert}
                    onDidDismiss={() => setShowSuccessAlert(false)}
                    cssClass='alert'
                    header={'Thank you for joining us'}
                    subHeader={''}
                    message={'Please wait a moment until you receive the completion email'}
                    buttons={[
                      {
                        text: 'OK',
                        role: 'confirm',
                        handler: () => {
                          handleLogOut();
                        },
                      },
                    ]}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        ) : null}
        {swiperIndex === 6 ? (
          <div style={{ height: '100%' }}>
            {!restaurant ? null : (
              <IonGrid>
                <IonRow className='ion-align-items-center'>
                  <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='8' offset-md='2' size-lg='6' offset-lg='3' className='ion-no-padding'>
                    <div className='ion-margin-start'>
                      <h1>Nice!</h1>
                      <IonLabel>You will be able to change categories later.</IonLabel>
                    </div>
                    <IonCard className='card-container ion-padding' style={{ marginTop: '2em' }}>
                      <IonInput
                        className='ion-no-padding rNameInput'
                        value={restaurant.name}
                        onIonInput={(e) => setRestaurant({ ...restaurant, name: e.target.value })}
                      />
                      <div className='ion-margin-top'>
                        {!restaurant.categories
                          ? null
                          : restaurant.categories.map((item) => (
                              <IonLabel key={nanoid()} className='ion-margin-end 250vh'>
                                {item.title}
                              </IonLabel>
                            ))}
                      </div>
                      <p>
                        <IonLabel className='300vh'>{restaurant.location.address1} </IonLabel>
                        {!restaurant.location.address2 ? null : <IonLabel className='300vh'>{restaurant.location.address2} </IonLabel>}
                        <IonLabel className='300vh'>{restaurant.location.address3}, </IonLabel>
                        <IonLabel className='300vh'>{restaurant.location.city}, </IonLabel>
                        <IonLabel className='300vh'>{restaurant.location.state} </IonLabel>
                        <IonLabel className='300vh'>{restaurant.location.zip_code}</IonLabel>
                      </p>
                      <p>
                        <IonLabel className='350vh'>{restaurant.display_phone}</IonLabel>
                      </p>
                      <IonItem className='ion-no-padding'>
                        <IonCheckbox className='checkbox' slot='start' value={termCheckbox1} onIonInput={() => setTermCheckbox1(!termCheckbox1)} />
                        <IonLabel className='ion-wrap vh175'>
                          "ono online" is only for food services.
                          <br />I confirm that I will use this app for restaurant or food services.
                        </IonLabel>
                      </IonItem>
                      <IonItem className='ion-no-padding'>
                        <IonCheckbox slot='start' className='checkbox' value={termCheckbox2} onIonInput={() => setTermCheckbox2(!termCheckbox2)} />
                        <IonLabel className='ion-wrap vh175'>
                          I agree to the{' '}
                          <IonLabel className='vh175' style={{ color: 'blue' }} onClick={() => openUrl('https://join.onoonline.app/termsofuse')}>
                            Terms of Use
                          </IonLabel>
                        </IonLabel>
                      </IonItem>
                      <div className='ion-margin-top ion-margin-bottom'>
                        {/* <IonButton className='ion-margin-start ion-margin-end' fill='outline' onClick={() => history.replace('/')}>
                          Cancel
                        </IonButton> */}
                        <IonButton
                          size='large'
                          expand='block'
                          onClick={() => handleSwiperIndex(7)}
                          disabled={termCheckbox1 && termCheckbox2 ? false : true}>
                          Confirm
                        </IonButton>
                      </div>
                    </IonCard>
                  </IonCol>
                </IonRow>
              </IonGrid>
            )}
          </div>
        ) : null}
        {swiperIndex === 7 ? (
          <div style={{ height: '100%' }}>
            <IonGrid>
              <IonRow className='ion-align-items-center'>
                <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='8' offset-md='2' size-lg='6' offset-lg='3' className='ion-no-padding'>
                  <div className='card-container'>
                    <h1 className='bold' style={{ fontSize: '1.75em' }}>
                      Last Step!!
                    </h1>
                    <IonLabel>When you press the button below, the code will be immediately notified to you via phone call.</IonLabel>
                    <div className='ion-margin-top'>
                      <IonLabel>Please input the 5-digit code on the next screen.</IonLabel>
                    </div>
                    <h1>{restaurant ? restaurant.phone : ''}</h1>
                    <div className='ion-margin-vertical'>
                      <IonLabel>Are you ready to take a call?</IonLabel>
                    </div>
                    <IonButton
                      style={{ marginTop: '2em' }}
                      size='large'
                      expand='block'
                      onClick={verifyPhoneNumber}
                      // onClick={() => handleSwiperIndex(8)}
                    >
                      I am ready
                    </IonButton>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        ) : null}
        {swiperIndex === 8 && user.id ? (
          <div style={{ height: '100%' }}>
            <IonGrid>
              <IonRow className='ion-align-items-center'>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='6' offset-lg='3' className='ion-no-padding'>
                  <h1>Please type 5-digit number</h1>
                  <IonLabel>Enter Code below</IonLabel>
                  <IonToolbar className='customStyles' style={{ margin: '2em 0' }}>
                    <ReactInputVerificationCode autoFocus={true} length={5} value={code} onChange={(e) => setCode(e)} />
                  </IonToolbar>
                  <div style={{ marginTop: '2em', marginBottom: '1em' }}>
                    <IonButton size='large' expand='block' disabled={code.length !== 5} fill='solid' onClick={verifyCode}>
                      Submit
                    </IonButton>
                  </div>
                  <IonLabel color='danger' onClick={verifyResendPhoneNumber}>
                    Resend Code
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        ) : null}

        <IonAlert
          isOpen={showErrorAlert}
          onDidDismiss={() => setShowErrorAlert(false)}
          cssClass='errorAlert'
          header={'Woops'}
          subHeader={errorText}
          message={''}
          buttons={['OK']}
        />
        <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} />
      </IonContent>
    </IonPage>
  );
};
export default SignupPage;
