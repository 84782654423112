import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Parse from 'parse/dist/parse.min.js';
import { IonButton, IonContent, IonPage, IonGrid, IonRow, IonCol, IonText } from '@ionic/react';
import { Browser } from '@capacitor/browser';
import AppContext from '../context/AppContext';

const InstagramAuth = () => {
  const { platform } = useContext(AppContext);
  const history = useHistory();

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const rId = urlParams.get('state');
  const error = urlParams.get('error');
  const currentURL = window.location.href;

  useEffect(() => {
    if (error) {
      console.log(error);
    }
    if (code && rId) {
      Parse.Cloud.run('instagramLoginFlowFromWeb', { code, rId }).then(async (result) => {
        console.log(result);
        if (result.username) {
          await Parse.Cloud.run('saveLinkedServices', {
            rId,
            data: JSON.stringify({
              instagram: { user_id: result.userId, access_token: result.longLivedAccessToken, username: result.username },
            }),
          })
            .then(() => {
              console.log(currentURL);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
      // exchangeCodeForAccessToken(code);
    }
  }, []);

  const handleCloseBrowser = async () => {
    window.open('onoonlinemanager://', '_blank');
    // await Browser.close();
  };

  // const exchangeCodeForAccessToken = (code) => {
  //   fetch('https://api.instagram.com/oauth/access_token', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //     body: `client_id=${clientId}&client_secret=${clientSecret}&grant_type=authorization_code&redirect_uri=${redirectUri}&code=${code}`,
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data.access_token);
  //       const shortLivedToken = data.access_token;
  //       exchangeForLongLivedToken(shortLivedToken);
  //     });
  // };

  // const exchangeForLongLivedToken = (shortLivedToken) => {
  //   fetch(`https://graph.instagram.com/access_token?grant_type=ig_exchange_token&client_secret=${clientSecret}&access_token=${shortLivedToken}`, {
  //     method: 'GET',
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data.access_token);
  //       const longLivedToken = data.access_token;
  //       getUserInfo(longLivedToken);
  //       getUserRecentMedia(longLivedToken);
  //     });
  // };

  // const getUserInfo = (accessToken) => {
  //   fetch(`https://graph.instagram.com/me?fields=id,username&access_token=${accessToken}`, {
  //     method: 'GET',
  //   })
  //     .then((response) => response.json())
  //     .then(async (data) => {
  //       await Parse.Cloud.run('saveLinkedServices', {
  //         rId: user.restaurant_id,
  //         data: JSON.stringify({
  //           instagram: { user_id: data.id, access_token: accessToken, username: data.username },
  //         }),
  //       })
  //         .then(() => {
  //           setRestaurant({ ...restaurant, instagram: data.username });
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     });
  // };

  //   const getUserRecentMedia = (accessToken) => {
  //     fetch(`https://graph.instagram.com/me/media?fields=id,caption,media_url,permalink&limit=50&access_token=${accessToken}`, {
  //       method: 'GET',
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         console.log('Recent Media:', data.data);
  //       });
  //   };
  return (
    <IonPage>
      <IonContent className='ion-padding'>
        <IonGrid style={{ height: '100%' }}>
          <IonRow style={{ height: '100%' }}>
            <IonCol className='ion-align-self-center'>
              <div className='ion-text-center'>
                <h5 className='bold vh250'>Nice work!</h5>
                {platform.includes('desktop') || platform.includes('mobileweb') ? (
                  <IonText className='vh175'>Please press Done button below. You will return to the original page.</IonText>
                ) : (
                  <IonText className='vh175'>
                    When you press the 'Done' button below and return to the original page, please pull down the screen to refresh the information.
                  </IonText>
                )}
                <br />
                {platform.includes('desktop') || platform.includes('mobileweb') ? (
                  <IonButton
                    style={{ marginTop: '2em' }}
                    onClick={() =>
                      currentURL.indexOf('/dashboard/') > -1
                        ? history.replace(`/dashboard/store/${rId}`)
                        : history.replace(`/onboarding/store/${rId}`)
                    }>
                    Done
                  </IonButton>
                ) : (
                  <IonButton style={{ marginTop: '2em' }} onClick={handleCloseBrowser}>
                    Done
                  </IonButton>
                )}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default InstagramAuth;
