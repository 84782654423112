import React, { useCallback, useState } from 'react';

export const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
  const [isAuthed, setIsAuthed] = useState(false);
  const updateIsAuthed = (value) => {
    setIsAuthed(value);
  };

  const [isAdmin, setIsAdmin] = useState(false);
  const updateIsAdmin = (value) => {
    setIsAdmin(value);
  };

  const [isOnboarding, setIsOnboarding] = useState(true);
  const updateIsOnboarding = (value) => {
    setIsOnboarding(value);
  };

  const [landingPage, setLandingPage] = useState(null);
  const updateLandingPage = (value) => {
    setLandingPage(value);
  };

  const [currentPage, setCurrentPage] = useState(null);
  const updateCurrentPage = (url) => {
    setCurrentPage(url);
  };

  const [worker, setWorker] = useState();
  const updateWorker = (val) => {
    setWorker(val);
  };

  const [user, setUser] = useState({
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    avatar: '',
    role: '',
    restaurant_id: '',
    restaurant_name: '',
    restaurant_logo: '',
    coordinates: '',
  });

  const updateUser = (data) => {
    setUser({ ...data });
  };

  const clearUser = () => {
    setUser({
      id: '',
      first_name: '',
      last_name: '',
      email: '',
      avatar: '',
      role: '',
      restaurant_id: '',
      restaurant_name: '',
      restaurant_logo: '',
      coordinates: '',
    });
  };

  const [admin, setAdmin] = useState({
    id: '',
    rId: '',
    rName: '',
  });
  const updateAdmin = (value) => {
    setAdmin({ ...admin, ...value });
  };

  const [orders, setOrders] = useState([]);
  const updateOrders = (array) => {
    setOrders(() => {
      return [...array];
    });
  };

  const [orderBadge, setOrderBadge] = useState(null);
  const updateOrderBadge = (val) => {
    setOrderBadge(val);
  };

  const [myNotifications, setMyNotifications] = useState([]);
  const [notify, setNotify] = useState(false);

  const [deviceWidth, setDeviceWidth] = useState();
  const updateDeviceWidth = (value) => {
    setDeviceWidth(value);
  };

  const [platform, setPlatform] = useState([]);
  const updatePlatform = (value) => {
    setPlatform([...value]);
  };

  let state = {
    isAuthed,
    setIsAuthed,
    updateIsAuthed,
    isAdmin,
    setIsAdmin,
    updateIsAdmin,
    isOnboarding,
    setIsOnboarding,
    updateIsOnboarding,
    landingPage,
    setLandingPage,
    updateLandingPage,
    currentPage,
    setCurrentPage,
    updateCurrentPage,
    worker,
    setWorker,
    updateWorker,
    user,
    setUser,
    updateUser,
    clearUser,
    admin,
    setAdmin,
    updateAdmin,
    orders,
    setOrders,
    updateOrders,
    orderBadge,
    setOrderBadge,
    updateOrderBadge,
    myNotifications,
    setMyNotifications,
    notify,
    setNotify,
    deviceWidth,
    setDeviceWidth,
    updateDeviceWidth,
    platform,
    setPlatform,
    updatePlatform,
  };
  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
};

export default AppContext;
