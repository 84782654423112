import React, { useMemo, useEffect } from 'react';
import {
  IonAvatar,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonImg,
  IonText,
  IonPage,
  IonBackButton,
} from '@ionic/react';
import useWindowDimensions from '../../hook/useWindowDimensions';

const AboutUsPage = () => {
  const { height, width } = useWindowDimensions();
  // console.log(width);

  return (
    <IonPage>
      <IonHeader>
        <IonRow>
          <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' offset-md='1' size-lg='8' offset-lg='2'>
            <IonToolbar className='ion-justify-content-center'>
              <IonButtons slot='start'>
                <IonBackButton />
              </IonButtons>
              <IonTitle style={{ fontSize: '1em', fontFamily: 'BrandonBold' }}>About us</IonTitle>
              <IonButtons slot='end'>
                <IonButton style={{ backgroundColor: '#FF007A', color: 'white' }} onClick={() => window.open('https://join.onoonline.app/')}>
                  Restaurant portal
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonCol>
        </IonRow>
      </IonHeader>

      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' offset-md='1' size-lg='8' offset-lg='2'>
              <section>
                {width > 650 ? (
                  <div style={{ position: 'relative' }}>
                    <video
                      autoPlay={'autoplay'}
                      muted={true}
                      loop={true}
                      src={'https://res.cloudinary.com/dn2gcd1cf/video/upload/f_auto:video,q_auto/about_top_video_xqffjh'}
                      type='video/mp4'
                      playsInLine='playsinline'
                      width='100%'
                    />

                    <div
                      className='ion-padding'
                      style={{ backgroundColor: 'rgba(0,0,0,0.2)', position: 'absolute', right: '20px', top: '20px', color: 'white' }}>
                      <IonText style={{ fontSize: '5vh', fontWeight: 'bolder' }}>Our Mission</IonText>
                      <br />
                      <div className='ion-margin-vertical'>
                        <IonText style={{ fontSize: '4vh' }}>
                          Provide <br />
                          <IonText style={{ color: '#FC0FC0', fontWeight: 'bolder' }}>Equal marketing opportunity</IonText> <br />
                          to all restaurants in Hawaii
                        </IonText>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <video
                      autoPlay={'autoplay'}
                      muted={true}
                      loop={true}
                      src={'https://res.cloudinary.com/dn2gcd1cf/video/upload/f_auto:video,q_auto/about_top_video_xqffjh'}
                      type='video/mp4'
                      playsInLine='playsinline'
                      width='100%'
                    />
                    <div className='ion-padding'>
                      <IonText style={{ fontSize: '5vh', fontWeight: 'bolder' }}>Our Mission</IonText>
                      <br />
                      <div className='ion-margin-vertical'>
                        <IonText style={{ fontSize: '4vh' }}>
                          Provide <br />
                          <IonText style={{ color: '#FC0FC0', fontWeight: 'bolder' }}>Equal marketing opportunity</IonText> <br />
                          to all restaurants in Hawaii
                        </IonText>
                      </div>
                    </div>
                  </div>
                )}
              </section>
              <section className='ion-padding'>
                <IonText style={{ fontSize: '5vh', fontWeight: 'bolder' }}>Story</IonText>
                <br />
                <div className='ion-margin-vertical'>
                  <IonText>
                    In July 2020, a new restaurant opened in the Kaimuki district of Honolulu, Hawaii. Despite the restrictions on going out, the
                    restaurant was able to get off to a good start thanks to local TV coverage and media exposure. However, due to the pandemic, there
                    was a shortage of staff, irregular business days and hours, and sales became unstable. The owner of the restaurant, who felt a
                    sense of crisis about the operation, put effort into Instagram, delivery orders, advertising, and tried various media, but did not
                    achieve the expected results. However, they were able to verify issues with SNS and the cost-effectiveness of advertising. In the
                    midst of this, suddenly a line formed at the restaurant.
                    <br />
                    When they talked to customers who came to the store, most of them saw YouTube! said.
                    <br />
                    "As a result of this, a complete plan for a truly necessary service for the restaurant and a practical app for users was
                    completed.” "And now, the app is about to be launched under the name 'ono online'."
                  </IonText>
                </div>
              </section>
              <section className='ion-padding'>
                <IonText style={{ fontSize: '5vh', fontWeight: 'bolder' }}>Team</IonText>
                <div className='ion-margin-vertical'>
                  <IonRow className='ion-margin-top ion-justify-content-center'>
                    <IonCol size='auto'>
                      <IonAvatar>
                        <img src={'assets/images/iwaki_profile.jpeg'} />
                      </IonAvatar>
                    </IonCol>
                    <IonCol className='ion-padding-start'>
                      <div>
                        <IonLabel>Iwaki Kishi</IonLabel>
                        <br />
                        <IonLabel>Co-founder & CEO</IonLabel>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-margin-top ion-justify-content-center'>
                    <IonCol size='auto'>
                      <IonAvatar>
                        <img src={'assets/images/kevin_profile.jpeg'} />
                      </IonAvatar>
                    </IonCol>
                    <IonCol className='ion-padding-start'>
                      <div>
                        <IonLabel>Kevin Yamazaki</IonLabel>
                        <br />
                        <IonLabel>Co-founder & COO</IonLabel>
                      </div>
                    </IonCol>
                  </IonRow>

                  <IonRow className='ion-margin-top ion-justify-content-center'>
                    <IonCol size='auto'>
                      <IonAvatar>
                        <img src={'assets/images/sean_profile.jpg'} />
                      </IonAvatar>
                    </IonCol>
                    <IonCol className='ion-padding-start'>
                      <div>
                        <IonLabel>Sean Morris</IonLabel>
                        <br />
                        <IonLabel>Food specialist</IonLabel>
                      </div>
                    </IonCol>
                  </IonRow>
                </div>
              </section>
              <div className='ion-padding' style={{ backgroundColor: '#36B7FF', color: 'white', margin: '40px 0' }}>
                <IonRow>
                  <IonCol>
                    <IonText>Company: ono online Inc</IonText>
                    <br />
                    <br />
                    <IonText>Address: 1441 Kapiolani Blvd Ste 1115, Honolulu, HI 96814</IonText>
                    <br />
                    <br />
                    <IonText>Contact: aloha@onoonline.app</IonText>
                  </IonCol>
                  <IonCol className='ion-align-self-end'>
                    <IonImg src={'assets/images/palm_tree_transparent.png'} style={{ width: 'auto', height: '16vh' }} />
                  </IonCol>
                </IonRow>
              </div>
            </IonCol>
          </IonRow>
          {/* <IonRow>
            <IonCol size='12' md='8' offsetMd='2'>
              <IonList>
                <IonItem>
                  <IonLabel>Find restaurants near you</IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>Save and share your favorite dishes</IonLabel>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow> */}
        </IonGrid>
      </IonContent>

      {/* <IonFooter>
        <IonRow>
          <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' offset-md='1' size-lg='8' offset-lg='2'>
            <IonToolbar className='ion-justify-content-center'>
              <IonTitle>ono online</IonTitle>
              <IonButtons slot='end'>
                <IonButton href='#'>Facebook</IonButton>
                <IonButton href='#'>Twitter</IonButton>
                <IonButton href='#'>Instagram</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonCol>
        </IonRow>
      </IonFooter> */}
    </IonPage>
  );
};

export default AboutUsPage;
