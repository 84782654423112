import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonButton,
  IonContent,
  IonPage,
  IonLabel,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonItem,
  IonCheckbox,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonList,
  IonButtons,
  IonModal,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonText,
  IonDatetimeButton,
  IonDatetime,
  IonSelect,
  IonSelectOption,
  IonSearchbar,
  IonCardSubtitle,
  IonPopover,
  IonThumbnail,
  IonImg,
  IonMenuButton,
  IonLoading,
  IonFooter,
} from '@ionic/react';
import AppContext from '../../context/AppContext';
import OrderRefundItemComponent from '../../components/OrderRefundItemComponent';
import { closeOutline } from 'ionicons/icons';
import { menuController } from '@ionic/core';
import Parse from 'parse/dist/parse.min.js';
import { nanoid } from 'nanoid';
import * as moment from 'moment';
import momentTZ from 'moment-timezone';

import './OrderPage.css';

const TransactionPage = (props) => {
  const history = useHistory();
  const { updateIsOnboarding, user, updateUser } = useContext(AppContext);

  const [firstDay, setFirstDay] = useState(momentTZ().tz('Pacific/Honolulu').format('ll'));
  const [endDay, setEndDay] = useState(momentTZ().tz('Pacific/Honolulu').format('ll'));
  const [refundAmount, setRefundAmount] = useState(null);
  const [ordersArray, setOrdersArray] = useState([]);
  const [orderBadge, setOrderBadge] = useState(null);
  const [orderCount, setOrderCount] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);
  const [orderTip, setOrderTip] = useState(0);
  const [orderDetail, setOrderDetail] = useState();
  const [qtyArray, setQtyArray] = useState([]);
  const [refundSegment, setRefundSegment] = useState('DETAIL');
  const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
  const [unitPriceArray, setUnitPriceArray] = useState([]);
  const [selectedOption, setSelectedOption] = useState('TODAY');
  const [searchText, setSearchText] = useState('');
  const [showRefundTypePopover, setShowRefundTypePopover] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const rId = props.match.params.id;

  useEffect(() => {
    const currentUser = Parse.User.current();
    if (!currentUser) {
      updateIsOnboarding(true);
      return history.replace('/');
    }

    const getRestaurant = async () => {
      console.log('getRestaurant run');
      await Parse.Cloud.run('getRestaurantFromApp', { rId: rId ? rId : user.restaurant_id }).then((result) => {
        if (!result) {
          alert('Please check internet connection');
        } else {
          updateUser({ ...user, restaurant_id: rId ? rId : user.restaurant_id, restaurant_name: result.name });
        }
      });
    };
    let localTime = momentTZ().tz('Pacific/Honolulu').startOf('day').format();
    // setFirstDay(moment(today.setDate(1)).format('ll'));
    // setEndDay(moment(new Date(today.getFullYear(), today.getMonth() + 1, 0)).format('ll'));
    if (!user.restaurant_name) {
      getRestaurant();
    }

    const getOrderBadge = async () => {
      await Parse.Cloud.run('getOrderBadge', { rId: rId ? rId : user.restaurant_id })
        .then((result) => {
          console.log(result);
          setOrderBadge(result);
        })
        .catch((error) => {
          alert(error);
        });
    };
    getOrderBadge();

    const getOrders = async () => {
      await Parse.Cloud.run('getTransactions', { rId: rId ? rId : user.restaurant_id, localTime }).then((result) => {
        console.log(result);
        if (!result) {
          return;
        } else {
          //updateOrders(result.data);
          setOrdersArray(result.data);
          setOrderCount(result.count);
          setOrderTotal(result.total);
          setOrderTip(result.tip);
        }
      });
    };
    getOrders();
  }, []);

  const filteredOrders = useMemo(() => {
    return ordersArray.filter((item) => {
      return item.guest_name.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [ordersArray, searchText]);

  const openMenu = async () => {
    await menuController.open('order');
  };

  const handleOrderDetail = (item, index) => {
    let order = item.order;
    let unitPrice = [];
    let qty = [];
    for (let i = 0; i < order.length; i++) {
      order[i].refundChecked = false;
      unitPrice.push(order[i].menu.price);
      qty.push(order[i].qty);
    }
    setOrderDetail(item);
    updateUnitPriceArray(unitPrice);
    updateQtyArray(qty);
    setShowOrderDetailModal(true);
  };

  const updateUnitPriceArray = (val) => {
    setUnitPriceArray(val);
  };

  const updateQtyArray = (val) => {
    setQtyArray(val);
  };

  const handleChangeSegment = (val) => {
    setRefundSegment(val);
    setShowRefundTypePopover(false);
  };

  const allCheckboxChecked = (checked) => {
    let checkbox = document.getElementsByClassName('refundCheckbox');
    let sum = 0;
    for (let i = 0; i < checkbox.length; i++) {
      if (checked) {
        checkbox[i].checked = true;
        let number = Number(checkbox[i].value);
        sum += number;
      } else {
        checkbox[i].checked = false;
      }
    }
    updateRefundAmount(sum);
  };

  const updateRefundAmount = (val) => {
    setRefundAmount(val);
  };

  const handleChangeSelected = async (value) => {
    console.log(value);
    setSelectedOption(value);
    let localTime = momentTZ().tz('Pacific/Honolulu').startOf('day').format();
    let startTime;
    let endTime;

    if (value === 'TODAY') {
      setFirstDay(momentTZ().tz('Pacific/Honolulu').format('ll'));
      startTime = localTime;
      endTime = null;
    }
    if (value === 'YESTERDAY') {
      setFirstDay(moment(localTime).utc().subtract(1, 'day').startOf('day').format('ll'));
      startTime = moment(localTime).utc().subtract(1, 'day').startOf('day').format();
      endTime = moment(localTime).utc().subtract(1, 'day').endOf('day').format();
      console.log(startTime, endTime);
    }
    if (value === 'THISWEEK') {
      setFirstDay(moment(localTime).utc().startOf('week').format('ll'));
      setEndDay(moment(localTime).utc().endOf('week').format('ll'));
      startTime = moment(localTime).utc().startOf('week').format();
      endTime = moment(localTime).utc().endOf('week').format();
      console.log(startTime, endTime);
    }
    if (value === 'THISMONTH') {
      setFirstDay(moment(localTime).utc().startOf('month').format('ll'));
      setEndDay(moment(localTime).utc().endOf('month').format('ll'));
      startTime = moment(localTime).utc().startOf('month').format();
      endTime = moment(localTime).utc().endOf('month').format();
      console.log(startTime, endTime);
    }
    if (value === 'THISYEAR') {
      setFirstDay(moment(localTime).utc().startOf('year').format('ll'));
      setEndDay(moment(localTime).utc().endOf('year').format('ll'));
      startTime = moment(localTime).utc().startOf('year').format();
      endTime = moment(localTime).utc().endOf('year').format();
      console.log(startTime, endTime);
    }
    if (value === 'LASTWEEK') {
      setFirstDay(moment(localTime).utc().subtract(1, 'week').startOf('week').format('ll'));
      setEndDay(moment(localTime).utc().subtract(1, 'week').endOf('week').format('ll'));
      startTime = moment(localTime).utc().subtract(1, 'week').startOf('week').format();
      endTime = moment(localTime).utc().subtract(1, 'week').endOf('week').format();
      console.log(startTime, endTime);
    }
    if (value === 'LASTMONTH') {
      setFirstDay(moment(localTime).utc().subtract(1, 'month').startOf('month').format('ll'));
      setEndDay(moment(localTime).utc().subtract(1, 'month').endOf('month').format('ll'));
      startTime = moment(localTime).utc().subtract(1, 'month').startOf('month').format();
      endTime = moment(localTime).utc().subtract(1, 'month').endOf('month').format();
      console.log(startTime, endTime);
    }
    if (value === 'THREEMONTH') {
      setFirstDay(moment(localTime).utc().subtract(90, 'day').startOf('month').format('ll'));
      setEndDay(moment(localTime).utc().subtract(1, 'day').endOf('day').format('ll'));
      startTime = moment(localTime).utc().subtract(90, 'day').startOf('month').format();
      endTime = moment(localTime).utc().subtract(1, 'day').endOf('day').format();
      console.log(startTime, endTime);
    }
    const getOrders = async () => {
      await Parse.Cloud.run('getFilteredTransactions', { rId: rId ? rId : user.restaurant_id, startTime, endTime }).then((result) => {
        console.log(result);
        if (!result) {
          setOrdersArray([]);
          setOrderCount(0);
          setOrderTotal(0);
          setOrderTip(0);
        } else {
          //updateOrders(result.data);
          setOrdersArray(result.data);
          setOrderCount(result.data.length);
          let total = 0;
          let tip = 0;
          for (let i = 0; i < result.data.length; i++) {
            total += Number(result.data[i].total);
            tip += Number(result.data[i].tip);
          }
          setOrderTotal(total);
          setOrderTip(tip);
        }
      });
    };
    getOrders();
  };

  const updateOrderDetail = (val) => {
    setOrderDetail({ ...orderDetail, is_refunded: true, refunded_amount: val });
  };

  const handleRefund = async () => {
    if (refundAmount * 100 > orderDetail.total) {
      alert('Refund amount is larger than order price. Please try again');
      return;
    }
    setShowLoading(true);
    await Parse.Cloud.run('handleRefund', {
      orderId: orderDetail.objectId,
      refundAmount: refundAmount * 100,
      paymentIntent: orderDetail.payment_intent,
    }).then((result) => {
      setShowLoading(false);
      if (result) {
        updateOrderDetail(refundAmount);
        handleChangeSegment('DETAIL');
      } else {
        alert('Refund error, please check your internet connection');
      }
    });
  };

  const handleCentsInput = (val) => {
    const value = val.replace(/[^\d]/g, '');
    let cents = parseInt(value, 10);
    if (isNaN(cents)) {
      cents = 0;
    }
    const dollars = (cents / 100).toFixed(2);
    setRefundAmount(dollars);
  };

  return (
    <IonPage id='orderMenu'>
      <IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' size-lg='8'>
              <IonToolbar>
                <IonTitle>Transactions</IonTitle>
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' size-lg='8'>
              <IonGrid>
                <IonRow className='ion-align-items-center'>
                  <IonCol size='auto'>
                    <IonItem>
                      <IonSelect value={selectedOption} interface='popover' onIonInput={(e) => handleChangeSelected(e.target.value)}>
                        <IonSelectOption value='TODAY'>Today</IonSelectOption>
                        <IonSelectOption value='YESTERDAY'>Yesterday</IonSelectOption>
                        <IonSelectOption value='THISWEEK'>This week</IonSelectOption>
                        <IonSelectOption value='THISMONTH'>This month</IonSelectOption>
                        <IonSelectOption value='THISYEAR'>This year</IonSelectOption>
                        <IonSelectOption value='LASTWEEK'>Last week</IonSelectOption>
                        <IonSelectOption value='LASTMONTH'>Last month</IonSelectOption>
                        <IonSelectOption value='THREEMONTH'>Last 3 month</IonSelectOption>
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                  <IonCol size='auto'>
                    <>
                      <IonDatetimeButton datetime='datetime' placeholder={momentTZ().tz('Pacific/Honolulu').format()} />
                      <IonModal keepContentsMounted={true}>
                        <IonDatetime id='datetime' presentation='date' multiple></IonDatetime>
                      </IonModal>
                    </>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonSearchbar className='ion-margin-top' placeholder='Search by customer name' onIonInput={(e) => setSearchText(e.target.value)} />
              <div className='ion-padding' style={{ marginTop: '36px', borderRadius: '10px' }}>
                <h5 style={{ fontFamily: 'BrandonBold' }}>
                  {selectedOption === 'TODAY' || selectedOption === 'YESTERDAY' ? firstDay : `${firstDay} - ${endDay}`}
                </h5>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <div>Transactions</div>
                      <h3>{orderCount}</h3>
                    </IonCol>
                    <IonCol>
                      <div>Total Sales</div>
                      <h3>${orderTotal / 100}</h3>
                    </IonCol>
                    <IonCol>
                      <div>Total Tips</div>
                      <h3>${orderTip / 100}</h3>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
              <h3>Transactions</h3>
              <IonList>
                {filteredOrders &&
                  filteredOrders.map((item, index) => {
                    let time = moment(item.createdAt).subtract(1, 'days').calendar();
                    return (
                      <IonItem key={item.objectId} className='ion-no-padding' onClick={() => handleOrderDetail(item, index)}>
                        <IonLabel>
                          <h5>Order created at {time}</h5>
                          <p>
                            <span className='ion-margin-end' style={{ fontFamily: 'BrandonBold' }}>
                              {item.guest_name}
                            </span>
                            <span style={{ fontFamily: 'BrandonBold' }}>${item.total / 100}</span>
                          </p>
                          <p>
                            {item.order.map((val) => (
                              <span key={nanoid()} className='ion-margin-end'>
                                {val.menu.name} x {val.qty}
                              </span>
                            ))}
                          </p>
                        </IonLabel>
                      </IonItem>
                    );
                  })}
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      <IonModal id='orderDetailModal' isOpen={showOrderDetailModal} onDidDismiss={() => setShowOrderDetailModal(false)}>
        <IonHeader>
          <IonGrid>
            <IonRow>
              <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='8' size-lg='8'>
                <IonToolbar>
                  <IonButtons slot='start'>
                    <IonButton
                      onClick={() => {
                        setRefundAmount(0);
                        setShowOrderDetailModal(false);
                      }}>
                      <IonIcon icon={closeOutline} />
                    </IonButton>
                  </IonButtons>
                  <IonTitle>Order for {!orderDetail ? null : orderDetail.guest_name}</IonTitle>
                </IonToolbar>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonHeader>
        <IonContent className='ion-padding'>
          <IonGrid>
            <IonRow>
              <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='8' size-lg='8'>
                {!orderDetail || !unitPriceArray || !qtyArray ? null : (
                  <main>
                    <IonCard>
                      <IonCardHeader className='ion-text-center'>
                        <IonCardTitle>${orderDetail.total / 100} Payment</IonCardTitle>
                        <IonCardSubtitle>Ordered at: {moment(orderDetail.createdAt).format('LLL')}</IonCardSubtitle>
                        <IonCardTitle>Customer name: {orderDetail.guest_name}</IonCardTitle>
                      </IonCardHeader>
                    </IonCard>
                    {/* <IonSegment value={refundSegment} onIonInput={(e) => handleChangeSlide(e.target.value)}>
                <IonSegmentButton value='DETAIL'>
                  <IonLabel>Detail</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value='REFUND'>
                  <IonLabel>Issue Refund</IonLabel>
                </IonSegmentButton>
              </IonSegment> */}
                    <IonRow className='ion-align-items-center'>
                      <IonCol>
                        <h3 style={{ fontFamily: 'BrandonBold' }}>{refundSegment === 'DETAIL' ? 'Detail' : 'Issue refund'}</h3>
                      </IonCol>
                      <IonCol size='auto'>
                        <IonButton id='popover'>Options</IonButton>
                        <IonPopover
                          trigger='popover'
                          triggerAction='click'
                          isOpen={showRefundTypePopover}
                          side='bottom'
                          size='auto'
                          onDidDismiss={() => setShowRefundTypePopover(false)}>
                          <IonContent>
                            <IonItem onClick={() => handleChangeSegment('DETAIL')}>Order detail</IonItem>
                            <IonItem onClick={() => handleChangeSegment('ITEM')}>Refund by items</IonItem>
                            <IonItem onClick={() => handleChangeSegment('AMOUNT')}>Refund by amount</IonItem>
                            <IonItem>Send receipt</IonItem>
                          </IonContent>
                        </IonPopover>
                      </IonCol>
                    </IonRow>
                    {refundSegment === 'DETAIL' && (
                      <div>
                        <IonGrid>
                          <IonRow className='ion-text-start ion-padding-bottom' style={{ borderBottom: '1px solid grey' }}>
                            <IonCol>
                              <IonLabel>Name</IonLabel>
                            </IonCol>
                            <IonCol size='auto' className='ion-text-center'>
                              <IonLabel>Qty</IonLabel>
                            </IonCol>
                            <IonCol size='auto' className='ion-text-end'>
                              <div style={{ width: '4em' }}>
                                <IonLabel>Price</IonLabel>
                              </div>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                        <IonGrid>
                          {orderDetail.order.map((item, index) => (
                            <React.Fragment key={index}>
                              <IonRow className='ion-text-start ion-align-items-start'>
                                {item.menu.images.length ? (
                                  <IonCol size='auto'>
                                    <IonThumbnail style={{ width: '4vh', height: '4vh' }}>
                                      <IonImg src={item.menu.images[0].uri} />
                                    </IonThumbnail>
                                  </IonCol>
                                ) : null}
                                <IonCol>
                                  <IonLabel style={{ fontSize: '2.5vh' }}>{item.menu.name}</IonLabel>
                                </IonCol>
                                <IonCol size='auto' className='ion-text-end'>
                                  <IonLabel>{item.qty}</IonLabel>
                                </IonCol>
                                <IonCol size='auto' className='ion-text-end'>
                                  <div style={{ width: '4em' }}>
                                    <IonLabel>$ {(item.menu.price * item.qty) / 100}</IonLabel>
                                  </div>
                                </IonCol>
                              </IonRow>
                              {item.sides.length
                                ? item.sides.map(
                                    (option) =>
                                      option.choice !== null ||
                                      (option.choice.length &&
                                        option.choice.map((value) => (
                                          <IonRow key={nanoid()}>
                                            <IonCol className='ion-text-start'>
                                              <IonLabel className='ion-margin-end' style={{ color: 'grey' }}>
                                                - {option.category}:
                                              </IonLabel>
                                              <IonLabel className='ion-margin-end' style={{ color: 'grey' }}>
                                                {value.name}
                                              </IonLabel>
                                            </IonCol>
                                            <IonCol size='1'></IonCol>
                                            <IonCol size='1.5' className='ion-text-end'>
                                              <IonLabel style={{ color: 'grey' }}>$ {value.price / 100}</IonLabel>
                                            </IonCol>
                                          </IonRow>
                                        )))
                                  )
                                : null}
                            </React.Fragment>
                          ))}
                          <IonRow className='ion-margin-top'>
                            <IonCol className='ion-text-end'>
                              <IonLabel>Subtotal</IonLabel>
                            </IonCol>
                            <IonCol size='auto' className='ion-text-end'>
                              <div style={{ width: '4em' }}>
                                <IonLabel>${orderDetail.subtotal / 100}</IonLabel>
                              </div>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol className='ion-text-end'>
                              <IonLabel>Sales tax</IonLabel>
                            </IonCol>
                            <IonCol size='auto' className='ion-text-end'>
                              <div style={{ width: '4em' }}>
                                <IonLabel>${orderDetail.tax / 100}</IonLabel>
                              </div>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol className='ion-text-right'>
                              <IonLabel>Tip</IonLabel>
                            </IonCol>
                            <IonCol size='auto' className='ion-text-end'>
                              <div style={{ width: '4em' }}>
                                <IonLabel>${orderDetail.tip / 100}</IonLabel>
                              </div>
                            </IonCol>
                          </IonRow>
                          {orderDetail.is_refunded ? (
                            <IonRow>
                              <IonCol className='ion-text-right'>
                                <IonLabel color='danger'>Refunded</IonLabel>
                              </IonCol>
                              <IonCol size='auto' className='ion-text-end'>
                                <div style={{ width: '4em' }}>
                                  <IonLabel>${orderDetail.refunded_amount / 100}</IonLabel>
                                </div>
                              </IonCol>
                            </IonRow>
                          ) : null}
                          <IonRow>
                            <IonCol className='ion-text-end'>
                              <IonLabel>Total</IonLabel>
                            </IonCol>
                            <IonCol size='auto' className='ion-text-end'>
                              <div style={{ width: '4em' }}>
                                <IonLabel>${orderDetail.total / 100 - orderDetail.refunded_amount / 100}</IonLabel>
                              </div>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>
                    )}
                    {refundSegment === 'ITEM' && (
                      <div className='ion-padding' style={{ width: '100%' }}>
                        <IonGrid>
                          <IonRow className='ion-align-items-center ion-text-start ion-padding-bottom' style={{ borderBottom: '1px solid grey' }}>
                            <IonCol size='auto'>
                              <IonCheckbox
                                indeterminate
                                className='refundCheckbox refundTopCheckbox'
                                value={'0'}
                                onClick={(e) => allCheckboxChecked(e.target.checked, true)}
                              />
                            </IonCol>
                            <IonCol>
                              <IonLabel>Name</IonLabel>
                            </IonCol>
                            <IonCol size='auto' className='ion-text-center'>
                              <IonLabel>Qty</IonLabel>
                            </IonCol>
                            <IonCol size='auto' className='ion-text-end'>
                              <div style={{ width: '4em' }}>
                                <IonLabel>Price</IonLabel>
                              </div>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                        <IonGrid>
                          {/* {orderDetail.order.map((item, index) => ( */}
                          <OrderRefundItemComponent
                            item={orderDetail.order}
                            updateOrderDetail={updateOrderDetail}
                            paymentIntent={orderDetail.payment_intent}
                            handleChangeSegment={handleChangeSegment}
                            orderId={orderDetail.objectId}
                            qtyArray={qtyArray}
                            updateQtyArray={updateQtyArray}
                            refundAmount={refundAmount}
                            updateRefundAmount={updateRefundAmount}
                            unitPriceArray={unitPriceArray}
                            updateUnitPriceArray={updateUnitPriceArray}
                            tip={orderDetail.tip}
                            tax={orderDetail.tax}
                          />
                        </IonGrid>
                      </div>
                    )}

                    {refundSegment === 'AMOUNT' && (
                      <div className='ion-padding ion-text-center' style={{ width: '100%' }}>
                        <h3>How much do you refund to this customer?</h3>
                        <div className='ion-margin-vertical'>
                          <IonLabel>Total paid: </IonLabel>
                          <IonText style={{ fontSize: '2em', fontWeight: 'bolder' }}>$ {orderDetail.total / 100}</IonText>
                        </div>
                        <div style={{ width: '100%', justifyContent: 'center' }}>
                          <IonInput
                            style={{ width: '150px', margin: 'auto', border: '1px solid grey', borderRadius: '5px' }}
                            inputmode='number'
                            placeholder='Only number'
                            value={refundAmount}
                            onIonInput={(e) => handleCentsInput(e.target.value)}
                          />
                        </div>
                        <IonButton style={{ margin: '48px 0' }} onClick={handleRefund}>
                          Proceed Refund
                        </IonButton>
                      </div>
                    )}
                  </main>
                )}
                <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
      <IonFooter>
        <IonToolbar color='secondary'>
          <IonButtons slot='start'>
            <IonMenuButton id='menu' />
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};
export default TransactionPage;
