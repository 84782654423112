import React, { useContext, useCallback, useMemo, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonIcon,
  IonLabel,
  IonItem,
  IonImg,
  IonInput,
  IonTextarea,
  IonCheckbox,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonCard,
  IonCardSubtitle,
  IonText,
  IonModal,
  IonSelect,
  IonList,
  IonAlert,
  IonSelectOption,
  IonListHeader,
  IonReorderGroup,
  IonReorder,
} from '@ionic/react';
import { closeOutline, trashOutline } from 'ionicons/icons';
import Parse from 'parse/dist/parse.min.js';
import EditSectionCategoryModal from './EditSectionCategoryModal';
import ModifierGroupsModal from './ModifierGroupsModal/ModifierGroupsModal';
import { FilePicker } from '@capawesome/capacitor-file-picker';

import AppContext from '../context/AppContext';

const roundToTwo = (num) => {
  return +(Math.round(num + 'e+2') + 'e-2');
};

export default function MenuEditorModal(props) {
  const {
    rId,
    flag,
    title,
    item,
    categories,
    menuIndex,
    handleUpdateEditedMenu,
    handleUpdateSections,
    modifierGroups,
    setModifierGroups,
    modifierItems,
    setModifierItems,
    handleUpdateModifierGroups,
    showMenuEditModal,
    setShowMenuEditModal,
    // handleShowNewModifierModal,
    handleRenderNewMenu,
    posProvider,
  } = props;

  const { user } = useContext(AppContext);

  const [currentSection, setCurrentSection] = useState({
    title: '',
    description: '',
    categories: [],
  });
  const [menu, setMenu] = useState({
    id: '',
    name: '',
    description: '',
    images: [],
    price: null,
    category_ids: [],
    attributes: {
      vegan: false,
      vegetarian: false,
      gluten_free: false,
      allergen: [],
      spice_level: null,
      calories: null,
    },
    modifier_groups: [],
    dine_in_only: false,
  });
  const [allSections, setAllSections] = useState([]);
  const [sectionIndex, setSectionIndex] = useState();
  const [showCalorieItem, setShowCalorieItem] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showSectionCategoryModal, setShowSectionCaregoryModal] = useState(false);
  const [showSpicyRange, setShowSpicyRange] = useState(false);
  const [tags, setTags] = useState([null, null, null]);
  const [showModifierModal, setShowModifierModal] = useState(false);
  const [selectedModifier, setSelectedModifier] = useState();
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [showImageEditModal, setShowImageEditModal] = useState(false);

  const sectionCheckBoxRef = useRef([]);
  const widgetApi = useRef();
  const tagInput1 = useRef(tags[1]);
  const tagInput2 = useRef(tags[2]);
  const tagInput3 = useRef(tags[3]);
  const nameInputRef = useRef(menu ? menu.name : null);
  const priceInputRef = useRef(menu ? menu.price : null);
  const contentRef = useRef(null);

  useMemo(() => {
    // if (posProvider === 'SQUARE') {
    //   contentRef.current.scrollToBottom();
    //   setShowAlert(true);
    // }

    if (flag === 'NEW') {
      return;
    }
    if (item.objectId) {
      let menuItem = item;
      setMenu({
        ...menu,
        id: menuItem.objectId,
        name: flag === 'EDIT' ? menuItem.name : `${menuItem.name} copy`,
        description: menuItem.description,
        images: menuItem.images,
        price: roundToTwo(menuItem.price / 100),
        category_ids: menuItem.category_ids,
        attributes: menuItem.attributes,
        modifier_groups: menuItem.modifier_groups,
        dine_in_only: menuItem.dine_in_only,
      });
      setTags(menuItem.tags);
    }
  }, []);

  // const SectionCheckBoxComponent = () =>
  //   !activeSections.length
  //     ? null
  //     : activeSections.map((activeSection, index) => (
  //         <IonCard key={index}>
  //           <IonItem lines='none'>
  //             <IonCheckbox
  //               slot='start'
  //               ref={(ref) => {
  //                 sectionCheckBoxRef.current[index] = ref;
  //               }}
  //               mode='md'
  //               checked={sectionsSegment.length && sectionsSegment[index].is_sectionChecked}
  //               onClick={(e) => onCheckBoxChange(e.target.checked, index)}
  //               disabled={sectionsSegment.length && !sectionsSegment[index].is_sectionChecked}
  //             />
  //             <IonLabel style={{ color: '#fff' }}>{activeSection.title}</IonLabel>
  //             {/* <IonButton slot='end' onClick={() => handleEditSection(index)}>
  //               Manage
  //             </IonButton> */}
  //             <IonButton slot='end' onClick={() => handleShowSectionCategoryModal(activeSection, sectionsSegment[index].originalSectionIndex)}>
  //               MANAGE
  //             </IonButton>
  //           </IonItem>

  //           <div className='ion-padding-vertical'>
  //             <IonRadioGroup
  //               name={activeSection.title}
  //               value={sectionsSegment.length ? sectionsSegment[index].categoryRadioCheckedIndex : null}
  //               onIonInput={(e) => onCategoryRadioChange(index, e.target.value)}>
  //               {/* {activeSection.categories.map((category, ii) => {
  //                 return (
  //                   <IonChip key={nanoid()}>
  //                     <IonRadio value={ii} className='ion-margin-end' mode='md' />
  //                     <IonLabel>{category.title}</IonLabel>
  //                   </IonChip>
  //                 );
  //               })} */}
  //             </IonRadioGroup>
  //           </div>
  //         </IonCard>
  //       ));

  // const onCheckBoxChange = useCallback(
  //   (checked, index) => {
  //     let copiedAllSections = [...allSections];
  //     let copiedSegment = [...sectionsSegment];

  //     if (!checked && flag === 'edit') {
  //       copiedSegment[index].is_sectionChecked = false;
  //       let uuidIndex = copiedAllSections[copiedSegment[index].originalSectionIndex].menuUuids.indexOf(menu.id);
  //       if (uuidIndex > -1) {
  //         copiedAllSections[index].menuUuids.splice(uuidIndex, 1);
  //       }
  //       let currentCheckedRadioIndex = copiedSegment[index].categoryRadioCheckedIndex;
  //       let currentUuidIndex = copiedAllSections[index].categories[currentCheckedRadioIndex].menuUuids.indexOf(menu.id);
  //       if (currentUuidIndex > -1) copiedAllSections[index].categories[currentCheckedRadioIndex].menuUuids.splice(currentUuidIndex, 1);
  //       copiedSegment[index].categoryRadioCheckedIndex = null;
  //       setAllSections([...copiedAllSections]);
  //       setSectionsSegment([...copiedSegment]);
  //     } else if (!checked && flag !== 'edit') {
  //       copiedSegment[index].is_sectionChecked = false;
  //       copiedSegment[index].categoryRadioCheckedIndex = null;
  //       setSectionsSegment([...copiedSegment]);
  //     } else {
  //       copiedSegment[index].is_sectionChecked = true;
  //       setSectionsSegment([...copiedSegment]);
  //     }
  //   },
  //   [sectionsSegment]
  // );

  // const onCategoryRadioChange = useCallback(
  //   (index, value) => {
  //     let copiedSegment = [...sectionsSegment];
  //     const currentRadioIndex = copiedSegment[index].categoryRadioCheckedIndex;
  //     let copiedSections = [...allSections];
  //     console.log(currentRadioIndex);
  //     if (!copiedSegment[index].is_sectionChecked) {
  //       copiedSegment[index].is_sectionChecked = true;
  //     }
  //     if (currentRadioIndex > -1 && flag === 'edit') {
  //       //Delete
  //       let targetCategoryMenuUuidsArray = copiedSections[copiedSegment[index].originalSectionIndex].categories[currentRadioIndex].menuUuids;
  //       let willDeleteUuidIndex = targetCategoryMenuUuidsArray.indexOf(menu.id);
  //       if (willDeleteUuidIndex > -1) targetCategoryMenuUuidsArray.splice(willDeleteUuidIndex, 1);
  //       //Push
  //       copiedSegment[index].categoryRadioCheckedIndex = value;
  //       copiedSections[copiedSegment[index].originalSectionIndex].menuUuids.push(menu.id);
  //       copiedSections[copiedSegment[index].originalSectionIndex].categories[value].menuUuids.push(menu.id);
  //       setAllSections([...copiedSections]);
  //     } else if (currentRadioIndex === -1 && flag === 'edit') {
  //       copiedSegment[index].categoryRadioCheckedIndex = value;
  //       copiedSections[copiedSegment[index].originalSectionIndex].menuUuids.push(menu.id);
  //       copiedSections[copiedSegment[index].originalSectionIndex].categories[value].menuUuids.push(menu.id);
  //       setAllSections([...copiedSections]);
  //     } else {
  //       copiedSegment[index].categoryRadioCheckedIndex = value;
  //     }
  //     setSectionsSegment([...copiedSegment]);
  //   },
  //   [sectionsSegment]
  // );

  const handleShowSectionCategoryModal = (section, index) => {
    // setCurrentSection(section);
    // setSectionIndex(index);
    setShowSectionCaregoryModal(true);
  };

  const handleChangeCategory = (e) => {
    if (e.target.value[0] === 'ADD') {
      handleShowSectionCategoryModal();
      return;
    } else {
      setMenu({ ...menu, category_ids: e.target.value });
    }
  };

  const closeSectionCategoryModal = () => {
    setShowSectionCaregoryModal(false);
  };

  const handleShowEditModifierModal = useCallback(
    (item, index) => {
      setSelectedGroupIndex(index);
      setSelectedModifier(item);
      setShowModifierModal(true);
    },
    [selectedModifier]
  );

  const handleShowNewModifierModal = () => {
    setShowModifierModal(true);
  };

  const ModifierComponent = useCallback(() => {
    const handleCheckModifierGroup = (checked, group) => {
      if (checked && !menu.modifier_groups.includes(group.objectId)) {
        setMenu({ ...menu, modifier_groups: [...menu.modifier_groups, group.objectId] });
      } else {
        const filtered = menu.modifier_groups.filter((item) => item !== group.objectId);
        setMenu({ ...menu, modifier_groups: filtered });
      }
    };

    return (
      <>
        {!modifierGroups.length ? (
          <IonCard>
            <IonHeader>
              <IonCardSubtitle>No modifier yet</IonCardSubtitle>
            </IonHeader>
          </IonCard>
        ) : (
          <IonList>
            {modifierGroups.map((group, index) => {
              return (
                <IonItem key={group.objectId}>
                  <IonCheckbox
                    className='modifierGroupCheckbox'
                    slot='start'
                    checked={menu.modifier_groups?.includes(group.objectId)}
                    onIonInput={(e) => handleCheckModifierGroup(e.target.checked, group, index)}
                    // disabled={posProvider === 'SQUARE' ? true : false}
                  />
                  <IonLabel>
                    <h2 className='vh200 bold'>{group.title}</h2>
                    {group.options.map((option) => (
                      <span key={nanoid(5)} style={{ color: 'grey', fontSize: '0.8em', marginRight: '8px' }}>
                        {option.name},
                      </span>
                    ))}
                  </IonLabel>
                  {posProvider === 'SQUARE' ? null : (
                    <IonButton slot='end' onClick={() => handleShowEditModifierModal(group, index)}>
                      Edit
                    </IonButton>
                  )}
                </IonItem>
              );
            })}
          </IonList>
        )}
      </>
    );
  }, [menu.modifier_groups]);

  const handleHideCategoryModal = (index) => {
    sectionCheckBoxRef.current[index].checked = false;
    //setShowCategoryModal(false);
  };

  const handleSpicy = (boolean) => {
    if (boolean) {
      setShowSpicyRange(true);
    } else {
      setMenu({ ...menu, spiceLevel: null });
    }
  };

  const handleCalorie = (boolean) => {
    if (boolean) {
      setShowCalorieItem(true);
    } else {
      setMenu({ ...menu, calorie: null });
    }
  };

  const onSaveMenu = async () => {
    setShowLoading(true);
    if (nameInputRef.current.value === '' || !nameInputRef.current.value) {
      alert('Please input menu name');
      setShowLoading(false);
      return;
    }
    if (priceInputRef.current.value === '' || priceInputRef.current.value < 0 || !priceInputRef.current.value) {
      alert('Please input price');
      setShowLoading(false);
      return;
    }
    if (isNaN(Number(priceInputRef.current.value))) {
      alert('Please input only numbers for the price.');
      setShowLoading(false);
      return;
    }
    if (!menu.category_ids.length) {
      alert('Please select category');
      setShowLoading(false);
      return;
    }
    if (flag === 'NEW' || flag === 'COPY') {
      await Parse.Cloud.run('saveNewMenu', {
        rId: user.restaurant_id,
        menu,
        name: nameInputRef.current.value,
        price: priceInputRef.current.value,
        tags,
      })
        .then((result) => {
          if (result) {
            handleRenderNewMenu(result);
            setShowLoading(false);
            setShowMenuEditModal(false);
          } else {
            setShowLoading(false);
          }
        })
        .catch((error) => {
          setShowLoading(false);
          alert('after result:' + error);
        });
    } else {
      await Parse.Cloud.run('saveEditedMenu', {
        rId,
        menu,
        name: nameInputRef.current.value,
        price: priceInputRef.current.value,
        tags,
      })
        .then((savedMenu) => {
          if (!savedMenu) {
            setShowLoading(false);
            return;
          }
          savedMenu.modifier_groups = menu.modifier_groups;
          handleUpdateEditedMenu(savedMenu);
          setShowLoading(false);
          setShowMenuEditModal(false);
        })
        .catch((error) => {
          setShowLoading(false);
          alert(error);
        });
    }
  };

  const onSaveContinue = async () => {
    console.log(nameInputRef.current.value, priceInputRef.current.value);
    setShowLoading(true);
    if (nameInputRef.current.value === '' || !nameInputRef.current.value) {
      alert('Please input menu name');
      setShowLoading(false);
      return;
    }
    if (priceInputRef.current.value === '' || priceInputRef.current.value < 0 || !priceInputRef.current.value) {
      alert('Please input price');
      setShowLoading(false);
      return;
    }
    if (isNaN(Number(priceInputRef.current.value))) {
      alert('Please input only numbers for the price.');
      setShowLoading(false);
      return;
    }
    if (!menu.category_ids.length) {
      alert('Please select category');
      setShowLoading(false);
      return;
    }
    await Parse.Cloud.run('saveNewMenu', {
      rId: user.restaurant_id,
      menu,
      name: nameInputRef.current.value,
      price: priceInputRef.current.value,
      tags,
    })
      .then((result) => {
        if (result) {
          handleRenderNewMenu(result);
          setMenu({
            ...menu,
            id: '',
            name: '',
            description: '',
            images: [],
            price: null,
            category_ids: [],
            attributes: {
              vegan: false,
              vegetarian: false,
              gluten_free: false,
              allergen: [],
              spice_level: null,
              calories: null,
            },
            modifier_groups: [],
            dine_in_only: false,
          });
          setShowLoading(false);
        } else {
          setShowLoading(false);
        }
      })
      .catch((error) => {
        setShowLoading(false);
        alert('after result:' + error);
      });
  };

  const ImagesComponent = (props) => {
    const { menu, setMenu, setShowLoading } = props;

    const pickImages = async () => {
      const result = await FilePicker.pickImages({
        // types: ['image/png', 'image/jpg', 'image/JPG', 'image/heic'],
        multiple: true,
        readData: true,
      });

      if (result.files.length === 1) {
        const file = result.files[0];
        const base64 = `data:${file.mimeType};base64,${file.data}`;
        let prevImages = menu.images;
        prevImages.unshift({ uri: base64, type: 'IMAGE' });
        setMenu({ ...menu, images: [...prevImages] });
      } else {
        let data = [];
        for (let i = 0; i < result.files.length; i++) {
          if (result.files[i].mimeType.includes('video') || result.files[i].mimeType.includes('mp')) {
            alert('You can not upload video at this time. Please wait for future update');
            continue;
          }
          const base64 = `data:${result.files[i].mimeType};base64,${result.files[i].data}`;
          data.push({ uri: base64, type: 'IMAGE', name: result.files[i].name });
        }
        let prevImages = menu.images;
        prevImages = [...data, ...prevImages];
        setMenu({ ...menu, images: [...prevImages] });
      }
    };

    return (
      <>
        <section style={{ borderRadius: '10px', marginBottom: '3em' }}>
          <IonRow className='ion-align-items-center'>
            <IonCol>
              <IonText style={{ fontFamily: 'BrandonBold', fontSize: '3vh' }}>Images</IonText>
            </IonCol>
            <IonCol size='auto'>
              {/* {platform.includes('ios') || platform.includes('android') ? ( */}
              {!menu?.images?.length ? null : (
                <IonButton
                  color='light'
                  size='small'
                  onClick={() => {
                    setShowImageEditModal(true);
                  }}>
                  Edit
                </IonButton>
              )}
              <IonButton size='small' onClick={pickImages}>
                Upload
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            {menu.images.map((image, index) => {
              return (
                <IonCol size='4' key={image.uri}>
                  <IonImg src={image.uri} style={{ aspectRatio: 1, width: '100%' }} />
                </IonCol>
              );
            })}
          </IonRow>
        </section>
      </>
      // <div className='ion-text-start' style={{ marginBottom: '3em' }}>
      //   {images.length > 0
      //     ? images.map((item, index) => {
      //         if (item.type === 'IMAGE') {
      //           return (
      //             <div key={index} style={{ width: '100%', borderRadius: '5px', position: 'relative' }}>
      //               <IonImg
      //                 src={item.uri}
      //                 style={{ width: '10vh', height: '10vh', margin: '24px auto' }}
      //                 onClick={() => widgetApi.current.openDialog()}
      //               />
      //               <IonIcon
      //                 style={{ position: 'absolute', top: '12px', right: '12px' }}
      //                 size='small'
      //                 icon={trashOutline}
      //                 onClick={() => {
      //                   images.splice(index, 1);
      //                   if (images.length === 0) {
      //                     setMenu({ ...menu, images: [] });
      //                   } else {
      //                     setMenu({ ...menu, images });
      //                   }
      //                 }}
      //               />
      //             </div>
      //           );
      //         } else {
      //           return (
      //             <div key={index} style={{ width: '100%', borderRadius: '5px' }}>
      //               <video width='10vh' height='500' controls>
      //                 <source src={item.uri} type='video/mp4' />
      //               </video>
      //             </div>
      //           );
      //         }
      //       })
      //     : null}
      //   <CloudinaryUploadWidget menu={menu} setMenu={setMenu} />
      // </div>
    );
  };

  const ImageModalComponent = (props) => {
    const { menu, setMenu, showImageEditModal, setShowImageEditModal, setShowLoading } = props;

    const handleReorderImages = async (event) => {
      let data = menu?.images;
      let copiedData = menu?.images[event.detail.from];
      data.splice(event.detail.from, 1);
      data.splice(event.detail.to, 0, copiedData);
      event.detail.complete();
      setMenu({ ...menu, images: [...data] });
      // await Parse.Cloud.run('saveRestaurantImages', { rId, images: data }).then(() => {
      //   setRestaurant({ ...restaurant, images: [...data] });
      // });
    };

    const deleteImage = async (index) => {
      setShowLoading(true);
      let copiedImages = menu?.images;
      copiedImages.splice(index, 1);
      setMenu({ ...menu, images: [...copiedImages] });
      setShowLoading(false);
      // await Parse.Cloud.run('saveRestaurantImages', { rId, images: copiedImages, action: 'DELETE' })
      //   .then((result) => {
      //     console.log(result);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    };

    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonButton fill='clear' onClick={() => setShowImageEditModal(false)}>
                <IonIcon slot='icon-only' icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className='ion-padding'>
          <IonListHeader>
            <h1>Please drag and drop images</h1>
          </IonListHeader>
          <IonList>
            <IonReorderGroup disabled={false} onIonItemReorder={(e) => handleReorderImages(e)}>
              {menu?.images.map((image, index) => {
                return (
                  <IonItem key={index} style={{ height: '10vh', marginBottom: '1em' }}>
                    <IonReorder slot='start'></IonReorder>
                    <img src={image.uri} alt={'1'} style={{ width: 'auto', height: '10vh' }} />
                    <IonButton slot='end' size='small' onClick={() => deleteImage(index)}>
                      Delete
                    </IonButton>
                  </IonItem>
                );
              })}
            </IonReorderGroup>
          </IonList>
        </IonContent>
      </>
    );
  };

  const handleSaveCategory = async (newSectionValue) => {
    setShowLoading(true);
    let copiedAllSections;
    if (allSections.length) {
      copiedAllSections = allSections;
      copiedAllSections[sectionIndex] = newSectionValue;
      console.log(copiedAllSections);
    } else {
      copiedAllSections = newSectionValue;
    }

    Parse.Cloud.run('saveSections', { id: user.restaurant_id, data: copiedAllSections }).then((result) => {
      if (result) {
        handleUpdateSections([...copiedAllSections]);
        setShowLoading(false);
        closeSectionCategoryModal();
      } else {
        alert('Something wrong');
      }
    });
  };

  const updateTags = useCallback((value, index) => {
    tagInput1.current = value;
    console.log(tagInput1.current);
    // let copy = [...tags];
    // copy[index] = value;
    // setTags(copy);
  }, []);

  const TagInput = React.memo(() => {
    return (
      <IonRow className='ion-align-items-end' style={{ width: '100%' }}>
        <IonCol size='12' size-sm>
          <IonInput
            ref={tagInput1}
            className='ion-margin-top ion-padding-start'
            style={{ border: '1px solid grey', borderRadius: '5px', width: '10em' }}
            value={tags[0] && tags[0]}
            placeholder='Steak'
            type='string'
            onIonInput={(e) => {
              updateTags(e.target.value, 0);
            }}
          />
        </IonCol>
        <IonCol size='12' size-sm>
          <IonInput
            ref={tagInput2}
            className='ion-margin-top ion-padding-start'
            style={{ border: '1px solid grey', borderRadius: '5px', width: '10em' }}
            value={tags[1] && tags[1]}
            placeholder=' Salad'
            type='string'
            onIonInput={(e) => {
              updateTags(e.target.value, 1);
            }}
          />
        </IonCol>
        <IonCol size='12' size-sm>
          <IonLabel position='stacked'></IonLabel>
          <IonInput
            ref={tagInput3}
            className='ion-margin-top ion-padding-start'
            style={{ border: '1px solid grey', borderRadius: '5px', width: '10em' }}
            value={tags[2] && tags[2]}
            placeholder=' Breakfast'
            type='string'
            onIonInput={(e) => {
              updateTags(e.target.value, 2);
            }}
          />
        </IonCol>
      </IonRow>
    );
  });

  const closeModal = () => {
    setShowMenuEditModal(false);
  };

  return (
    <>
      <IonHeader>
        <IonRow>
          <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='8' offset-md='2' size-lg='8' offset-lg='2'>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonButton onClick={closeModal}>
                  <IonIcon icon={closeOutline} />
                </IonButton>
              </IonButtons>
              <IonTitle>{title}</IonTitle>
              <IonButtons slot='end'>
                <IonButton slot='end' fill='solid' expand='block' color='warning' onClick={onSaveMenu}>
                  Submit
                </IonButton>
                {flag !== 'EDIT' ? (
                  <IonButton slot='end' fill='solid' expand='block' color='warning' onClick={onSaveContinue}>
                    Continue
                  </IonButton>
                ) : null}
              </IonButtons>
            </IonToolbar>
          </IonCol>
        </IonRow>
      </IonHeader>
      <IonContent ref={contentRef} className='ion-padding' style={{ paddingBottom: '120px' }}>
        {/* <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header=''
          subHeader='You are using Square POS integration'
          message='Other than Tags and attributes, please modify menus with Square POS'
          buttons={['OK']}
        /> */}
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='8' offset-md='2' size-lg='6' offset-lg='3'>
              <IonRow>
                <IonCol size='12' className='ion-padding-end'>
                  <ImagesComponent images={menu?.images} menu={menu} setMenu={setMenu} setShowLoading={setShowLoading} />
                  <IonModal style={{ backgroundColor: '#eee' }} isOpen={showImageEditModal} onIonModalDidDismiss={() => setShowImageEditModal(false)}>
                    <ImageModalComponent
                      menu={menu}
                      setMenu={setMenu}
                      setShowLoading={setShowLoading}
                      showImageEditModal={showImageEditModal}
                      setShowImageEditModal={setShowImageEditModal}
                    />
                  </IonModal>
                  <section>
                    <div>
                      <IonLabel>Menu Name</IonLabel>
                      <IonInput
                        ref={nameInputRef}
                        className='ion-padding-horizontal'
                        style={{ border: '1px solid #333', borderRadius: '5px', height: '40px', margin: '8px 0 24px' }}
                        value={menu.name}
                        // disabled={posProvider === 'SQUARE' ? true : false}
                        onIonInput={(e) => setMenu({ ...menu, name: e.target.value })}
                      />
                    </div>
                    <div className='ion-margin-top'>
                      <IonLabel>Price ($)</IonLabel>
                      <IonInput
                        ref={priceInputRef}
                        className='ion-padding-horizontal'
                        style={{ border: '1px solid #333', borderRadius: '5px', height: '40px', margin: '8px 0 24px', width: '10em' }}
                        value={menu.price}
                        onIonInput={(e) => {
                          setMenu({ ...menu, price: e.target.value });
                        }}
                        type='number'
                        // disabled={posProvider === 'SQUARE' ? true : false}
                        required
                      />
                    </div>
                    <div className='ion-margin-top'>
                      <IonLabel>Category</IonLabel>
                      <IonSelect
                        mode='md'
                        interface='popover'
                        interfaceOptions={{ size: 'auto' }}
                        multiple
                        value={menu.category_ids}
                        style={{ border: '1px solid #333', borderRadius: '5px', marginTop: '8px', width: '20em' }}
                        onIonInput={(e) => {
                          handleChangeCategory(e);
                        }}
                        // disabled={posProvider === 'SQUARE' ? true : false}
                        onIonDismiss={() => {
                          if (menu.category_ids.includes('ADD')) {
                            let copy = menu.category_ids;
                            const index = copy.indexOf('ADD');
                            console.log(index);
                            const value = copy.splice(index, 1);
                            setMenu({ ...menu, category_ids: value });
                          }
                        }}>
                        <IonSelectOption value='ADD'>Add new category</IonSelectOption>
                        {categories?.map((item, index) => (
                          <IonSelectOption key={item.objectId} value={item.objectId}>
                            {item.name}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </div>
                    <div className='ion-margin-top'>
                      <IonLabel>Description</IonLabel>
                      <IonTextarea
                        style={{ border: '1px solid #333', borderRadius: '5px', margin: '8px 0 24px', padding: '4px' }}
                        placeholder='Describe this menu'
                        rows={2}
                        autoGrow
                        value={menu.description}
                        onIonInput={(e) => setMenu({ ...menu, description: e.target.value })}
                        // disabled={posProvider === 'SQUARE' ? true : false}
                      />
                    </div>
                  </section>
                </IonCol>
              </IonRow>

              {/* <IonItem>
                <h3>Category</h3>
                <IonButton slot='end' onClick={() => handleShowAddSectionModal('edit')}>
                  Edit Business Hours
                </IonButton>
              </IonItem>
              <SectionCheckBoxComponent /> */}

              <section className='ion-margin-vertical'>
                <IonItem>
                  <h3>Modifiers</h3>
                  <IonButton slot='end' onClick={handleShowNewModifierModal}>
                    Add Modifier
                  </IonButton>
                </IonItem>

                <ModifierComponent />
              </section>

              <section className='ion-margin-vertical'>
                <IonGrid>
                  <IonRow className='ion-align-items-center'>
                    <IonCol size='auto' className='ion-padding-end'>
                      <IonText style={{ fontSize: '1.5em', fontWeight: 'bolder' }}>Dine-in option</IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonItem>
                  <IonCheckbox slot='start' checked={menu.dine_in_only} onIonInput={(e) => setMenu({ ...menu, dine_in_only: e.target.checked })} />
                  <IonLabel>Is this menu dine-in only?</IonLabel>
                </IonItem>
              </section>

              <section className='ion-margin-vertical'>
                <IonGrid>
                  <IonRow className='ion-align-items-center'>
                    <IonCol size='auto' className='ion-padding-end'>
                      <IonText style={{ fontSize: '1.5em', fontWeight: 'bolder' }}>Tags</IonText>
                    </IonCol>
                    <IonCol className='ion-padding-start'>
                      <IonLabel>Tag is extremly important for being searched this item by customer</IonLabel>
                    </IonCol>
                  </IonRow>

                  <TagInput />
                </IonGrid>
              </section>
              {!menu?.attributes ? null : (
                <section className='ion-margin-vertical' style={{ marginBottom: '180px' }}>
                  <IonItem>
                    <h3>Others</h3>
                  </IonItem>
                  <div>
                    <IonItem>
                      <IonCheckbox
                        slot='start'
                        checked={menu?.attributes?.gluten_free}
                        onIonInput={(e) => setMenu({ ...menu, attributes: { ...menu.attributes, gluten_free: e.detail.checked } })}
                      />
                      <IonLabel className='chipLabel'>Gluten Free?</IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonCheckbox
                        slot='start'
                        checked={menu?.attributes.vegan}
                        onIonInput={(e) => setMenu({ ...menu, attributes: { ...menu.attributes, vegan: e.detail.checked } })}
                      />
                      <IonLabel>Vegan?</IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonCheckbox
                        slot='start'
                        checked={menu?.attributes.vegetarian}
                        onIonInput={(e) => setMenu({ ...menu, attributes: { ...menu.attributes, vegetarian: e.detail.checked } })}
                      />
                      <IonLabel>Vegetalian?</IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Any allergen?</IonLabel>
                      <IonCheckbox slot='start' />
                    </IonItem>
                    <IonItem>
                      <IonLabel>Spice Level?</IonLabel>
                      <IonCheckbox
                        slot='start'
                        onIonInput={(e) => setMenu({ ...menu, attributes: { ...menu.attributes, spicy_level: e.detail.checked } })}
                      />
                    </IonItem>
                    <IonItem>
                      <IonInput
                        slot='start'
                        type='tel'
                        style={{ border: '1px solid grey', borderRadius: '5px', width: '100px' }}
                        value={menu.calorie}
                        onIonInput={(e) => setMenu({ ...menu, attributes: { ...menu.attributes, calorie: e.detail.value } })}
                      />
                      <IonLabel>Calorie?</IonLabel>
                    </IonItem>
                  </div>
                </section>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonLoading cssClass='' isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />
      </IonContent>

      <IonModal
        id='sectionCategoryModal'
        isOpen={showSectionCategoryModal}
        onDidDismiss={() => {
          closeSectionCategoryModal(false);
          setSectionIndex(null);
        }}>
        <EditSectionCategoryModal
          closeSectionCategoryModal={closeSectionCategoryModal}
          currentSection={currentSection}
          handleSaveCategory={handleSaveCategory}
        />
      </IonModal>

      <ModifierGroupsModal
        rId={rId ? rId : user.restaurant_id}
        selectedGroup={selectedModifier}
        selectedGroupIndex={selectedGroupIndex}
        modifierItemsList={modifierItems}
        setModifierItemsList={setModifierItems}
        modifierGroups={modifierGroups}
        setModifierGroups={setModifierGroups}
        showModifierModal={showModifierModal}
        setShowModifierModal={setShowModifierModal}
      />

      {/* <IonModal isOpen={showCategoryModal} onDidDismiss={() => setShowCategoryModal(false)}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonButton onClick={() => handleHideCategoryModal(sectionIndex)}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
            {section && <IonTitle>{section.title} Categories</IonTitle>}
          </IonToolbar>
        </IonHeader>
        <IonContent className='ion-padding'>
          <IonItemDivider>Please Select Category</IonItemDivider>
          <IonRadioGroup onIonInput={(e) => handleSelectCategory(e.target.value)}>
            {!section || !section.categories.length ? null : (
              <>
                {section.categories.map((item, index) => (
                  <IonItem key={nanoid(5)} onClick={() => setShowCategoryModal(false)}>
                    <IonRadio slot='start' value={[item.name, index]} />
                    <IonLabel>{item.name}</IonLabel>
                  </IonItem>
                ))}
              </>
            )}
          </IonRadioGroup>
          <IonItem className='ion-margin-top'>
            <IonLabel position='floating'>Create New Category</IonLabel>
            <IonInput value={newCategoryName} onIonInput={(e) => setNewCategoryName(e.target.value)} />
            <IonButton slot='end' onClick={handleSaveCategory}>
              Add
            </IonButton>
          </IonItem>
        </IonContent>
      </IonModal> */}
    </>
  );
}
