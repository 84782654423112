import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonButton,
  IonCard,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonLabel,
  IonItem,
  IonThumbnail,
  IonImg,
  IonRow,
  IonCol,
  IonGrid,
  IonSearchbar,
  IonInput,
  IonLoading,
  IonAccordionGroup,
  IonAccordion,
  IonTitle,
  IonButtons,
  IonMenuButton,
  IonFooter,
  IonIcon,
  IonModal,
} from '@ionic/react';
import Parse from 'parse/dist/parse.min.js';
import { nanoid } from 'nanoid';
import AppContext from '../../../context/AppContext';
import CategoryModal from '../../../components/CategoryModal/CategoryModal';
import MenuEditorModal from '../../../components/MenuEditorModal';

import './MenuCategoryPage.css';
import { notifications, receipt } from 'ionicons/icons';

const MenuCategoryPage = (props) => {
  const { deviceWidth, user, updateUser, updateIsOnboarding } = useContext(AppContext);
  const history = useHistory();

  const [categoryIndex, setCategoryIndex] = useState(null);
  const [categoryObj, setCategoryObj] = useState({
    section: '',
    title: '',
    description: '',
    menuUuids: [],
  });
  const [menus, setMenus] = useState(null);
  const [originalMenus, setOriginalMenus] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [sections, setSections] = useState([]);
  const [sectionIndex, setSectionIndex] = useState(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null);
  const [showMenuEditModal, setShowMenuEditModal] = useState(false);

  const newCategoryInputRef = React.useRef([]);

  const rId = props.match.params.id;

  useEffect(() => {
    console.log('Category page rendered');
    const currentUser = Parse.User.current();
    if (!currentUser) {
      updateIsOnboarding(true);
      return history.replace('/');
    }
    const getRestaurant = async () => {
      await Parse.Cloud.run('getRestaurantFromApp', { rId: rId ? rId : user.restaurant_id }).then((result) => {
        if (!result) {
          alert('Please check internet connection');
        } else {
          updateUser({ ...user, restaurant_id: rId ? rId : user.restaurant_id, restaurant_name: result.name });
        }
      });
    };
    if (!user.restaurant_name) {
      getRestaurant();
    }

    const getMenuData = async () => {
      await Parse.Cloud.run('initCategoryPage', { rId: rId ? rId : user.restaurant_id })
        .then((results) => {
          if (results) {
            setMenus(results.menus);
            setOriginalMenus(results.menus);
            setCategories(results.categories);
          } else {
            console.log('No restaurant data');
          }
        })
        .catch((error) => {
          alert(error);
        });
    };
    getMenuData();
  }, []);

  const filteredCategory = useMemo(() => {
    return categories.filter((item) => {
      return item.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [categories, searchText]);

  const handleSaveCategory = async () => {
    if (!newCategoryInputRef.current.value.length) {
      alert('Please type new category name');
    }
    setShowLoading(true);
    const newCategory = {
      name: newCategoryInputRef.current.value,
      description: '',
    };
    newCategoryInputRef.current.value = '';
    await Parse.Cloud.run('saveNewCategory', { rId: rId ? rId : user.restaurant_id, data: newCategory }).then((result) => {
      if (result) {
        setSelectedCategoryIndex(categories.length);
        setSelectedCategory(result);
        setCategories([...categories, result]);
        setShowEditCategoryModal(true);
        setShowLoading(false);
      } else {
        setShowLoading(false);
        alert(result);
      }
    });
  };

  const handleEditCategory = (category) => {
    const idx = categories.findIndex((item) => item.objectId === category.objectId);
    setSelectedCategory(category);
    setSelectedCategoryIndex(idx);
    setShowEditCategoryModal(true);
  };

  const EditMenuModal = useCallback((item, flag, title, index) => {
    setShowMenuEditModal(true);

    return (
      <IonModal id='editorModal' className='editorModal' isOpen={showMenuEditModal} onDidDismiss={() => setShowMenuEditModal(false)}>
        {/* <MenuEditorModal
          rId={rId ? rId : user.restaurant_id}
          flag={flag}
          title={title}
          item={item}
          categories={categories}
          businessHours={restaurantHours}
          menuIndex={index}
          handleUpdateEditedMenu={handleUpdateEditedMenu}
          modifierGroups={modifierGroups}
          setModifierGroups={setModifierGroups}
          modifierItems={modifierItems}
          handleUpdateModifierGroups={handleUpdateModifierGroups}
          sections={sections}
          handleUpdateSections={handleUpdateSections}
          handleEditSection={handleEditSection}
          showMenuEditModal={showMenuEditModal}
          setShowMenuEditModal={setShowMenuEditModal}
          handleShowNewModifierModal={handleShowNewModifierModal}
          handleShowEditModifierModal={handleShowEditModifierModal}
          handleShowAddSectionModal={handleShowAddSectionModal}
          handleRenderNewMenu={handleRenderNewMenu}
          restaurantId={user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id}
          restaurantName={user.restaurant_name}
          posProvider={posProvider}
        /> */}
      </IonModal>
    );
  }, []);

  const onSaveCategory = async () => {
    let copiedSection = sections;
    let checkboxes = document.getElementsByClassName('categoryModalCheckBox');
    let uuids = [];
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        uuids.push(checkboxes[i].value);
        let index = copiedSection[sectionIndex].menuUuids.indexOf(checkboxes[i].value);
        if (index === -1) {
          copiedSection[sectionIndex].menuUuids.push(checkboxes[i].value);
        }
      } else {
        let index = copiedSection[sectionIndex].menuUuids.indexOf(checkboxes[i].value);
        if (index > -1) {
          copiedSection[sectionIndex].menuUuids.splice(index, 1);
        }
      }
    }
    let targetObj = categoryObj;
    targetObj.menuUuids = uuids;
    copiedSection[sectionIndex].categories[categoryIndex] = targetObj;
    //copiedSection[sectionIndex].menuUuids = [...new Set([...copiedSection[sectionIndex].menuUuids, ...uuids])];
    Parse.Cloud.run('saveSections', { id: user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id, data: copiedSection }).then((result) => {
      if (result) {
        setSections([...copiedSection]);
        setShowEditCategoryModal(false);
      } else {
        alert(result);
      }
    });
  };

  const onDeleteCategory = async (event, index, i) => {
    event.stopPropagation();
    let copy = [...sections];
    copy[index].categories.splice(i, 1);
    setSections([...copy]);
    let query = new Parse.Query('Restaurants');
    try {
      let result = await query.get(user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id);
      result.set('sections', copy);
      result.save();
    } catch (error) {
      alert(error);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' size-lg='8'>
              <IonToolbar>
                {deviceWidth > 500 ? (
                  <IonSearchbar
                    value={searchText}
                    showCancelButton='always'
                    mode='ios'
                    animated={true}
                    onIonInput={(e) => setSearchText(e.detail.value)}
                  />
                ) : (
                  <>
                    <IonTitle>Categories</IonTitle>
                    <IonButtons slot='end'>
                      <IonButton>
                        <IonIcon slot='icon-only' icon={notifications} size='small' />
                      </IonButton>
                    </IonButtons>
                  </>
                )}
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>

      <IonContent scrollX='true' className='ion-padding'>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' size-lg='8'>
              <div style={{ borderRadius: '10px', borderWidth: '3px', borderColor: '#333' }}>
                <IonLabel style={{ fontSize: '1rem', fontFamily: 'BrandonBold' }}>Add New Category</IonLabel>
                <IonItem fill='outline' className='ion-margin-top'>
                  <IonInput
                    style={{ borderRadius: '20px', height: '40px' }}
                    ref={newCategoryInputRef}
                    placeholder={'New Category Name'}
                    value={''}
                    onIonInput={(e) => (newCategoryInputRef.current.value = e.target.value)}
                  />
                  <IonButton slot='end' style={{ height: '40px', borderRadius: '20px' }} onClick={handleSaveCategory}>
                    Add
                  </IonButton>
                </IonItem>

                {/* <IonRow className='ion-align-items-center ion-margin-top'>
                  <IonCol></IonCol>
                  <IonCol size='auto'></IonCol>
                </IonRow> */}
              </div>

              <IonAccordionGroup className='ion-margin-top'>
                {filteredCategory?.map((category, index) => (
                  <IonCard
                    key={category.objectId}
                    className='ion-margin-bottom ion-padding-horizontal'
                    style={{ marginLeft: '0px', marginRight: '0px' }}
                    color='light'>
                    <IonAccordion value={index} color='light'>
                      <IonItem slot='header' className='ion-no-padding' color='light'>
                        <IonLabel style={{ fontFamily: 'BrandonBold', fontSize: '1rem' }}>{category.name}</IonLabel>
                        {category.menus.length > 0 ? (
                          <IonLabel slot='end'>
                            {category.menus.length} {category.menus.length > 1 ? 'items' : 'item'}
                          </IonLabel>
                        ) : (
                          <IonLabel slot='end'>0 item</IonLabel>
                        )}
                        <IonButton slot='end' onClick={() => handleEditCategory(category)}>
                          Edit
                        </IonButton>
                      </IonItem>
                      <div slot='content' color='light'>
                        {category.menus.map((menu, i) => (
                          <IonItem key={nanoid()} color='light'>
                            {menu.images[0]?.uri ? (
                              <IonThumbnail slot='start' style={{ width: '5vh', height: '5vh' }}>
                                <IonImg src={menu.images[0].uri} style={{ width: '5vh', height: '5vh' }} />
                              </IonThumbnail>
                            ) : (
                              <IonThumbnail slot='start' style={{ width: '5vh', height: '5vh' }}></IonThumbnail>
                            )}
                            <IonLabel>
                              <h3>{menu.name}</h3>
                              <p>{menu.description}</p>
                            </IonLabel>

                            {/* <IonButton slot='end' fill='clear' onClick={(e) => onDeleteCategory(e, index, i)}>
                              <IonIcon icon={trashOutline} color='white' />
                            </IonButton> */}
                          </IonItem>
                        ))}
                        <div className='ion-text-center ion-margin-top' color='light'>
                          <IonButton
                            fill='solid'
                            style={{ color: 'white', fontFamily: 'BrandonBold', textDecorationLine: 'none' }}
                            onClick={() => handleEditCategory(category)}>
                            {`EDIT "${category.name}"`}
                          </IonButton>
                        </div>
                      </div>
                    </IonAccordion>
                  </IonCard>
                ))}
              </IonAccordionGroup>
            </IonCol>
          </IonRow>
        </IonGrid>

        <CategoryModal
          flag={'EDIT'}
          selectedCategory={selectedCategory}
          selectedCategoryIndex={selectedCategoryIndex}
          showEditCategoryModal={showEditCategoryModal}
          setShowEditCategoryModal={setShowEditCategoryModal}
          originalMenus={originalMenus}
          prevCategories={categories}
          setPrevCategories={setCategories}
        />

        <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />
      </IonContent>
      {deviceWidth < 501 ? (
        <IonFooter>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonMenuButton id='menu' />
            </IonButtons>
            <IonSearchbar
              class='customSearchBar'
              color='medium'
              debounce={400}
              value={searchText}
              onIonInput={(e) => {
                setSearchText(e.target.value);
              }}
              // color='medium'
            />
            <IonButtons slot='end'>
              <IonButton>
                <IonIcon slot='icon-only' icon={receipt} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      ) : null}
    </IonPage>
  );
};

export default MenuCategoryPage;
