import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonIcon,
  IonLabel,
  IonModal,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonThumbnail,
  IonImg,
  IonRow,
  IonCol,
  IonGrid,
  IonSearchbar,
  IonReorderGroup,
  IonReorder,
  IonInput,
  IonTextarea,
  IonCheckbox,
  IonLoading,
  IonAccordionGroup,
  IonAccordion,
  IonFooter,
  IonMenuButton,
  IonSpinner,
} from '@ionic/react';
import { addCircleOutline, addOutline, closeOutline } from 'ionicons/icons';
import Parse from 'parse/dist/parse.min.js';
import { nanoid } from 'nanoid';
import AppContext from '../../../context/AppContext';
import EditBusinessHourModal from '../../../components/EditBusinessHourModal/EditBusinessHourModal';

import './MenuHourPage.css';

const roundToTwo = (num) => {
  return +(Math.round(num + 'e+2') + 'e-2');
};

const MenuHourPage = (props) => {
  const { menusProp, categoriesProp, sectionsProp, restaurantHoursProp, rId } = props;
  const { deviceWidth, user, updateUser, updateIsOnboarding } = useContext(AppContext);
  const history = useHistory();

  const [selectedCategory, setSelectedCategory] = useState({
    objectId: '',
    name: '',
    description: '',
    menu_ids: [],
    menus: [],
  });
  const [searchText, setSearchText] = useState('');
  const [menus, setMenus] = useState(menusProp);
  const [restaurantHours, setRestaurantHours] = useState(restaurantHoursProp);
  const [sections, setSections] = useState(sectionsProp);
  const [categories, setCategories] = useState(categoriesProp);
  // const [menus, setMenus] = useState([]);
  // const [restaurantHours, setRestaurantHours] = useState([]);
  // const [sections, setSections] = useState([]);
  // const [categories, setCategories] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [sectionIndex, setSectionIndex] = useState();
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState();
  const [showAddSectionModal, setShowAddSectionModal] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showAssignCategoryModal, setShowAssignCategoryModal] = useState(false);
  const [newSectionTitle, setNewSectionTitle] = useState('');
  const [showBusinessHourModal, setShowBusinessHourModal] = useState(false);
  const [prevCategoryObj, setPrevCategoryObj] = useState(null);

  const newCategoryInputRef = useRef([]);
  const newMenuHourInput = useRef();
  const sectionTitleInputRef = useRef();

  // const rId = props.match.params.id;

  const filteredMenus = useMemo(() => {
    if (menus) {
      return menus.filter((item) => {
        return item.name.toLowerCase().includes(searchText.toLowerCase());
      });
    } else {
      return [];
    }
  }, [menus, searchText]);

  const handleSectionChange = async (sectionIndex, event) => {
    let section = sections[sectionIndex];
    if (event.detail.from > event.detail.to) {
      for (let i = event.detail.to; i < event.detail.from; i++) {
        section.active_categories[i].ordinal++;
      }
      section.active_categories[event.detail.from].ordinal = event.detail.to;
    }
    if (event.detail.from < event.detail.to) {
      for (let i = event.detail.from; i < event.detail.to; i++) {
        section.active_categories[i + 1].ordinal--;
      }
      section.active_categories[event.detail.from].ordinal = event.detail.to;
    }
    event.detail.complete();
    section.active_categories.sort((a, b) => {
      if (a.ordinal < b.ordinal) return -1;
      else if (a.ordinal > b.ordinal) return 1;
      return 0;
    });
    let copy = sections;
    copy[sectionIndex] = section;
    setSections([...copy]);

    await Parse.Cloud.run('updateSectionOrdinal', { rId: rId ? rId : user.restaurant_id, data: JSON.stringify(copy) }).then((result) => {
      if (!result) {
        alert('Error');
      }
    });
  };

  const onDeleteSection = () => {
    let copiedSection = sections;
    copiedSection.splice(sectionIndex, 1);
    Parse.Cloud.run('saveSections', { id: user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id, data: copiedSection }).then((result) => {
      if (result) {
        setSections(copiedSection);
        setShowAddSectionModal(false);
      } else {
        alert(result);
      }
    });
  };

  const handleEditCategory = (categoryItem, sIndex, cIndex) => {
    setSectionIndex(sIndex);
    setSelectedCategoryIndex(cIndex);
    let copiedCategory = categoryItem;
    copiedCategory.menu_ids = copiedCategory.menus.map((item) => item.objectId);
    setSelectedCategory(copiedCategory);
    setPrevCategoryObj(copiedCategory);
    setShowEditCategoryModal(true);
  };

  // const onDeleteCategory = async (event, index, i) => {
  //   event.stopPropagation();
  //   let copy = [...sections];
  //   copy[index].categories.splice(i, 1);
  //   setSections([...copy]);
  //   let query = new Parse.Query('Restaurants');
  //   try {
  //     let result = await query.get(user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id);
  //     result.set('sections', copy);
  //     result.save();
  //   } catch (error) {
  //     alert(error);
  //   }
  // };

  // const handleEditSection = (e, index) => {
  //   e.stopPropagation();
  //   //sectionEditButton[index].current.value = false;
  //   setSectionIndex(index);
  //   setNewSection({ ...newSection, title: sections[index].title, categories: sections[index].categories, menuUuids: sections[index].menuUuids });
  //   setSectionFlag('EDIT');
  //   setShowAddSectionModal(true);
  // };

  const showEditBusinessHourModal = () => {
    setShowBusinessHourModal(true);
  };

  const handleShowAssignCategoryModal = (section, index) => {
    setSelectedSection(section);
    setSectionIndex(index);
    setShowAssignCategoryModal(true);
  };

  const handleCloseAssignCategoryModal = () => {
    setShowAssignCategoryModal(false);
  };

  const handleCategoryCheckbox = useCallback(
    (e, checked, selectedItem) => {
      e.preventDefault();
      let prev = selectedSection;
      let index = prev.active_categories.findIndex((item) => item.id === selectedItem.objectId);
      if (checked && index === -1) {
        console.log(index);
        prev.category_ids.push(selectedItem.objectId);
        prev.active_categories.push({
          ordinal: prev.active_categories.length,
          id: selectedItem.objectId,
          data: selectedItem,
        });
        console.log(prev.active_categories);
      }
      // if (!checked && index > -1) {
      else {
        console.log(index);
        prev.category_ids.splice(index, 1);
        prev.active_categories.splice(index, 1);
        console.log(prev);
      }
      //setCopiedSections(copy);
    },
    [selectedSection]
  );

  const onSaveNewSectionTitle = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('saveNewSection', {
      rId: rId ? rId : user.restaurant_id,
      data: { title: newSectionTitle, category_ids: [], active_categories: [] },
    }).then((result) => {
      if (result) {
        setSections([...sections, { title: newSectionTitle, active_categories: [], category_ids: [] }]);
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    });
  };

  const onSaveSection = async () => {
    let copiedSections = sections;
    copiedSections[sectionIndex] = selectedSection;
    console.log(copiedSections);
    const mapData = copiedSections.map((item) => {
      return {
        title: item.title,
        category_ids: item.category_ids ? item.category_ids : [],
        active_categories: item.active_categories.map((c, index) => {
          return { ordinal: index, id: c.id };
        }),
      };
    });
    setShowLoading(true);
    await Parse.Cloud.run('saveSections', {
      rId: rId ? rId : user.restaurant_id,
      data: JSON.stringify(mapData),
    }).then((result) => {
      if (result) {
        //setSections([...prevSections]);
        setShowAssignCategoryModal(false);
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    });
  };

  const onSaveSectionInHours = async (index, value) => {
    let copiedRestaurantHour = restaurantHours;
    copiedRestaurantHour[0].open[index].section = value;
    setRestaurantHours(copiedRestaurantHour);
    await Parse.Cloud.run('onSaveHours', { data: copiedRestaurantHour, rId: user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id })
      .then((result) => {
        console.log(result);
      })
      .catch((error) => alert(error));
  };

  const _handleCheckMenu = (checked, item) => {
    let copiedCategory = selectedCategory;
    if (checked) {
      setSelectedCategory({ ...selectedCategory, menu_ids: [...selectedCategory.menu_ids, item.objectId], menus: [...selectedCategory.menus, item] });
    } else {
      const index = copiedCategory.menu_ids.indexOf(item.objectId);
      copiedCategory.menu_ids.splice(index, 1);
      copiedCategory.menus.splice(index, 1);
      setSelectedCategory(copiedCategory);
    }
  };

  const onSaveCategory = async () => {
    console.log(selectedCategory);
    setShowLoading(true);
    await Parse.Cloud.run('saveEditedCategoryFromHour', { data: JSON.stringify(selectedCategory) })
      .then(() => {
        let copy = [...sections];
        let copiedSectionCategory = copy[sectionIndex].active_categories;
        copiedSectionCategory[selectedCategoryIndex].data = selectedCategory;
        setSections(copy);
        setSearchText('');
        setShowLoading(false);
        setShowEditCategoryModal(false);
      })
      .catch((error) => {
        console.log(error);
        setSearchText('');
        setShowLoading(false);
      });
  };

  return (
    // <IonPage>
    //   <IonHeader>
    //     <IonToolbar></IonToolbar>
    //   </IonHeader>
    <>
      <div style={{ backgroundColor: 'lightgreen', borderRadius: '10px', padding: '1px 12px 24px' }}>
        {/* <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' size-lg='8'> */}
        <h1>Menu Hours</h1>
        <h5>Setting up this section is necessary for taking online order</h5>
        {/* <div className='ion-padding-vertical'> */}

        <IonAccordionGroup>
          {/* {!sections.length ? ( */}
          {!sections ? (
            <div className='ion-text-center'>
              <IonSpinner name='dots' />
            </div>
          ) : (
            sections.map((section, index) => {
              return (
                <IonAccordion key={index} value={index}>
                  <IonItem slot='header'>
                    <IonLabel>
                      <h3 className='vh250 bold ion-margin-end'>{section.title}</h3>
                      <IonLabel className='vh175' style={{ color: '#999' }}>
                        {section.active_categories.length} categories assigned
                      </IonLabel>
                    </IonLabel>
                  </IonItem>
                  <div className='ion-padding-start' slot='content'>
                    {/* <IonLabel className='vh175' style={{ color: 'lightgreen' }}> */}
                    <IonLabel className='vh175'>
                      In the user app, they are displayed in the following order. The order can be changed by drag & drop.
                    </IonLabel>
                    <IonReorderGroup className='ion-margin-top' disabled={false} onIonItemReorder={(e) => handleSectionChange(index, e)}>
                      {section.active_categories.map((category, i) => {
                        return (
                          <IonItem
                            key={category.id}
                            className='ion-no-padding'
                            button
                            onClick={() => {
                              handleEditCategory(category.data, index, i);
                            }}>
                            <IonReorder slot='start' />
                            <IonLabel>
                              <h3 className='vh225 bold'>{category.data.name}</h3>
                              <p>{category.data.description}</p>
                            </IonLabel>
                            {category.data.menus.length > 1 ? (
                              <IonLabel slot='end'>{category.data.menus.length} items</IonLabel>
                            ) : (
                              <IonLabel slot='end'>{category.data.menus.length} item</IonLabel>
                            )}
                            {/* <IonButton slot='end' fill='clear' onClick={(e) => onDeleteCategory(e, index, i)}>
                                <IonIcon icon={trashOutline} color='white' />
                              </IonButton> */}
                          </IonItem>
                        );
                      })}
                    </IonReorderGroup>
                    <div className='ion-text-center'>
                      <IonButton className='ion-margin-vertical' onClick={() => handleShowAssignCategoryModal(section, index)}>
                        {section.active_categories.length ? 'Edit category' : 'Edit or assign category'}
                      </IonButton>
                    </div>
                  </div>
                </IonAccordion>
              );
            })
          )}
        </IonAccordionGroup>
        <IonAccordionGroup className='ion-margin-bottom'>
          <IonAccordion toggleIcon={addOutline}>
            <IonItem slot='header' color='light' onClick={() => newMenuHourInput.current.focus()}>
              {/* <IonLabel className='bold' style={{ color: 'lightgreen' }}> */}
              <IonLabel className='bold'>Add Menu Hour</IonLabel>
            </IonItem>
            <div slot='content'>
              <IonItem className='ion-no-padding ion-margin-top' fill={'outline'} style={{ borderRadius: '20px' }}>
                {/* <IonLabel position='fixed'>New title</IonLabel> */}
                <IonInput
                  ref={newMenuHourInput}
                  placeholder='Type new title'
                  value={newSectionTitle}
                  onIonInput={(e) => setNewSectionTitle(e.target.value)}
                />
              </IonItem>
              <IonButton className='ion-margin-vertical' disabled={!newSectionTitle} onClick={onSaveNewSectionTitle}>
                Save new menu hour
              </IonButton>
            </div>
          </IonAccordion>
        </IonAccordionGroup>
        {/* </div> */}
        {/* <div style={{ marginTop: '4em' }}>
                <h1>
                  Restaurant business hours
                  <br />
                  and menu section
                </h1>

                <IonGrid className='ion-padding-bottom' style={{ borderBottom: '1px solid grey' }}>
                  <IonRow>
                    <IonCol size-xs='auto' size-sm='3' size-md='3' size-lg='3'>
                      <div style={{ width: '12vh', fontSize: '1rem' }}>Days</div>
                    </IonCol>
                    <IonCol size-xs='auto' size-sm='3' size-md='5' size-lg='5'>
                      <div style={{ width: '15vh', fontSize: '1rem' }}>Hours</div>
                    </IonCol>
                    <IonCol size-xs='auto' size-sm='3' size-md='4' size-lg='4'>
                      <IonLabel style={{ fontSize: '1rem' }}>Hour Menu</IonLabel>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonGrid className='ion-margin-top'>
                  {!restaurantHours?.length && !sections?.length
                    ? null
                    : restaurantHours[0].open.map((hour, index) => {
                        const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
                        let start = String(hour.start);
                        let end = String(hour.end);
                        let startHour;
                        let startMin = start.substr(-2);
                        let endHour;
                        let endMin = end.substr(-2);
                        if (start.length === 4) {
                          startHour = start.substring(0, 2);
                        } else {
                          startHour = start.substring(0, 1);
                        }
                        if (end.length === 4) {
                          endHour = end.substring(0, 2);
                        } else {
                          endHour = end.substring(0, 1);
                        }
                        let prevState = 0;
                        if (index > 0) {
                          prevState = restaurantHours[0].open[index - 1];
                        }

                        return (
                          <IonRow key={nanoid()} className='ion-align-items-center' style={{ height: '50px' }}>
                            <IonCol size-xs='auto' size-sm='3' size-md='3' size-lg='3'>
                              {!prevState !== 0 && prevState.day === hour.day ? (
                                <div style={{ fontFamily: 'Brandon', fontSize: '2.2vh', width: '12vh' }}></div>
                              ) : (
                                <div style={{ fontFamily: 'Brandon', fontSize: '2.2vh', width: '12vh' }}>{days[hour.day]}</div>
                              )}
                            </IonCol>
                            {hour.is_open ? (
                              <>
                                <IonCol size-xs='auto' size-sm='5' size-md='5' size-lg='5'>
                                  <div style={{ width: '15vh', fontSize: '2vh' }}>
                                    {Number(startHour)}:{startMin} - {Number(endHour)}:{endMin}
                                  </div>
                                </IonCol>
                                <IonCol size-sm='4' size-md='4' size-lg='4'>
                                  <IonSelect
                                    id='select'
                                    style={{ fontSize: '2vh' }}
                                    interface='popover'
                                    value={hour.section}
                                    onIonInput={(e) => onSaveSectionInHours(index, e.detail.value)}>
                                    {sections.map((item, i) => (
                                      <IonSelectOption key={nanoid()} value={i}>
                                        {item.title}
                                      </IonSelectOption>
                                    ))}
                                  </IonSelect>
                                </IonCol>
                              </>
                            ) : (
                              <>
                                <IonCol>{!prevState !== 0 && prevState.day === hour.day && !hour.is_open ? null : <IonLabel>Close</IonLabel>}</IonCol>
                                <IonCol></IonCol>
                              </>
                            )}
                          </IonRow>
                        );
                      })}
                </IonGrid>
                <IonButton onClick={showEditBusinessHourModal} style={{ fontFamily: 'BrandonBold', margin: '24px 0', width: '50%' }}>
                  Edit Operation Hour
                </IonButton>
              </div> */}
        {/* </IonCol>
          </IonRow>
        </IonGrid> */}

        {/* <EditBusinessHourModal
          rId={rId ? rId : user.restaurant_id}
          restaurantHours={restaurantHours}
          setRestaurantHours={setRestaurantHours}
          showBusinessHourModal={showBusinessHourModal}
          setShowBusinessHourModal={setShowBusinessHourModal}
        /> */}
      </div>
      <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />

      <IonModal id='categoryModal' isOpen={showEditCategoryModal} onDidDismiss={() => setShowEditCategoryModal(false)}>
        <IonHeader>
          <IonGrid>
            <IonRow>
              <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='8' offset-md='2' size-lg='6' offset-lg='3'>
                <IonToolbar>
                  <IonButtons slot='start'>
                    <IonButton
                      onClick={() => {
                        setSelectedCategory(prevCategoryObj);
                        setShowEditCategoryModal(false);
                      }}>
                      <IonIcon icon={closeOutline} />
                    </IonButton>
                  </IonButtons>
                  <IonTitle>Edit Category in "{selectedCategory.name}"</IonTitle>
                  <IonButtons slot='end'>
                    <IonButton fill='solid' expand='block' color='primary' onClick={onSaveCategory}>
                      Save
                    </IonButton>
                  </IonButtons>
                </IonToolbar>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonHeader>
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='8' offset-md='2' size-lg='6' offset-lg='3'>
                <IonLabel position='stacked'>Title</IonLabel>
                <IonInput
                  fill='outline'
                  value={selectedCategory.name}
                  onIonInput={(e) => setSelectedCategory({ ...selectedCategory, name: e.target.value })}
                />
                <IonItem>
                  <IonLabel position='stacked'>Description</IonLabel>
                  <IonTextarea
                    fill='outline'
                    value={selectedCategory?.description}
                    onIonInput={(e) => setSelectedCategory({ ...selectedCategory, description: e.target.value })}
                  />
                </IonItem>
                <IonSearchbar className='customSearchBar' value={searchText} onIonInput={(e) => setSearchText(e.target.value)} />
                <IonList className='ion-margin-top'>
                  {filteredMenus.length ? (
                    filteredMenus.map((item, index) => (
                      <IonItem key={item.objectId} className='ion-align-items-start'>
                        <IonCheckbox
                          slot='start'
                          className='categoryModalCheckBox'
                          checked={selectedCategory.menu_ids?.includes(item.objectId)}
                          value={item.objectId}
                          onIonInput={(e) => _handleCheckMenu(e.target.checked, item)}
                        />
                        {!item.images.length > 0 ? null : (
                          <IonThumbnail slot='start' className='ion-margin-top' style={{ width: '5vh', height: '5vh' }}>
                            <IonImg src={item.images[0].uri} style={{ width: '5vh', height: '5vh' }} />
                          </IonThumbnail>
                        )}
                        <IonLabel className='ion-text-wrap'>
                          <h3>{item.name}</h3>
                          <p>{item.description}</p>
                          <p>${roundToTwo(item.price / 100)}</p>
                        </IonLabel>
                      </IonItem>
                    ))
                  ) : (
                    <IonLabel>No Item Yet</IonLabel>
                  )}
                </IonList>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={showAssignCategoryModal}
        onDidDismiss={() => {
          setSectionIndex(null);
          setSelectedSection(null);
          setShowAssignCategoryModal(false);
        }}>
        <IonHeader>
          <IonGrid>
            <IonRow>
              <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' size-lg='8'>
                <IonToolbar>
                  <IonButtons slot='start' onClick={handleCloseAssignCategoryModal}>
                    <IonIcon icon={closeOutline} />
                  </IonButtons>
                  <IonTitle>Add or remove categories</IonTitle>
                  <IonButtons slot='end'>
                    <IonButton fill='solid' expand='block' color='primary' onClick={onSaveSection}>
                      Save
                    </IonButton>
                  </IonButtons>
                </IonToolbar>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonHeader>
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' size-lg='8'>
                <div className='ion-margin-bottom'>
                  <IonLabel className='ion-padding-start' style={{ fontSize: '0.8em' }}>
                    Title
                  </IonLabel>
                  <IonInput
                    ref={sectionTitleInputRef}
                    fill='solid'
                    style={{
                      borderRadius: '5px',
                      height: '40px',
                      marginTop: '8px',
                    }}
                    value={selectedSection?.title}
                    onIonInput={(e) => setSelectedSection({ ...selectedSection, title: e.target.value })}
                  />
                </div>
                {categories?.map((category, index) => {
                  return (
                    <IonItem key={index}>
                      <IonCheckbox
                        slot='start'
                        checked={selectedSection?.active_categories.findIndex((item) => item.id === category.objectId) > -1}
                        onIonInput={(e) => handleCategoryCheckbox(e, e.target.checked, category)}
                      />
                      <IonLabel className='bold'>{category.name}</IonLabel>
                    </IonItem>
                  );
                })}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
    </>
    // </IonPage>
  );
};

export default MenuHourPage;
