import React, { useRef, useState } from 'react';
import { IonButton, IonLabel, IonRow, IonCol, IonCheckbox, IonSelect, IonSelectOption } from '@ionic/react';
import { nanoid } from 'nanoid';
import Parse from 'parse/dist/parse.min.js';

import OrderRefundChildComponent from '../components/OrderRefundChildComponent';

const roundToTwo = (num) => {
  return +(Math.round(num + 'e+2') + 'e-2');
};

export default function OrderRefundItemComponent(props) {
  const {
    item,
    orderId,
    paymentIntent,
    updateOrderDetail,
    handleChangeSegment,
    qtyArray,
    updateQtyArray,
    updateRefundAmount,
    unitPriceArray,
    tip,
    tax,
  } = props;

  const [tipChecked, setTipChecked] = useState(false);
  const [refundAmount, setRefundAmount] = useState(0);
  const [checkBoxCheckedArray, setCheckBoxCheckedArray] = useState(
    [...Array(item.length)].map((_, i) => {
      return false;
    })
  );
  const [showLoading, setShowLoading] = useState(false);
  const itemCheckBox = useRef([]);
  const tipChechBox = useRef();

  const handleParentItemCheckbox = (index, checked) => {
    //let topCheckbox = document.getElementsByClassName('refundTopCheckbox');
    if (checked) {
      setRefundAmount((prev) => {
        return prev + Number(itemCheckBox.current[index].value) * 1.04712;
      });
      setCheckBoxCheckedArray((prev) => {
        let copied = prev;
        copied[index] = true;
        return [...copied];
      });
    } else {
      setRefundAmount((prev) => {
        return prev - Number(itemCheckBox.current[index].value) * 1.04712;
      });
      setCheckBoxCheckedArray((prev) => {
        let copied = prev;
        copied[index] = false;
        return [...copied];
      });
      //topCheckbox[0].checked = false;
    }
  };

  const handleQtyChange = (val, index) => {
    let newQty = qtyArray;
    newQty[index] = Number(val);
    updateQtyArray([...newQty]);
    itemCheckBox.current[index].value = unitPriceArray[index] * Number(val);
    if (itemCheckBox.current[index].checked) {
      let sum = 0;
      for (let i = 0; i < itemCheckBox.current.length; i++) {
        sum += Number(itemCheckBox.current[i].value) * 1.04712;
      }
      if (tipChecked) {
        setRefundAmount(sum + tip);
      } else {
        setRefundAmount(sum);
      }
    }
  };

  const handleRefundTip = (checked) => {
    if (checked) {
      setRefundAmount((prev) => {
        return prev + tip;
      });
      setTipChecked(true);
    } else {
      setRefundAmount((prev) => {
        return prev - tip;
      });
      setTipChecked(false);
    }
  };

  const handleRefund = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('handleRefund', { orderId, refundAmount: Math.round(refundAmount), paymentIntent }).then((result) => {
      setShowLoading(false);
      if (result) {
        updateOrderDetail(refundAmount);
        handleChangeSegment('DETAIL');
      } else {
        alert('Refund error, please check your internet connection');
      }
    });
  };

  return (
    <>
      {item.map((order, index) => {
        return (
          <React.Fragment key={nanoid()}>
            <IonRow className='ion-align-items-center'>
              <IonCol size='auto'>
                <IonCheckbox
                  ref={(ref) => (itemCheckBox.current[index] = ref)}
                  value={`${unitPriceArray[index] * qtyArray[index]}`}
                  checked={checkBoxCheckedArray[index]}
                  onIonInput={(e) => {
                    //e.stopPropagation();
                    handleParentItemCheckbox(index, e.target.checked);
                  }}
                />
              </IonCol>
              <IonCol>
                <IonLabel>{order.menu.name}</IonLabel>
              </IonCol>
              <IonCol size='auto' className='ion-text-center'>
                {order.qty > 1 ? (
                  <IonSelect
                    value={qtyArray[index]}
                    style={{
                      border: '1px solid grey',
                    }}
                    onIonInput={(e) => handleQtyChange(e.target.value, index)}>
                    {[...Array(order.qty)].map((_, i) => (
                      <IonSelectOption key={nanoid()} value={i + 1}>
                        <IonLabel>{i + 1}</IonLabel>
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                ) : (
                  <IonLabel className='ion-text-center'>1</IonLabel>
                )}
              </IonCol>
              <IonCol size='auto' className='ion-text-end'>
                <div style={{ width: '4em' }}>
                  <IonLabel>${(unitPriceArray[index] * qtyArray[index]) / 100}</IonLabel>
                </div>
              </IonCol>
            </IonRow>
            {order.sides.length
              ? order.sides.map((option) => (
                  <OrderRefundChildComponent
                    key={nanoid()}
                    option={option}
                    index={index}
                    refundAmount={refundAmount}
                    updateRefundAmount={updateRefundAmount}
                  />
                ))
              : null}
          </React.Fragment>
        );
      })}
      <IonRow className='ion-margin-top ion-align-items-center'>
        <IonCol className='ion-text-end'>
          <IonCheckbox
            ref={tipChechBox}
            className='ion-margin-end'
            value={tip}
            checked={tipChecked}
            onIonInput={(e) => handleRefundTip(e.target.checked)}
          />
          <IonLabel>Tip</IonLabel>
        </IonCol>
        <IonCol size='auto' className='ion-text-end'>
          <div style={{ width: '4em' }}>
            <IonLabel>${tip / 100}</IonLabel>
          </div>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className='ion-text-end'>
          <IonLabel>Sales Tax</IonLabel>
        </IonCol>
        <IonCol size='auto' className='ion-text-end'>
          <div style={{ width: '4em' }}>
            <IonLabel>${tax / 100}</IonLabel>
          </div>
        </IonCol>
      </IonRow>

      <IonRow className='ion-text-end'>
        <IonCol className='ion-text-end'>
          <IonLabel color='danger'>Refund</IonLabel>
        </IonCol>
        <IonCol size='auto' className='ion-text-end'>
          <div style={{ width: '4em' }}>
            <IonLabel>${roundToTwo(refundAmount / 100)}</IonLabel>
          </div>
        </IonCol>
      </IonRow>
      <div className='ion-text-end ion-margin-top'>
        <IonButton disabled={!refundAmount} onClick={handleRefund}>
          Submit refund
        </IonButton>
      </div>
    </>
  );
}
