import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import { businessOutline } from 'ionicons/icons';
import Parse from 'parse/dist/parse.min.js';
import axios from 'axios';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { AddressElement } from '@stripe/react-stripe-js';

import AppContext from '../../context/AppContext';

import './BankPage.css';

export default function BankPage(props) {
  const { user } = useContext(AppContext);
  const [bank, setBank] = useState(null);
  const [stripeLink, setStripeLink] = useState(null);
  const [businessType, setBusinessType] = useState('company');
  const [data, setData] = useState({
    email: '',
    businessName: '',
    address: '',
    ein: null,
    firstName: '',
    lastName: '',
    ssn: null,
    phone: '',
    dob: '',
    bankHolderName: '',
    bankRoutineNumber: null,
    bankAccountNumber: null,
  });
  const [ip, setIp] = useState(null);

  const businessNameInput = useRef('');
  const emailInput = useRef('');
  const addressInput = useRef();
  const autoCompleteRef = useRef();
  const einInput = useRef('');
  const firstNameInput = useRef('');
  const lastNameInput = useRef('');
  const ssnInput = useRef('');
  const bankHolderNameInput = useRef('');
  const bankRoutineNumberInput = useRef('');
  const bankAccountInput = useRef('');
  const bankAccountConfirmInput = useRef('');

  const rId = props.match.params.id;
  const history = useHistory();
  const stripe = loadStripe('pk_test_51M0D78EfoYpPzdnltkrIPS4TRQiuR4RKoXAS25NOlTMh3gD8R0EGQ2PxT2Rc3RRDcDsJCppoNlfmDcQy4K6Wk3zu00DyMjaboz');

  useEffect(() => {
    const getStripeId = async () => {
      await Parse.Cloud.run('getStripeAccount', { rId: rId ? rId : user.restaurant_id }).then(async (result) => {
        if (!result) {
          return;
        } else {
          setBank(result.account.external_accounts.data[0]);
          await Parse.Cloud.run('stripeAccountLink', {
            id: result.account.id,
            rId: rId ? rId : user.restaurant_id,
            currentPage: props.match.path.includes('dashboard') ? 'DASHBOARD' : 'ONBOARDING',
          }).then((link) => {
            setStripeLink(link);
          });
        }
      });
    };
    getStripeId();
    const getIp = async () => {
      const res = await axios.get('https://geolocation-db.com/json/');
      setIp(res.data.IPv4);
    };
    getIp();
  }, []);

  // useEffect(() => {
  //   autoCompleteRef.current = new window.google.maps.places.Autocomplete(addressInput.current, options);
  //   autoCompleteRef.current.addListener('place_changed', async function () {
  //     const place = await autoCompleteRef.current.getPlace();
  //     console.log({ place });
  //   });
  // }, []);

  const _handleConnectStripe = async () => {
    window.open(stripeLink, '_self');
    // await Browser.open({
    //   url: stripeLink,
    // });
  };

  const handleStripeCreateAccount = () => {
    Parse.Cloud.run('stripeCreateAccount').then((result) => {
      setStripeLink(result.url);
    });
  };

  const onSubmit = async () => {
    if (!data.email) {
      alert('Please input email');
      return;
    }
    if (businessType === 'company') {
      if (!data.businessName) {
        alert('Please input business name');
        return;
      }

      if (!data.ein) {
        alert('Please input EIN');
        return;
      }
    }
    if (businessType === 'individual') {
      if (!data.firstName) {
        alert('Please input first name');
        return;
      }
      if (!data.lastName) {
        alert('Please input last name');
        return;
      }
      if (!data.ssn) {
        alert('Please input SSN');
        return;
      }
    }
    if (!data.bankHolderName) {
      alert('Please input bank holder name');
      return;
    }
    if (!data.bankRoutineNumber || data.bankRoutineNumber.length !== 9) {
      alert('Please double-check bank routine number');
      return;
    }
    if (!data.bankAccountNumber) {
      alert('Please input bank account number');
      return;
    }
    if (data.bankAccountNumber !== bankAccountConfirmInput.current.value) {
      alert('Please double-check bank account number');
      return;
    }
    let tos = {
      date: Math.round(new Date().getTime() / 1000),
      ip,
    };
    await Parse.Cloud.run('stripeCreateAccount', {
      rId: rId ? rId : user.restaurant_id,
      businessType,
      data,
      tos,
    }).then((result) => {});
  };

  const options = {
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#0570de',
        colorBackground: '#333',
        colorText: '#fff',
        colorDanger: '#df1b41',
        fontFamily: 'Brandon, Ideal Sans, system-ui, sans-serif',
        fontSizeBase: '1rem',
        spacingUnit: '3px',
        borderRadius: '10px',
        //spacingGridColumn: '10px',
        spacingGridRow: '20px',

        // See all possible variables below
      },
      rules: {
        '.Label': {
          fontSize: '0.7rem',
          marginBottom: '8px',
        },
        '.Input': {
          fontSize: '1rem',
        },
        '.Input--invalid': {
          boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',
        },

        // See all supported class names and selector syntax below
      },
    },
  };

  const AddressComponent = useCallback(() => {
    return (
      <Elements stripe={stripe} options={options}>
        <form>
          <AddressElement
            options={{
              mode: 'billing',
              autocomplete: {
                mode: 'google_maps_api',
                apiKey: 'AIzaSyBhJtVwX5URI7JOvYw7pDM6v5f7tLKJODw',
              },
              allowedCountries: ['US'],
              blockPoBox: true,
              fields: {
                phone: 'always',
              },
              validation: {
                phone: {
                  required: 'always',
                },
              },
              display: { name: businessType === 'company' ? 'organization' : 'split' },
            }}
            onChange={(e) => {
              if (e.complete) {
                // Extract potentially complete address
                if (businessType === 'company') {
                  setData({ ...data, businessName: e.value.name, address: e.value.address, phone: e.value.phone });
                } else {
                  setData({ ...data, firstName: e.value.firstName, lastName: e.value.lastName, address: e.value.address, phone: e.value.phone });
                }
              }
            }}
          />
        </form>
      </Elements>
    );
  }, [businessType]);

  return (
    <IonPage>
      <IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='8' offset-md='2' size-lg='6' offset-lg='3'>
              <IonToolbar>
                <IonButtons slot='start'>
                  <IonBackButton text='' />
                </IonButtons>
                <IonTitle style={{ fontFamily: 'BrandonBold', fontSize: '1rem' }}>Bank Account</IonTitle>
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='8' offset-md='2' size-lg='6' offset-lg='3'>
              <img src={'assets/images/Stripe.png'} style={{ width: '15vh' }} alt='logo' />
              <h3 className='vh250 bold'>ono online uses Stripe payment system</h3>
              <h5 className='vh200'>
                To keep your account safe, at ono online, we do not store your personal information and bank account details, which you input on
                Stripe's website, on our servers.
              </h5>

              {!bank ? (
                <IonButton className='ion-margin-top' onClick={_handleConnectStripe}>
                  Register Bank Account
                </IonButton>
              ) : (
                // <div>
                //   <IonSegment
                //     mode='ios'
                //     value={businessType}
                //     style={{ margin: '36px 0', width: '90%' }}
                //     onIonInput={(e) => setBusinessType(e.target.value)}>
                //     <IonSegmentButton className='vh200 bold' style={{ height: '5vh' }} value='company'>
                //       Company
                //     </IonSegmentButton>
                //     <IonSegmentButton className='vh200 bold' style={{ height: '5vh' }} value='individual'>
                //       Individual
                //     </IonSegmentButton>
                //   </IonSegment>
                //   <IonItem className='ion-no-padding'>
                //     <IonLabel position='stacked'>Account manager email</IonLabel>
                //     <IonInput
                //       ref={emailInput}
                //       className='bankPageInput ion-margin-top'
                //       style={{ backgroundColor: '#333', borderRadius: '10px' }}
                //       type='email'
                //       value={data.email}
                //       onIonInput={(e) => setData({ ...data, email: e.target.value })}
                //     />
                //   </IonItem>

                //   {businessType === 'company' ? (
                //     <div className='ion-margin-top'>
                //       <AddressComponent />

                //       <IonItem className='ion-no-padding'>
                //         <IonLabel position='stacked'>Tax ID Number</IonLabel>
                //         <IonInput
                //           ref={einInput}
                //           className='bankPageInput ion-margin-top'
                //           style={{ backgroundColor: '#333', borderRadius: '10px' }}
                //           type='number'
                //           value={data.ein}
                //           onIonInput={(e) => setData({ ...data, ein: e.target.value })}
                //         />
                //       </IonItem>
                //       <IonNote className='vh175'>
                //         You can locate your EIN on your confirmation letter from the IRS. You can also call the IRS to look up your federal Tax ID
                //         number.
                //       </IonNote>
                //       <IonItem className='ion-margin-top ion-no-padding'>
                //         <IonLabel position='stacked'>Representative First Name</IonLabel>
                //         <IonInput
                //           ref={firstNameInput}
                //           className='bankPageInput ion-margin-top'
                //           style={{ backgroundColor: '#333', borderRadius: '10px' }}
                //           value={data.firstName}
                //           onIonInput={(e) => setData({ ...data, firstName: e.target.value })}
                //         />
                //       </IonItem>
                //       <IonItem className='ion-no-padding'>
                //         <IonLabel position='stacked'>Representative Last Name</IonLabel>
                //         <IonInput
                //           ref={lastNameInput}
                //           className='bankPageInput ion-margin-top'
                //           style={{ backgroundColor: '#333', borderRadius: '10px' }}
                //           value={data.lastName}
                //           onIonInput={(e) => setData({ ...data, lastName: e.target.value })}
                //         />
                //       </IonItem>
                //     </div>
                //   ) : (
                //     <div className='ion-margin-top'>
                //       <AddressComponent />

                //       <IonItem className='ion-no-padding'>
                //         <IonLabel position='stacked'>SSN (Social Security Number)</IonLabel>
                //         <IonInput
                //           ref={ssnInput}
                //           className='bankPageInput ion-margin-top'
                //           style={{ backgroundColor: '#333', borderRadius: '10px' }}
                //           value={data.ssn}
                //           onIonInput={(e) => setData({ ...data, ssn: e.target.value })}
                //         />
                //       </IonItem>
                //     </div>
                //   )}

                //   <div className='ion-margin-vertical'>
                //     <IonItem className='ion-no-padding'>
                //       <IonLabel position='stacked'>Date of Birth</IonLabel>
                //       <IonInput
                //         // ref={lastNameInput}
                //         className='bankPageInput ion-margin-top'
                //         placeholder='MM/DD/YYYY'
                //         style={{ backgroundColor: '#333', borderRadius: '10px' }}
                //         value={data.dob}
                //         onIonInput={(e) => setData({ ...data, dob: e.target.value })}
                //       />
                //     </IonItem>
                //   </div>
                //   <div>
                //     <h5 className='vh200 bold onoPink' style={{ marginTop: '2em' }}>
                //       Bank Account for Receiving Payouts
                //     </h5>
                //     <IonItem className='ion-no-padding'>
                //       <IonLabel position='stacked'>Bank account holder name</IonLabel>
                //       <IonInput
                //         ref={bankHolderNameInput}
                //         className='bankPageInput ion-margin-top'
                //         style={{ backgroundColor: '#333', borderRadius: '10px' }}
                //         value={data.bankHolderName}
                //         onIonInput={(e) => setData({ ...data, bankHolderName: e.target.value })}
                //       />
                //     </IonItem>
                //     <IonItem className='ion-no-padding'>
                //       <IonLabel position='stacked'>Bank routine number</IonLabel>
                //       <IonInput
                //         ref={bankRoutineNumberInput}
                //         className='bankPageInput ion-margin-top'
                //         style={{ backgroundColor: '#333', borderRadius: '10px' }}
                //         value={data.bankRoutineNumber}
                //         onIonInput={(e) => setData({ ...data, bankRoutineNumber: e.target.value })}
                //       />
                //     </IonItem>
                //     <IonItem className='ion-no-padding'>
                //       <IonLabel position='stacked'>Account number</IonLabel>
                //       <IonInput
                //         ref={bankAccountInput}
                //         className='bankPageInput ion-margin-top'
                //         style={{ backgroundColor: '#333', borderRadius: '10px' }}
                //         value={data.bankAccountNumber}
                //         onIonInput={(e) => setData({ ...data, bankAccountNumber: e.target.value })}
                //       />
                //     </IonItem>
                //     <IonItem className='ion-no-padding'>
                //       <IonLabel position='stacked'>Account number (confirm)</IonLabel>
                //       <IonInput
                //         ref={bankAccountConfirmInput}
                //         className='bankPageInput ion-margin-top'
                //         style={{ backgroundColor: '#333', borderRadius: '10px' }}
                //       />
                //     </IonItem>
                //   </div>
                //   <div style={{ marginTop: '36px' }}>
                //     <IonLabel className='vh200'>
                //       By registering your account, you agree to our Services Agreement and the Stripe Connected Account Agreement.
                //     </IonLabel>
                //   </div>
                //   <div>
                //     <IonButton onClick={onSubmit}>Submit</IonButton>
                //   </div>
                // </div>
                <IonItem color='primary' style={{ borderRadius: '10px', marginTop: '36px' }}>
                  <IonIcon slot='start' icon={businessOutline} />
                  <IonLabel>
                    <h3 className='vh225 bold'>{bank.bank_name}</h3>
                    <p>Routing Number: {bank.routing_number}</p>
                    <p>Last 4 digit: {bank.last4}</p>
                  </IonLabel>
                  <IonButton slot='end' className='ion-margin-start ion-margin-top' onClick={_handleConnectStripe} color='dark'>
                    Edit
                  </IonButton>
                </IonItem>
              )}
              {props.match.path.includes('onboarding') && (
                <IonButton style={{ marginTop: '120px' }} fill='clear' onClick={() => history.replace(`/onboarding/${rId}`)}>
                  Back to Onbording page
                </IonButton>
              )}

              {/* {bank ? (
                <IonItem>
                  <IonIcon slot='start' icon={businessOutline} />
                  <IonLabel>
                    <h3>{bank.bank_name}</h3>
                    <p>Routing Number: {bank.routing_number}</p>
                    <p>Last 4 digit: {bank.last4}</p>
                  </IonLabel>
                  <IonButton slot='end' className='ion-margin-start ion-margin-top' href={stripeLink} color='tertiary'>
                    Edit
                  </IonButton>
                </IonItem>
              ) : !stripeLink ? null : (
                <IonButton onClick={_handleConnectStripe} style={{ fontFamily: 'BrandonBold', marginTop: '2em' }} strong target='_blank'>
                  Manage bank account
                </IonButton>
              )} */}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
