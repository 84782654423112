import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonButton,
  IonHeader,
  IonContent,
  IonPage,
  IonAvatar,
  IonLabel,
  IonToolbar,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonMenuButton,
  IonTitle,
  IonList,
  IonItem,
  IonButtons,
  IonText,
  IonInput,
  IonTextarea,
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonLoading,
  IonModal,
  IonSpinner,
  IonBackButton,
} from '@ionic/react';
import { closeOutline, createOutline, imageOutline, personCircle } from 'ionicons/icons';
import Parse from 'parse/dist/parse.min.js';
import { nanoid } from 'nanoid';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Browser } from '@capacitor/browser';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import MenuHourComponent from '../../components/MenuHourComponent/MenuHourComponent';
import EditBusinessHourModal from '../../components/EditBusinessHourModal/EditBusinessHourModal';

import './AccountPage.css';

import AppContext from '../../context/AppContext';

const StorePage = (props) => {
  const { platform, user, updateUser } = useContext(AppContext);
  const history = useHistory();

  const [restaurant, setRestaurant] = useState(null);
  const [copiedRestaurant, setCopiedRestaurant] = useState();
  const [restaurantHours, setRestaurantHours] = useState([]);
  const [copiedRestaurantHours, setCopiedRestaurantHours] = useState([]);
  const [menus, setMenus] = useState([]);
  const [categories, setCategories] = useState(null);
  const [sections, setSections] = useState([]);
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [showBusinessHourModal, setShowBusinessHourModal] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);

  const categoryModal = useRef(null);
  const storeCategoryInput = useRef([]);

  const rId = props.match.params.id;

  useEffect(() => {
    const currentUser = Parse.User.current();
    if (!currentUser) {
      return history.replace('/');
    }
    const initAccountPage = async () => {
      await Parse.Cloud.run('getMenuPageData', { rId: rId ? rId : user.restaurant_id })
        .then(async (result) => {
          setRestaurant(result.restaurant);
          setCopiedRestaurant(result.restaurant);
          setRestaurantHours(result.restaurantHours);
          setSections(result.sections);
          setMenus(result.data);
          setCategories(result.categories);
          setShowLoading(false);
          // if (result.restaurant.linked_services.instagram.username) {
          //   const accessToken = result.restaurant.linked_services.instagram.access_token;
          //   const results = await Parse.Cloud.run('getMyIgPostForPlaceDetail', {
          //     accessToken,
          //   });
          //   if (results.length) {
          //     setIgData(results);
          //   } else {
          //     setIgData([]);
          //   }
          // }
        })
        .catch((error) => {
          setShowLoading(false);
          console.log(error);
        });
    };
    initAccountPage();
  }, []);

  const handleUpdateLogo = async (uri) => {
    await Parse.Cloud.run('updateRestaurantLogo', { uri, rId: rId ? rId : user.restaurant_id }).then(() => {
      return;
    });
  };

  const onSaveSectionInHours = async (index, value) => {
    let copiedRestaurantHour = restaurantHours;
    copiedRestaurantHour[0].open[index].section = value;
    setRestaurantHours(copiedRestaurantHour);
    await Parse.Cloud.run('onSaveHours', { data: copiedRestaurantHour, rId: user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id })
      .then((result) => {
        console.log(result);
      })
      .catch((error) => alert(error));
  };

  const showEditBusinessHourModal = () => {
    setCopiedRestaurantHours([...restaurantHours]);
    setShowBusinessHourModal(true);
  };

  const handleSaveDescription = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('saveRestaurantDescription', { rId: rId ? rId : user.restaurant_id, data: restaurant.description }).then(() => {
      setShowLoading(false);
    });
  };

  const onSaveStoreCategories = async () => {
    setShowLoading(true);
    const array = storeCategoryInput.current.map((item) => {
      return { alias: item.value.toLowerCase(), title: item.value };
    });
    await Parse.Cloud.run('saveStoreCategories', { rId: rId ? rId : user.restaurant_id, data: array })
      .then(() => {
        console.log(categoryModal.current);
        categoryModal.current?.dismiss();
        setRestaurant({ ...restaurant, categories: [...array] });
        setShowLoading(false);
      })
      .catch((error) => {
        setShowLoading(false);
        categoryModal.current?.dismiss();
        console.log(error);
      });
  };

  const onSaveWebSite = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('updateWebSite', { rId, url: restaurant.web }).then((result) => {
      setShowLoading(false);
      if (!result) {
        console.log(result);
      }
    });
  };

  const onSaveReservation = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('updateReservationUrl', { rId, url: restaurant.reservation_url }).then((result) => {
      setShowLoading(false);
      if (!result) {
        console.log(result);
      }
    });
  };

  const takePicture = async () => {
    const result = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Camera,
    });
    setShowSpinner(true);
    const base64 = `${result.dataUrl}`;
    setRestaurant({ ...restaurant, logo: base64 });
    try {
      await Parse.Cloud.run('uploadRestaurantLogo', { base64, rId })
        .then((uploadedFile) => {
          updateUser({
            ...user,
            restaurant_logo: uploadedFile,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
    setShowSpinner(false);
  };

  const pickImage = async () => {
    const result = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Photos,
    });
    setShowSpinner(true);
    const base64 = `${result.dataUrl}`;
    setRestaurant({ ...restaurant, logo: base64 });
    try {
      await Parse.Cloud.run('uploadRestaurantLogo', { base64, rId })
        .then((uploadedFile) => {
          updateUser({
            ...user,
            restaurant_logo: uploadedFile,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
    setShowSpinner(false);
  };

  const pickLogo = async () => {
    const result = await FilePicker.pickFiles({
      types: ['image/png', 'image/jpg', 'image/heic'],
      multiple: false,
      readData: true,
    });
    const file = result.files[0];
    setShowSpinner(true);
    try {
      const base64 = `data:${file.mimeType};base64,${file.data}`;
      setRestaurant({ ...restaurant, logo: base64 });
      await Parse.Cloud.run('uploadRestaurantLogo', { base64, rId })
        .then((uploadedFile) => {
          updateUser({
            ...user,
            restaurant_logo: uploadedFile,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
    setShowSpinner(false);
  };

  const handleConnectInstagram = async () => {
    const clientId = process.env.REACT_APP_INSTAGRAM_CLIENT_ID;
    const redirectUri = 'https://join.onoonline.app/onboarding/store/instagram_auth/';
    console.log(platform);
    const result = await Browser.open({
      url: `https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=user_profile,user_media&response_type=code&state=${rId}`,
      windowName: platform.includes('desktop') || platform.includes('mobileweb') ? '_self' : '_blank',
    });
  };

  const unlinkInstagram = async (accessToken) => {
    setShowLoading(true);
    await Parse.Cloud.run('unlinkInstagram', {
      rId,
      accessToken,
    })
      .then((linkedServices) => {
        // setIgData([]);
        setShowLoading(false);
        setRestaurant({ ...restaurant, instagram: '', linked_services: linkedServices });
      })
      .catch((error) => {
        setShowLoading(false);
        console.log(error);
      });
  };

  const confirmStoreInfo = async () => {
    setShowLoading(true);
    try {
      await Parse.Cloud.run('updateStoreInfoStatus', { rId: rId ? rId : user.restaurant_id }).then((result) => {
        if (result) {
          console.log(result);
          history.replace(`/onboarding/${rId}`);
        }
        setShowLoading(false);
      });
    } catch (error) {
      setShowLoading(false);
      console.log(error);
    }
  };

  if (showLoading) {
    return <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={''} />;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' offset-md='1' size-lg='6' offset-lg='1'>
              <IonToolbar>
                <IonButtons slot='start'>
                  <IonBackButton />
                </IonButtons>
                <IonTitle>Store</IonTitle>
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>

      <IonPopover
        cssClass='my-custom-class'
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}>
        <IonItem
          onClick={(e) => {
            setShowPopover({ showPopover: false, event: e });
            history.push(`/dashboard/account/bank/${user.restaurant_id}`);
          }}>
          Bank
        </IonItem>
      </IonPopover>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='8' offset-md='2' size-lg='6' offset-lg='3'>
              <section className='ion-padding ion-text-center'>
                <IonRow className='ion-justify-content-center'>
                  <IonCol size='auto'>
                    <div style={{ margin: 'auto', position: 'relative', width: '12vh', height: '12vh' }}>
                      {!restaurant ? null : restaurant.logo ? (
                        <>
                          <IonAvatar
                            id={platform.includes('desktop') || platform.includes('mobileweb') ? 'popover-button1' : ''}
                            style={{ width: '12vh', height: '12vh' }}
                            onClick={platform.includes('desktop') || (platform.includes('mobileweb') ? pickLogo : null)}>
                            <IonImg src={restaurant.logo} />
                          </IonAvatar>
                          {platform.includes('desktop') || platform.includes('mobileweb') ? null : (
                            <IonPopover trigger='popover-button1' dismissOnSelect={true}>
                              <IonContent>
                                <IonList className='ion-no-padding'>
                                  <IonItem button={true} detail={false} onClick={takePicture}>
                                    Take photo
                                  </IonItem>
                                  <IonItem button={true} detail={false} onClick={pickImage}>
                                    Choose from library
                                  </IonItem>
                                  <IonItem button={true} detail={false} onClick={pickLogo}>
                                    Browse files
                                  </IonItem>
                                </IonList>
                              </IonContent>
                            </IonPopover>
                          )}
                        </>
                      ) : (
                        <>
                          <IonAvatar
                            id={platform.includes('desktop') || platform.includes('mobileweb') ? 'popover-button2' : ''}
                            style={{ width: '12vh', height: '12vh' }}
                            onClick={platform.includes('desktop') || (platform.includes('mobileweb') ? pickLogo : null)}>
                            <IonImg
                              src='https://fakeimg.pl/150x150/?retina=1&text=Logo&font=Montserrat'
                              style={{ width: '100px', height: '100px' }}
                            />
                          </IonAvatar>
                          {platform.includes('desktop') || platform.includes('mobileweb') ? null : (
                            <IonPopover trigger='popover-button2' dismissOnSelect={true}>
                              <IonContent>
                                <IonList className='ion-no-padding'>
                                  <IonItem button={true} detail={false} onClick={takePicture}>
                                    Take photo
                                  </IonItem>
                                  <IonItem button={true} detail={false} onClick={pickImage}>
                                    Choose from library
                                  </IonItem>
                                  <IonItem button={true} detail={false} onClick={pickLogo}>
                                    Browse files
                                  </IonItem>
                                </IonList>
                              </IonContent>
                            </IonPopover>
                          )}
                        </>
                      )}
                      {showSpinner ? (
                        <IonSpinner style={{ position: 'absolute', top: 0, right: '-5vh' }} name='crescent' color='danger'></IonSpinner>
                      ) : (
                        <>
                          <IonButton
                            id={platform.includes('desktop') || platform.includes('mobileweb') ? 'popover-button3' : ''}
                            style={{ position: 'absolute', top: 0, right: '-5vh' }}
                            size='small'
                            shape='round'
                            fill='clear'
                            onClick={platform.includes('desktop') || (platform.includes('mobileweb') ? pickLogo : null)}>
                            <IonIcon slot='icon-only' icon={imageOutline} color='dark' />
                          </IonButton>
                          {platform.includes('desktop') || platform.includes('mobileweb') ? null : (
                            <IonPopover trigger='popover-button3' dismissOnSelect={true}>
                              <IonContent>
                                <IonList className='ion-no-padding'>
                                  <IonItem button={true} detail={false} onClick={takePicture}>
                                    Take photo
                                  </IonItem>
                                  <IonItem button={true} detail={false} onClick={pickImage}>
                                    Choose from library
                                  </IonItem>
                                  <IonItem button={true} detail={false} onClick={pickLogo}>
                                    Browse files
                                  </IonItem>
                                </IonList>
                              </IonContent>
                            </IonPopover>
                          )}
                        </>
                      )}
                    </div>
                  </IonCol>
                </IonRow>
                <div style={{ margin: '2rem 0' }}>
                  <div style={{ fontFamily: 'BrandonBold', fontSize: '1.3rem' }}>{user.restaurant_name}</div>
                  {!restaurant ? null : (
                    <>
                      <div style={{ fontSize: '0.9rem', marginTop: '8px' }}>
                        {restaurant.categories.map((item, index) => (
                          <IonLabel key={index} style={{ marginRight: '7px' }}>
                            {item.title}
                          </IonLabel>
                        ))}
                      </div>
                      <div style={{ fontSize: '0.9rem', marginTop: '4px' }}>
                        <IonLabel>{restaurant.location.address1} </IonLabel>
                        {!restaurant.location.address2 ? null : <IonLabel>{restaurant.location.address2} </IonLabel>}
                        <IonLabel>{restaurant.location.address3}, </IonLabel>
                        <IonLabel>{restaurant.location.city}, </IonLabel>
                        <IonLabel>{restaurant.location.state} </IonLabel>
                        <IonLabel>{restaurant.location.zip_code}</IonLabel>
                        <div style={{ fontSize: '0.9rem', marginTop: '8px' }}>{restaurant.display_phone}</div>
                      </div>
                    </>
                  )}
                  <IonButton id='openCategoryModal' className='ion-margin-top'>
                    <IonIcon style={{ marginRight: '5px' }} size='small' icon={createOutline} /> Edit categories
                  </IonButton>
                  <IonModal
                    ref={categoryModal}
                    trigger='openCategoryModal'
                    keepContentsMounted={false}
                    // onDidDismiss={() => setShowStoreCategoryModal(false)}
                  >
                    <IonContent className='ion-padding'>
                      <IonRow className='ion-align-items-center'>
                        <IonCol size='auto'>
                          <IonButton
                            fill='clear'
                            onClick={() => {
                              categoryModal.current?.dismiss();
                            }}>
                            <IonIcon slot='icon-only' icon={closeOutline} />
                          </IonButton>
                        </IonCol>
                        <IonCol>
                          <div>Edit restaurant categories</div>
                        </IonCol>
                      </IonRow>
                      <h3 className='ion-text-center ion-margin-top' style={{ fontFamily: 'BrandonBold' }}>
                        Please register restaurant categories
                      </h3>
                      <div style={{ margin: '24px 0' }}>
                        {restaurant?.categories.map((item, index) => (
                          <IonItem key={index}>
                            <IonLabel slot='start'>{index + 1}.</IonLabel>
                            <IonInput value={item.title} ref={(ref) => (storeCategoryInput.current[index] = ref)} />
                          </IonItem>
                        ))}
                      </div>
                      <div className='ion-text-center' style={{ marginTop: '36px' }}>
                        <IonButton style={{ width: '70%' }} onClick={onSaveStoreCategories}>
                          Save
                        </IonButton>
                      </div>
                    </IonContent>
                  </IonModal>
                </div>
              </section>

              <section style={{ borderRadius: '10px', marginTop: '50px' }}>
                <IonRow className='ion-align-items-center'>
                  <IonCol>
                    <IonText style={{ fontFamily: 'BrandonBold', fontSize: '3vh' }}>1. Restaurant Bio</IonText>
                  </IonCol>
                </IonRow>
                <IonLabel style={{ fontSize: '0.7rem', marginTop: '12px' }}>Title</IonLabel>
                <IonInput
                  placeholder='Title'
                  value={restaurant?.description.title}
                  style={{ border: '1px solid grey', borderRadius: '5px' }}
                  onIonInput={(e) => setRestaurant({ ...restaurant, description: { ...restaurant.description, title: e.target.value } })}
                />
                <IonLabel style={{ fontSize: '0.7rem', marginTop: '12px' }}>Content</IonLabel>
                <IonTextarea
                  style={{ border: '1px solid grey', borderRadius: '5px', paddingHorizontal: '5px' }}
                  placeholder='Describe restaurant'
                  rows={2}
                  autoGrow={true}
                  value={restaurant?.description.content}
                  onIonInput={(e) => setRestaurant({ ...restaurant, description: { ...restaurant.description, content: e.target.value } })}
                />
                <IonButton
                  style={{ marginTop: '2em' }}
                  disabled={
                    restaurant?.description.title === copiedRestaurant?.description.title &&
                    restaurant?.description.content === copiedRestaurant?.description.content
                  }
                  onClick={handleSaveDescription}>
                  Save
                </IonButton>
              </section>

              {!restaurant ? null : (
                <section style={{ marginTop: '4em' }}>
                  {!restaurant || !categories || !sections || !restaurantHours ? null : (
                    <MenuHourComponent
                      rId={restaurant.objectId}
                      menus={menus}
                      categories={categories}
                      sections={sections}
                      setSections={setSections}
                      restaurantHours={restaurantHours ? restaurantHours : []}
                    />
                  )}
                </section>
              )}

              {!restaurant ? null : (
                <section style={{ marginTop: '4em' }}>
                  <IonText style={{ fontFamily: 'BrandonBold', fontSize: '3vh' }}>3. Restaurant business hours</IonText>
                  <br />
                  <IonText style={{ fontFamily: 'BrandonBold', fontSize: '3vh' }}>and menu section</IonText>

                  <IonGrid className='ion-padding-bottom' style={{ borderBottom: '1px solid grey' }}>
                    <IonRow>
                      <IonCol size-xs='auto' size-sm='3' size-md='3' size-lg='3'>
                        <div style={{ width: '12vh', fontSize: '1rem' }}>Days</div>
                      </IonCol>
                      <IonCol size-xs='auto' size-sm='3' size-md='5' size-lg='5'>
                        <div style={{ width: '15vh', fontSize: '1rem' }}>Hours</div>
                      </IonCol>
                      <IonCol size-xs='auto' size-sm='3' size-md='4' size-lg='4'>
                        <IonLabel style={{ fontSize: '1rem' }}>Applied Menu</IonLabel>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  <IonGrid className='ion-margin-top'>
                    {!restaurantHours.length && !sections.length
                      ? null
                      : restaurantHours[0].open.map((hour, index) => {
                          const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
                          let start = String(hour.start);
                          if (start === '0000') {
                            start = '000';
                          }
                          let end = String(hour.end);
                          if (end === '0000') {
                            end = '000';
                          }
                          let startHour;
                          let startMin = start.substr(-2);
                          let endHour;
                          let endMin = end.substr(-2);
                          if (start.length === 4) {
                            startHour = start.substring(0, 2);
                          } else {
                            startHour = start.substring(0, 1);
                          }
                          if (end.length === 4) {
                            endHour = end.substring(0, 2);
                          } else {
                            endHour = end.substring(0, 1);
                          }
                          let prevState = 0;
                          if (index > 0) {
                            prevState = restaurantHours[0].open[index - 1];
                          }

                          return (
                            <IonRow key={nanoid()} className='ion-align-items-center' style={{ paddingBottom: '6px' }}>
                              <IonCol size-xs='auto' size-sm='3' size-md='3' size-lg='3'>
                                {!prevState !== 0 && prevState.day === hour.day ? (
                                  <div style={{ fontFamily: 'Brandon', fontSize: '2.2vh', width: '12vh' }}></div>
                                ) : (
                                  <div style={{ fontFamily: 'Brandon', fontSize: '2.2vh', width: '12vh' }}>{days[hour.day]}</div>
                                )}
                              </IonCol>
                              {hour.is_open ? (
                                <>
                                  <IonCol size-xs='auto' size-sm='5' size-md='5' size-lg='5'>
                                    <div style={{ width: '15vh', fontSize: '2vh' }}>
                                      {Number(startHour)}:{startMin} - {Number(endHour)}:{endMin}
                                    </div>
                                  </IonCol>
                                  <IonCol size-sm='4' size-md='4' size-lg='4'>
                                    {/* <IonChip> */}
                                    <IonSelect
                                      id='select'
                                      style={{ fontSize: '2vh' }}
                                      value={hour.section}
                                      onIonInput={(e) => onSaveSectionInHours(index, e.detail.value)}>
                                      {sections.map((item, i) => (
                                        <IonSelectOption key={nanoid()} value={i}>
                                          {item.title}
                                        </IonSelectOption>
                                      ))}
                                    </IonSelect>
                                    {/* </IonChip> */}
                                  </IonCol>
                                </>
                              ) : (
                                <>
                                  <IonCol>
                                    {!prevState !== 0 && prevState.day === hour.day && !hour.is_open ? null : <IonLabel>Close</IonLabel>}
                                  </IonCol>
                                  <IonCol></IonCol>
                                </>
                              )}
                            </IonRow>
                          );
                        })}
                  </IonGrid>
                  <IonButton onClick={showEditBusinessHourModal} style={{ fontFamily: 'BrandonBold', margin: '24px 0', width: '50%' }}>
                    Edit Operation Hour
                  </IonButton>
                  <section style={{ marginTop: '5em' }}>
                    <h1>Links</h1>
                    <div className='ion-padding-horizontal ion-margin-top' style={{ border: '0.5px solid grey', borderRadius: '5px' }}>
                      <IonRow className='ion-margin-top'>
                        <div style={{ fontSize: '1.25em', fontFamily: 'BrandonBold' }}>Business url</div>
                      </IonRow>
                      <IonItem className='ion-no-padding'>
                        <IonInput
                          placeholder='https://your-restaurant-url.com'
                          value={restaurant?.web}
                          onIonInput={(e) => setRestaurant({ ...restaurant, web: e.target.value })}
                        />
                      </IonItem>
                      <IonButton className='ion-margin-vertical' disabled={restaurant?.web === copiedRestaurant?.web} onClick={onSaveWebSite}>
                        Save
                      </IonButton>
                      <div style={{ marginTop: '2em' }}>
                        <IonText style={{ fontSize: '1.25em', fontFamily: 'BrandonBold' }}>Instagram username</IonText>
                        <br />
                        <IonLabel color='medium'>
                          By connecting your restaurant's Instagram account, you will be able to display your Instagram posts on the store's detail
                          page.
                        </IonLabel>
                      </div>
                      {restaurant?.instagram ? (
                        <>
                          <IonItem className='ion-no-padding'>
                            <IonInput
                              placeholder='your-instagram-account'
                              value={restaurant.instagram}
                              inputMode='text'
                              disabled
                              onIonInput={(e) => setRestaurant({ ...restaurant, instagram: e.target.value })}
                            />
                          </IonItem>

                          <IonButton
                            className='ion-margin-vertical'
                            onClick={() => unlinkInstagram(restaurant?.linked_services.instagram.access_token)}>
                            Unlink
                          </IonButton>
                        </>
                      ) : (
                        <IonButton className='ion-margin-top' onClick={handleConnectInstagram}>
                          Connect Instagram Account
                        </IonButton>
                        // <IonButton
                        //   className='ion-margin-vertical'
                        //   disabled={restaurant.instagram === copiedRestaurant?.instagram}
                        //   onClick={onSaveInstagram}>
                        //   Connect your Instagram account
                        // </IonButton>
                      )}
                      <div style={{ marginTop: '2em' }}>
                        <IonText className='ion-padding-bottom' style={{ fontSize: '1.25em', fontFamily: 'BrandonBold' }}>
                          3rd party reservation service URL
                        </IonText>
                        <br />
                        <IonLabel color='medium'>
                          Are you using reservation management services like Opentable or Resy? If so, please paste the URL of your restaurant on the
                          reservation site below. Users will be able to make reservations through this link from the store's detail page.
                        </IonLabel>
                      </div>
                      <IonItem className='ion-no-padding ion-margin-top'>
                        <IonLabel position='stacked'>Link</IonLabel>
                        <IonInput
                          placeholder='https://opentable.com/??? or Resy?'
                          value={restaurant.reservation_url}
                          inputMode='text'
                          onIonInput={(e) => setRestaurant({ ...restaurant, reservation_url: e.target.value })}
                        />
                      </IonItem>
                      <IonButton
                        className='ion-margin-vertical'
                        disabled={restaurant.reservation_url === copiedRestaurant?.reservation_url}
                        onClick={onSaveReservation}>
                        Save
                      </IonButton>
                      <div style={{ marginTop: '2em' }}>
                        <IonText style={{ fontSize: '1.25em', fontFamily: 'BrandonBold' }}>yelp url - not editable</IonText>
                      </div>
                      <IonItem className='ion-no-padding' style={{ marginBottom: '3em' }}>
                        <IonInput disabled value={restaurant?.yelp_url} />
                      </IonItem>
                    </div>
                  </section>
                  <section className='ion-margin-vertical'>
                    <IonButton expand='block' size='large' color={'danger'} onClick={confirmStoreInfo}>
                      Confirm
                    </IonButton>
                  </section>
                </section>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />
      </IonContent>

      <EditBusinessHourModal
        rId={rId ? rId : user.restaurant_id}
        restaurantHours={restaurantHours}
        setRestaurantHours={setRestaurantHours}
        showBusinessHourModal={showBusinessHourModal}
        setShowBusinessHourModal={setShowBusinessHourModal}
      />
      <IonToolbar>
        <IonButtons slot='start'>
          <IonMenuButton />
        </IonButtons>
        <IonButtons slot='end'>
          <IonAvatar
            className='ion-margin-end'
            style={{ width: '36px', height: '36px' }}
            onClick={(e) => {
              //e.persist();
              setShowPopover({ showPopover: true, event: e });
            }}>
            {user.avatar ? <IonImg src={user.avatart} /> : <IonIcon icon={personCircle} size='large' color='primary' />}
          </IonAvatar>
        </IonButtons>
      </IonToolbar>
    </IonPage>
  );
};
export default StorePage;
