import { useContext, useEffect, useState } from 'react';
import { Link, NavLink, Redirect, Route } from 'react-router-dom';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { setupIonicReact } from '@ionic/react';
import { SplashScreen } from '@capacitor/splash-screen';
import { Network } from '@capacitor/network';
// import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
// import { FacebookLogin } from '@capacitor-community/facebook-login';
import {
  IonApp,
  IonAccordionGroup,
  IonAccordion,
  IonRouterOutlet,
  getPlatforms,
  IonLabel,
  IonSplitPane,
  IonMenu,
  IonContent,
  IonItem,
  IonItemGroup,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonMenuToggle,
  IonMenuButton,
  IonButtons,
  IonAlert,
} from '@ionic/react';
import { Storage } from '@ionic/storage';
import Parse from 'parse/dist/parse.min.js';
import { initializeParse, useParseQuery } from '@parse/react';
import { IonReactRouter } from '@ionic/react-router';
import useWindowDimensions from './hook/useWindowDimensions';
import LandingPage from './pages/LandingPage/LandingPage';
import AccountPage from './pages/AccountPage/AccountPage';
import BankPage from './pages/BankPage/BankPage';
import ReportPage from './pages/ReportPage/ReportPage';
import HomePage from './pages/HomePage/HomePage';
import LaunchPage from './pages/LaunchPage/LaunchPage';
import GetParamsPage from './pages/GetParamsPage';
import InvitedUserPage from './pages/InvitedUserPage/InvitedUserPage';
import InvitedUserIntroPage from './pages/InvitedUserIntroPage/InvitedUserIntroPage';
import InvitedUserInstagramPage from './pages/InvitedUserInstagramPage/InvitedUserInstagramPage';
import LoginPage from './pages/LoginPage/LoginPage';
import AdminPage from './pages/AdminPage';
import OnboardingPage from './pages/OnboardingPage/OnboardingPage';
import OauthPage from './pages/OauthPage';
import FacebookLogInAuth from './pages/FacebookLogInAuth';
import WebhookPage from './pages/WebhookPage';
import StorePage from './pages/StorePage/StorePage';
import MenuPage from './pages/MenuPage/MenuPage';
import MenuSectionPage from './pages/MenuPage/MenuSectionPage/MenuSectionPage';
import MenuCategoryPage from './pages/MenuPage/MenuCategoryPage/MenuCategoryPage';
import MenuHourPage from './pages/MenuPage/MenuHourPage/MenuHourPage';
import MenuSettingPage from './pages/MenuPage/MenuSettingPage/MenuSettingPage';
import ModifierGroupPage from './pages/MenuPage/ModifierGroupPage';
import ModifierItemsPage from './pages/MenuPage/ModifierItemsPage/ModifierItemsPage';
import OrderPage from './pages/OrderPage/OrderPage';
import TransactionPage from './pages/OrderPage/TransactionPage';
import OrderSettingPage from './pages/OrderPage/OrderSettingPage';
import MyPostPage from './pages/PostPage/MyPostPage';
import PostPage from './pages/PostPage/PostPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import FaqPage from './pages/Faq';
import TermsofUsePage from './pages/TermsofUsePage';
import SignupPage from './pages/SignupPage/SignupPage';
import SignupAdminPage from './pages/SignupAminPage/SignupAdminPage';
import TeamPage from './pages/TeamPage/TeamPage';
import MyAccountPage from './pages/MyAccount/MyAccountPage';
import WelcomePage from './pages/WelcomePage/WelcomePage';
import AboutUsPage from './pages/AboutUsPage/AboutUsPage';
import ContactPage from './pages/ContactPage/ContactPage';
import InstagramAuth from './pages/InstagramAuth';

import AppContext from './context/AppContext';

import momentTZ from 'moment-timezone';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Global css */
import './theme/global.css';

//Product version
Parse.initialize(process.env.REACT_APP_PARSE_APPLICATION_ID, process.env.REACT_APP_PARSE_JAVASCRIPT_KEY);
Parse.serverURL = 'https://onoonline.b4a.io/';

//Dev version
// Parse.initialize('QIwqMvdDr40ClrNn2vwtjGKgcLsH5PlV6APprS1R', 'pvK61AzxP8d7t7572TGL3ZlEzdwXlDcOd74Be1K5');
// Parse.serverURL = 'https://parseapi.back4app.com/';

//Just Test version
// Parse.initialize('abHJ4MkuEMPZjBo9GNFtq989s4pnjKfcl7ewBjLI', 'xF2p7AVyRusI165dOmmoH7snH1S52V8rGkEjEUu9');
// Parse.serverURL = 'https://parseapi.back4app.com/';

//initializeParse('https://onoonline.b4a.io/', 'c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY', 'EFjc0lhXiMSVk3wEw2mMAcicPNDLvzO4UhBLoARR');

const App = () => {
  const {
    updatePlatform,
    user,
    updateUser,
    orderBadge,
    updateIsAuthed,
    isAdmin,
    updateIsAdmin,
    isOnboarding,
    updateIsOnboarding,
    updateDeviceWidth,
  } = useContext(AppContext);

  const [clickedMenu, setClickedMenu] = useState('HOME');
  const [inMenu, setInMenu] = useState('ITEM');
  const [showConnectionAlert, setShowConnectionAlert] = useState(false);
  const [hasLink, setHasLink] = useState(false);

  const { width } = useWindowDimensions();

  defineCustomElements(window);
  setupIonicReact({
    mode: 'md',
  });

  // Use matchMedia to check the user preference

  let localTime = momentTZ().tz('Pacific/Honolulu').format('MMM DD YYYY');

  Network.addListener('networkStatusChange', (status) => {
    console.log('Network status changed', status);
  });

  const logCurrentNetworkStatus = async () => {
    const status = await Network.getStatus();
    console.log('Network status:', status);
    // if (!status.connected) {
    //   setShowConnectionAlert(true);
    // }
  };

  useEffect(() => {
    // handleLogOut();
    console.log('App js Render');
    logCurrentNetworkStatus();
    console.log('platform', getPlatforms());
    updatePlatform(getPlatforms());
    updateDeviceWidth(width);

    const getUser = async () => {
      // use hook after platform dom ready
      const currentUser = Parse.User.current();
      if (currentUser) {
        console.log('Current user');
        if (currentUser.get('type') === 'ADMIN') {
          const getLocalStorage = async () => {
            const store = new Storage();
            try {
              await store.create();
              const localValue = await store.get('restaurantId').then((result) => {
                return result;
              });
              console.log(localValue);
              updateUser({ ...user, id: currentUser.id, role: 'ADMIN', restaurant_id: localValue });
              updateIsAdmin(true);
              updateIsOnboarding(false);
            } catch (e) {
              console.log('storage get error');
            }
          };
          getLocalStorage();
        } else if (currentUser.get('type') === 'RESTAURANT') {
          await Parse.Cloud.run('getUserEditingRestaurant', { userId: currentUser.id }).then((result) => {
            if (!result) {
              console.log('error');
              return;
            } else {
              updateUser({
                ...user,
                id: currentUser.id,
                first_name: currentUser.get('first_name'),
                last_name: currentUser.get('last_name'),
                email: currentUser.get('email'),
                avatar: currentUser.get('avatar'),
                role: currentUser.get('role'),
                restaurant_id: result.rId,
                restaurant_name: result.rName,
                restaurant_logo: result.rLogo,
              });
            }
          });
          const location = window.location.pathname;
          if (location.split('/').includes('dashboard')) {
            updateIsOnboarding(false);
            if (location.indexOf('dashboard/home') > 0) {
              setClickedMenu('HOME');
            }
            if (location.indexOf('dashboard/store') > 0) {
              setClickedMenu('STORE');
            }
            if (location.indexOf('dashboard/menu') > 0) {
              setClickedMenu('MENU');
            }
            if (location.indexOf('dashboard/order') > 0) {
              setClickedMenu('ORDER');
            }
            if (location.indexOf('dashboard/post') > 0) {
              setClickedMenu('POST');
            }
            if (location.indexOf('dashboard/account') > 0) {
              setClickedMenu('ACCOUNT');
            }
          }
          window.addEventListener('popstate', (event) => {
            const page = event.target.location.pathname;
            if (page.indexOf('launch') > 0) {
              updateIsOnboarding(true);
            }
          });
        } else {
          console.log('Not restaurant');
        }
      } else {
        const path = window.location.pathname;

        if (path.includes('/invite/restaurant')) {
          const handleLogOut = async () => {
            await Parse.User.logOut().then(() => {
              updateIsOnboarding(true);
            });
          };
          handleLogOut();
          const searchParams = window.location.search;
          if (searchParams) {
            const urlParams = new URLSearchParams(searchParams);
            if (window.location.hostname === 'localhost') {
              return (window.location.href = `http://localhost:8100/invite/restaurant/${urlParams.get('rId')}`);
            } else {
              return (window.location.href = `https://join.onoonline.app/invite/restaurant/${urlParams.get('rId')}`);
            }
          }
        }
      }
      await SplashScreen.hide();
    };
    getUser();

    // const getDeeplink = async () => {
    //   await Deeplinks.route({
    //     '/home': () =>  <HomePage />,
    //     '/universal-links-test': ()=> <,
    //     '/products/:productId': ProductPage,
    //   }).subscribe(
    //     (match) => {
    //       // match.$route - the route we matched, which is the matched entry from the arguments to route()
    //       // match.$args - the args passed in the link
    //       // match.$link - the full link data
    //       console.log('Successfully matched route', match);
    //     },
    //     (nomatch) => {
    //       // nomatch.$link - the full link data
    //       console.error('Got a deep');
    //     }
    //   );
    // }
    // getDeeplink()
  }, []);

  // const orderQuery = new Parse.Query('Orders');
  // orderQuery.equalTo('restaurant_id', user.restaurant_id);
  // orderQuery.equalTo('status', 'OPEN');
  // const { isLive, isLoading, isSyncing, results, count, error, reload } = useParseQuery(orderQuery, {
  //   enabled: true, // Enables the parse query (default: true)
  //   enableLocalDatastore: true, // Enables cache in local datastore (default: true)
  //   enableLiveQuery: true, // Enables live query for real-time update (default: true)});
  // });

  // useEffect(() => {
  //   if (results) {
  //     console.log(`count=${results.length}`);
  //     updateOrderBadge(results.length);
  //   }
  // }, [results]);

  // const liveQuery = async () => {
  //   console.log(`Live query run`, user.restaurant_id);
  //   if (user.restaurant_id) {
  //     const query = new Parse.Query('Orders');
  //     query.equalTo('restaurant_id', user.restaurant_id);
  //     try {
  //       let subscription = await query.subscribe();
  //       subscription.on('create', (object) => {
  //         console.log(object);
  //         let newObj = JSON.stringify(object);
  //         newObj = JSON.parse(newObj);
  //         updateOrderBadge(orders.length + 1);
  //         updateOrders([...orders, newObj]);
  //       });
  //       subscription.on('update', (object) => {
  //         console.log(object);
  //         let copiedOrders = orders;
  //         let idArray = copiedOrders.map((item) => {
  //           return item.objectId;
  //         });
  //         let newObj = JSON.stringify(object);
  //         newObj = JSON.parse(newObj);
  //         let id = newObj.objectId;
  //         let index = idArray.indexOf(id);
  //         copiedOrders[index] = newObj;
  //         updateOrders([...copiedOrders]);
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   } else {
  //     return;
  //   }
  // };

  // if (isLive || isLoading || isSyncing) {
  //   liveQuery();
  // }

  const handleLogOut = async () => {
    await Parse.User.logOut().then(() => {
      updateIsAuthed(false);
      updateIsAdmin(false);
      updateIsOnboarding(true);
    });
  };

  if (showConnectionAlert) {
    return (
      <IonAlert
        //isOpen={showAlert}
        //onDidDismiss={() => setShowAlert(false)}
        header='Alert'
        subHeader='Please check internet connection'
        message=''
        buttons={['OK']}
      />
    );
  }

  const Router = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const rId = searchParams.get('rId');
    const email = searchParams.get('email');
    if (hasLink && isOnboarding) {
      console.log(rId, email);
      if (searchParams) {
        // localhost:8100/?rId=hh0ONtH3CP&where=account
        return (
          <IonRouterOutlet>
            {/* <Route exact path='/' component={LandingPage} /> */}
            {/* <Route
              exact
              path='/login/:id/:where'
              render={() => <Redirect to={`/login/${rId}/${searchParams.get('where')}`} />}
              component={LoginPage}
            /> */}

            {/* <Route path='/launch/:id' component={LaunchPage} exact /> */}
          </IonRouterOutlet>
        );
      } else {
        console.log('else');
      }
    }
    if (isOnboarding) {
      return (
        <IonRouterOutlet>
          <Route
            path='/invite/restaurant/:rId'
            render={() => <Redirect to={`/invite/restaurant?rId=${rId.toString()}`} />}
            component={GetParamsPage}
            exact={true}
          />
          {/* <Route path='/invite/:rId' render={() => <Redirect to={`/invite?restaurant=${rId.toString()}`} />} component={GetParamsPage} exact={true} /> */}
          {/* <Route exact path='/' component={LandingPage} /> */}
          <Route path='/' component={WelcomePage} exact={true} />
          <Route path='/about' component={AboutUsPage} exact={true} />
          <Route path='/login' component={LoginPage} exact={true} />
          <Route path='/launch/:id' component={LaunchPage} exact={true} />
          <Route path='/signup' component={SignupPage} exact={true} />
          <Route path='/signup/admin' component={SignupAdminPage} exact={true} />
          <Route path='/signup/invite/restaurant' component={InvitedUserPage} exact={true} />
          <Route path='/signup/invite/restaurant/introduction' component={InvitedUserIntroPage} exact={true} />
          <Route path='/signup/invite/restaurant/instagram' component={InvitedUserInstagramPage} exact={true} />
          <Route path='/privacypolicy' component={PrivacyPolicyPage} exact={true} />
          <Route path='/faq' component={FaqPage} exact={true} />
          <Route path='/termsofuse' component={TermsofUsePage} exact={true} />
          <Route path='/oauth/square' component={OauthPage} exact={true} />
          <Route path='/oauth/clover' component={OauthPage} exact={true} />
          <Route path='/oauth/ubereats' component={OauthPage} exact={true} />
          <Route path='/oauth/facebook' component={FacebookLogInAuth} exact={true} />
          <Route path='/onboarding/:id' component={OnboardingPage} exact={true} />
          <Route path='/onboarding/store/:id' component={StorePage} exact={true} />
          <Route path='/onboarding/store/instagram_auth/' component={InstagramAuth} exact={true} />
          <Route path='/dashboard/store/instagram_auth/' component={InstagramAuth} exact={true} />
          <Route path='/dashboard/contact' component={ContactPage} exact={true} />
          <Route path='/onboarding/account/:id' component={AccountPage} exact={true} />
          <Route path='/onboarding/bank/:id' component={BankPage} exact={true} />
        </IonRouterOutlet>
      );
    } else if (isAdmin) {
      return (
        <IonSplitPane contentId='adminMenu' when='md'>
          <IonMenu side='start' contentId='adminMenu'>
            <IonHeader>
              <IonToolbar>
                <IonButtons slot='start'>
                  <IonMenuButton menu='adminMenu' autoHide={false}></IonMenuButton>
                </IonButtons>
                <IonTitle>
                  ADMIN
                  <IonMenuToggle autoHide={false}></IonMenuToggle>
                </IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonItem onClick={() => setClickedMenu('HOME')}>
                <NavLink activeClassName='active' className='navLink' exact to={`/admin/${user.id}/home/${user.restaurant_id}`}>
                  Home
                </NavLink>
              </IonItem>
              <IonItem onClick={() => setClickedMenu('STORE')}>
                <IonLabel>
                  <NavLink activeClassName='active' className='navLink' exact to={`/admin/${user.id}/store/${user.restaurant_id}`}>
                    Store
                  </NavLink>
                </IonLabel>
              </IonItem>
              <IonAccordionGroup value={clickedMenu}>
                <IonAccordion value='MENU'>
                  <IonItem slot='header'>
                    <IonLabel style={{ color: clickedMenu === 'MENU' && '#FC0FC0', fontFamily: 'BrandonBold' }}>Menu Manager</IonLabel>
                  </IonItem>
                  <div slot='content'>
                    <IonItemGroup>
                      <IonItem
                        onClick={() => {
                          setClickedMenu('MENU');
                          setInMenu('ITEM');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/admin/${user.id}/menu/${user.restaurant_id}`}>
                            Items
                          </NavLink>
                        </IonLabel>
                      </IonItem>
                      <IonItem
                        onClick={() => {
                          setClickedMenu('MENU');
                          setInMenu('CATEGORY');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/admin/${user.id}/menu/category/${user.restaurant_id}`}>
                            Category
                          </NavLink>
                        </IonLabel>
                      </IonItem>
                      <IonItem
                        onClick={() => {
                          setClickedMenu('MENU');
                          setInMenu('MODIFIERGROUP');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink
                            activeClassName='active'
                            className='navLink'
                            exact
                            to={`/admin/${user.id}/menu/modifiergroup/${user.restaurant_id}`}>
                            Modifier Group
                          </NavLink>
                        </IonLabel>
                      </IonItem>
                      <IonItem
                        onClick={() => {
                          setClickedMenu('MENU');
                          setInMenu('MODIFIERITEM');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink
                            activeClassName='active'
                            className='navLink'
                            exact
                            to={`/admin/${user.id}/menu/modifieritems/${user.restaurant_id}`}>
                            Modifier Item
                          </NavLink>
                        </IonLabel>
                      </IonItem>
                      {/* <IonItem
                        onClick={() => {
                          setClickedMenu('MENU');
                          setInMenu('HOUR');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/admin/${user.id}/menu/hour/${user.restaurant_id}`}>
                            Menu Hours
                          </NavLink>
                        </IonLabel>
                      </IonItem> */}
                      <IonItem
                        onClick={() => {
                          setClickedMenu('MENU');
                          setInMenu('SETTING');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/admin/${user.id}/menu/setting/${user.restaurant_id}`}>
                            Settings
                          </NavLink>
                        </IonLabel>
                      </IonItem>
                    </IonItemGroup>
                  </div>
                </IonAccordion>
                <IonAccordion value='POST'>
                  <IonItem slot='header'>
                    <IonLabel style={{ color: clickedMenu === 'POST' && '#FC0FC0', fontFamily: 'BrandonBold' }}>Posts</IonLabel>
                  </IonItem>
                  <div slot='content'>
                    <IonItem
                      onClick={() => {
                        setClickedMenu('POST');
                        setInMenu('MYPOST');
                      }}>
                      <IonLabel className='ion-padding-start'>
                        <NavLink activeClassName='active' className='navLink' exact to={`/admin/${user.id}/mypost/${user.restaurant_id}`}>
                          My Posts
                        </NavLink>
                      </IonLabel>
                    </IonItem>
                    <IonItem
                      onClick={() => {
                        setClickedMenu('POST');
                        setInMenu('POST');
                      }}>
                      <IonLabel className='ion-padding-start'>
                        <NavLink activeClassName='active' className='navLink' exact to={`/admin/${user.id}/post/${user.restaurant_id}`}>
                          Make Post
                        </NavLink>
                      </IonLabel>
                    </IonItem>
                  </div>
                </IonAccordion>
                <IonAccordion value='ORDER'>
                  <IonItem slot='header'>
                    <IonLabel style={{ color: clickedMenu === 'ORDER' && '#FC0FC0', fontFamily: 'BrandonBold' }}>
                      Orders
                      {orderBadge && (
                        <span className='ion-margin-start' style={{ backgroundColor: 'skyblue', color: 'white', borderRadius: '1em', width: '2em' }}>
                          {orderBadge}
                        </span>
                      )}
                    </IonLabel>
                  </IonItem>
                  <div slot='content'>
                    <IonItemGroup>
                      <IonItem
                        onClick={() => {
                          setClickedMenu('ORDER');
                          setInMenu('ORDER');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/admin/${user.id}/orders/${user.restaurant_id}`}>
                            Orders
                          </NavLink>
                          {orderBadge && (
                            <span className='ion-margin-start' style={{ backgroundColor: 'skyblue', borderRadius: '1em', width: '2em' }}>
                              {orderBadge}
                            </span>
                          )}
                        </IonLabel>
                      </IonItem>
                      <IonItem
                        onClick={() => {
                          setClickedMenu('ORDER');
                          setInMenu('TRANSACTION');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink
                            activeClassName='active'
                            className='navLink'
                            exact
                            to={`/admin/${user.id}/orders/transaction/${user.restaurant_id}`}>
                            Transactions
                          </NavLink>
                        </IonLabel>
                      </IonItem>
                      <IonItem
                        onClick={() => {
                          setClickedMenu('ORDER');
                          setInMenu('ORDERSETTING');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/admin/${user.id}/orders/settings/${user.restaurant_id}`}>
                            Settings
                          </NavLink>
                        </IonLabel>
                      </IonItem>
                    </IonItemGroup>
                  </div>
                </IonAccordion>
                <IonAccordion value='ACCOUNT'>
                  <IonItem slot='header'>
                    <IonLabel style={{ color: clickedMenu === 'ACCOUNT' && '#FC0FC0', fontFamily: 'BrandonBold' }}>Account</IonLabel>
                  </IonItem>
                  <div slot='content'>
                    <IonItemGroup>
                      {/* <IonItem
                        onClick={() => {
                          setClickedMenu('ACCOUNT');
                          setInMenu('LOCATION');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/admin/${user.id}/account/${user.restaurant_id}`}>
                            Location
                          </NavLink>
                        </IonLabel>
                      </IonItem> */}
                      <IonItem
                        onClick={() => {
                          setClickedMenu('ACCOUNT');
                          setInMenu('BANK');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/admin/${user.id}/account/bank/${user.restaurant_id}`}>
                            Bank
                          </NavLink>
                        </IonLabel>
                      </IonItem>
                      <IonItem
                        onClick={() => {
                          setClickedMenu('ACCOUNT');
                          setInMenu('REPORT');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/admin/${user.id}/account/report/${user.restaurant_id}`}>
                            Report
                          </NavLink>
                        </IonLabel>
                      </IonItem>
                      <IonItem
                        onClick={() => {
                          setClickedMenu('ACCOUNT');
                          setInMenu('TEAM');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/admin/${user.id}/account/team/${user.restaurant_id}`}>
                            Team
                          </NavLink>
                        </IonLabel>
                      </IonItem>
                      <IonItem onClick={handleLogOut} href={'/'}>
                        <IonLabel className='ion-padding-start'>Log out</IonLabel>
                      </IonItem>
                    </IonItemGroup>
                  </div>
                </IonAccordion>
              </IonAccordionGroup>
            </IonContent>
          </IonMenu>
          <IonRouterOutlet id='adminMenu'>
            <Route exact path='/' component={WelcomePage} />
            <Route path='/admin/:adminId' component={AdminPage} exact={true} />
            <Route path='/admin/:adminId/home/:id' component={HomePage} />
            <Route path='/admin/:adminId/store/:id' component={AccountPage} />
            <Route path='/admin/:adminId/menu/:id' component={MenuPage} exact={true} />
            <Route path='/admin/:adminId/menu/section/:id' component={MenuSectionPage} exact={true} />
            <Route path='/admin/:adminId/menu/category/:id' component={MenuCategoryPage} exact={true} />
            <Route path='/admin/:adminId/menu/modifiergroup/:id' component={ModifierGroupPage} exact={true} />
            <Route path='/admin/:adminId/menu/modifieritems/:id' component={ModifierItemsPage} exact={true} />
            <Route path='/admin/:adminId/menu/hour/:id' component={MenuHourPage} exact={true} />
            <Route path='/admin/:adminId/menu/setting/:id' component={MenuSettingPage} exact={true} />
            <Route path='/admin/:adminId/mypost/:id' component={MyPostPage} exact={true} />
            <Route path='/admin/:adminId/post/:id' component={PostPage} exact={true} />
            <Route path='/admin/:adminId/orders/:id' component={OrderPage} exact={true} />
            <Route path='/admin/:adminId/orders/transaction/:id' component={TransactionPage} exact={true} />
            <Route path='/admin/:adminId/orders/settings/:id' component={OrderSettingPage} exact={true} />
            {/* <Route path='/admin/:adminId/account/:id' component={AccountPage} exact={true} /> */}
            <Route path='/admin/:adminId/account/bank/:id' component={BankPage} exact={true} />
            {/* <Route path='/admin/:adminId/account/report/:id' component={BankPage} exact={true} /> */}
            <Route path='/admin/:adminId/account/team/:id' component={TeamPage} exact={true} />
            <Route path='/webhook/square/catalog_updated' component={WebhookPage} exact={true} />
            <Route path='/oauth/square' component={OauthPage} exact={true} />
            <Route path='/oauth/ubereats' component={OauthPage} exact={true} />
          </IonRouterOutlet>
        </IonSplitPane>
      );
    } else {
      // if (platform.includes('desktop')) {
      return (
        <IonSplitPane contentId='menu' when='md'>
          <IonMenu side='start' contentId='menu' type='overlay' className='customMenu'>
            <IonHeader>
              <IonToolbar>
                <IonButtons slot='start'>
                  <IonMenuButton menu='menu' autoHide={true}></IonMenuButton>
                </IonButtons>
                <IonTitle>
                  {localTime}
                  <IonMenuToggle autoHide={true}></IonMenuToggle>
                </IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonItem onClick={() => setClickedMenu('HOME')}>
                <NavLink activeClassName='active' className='navLink' exact to={`/dashboard/home/${user.restaurant_id}`}>
                  Home
                </NavLink>
              </IonItem>
              <IonItem onClick={() => setClickedMenu('STORE')}>
                <NavLink activeClassName='active' className='navLink' exact to={`/dashboard/store/${user.restaurant_id}`}>
                  <IonLabel style={{ color: clickedMenu === 'STORE' && '#FC0FC0', fontFamily: 'BrandonBold' }}>Store</IonLabel>
                </NavLink>
              </IonItem>
              <IonAccordionGroup value={clickedMenu}>
                <IonAccordion value='MENU'>
                  <IonItem slot='header'>
                    <IonLabel style={{ color: clickedMenu === 'MENU' && '#FC0FC0', fontFamily: 'BrandonBold' }}>Menu Manager</IonLabel>
                  </IonItem>
                  <div slot='content'>
                    <IonItemGroup>
                      <IonItem
                        onClick={() => {
                          setClickedMenu('MENU');
                          setInMenu('ITEM');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/dashboard/menu/${user.restaurant_id}`}>
                            <IonLabel color='dark'>Items</IonLabel>
                          </NavLink>
                        </IonLabel>
                      </IonItem>
                      <IonItem
                        onClick={() => {
                          setClickedMenu('MENU');
                          setInMenu('CATEGORY');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/dashboard/menu/category/${user.restaurant_id}`}>
                            <IonLabel color='dark'>Category</IonLabel>
                          </NavLink>
                        </IonLabel>
                      </IonItem>
                      <IonItem
                        onClick={() => {
                          setClickedMenu('MENU');
                          setInMenu('MODIFIERGROUP');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/dashboard/menu/modifiergroup/${user.restaurant_id}`}>
                            Modifier Group
                          </NavLink>
                        </IonLabel>
                      </IonItem>
                      <IonItem
                        onClick={() => {
                          setClickedMenu('MENU');
                          setInMenu('MODIFIERITEM');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/dashboard/menu/modifieritems/${user.restaurant_id}`}>
                            Modifier Item
                          </NavLink>
                        </IonLabel>
                      </IonItem>
                      {/* <IonItem
                        onClick={() => {
                          setClickedMenu('MENU');
                          setInMenu('HOUR');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/dashboard/menu/hour/${user.restaurant_id}`}>
                            Menu Hours
                          </NavLink>
                        </IonLabel>
                      </IonItem> */}
                      <IonItem
                        onClick={() => {
                          setClickedMenu('MENU');
                          setInMenu('SETTING');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/dashboard/menu/setting/${user.restaurant_id}`}>
                            Settings
                          </NavLink>
                        </IonLabel>
                      </IonItem>
                    </IonItemGroup>
                  </div>
                </IonAccordion>
                <IonAccordion value='POST'>
                  <IonItem slot='header'>
                    <IonLabel style={{ color: clickedMenu === 'POST' && '#FC0FC0', fontFamily: 'BrandonBold' }}>Posts</IonLabel>
                  </IonItem>
                  <div slot='content'>
                    <IonItem
                      onClick={() => {
                        setClickedMenu('POST');
                        setInMenu('MYPOST');
                      }}>
                      <IonLabel className='ion-padding-start'>
                        <NavLink activeClassName='active' className='navLink' exact to={`/dashboard/mypost/${user.restaurant_id}`}>
                          My Posts
                        </NavLink>
                      </IonLabel>
                    </IonItem>
                    <IonItem
                      onClick={() => {
                        setClickedMenu('POST');
                        setInMenu('POST');
                      }}>
                      <IonLabel className='ion-padding-start'>
                        <NavLink activeClassName='active' className='navLink' exact to={`/dashboard/post/${user.restaurant_id}`}>
                          Make Post
                        </NavLink>
                      </IonLabel>
                    </IonItem>
                  </div>
                </IonAccordion>
                <IonAccordion value='ORDER'>
                  <IonItem slot='header'>
                    <IonLabel style={{ color: clickedMenu === 'ORDER' && '#FC0FC0', fontFamily: 'BrandonBold' }}>
                      Orders
                      {orderBadge && (
                        <span className='ion-margin-start' style={{ backgroundColor: 'skyblue', color: 'white', borderRadius: '1em', width: '2em' }}>
                          {orderBadge}
                        </span>
                      )}
                    </IonLabel>
                  </IonItem>
                  <div slot='content'>
                    <IonItemGroup>
                      <IonItem
                        onClick={() => {
                          setClickedMenu('ORDER');
                          setInMenu('ORDER');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/dashboard/order/${user.restaurant_id}`}>
                            Orders
                          </NavLink>
                          {orderBadge && (
                            <span className='ion-margin-start' style={{ backgroundColor: 'skyblue', borderRadius: '1em', width: '2em' }}>
                              {orderBadge}
                            </span>
                          )}
                        </IonLabel>
                      </IonItem>
                      <IonItem
                        onClick={() => {
                          setClickedMenu('ORDER');
                          setInMenu('TRANSACTION');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/dashboard/order/transaction/${user.restaurant_id}`}>
                            Transactions
                          </NavLink>
                        </IonLabel>
                      </IonItem>
                      <IonItem
                        onClick={() => {
                          setClickedMenu('ORDER');
                          setInMenu('ORDERSETTING');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/dashboard/order/settings/${user.restaurant_id}`}>
                            Settings
                          </NavLink>
                        </IonLabel>
                      </IonItem>
                    </IonItemGroup>
                  </div>
                </IonAccordion>
                <IonAccordion value='ACCOUNT'>
                  <IonItem slot='header'>
                    <IonLabel style={{ color: clickedMenu === 'ACCOUNT' && '#FC0FC0', fontFamily: 'BrandonBold' }}>Account</IonLabel>
                  </IonItem>
                  <div slot='content'>
                    <IonItemGroup>
                      <IonItem
                        onClick={() => {
                          setClickedMenu('ACCOUNT');
                          setInMenu('BANK');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/dashboard/account/bank/${user.restaurant_id}`}>
                            Bank
                          </NavLink>
                        </IonLabel>
                      </IonItem>
                      <IonItem
                        onClick={() => {
                          setClickedMenu('ACCOUNT');
                          setInMenu('REPORT');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/dashboard/account/report/${user.restaurant_id}`}>
                            Report
                          </NavLink>
                        </IonLabel>
                      </IonItem>
                      <IonItem
                        onClick={() => {
                          setClickedMenu('ACCOUNT');
                          setInMenu('TEAM');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/dashboard/account/team/${user.restaurant_id}`}>
                            Team
                          </NavLink>
                        </IonLabel>
                      </IonItem>
                      <IonItem
                        onClick={() => {
                          setClickedMenu('ACCOUNT');
                          setInMenu('MYACCOUNT');
                        }}>
                        <IonLabel className='ion-padding-start'>
                          <NavLink activeClassName='active' className='navLink' exact to={`/dashboard/account/myaccount/${user.id}`}>
                            My Account
                          </NavLink>
                        </IonLabel>
                      </IonItem>
                      <IonItem onClick={handleLogOut} href={'/'}>
                        <IonLabel className='ion-padding-start'>Log out</IonLabel>
                      </IonItem>
                    </IonItemGroup>
                  </div>
                </IonAccordion>
              </IonAccordionGroup>
              <IonItem />
              <IonItem
                onClick={() => {
                  setClickedMenu('CONTACT');
                  setInMenu('CONTACT');
                }}>
                <IonLabel>
                  <NavLink className='navLink' exact to={`/dashboard/contact`}>
                    <IonLabel color='dark'>Contact us</IonLabel>
                  </NavLink>
                </IonLabel>
              </IonItem>
              <IonItem
                onClick={() => {
                  setClickedMenu('PRIVACYPOLICY');
                  setInMenu('PRIVACYPOLICY');
                }}>
                <IonLabel>
                  <NavLink className='navLink' exact to={`/privacypolicy`}>
                    <IonLabel color='dark'>Privacy policy</IonLabel>
                  </NavLink>
                </IonLabel>
              </IonItem>
              <IonItem
                onClick={() => {
                  setClickedMenu('TERMSOFUSE');
                  setInMenu('TERMSOFUSE');
                }}>
                <IonLabel>
                  <NavLink className='navLink' exact to={`/termsofuse`}>
                    <IonLabel color='dark'>Terms of use</IonLabel>
                  </NavLink>
                </IonLabel>
              </IonItem>
            </IonContent>
          </IonMenu>
          <IonRouterOutlet id='menu'>
            <Route path='/dashboard' component={HomePage} exact={true} />
            <Route path='/dashboard/home/:id' component={HomePage} exact={true} />
            <Route path='/dashboard/store/:id' component={AccountPage} exact={true} />
            <Route path='/dashboard/store/instagram_auth/' component={InstagramAuth} exact={true} />
            <Route path='/dashboard/menu/:id' component={MenuPage} exact={true} />
            <Route path='/dashboard/menu/section/:id' component={MenuSectionPage} exact={true} />
            <Route path='/dashboard/menu/category/:id' component={MenuCategoryPage} exact={true} />
            <Route path='/dashboard/menu/modifiergroup/:id' component={ModifierGroupPage} exact={true} />
            <Route path='/dashboard/menu/modifieritems/:id' component={ModifierItemsPage} exact={true} />
            <Route path='/dashboard/menu/hour/:id' component={MenuHourPage} exact={true} />
            <Route path='/dashboard/menu/setting/:id' component={MenuSettingPage} exact={true} />
            <Route path='/dashboard/mypost/:id' component={MyPostPage} exact={true} />
            <Route path='/dashboard/post/:id' component={PostPage} exact={true} />
            <Route path='/dashboard/order/:id' component={OrderPage} exact={true} />
            <Route path='/dashboard/order/transaction/:id' component={TransactionPage} exact={true} />
            <Route path='/dashboard/order/settings/:id' component={OrderSettingPage} exact={true} />
            {/* <Route path='/dashboard/account/:id' component={AccountPage} exact={true} /> */}
            <Route path='/dashboard/account/bank/:id' component={BankPage} exact={true} />
            <Route path='/dashboard/account/report/:id' component={ReportPage} exact={true} />
            <Route path='/dashboard/account/team/:id' component={TeamPage} exact={true} />
            <Route path='/dashboard/account/myaccount/:id' component={MyAccountPage} exact={true} />
            <Route path='/privacypolicy' component={PrivacyPolicyPage} exact={true} />
            <Route path='/termsofuse' component={TermsofUsePage} exact={true} />
            <Route path='/dashboard/contact' component={ContactPage} exact={true} />
            <Route path='/webhook/square/catalog_updated' component={WebhookPage} exact={true} />
            <Route path='/oauth/square' component={OauthPage} exact={true} />
            <Route path='/oauth/clover' component={OauthPage} exact={true} />
            <Route path='/oauth/ubereats' component={OauthPage} exact={true} />
          </IonRouterOutlet>
        </IonSplitPane>
      );
      // } else {
      //   return (
      //     <IonTabs>
      //       <IonRouterOutlet>
      //         <Route path={`/dashboard/home/:id`} component={HomePage} exact={true} />
      //         <Route path={`/dashboard/menu/:id`} component={MenuPage} exact={true} />
      //         <Route path={`/dashboard/post/:id`} component={PostPage} exact={true} />
      //         <Route path={`/dashboard/orders/:id`} component={OrderPage} exact={true} />
      //         <Route path={`/dashboard/account/:id`} component={AccountPage} exact={true} />
      //         <Route path={`/dashboard/account/bank/:id`} component={BankPage} exact={true} />
      //         <Route path={`/dashboard/account/team:id`} component={TeamPage} exact={true} />
      //         <Route path='/privacypolicy' component={PrivacyPolicyPage} exact={true} />
      //       </IonRouterOutlet>
      //       <IonTabBar id='mainTabBar' slot='bottom' style={{ backgroundColor: '#333' }}>
      //         <IonTabButton tab='home' href={`/dashboard/home/${user.restaurant_id}`}>
      //           <IonIcon icon={home} />
      //           <IonLabel>Home</IonLabel>
      //         </IonTabButton>
      //         <IonTabButton tab='menu' href={`/dashboard/menu/${user.restaurant_id}`}>
      //           <IonIcon icon={bookOutline} />
      //           <IonLabel>Menus</IonLabel>
      //         </IonTabButton>
      //         <IonTabButton tab='order' href={`/dashboard/orders/${user.restaurant_id}`}>
      //           <IonIcon icon={receiptOutline} />
      //           <IonBadge>{orderBadge}</IonBadge>
      //           <IonLabel>Orders</IonLabel>
      //         </IonTabButton>
      //         <IonTabButton tab='post' href={`/dashboard/post/${user.restaurant_id}`}>
      //           <IonIcon icon={cameraOutline} />
      //           <IonLabel>Post</IonLabel>
      //         </IonTabButton>
      //         <IonTabButton tab='account' href={`/dashboard/account/${user.restaurant_id}`}>
      //           <IonIcon icon={personCircleOutline} />
      //           <IonLabel>Account</IonLabel>
      //         </IonTabButton>
      //       </IonTabBar>
      //     </IonTabs>
      //   );
      // }
    }
  };

  return (
    <IonApp>
      <IonReactRouter>
        <Router />
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
