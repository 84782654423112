import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonContent,
  IonPage,
  IonLabel,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonMenuButton,
  IonItem,
  IonToggle,
  IonFooter,
  IonList,
  IonListHeader,
  IonButton,
  IonInput,
  IonLoading,
} from '@ionic/react';
import AppContext from '../../context/AppContext';
import Parse from 'parse/dist/parse.min.js';

import './OrderPage.css';

const OrderSettingPage = (props) => {
  const history = useHistory();
  const { updateIsOnboarding, deviceWidth, user } = useContext(AppContext);

  const [showLoading, setShowLoading] = useState(false);
  const [newStatus, setNewStatus] = useState(null);
  const [currentStatus, setCurrentStatus] = useState({
    phone: {
      status: false,
      number: '',
    },
    email: {
      status: false,
      email: '',
    },
    sms: {
      status: false,
      number: '',
    },
  });

  const rId = props.match.params.id;

  useEffect(() => {
    const currentUser = Parse.User.current();
    if (!currentUser) {
      updateIsOnboarding(true);
      return history.replace('/');
    }

    const getRestaurant = async () => {
      console.log('getConfirmation run');
      await Parse.Cloud.run('getOrderConfirmations', { rId: rId ? rId : user.restaurant_id }).then((result) => {
        if (!result) {
          alert('Please check internet connection');
        } else {
          console.log(result);
          //setCurrentStatus(result);
          setNewStatus(result);
        }
      });
    };
    getRestaurant();
  }, []);

  const saveOrderConfirmation = async () => {
    if (newStatus.phone.status && String(newStatus.phone.number).length !== 10) {
      alert('Please check phone number');
      return;
    }
    if (newStatus.sms.status && String(newStatus.sms.number).length !== 10) {
      alert('Please check phone number');
      return;
    }
    if (newStatus.email.status && newStatus.email.email.length < 3) {
      alert('Please check email');
      return;
    }
    const data = {
      phone: {
        status: newStatus.phone.status,
        number: newStatus.phone.number.length ? `+1${newStatus.phone.number}` : '',
      },
      email: {
        status: newStatus.email.status,
        email: newStatus.email.email.length ? newStatus.email.email : '',
      },
      sms: {
        status: newStatus.sms.status,
        number: newStatus.sms.number ? `+1${newStatus.sms.number}` : '',
      },
    };
    setShowLoading(true);
    await Parse.Cloud.run('changeOrderConfirmations', { rId: rId ? rId : user.restaurant_id, data }).then(() => {
      setShowLoading(false);
    });
  };

  const makeOrderPhoneCall = async () => {
    await Parse.Cloud.run('makeOrderPhoneCall');
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Order setting</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className='ion-padding'>
        {!newStatus ? null : (
          <IonGrid>
            <IonRow>
              <IonCol className='ion-no-padding' size-xs='12' size-sm='10' offset-sm='1' size-md='10' size-lg='8'>
                <IonList>
                  <IonListHeader className='ion-text-center ion-no-padding'>
                    <IonLabel className='vh275 bold'>How would you like to be notified?</IonLabel>
                  </IonListHeader>
                  <div className='ion-padding' style={{ borderRadius: '10px', marginTop: '24px' }}>
                    <IonItem>
                      <IonLabel className='vh250 bold'>Phone call</IonLabel>
                      <IonToggle
                        //ref={phoneToggleRef}
                        slot='end'
                        checked={newStatus.phone.status}
                        onIonInput={() => setNewStatus({ ...newStatus, phone: { ...newStatus.phone, status: !newStatus.phone.status } })}
                      />
                    </IonItem>
                    {!newStatus.phone.status ? null : (
                      <IonItem className='ion-align-items-end'>
                        <IonLabel className='onoPink' position='stacked'>
                          Enter Phone number
                        </IonLabel>
                        <IonInput
                          value={newStatus.phone.number}
                          type='tel'
                          inputmode='tel'
                          size='tel'
                          minlength={10}
                          maxlength={10}
                          placeholder='808*******'
                          onIonInput={(e) => setNewStatus({ ...newStatus, phone: { ...newStatus.phone, number: e.target.value } })}
                        />
                      </IonItem>
                    )}
                  </div>
                  <div className='ion-padding ion-margin-top' style={{ borderRadius: '10px' }}>
                    <IonItem>
                      <IonLabel className='vh250 bold'>Email</IonLabel>
                      <IonToggle
                        slot='end'
                        checked={newStatus.email.status}
                        onIonInput={() => setNewStatus({ ...newStatus, email: { ...newStatus.email, status: !newStatus.email.status } })}
                      />
                    </IonItem>
                    {!newStatus.email.status ? null : (
                      <IonItem className='ion-align-items-end'>
                        <IonLabel className='onoPink' position='stacked'>
                          Confirmation Email to{' '}
                        </IonLabel>
                        <IonInput
                          type='email'
                          inputmode='email'
                          placeholder='order_receive@abc.com'
                          value={newStatus.email.email}
                          onIonInput={(e) => setNewStatus({ ...newStatus, email: { ...newStatus.email, email: e.target.value } })}
                        />
                      </IonItem>
                    )}
                  </div>
                  <div className='ion-padding ion-margin-top' style={{ borderRadius: '10px' }}>
                    <IonItem>
                      <IonLabel className='vh250 bold'>Text message</IonLabel>
                      <IonToggle
                        slot='end'
                        checked={newStatus.sms.status}
                        onIonInput={() => setNewStatus({ ...newStatus, sms: { ...newStatus.sms, status: !newStatus.sms.status } })}
                      />
                    </IonItem>
                    {!newStatus.sms.status ? null : (
                      <IonItem className='ion-align-items-end'>
                        <IonLabel className='onoPink' position='stacked'>
                          Enter phone number
                        </IonLabel>
                        <IonInput
                          type='tel'
                          inputmode='tel'
                          size='tel'
                          minlength={10}
                          maxlength={10}
                          placeholder='808*******'
                          value={newStatus.sms.number}
                          onIonInput={(e) => setNewStatus({ ...newStatus, sms: { ...newStatus.sms, number: e.target.value } })}
                        />
                      </IonItem>
                    )}
                  </div>
                </IonList>

                <div className='ion-margin-top ion-text-center'>
                  <IonButton onClick={saveOrderConfirmation}>Submit</IonButton>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
        {/* <IonButton onClick={makeOrderPhoneCall}>Test call</IonButton> */}
        <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />
      </IonContent>
      {deviceWidth < 501 ? (
        <IonFooter collapse='fade'>
          <IonToolbar color='light'>
            <IonButtons slot='start'>
              <IonMenuButton id='menu' />
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      ) : null}
    </IonPage>
  );
};
export default OrderSettingPage;
