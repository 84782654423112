import { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonAvatar,
  IonButton,
  IonContent,
  IonPage,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonLoading,
  IonCol,
  IonRow,
  IonGrid,
  IonIcon,
} from '@ionic/react';
import './LaunchPage.css';
import Parse from 'parse/dist/parse.min.js';
import AppContext from '../../context/AppContext';
import { errorAlert } from '../../components/errorAlert';

const LaunchPage = ({ match }) => {
  const history = useHistory();
  const { user, updateUser, updateIsAdmin, updateIsOnboarding } = useContext(AppContext);
  const [showLoading, setShowLoading] = useState(false);
  const [restaurants, setRestaurants] = useState();

  useEffect(() => {
    const launchFetch = async () => {
      await Parse.Cloud.run('launchRestaurants', { userId: match.params.id }).then((results) => {
        if (results.length > 0) {
          setRestaurants(results);
        } else {
          console.log(results);
        }
      });
    };
    launchFetch();
  }, []);

  const handleLogOut = async () => {
    await Parse.User.logOut().then(() => {
      updateIsAdmin(false);
      updateIsOnboarding(true);
    });
    return history.replace('/');
  };

  const handleRestaurant = async (rId, rName, rLogo, onboarding) => {
    setShowLoading(true);
    await Parse.Cloud.run('retrieveRestaurant', { userId: user.id, rId })
      .then((result) => {
        if (result) {
          updateUser({
            ...user,
            restaurant_id: rId,
            restaurant_name: rName,
            restaurant_logo: rLogo,
          });

          if (onboarding) {
            setShowLoading(false);
            history.push(`/onboarding/${rId}`);
          } else {
            updateIsOnboarding(false);
            history.push(`/dashboard/home/${rId}`);
          }
        } else {
          console.log(result);
        }
      })
      .catch((error) => {
        alert('Please check internet connection');
      });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonRow>
          <IonCol size-xs='10' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4'>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonBackButton
                  onClick={() => {
                    handleLogOut();
                  }}></IonBackButton>
              </IonButtons>
              <IonButtons slot='end'>
                <IonButton onClick={() => handleLogOut()}>Log out</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonCol>
        </IonRow>
      </IonHeader>
      <IonContent className='container'>
        <IonGrid style={{ height: '100%' }}>
          <IonRow className='ion-justify-content-center' style={{ height: '100%' }}>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='8' offset-md='2' size-lg='6' offset-lg='3'>
              <IonListHeader style={{ marginTop: '5em' }}>
                <h1 style={{ fontFamily: 'BrandonBold', marginBottom: '24px' }}>Choose a restaurant to manage</h1>
              </IonListHeader>
              <IonList lines='none'>
                {!restaurants
                  ? null
                  : restaurants.map((restaurant, index) => (
                      <IonItem
                        key={index}
                        className='detail'
                        lines='none'
                        onClick={() =>
                          handleRestaurant(restaurant.objectId, restaurant.name, restaurant.logo, restaurant.onboarding_status.is_onboarding)
                        }>
                        {restaurant.logo ? (
                          <IonAvatar slot='start'>
                            <img src={restaurant.logo} alt='logo' />
                          </IonAvatar>
                        ) : null}
                        <IonLabel>
                          <h3 className='vh250 bold'>{restaurant.name}</h3>
                          <p>
                            {restaurant.location.address1}, {restaurant.location.address2}, {restaurant.location.address3}
                            {restaurant.location.city}, {restaurant.location.state} {restaurant.location.zip_code}
                          </p>
                        </IonLabel>
                      </IonItem>
                    ))}
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={''} />
    </IonPage>
  );
};
export default LaunchPage;
