import React, { useContext, useEffect, useState } from 'react';
import {
  IonButton,
  IonHeader,
  IonContent,
  IonImg,
  IonItem,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonFooter,
  IonMenuButton,
  IonIcon,
  IonLabel,
} from '@ionic/react';
import { Browser } from '@capacitor/browser';
import Parse from 'parse/dist/parse.min.js';

import './OnboardingPage.css';

import AppContext from '../../../context/AppContext';
import { receipt } from 'ionicons/icons';

const MenuSettingPage = (props) => {
  const { user } = useContext(AppContext);

  const [showLoading, setShowLoading] = useState(false);

  const [provider, setProvider] = useState(null);
  const [posAuth, setPosAuth] = useState(null);

  const rId = props.match.params.id;
  console.log(rId);

  useEffect(() => {
    const getProvider = async () => {
      await Parse.Cloud.run('getPosProvider', { rId: rId ? rId : user.restaurant_id }).then((result) => {
        setProvider(result.provider);
        setPosAuth(result.auth);
      });
    };
    getProvider();
  }, []);

  const _handleConnectSquare = async (rId) => {
    setShowLoading(true);
    await Parse.Cloud.run('getSquareEnvironmentVariable').then(async (result) => {
      setShowLoading(false);
      const SQUARE_CLIENT_ID = result;
      await Browser.open({
        url: `https://connect.squareup.com/oauth2/authorize?client_id=${SQUARE_CLIENT_ID}&scope=ITEMS_READ+ITEMS_WRITE+INVENTORY_READ+ORDERS_WRITE+ORDERS_READ+MERCHANT_PROFILE_READ+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_WRITE+PAYMENTS_READ&session=false&state=${rId}__web`,
      });
    });
  };

  const _handleConnectClover = async (restaurantId) => {
    setShowLoading(true);
    await Parse.Cloud.run('getCloverEnvironmentVariable').then(async (result) => {
      setShowLoading(false);
      const CLOVER_CLIENT_ID = result;
      const CLIENT_REDIRECT_URL = `https://join.onoonline.app/oauth/clover`;

      // Product
      // await Browser.open({
      //   url: `https://sandbox.dev.clover.com/oauth/authorize?client_id=${CLOVER_CLIENT_ID}&redirect_uri=${CLIENT_REDIRECT_URL}&state=${rId}__dashboard`,
      // });
      // Sandbox
      await Browser.open({
        url: `https://sandbox.dev.clover.com/oauth/authorize?client_id=ZJZ5VM8QKA58T&redirect_uri=${CLIENT_REDIRECT_URL}&state=${rId}__dashboard`,
      });
    });
  };

  const handleDisconnectPos = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('disconnectPos', { rId: rId ? rId : user.restaurant_id })
      .then(() => {
        setProvider('ONOONLINE');
        setPosAuth(null);
        setShowLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChangePOSProvider = async (provider) => {
    setShowLoading(true);
    await Parse.Cloud.run('changePOSProvider', { rId, provider }).then((result) => {
      setProvider(result);
    });
    setShowLoading(false);
  };

  const _handleDisconnectUberEats = async (rId) => {
    setShowLoading(true);
    await Parse.Cloud.run('getUberEatsEnvironmentVariable').then(async (result) => {
      setShowLoading(false);
      console.log(result);
      //window.location = `https://login.uber.com/oauth/v2/token?client_id=${result.UBER_CLIENT_ID}&client_secret=${result.UBER_CLIENT_SECRET}&grant_type=client_credentials&scope=eats.pos_provisioning`;
      window.location = `https://login.uber.com/oauth/v2/authorize?client_id=${result.UBER_CLIENT_ID}&response_type=code&redirect_uri=https://join.onoonline.app/oauth/ubereats`;
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonRow>
          <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' offset-md='1' size-lg='8' offset-lg='2'>
            <IonToolbar>
              <IonTitle>Menu settings</IonTitle>
            </IonToolbar>
          </IonCol>
        </IonRow>
      </IonHeader>

      <IonContent className='ion-padding'>
        <IonGrid>
          <IonRow className='ion-align-items-center'>
            <IonCol size-xs='12' size-sm='10' size-md='8' size-lg='6'>
              <h1>POS integration</h1>
              <div className='ion-margin-top' style={{ borderRadius: '10px', marginBottom: '54px' }}>
                {provider === 'ONOONLINE' ? (
                  <div className='vh200'>Currently, you are not using POS integration</div>
                ) : (
                  <div className='vh200'>Currently, menu items are syncing with</div>
                )}
                <div className='vh200 ion-margin-top'>
                  Occasionally, a 'Popup Blocked' message may appear when you press any button to move to the next screen. In such cases, a button to
                  unblock the pop-up will appear next to the browser's search bar. Please click on that to proceed.
                </div>
                <div style={{ marginTop: '2em' }}>
                  <IonItem className='ion-margin-top ion-no-padding'>
                    <div
                      style={{
                        width: '7rem',
                        height: '3rem',
                        backgroundColor: 'white',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '10px',
                      }}>
                      <IonImg
                        slot='start'
                        src={'assets/images/Square_LogoLockup_Black.png'}
                        style={{ width: '7rem', borderRadius: '0.5em', backgroundColor: 'white' }}
                        alt='square'
                      />
                    </div>
                    {provider === 'SQUARE' ? (
                      <IonButton slot='end' onClick={() => handleDisconnectPos(rId)}>
                        Disconnect
                      </IonButton>
                    ) : (
                      <IonButton slot='end' onClick={() => _handleConnectSquare(rId)}>
                        Connect
                      </IonButton>
                    )}
                  </IonItem>
                  <IonItem className='ion-margin-top ion-no-padding'>
                    <div
                      style={{
                        width: '7rem',
                        height: '3rem',
                        backgroundColor: 'white',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '10px',
                      }}>
                      <IonImg
                        src={'assets/images/clover-logo.svg'}
                        style={{ width: '5rem', height: '2rem', margin: 'auto', marginTop: '0.5rem' }}
                        alt='clover'
                      />
                    </div>
                    {provider === 'CLOVER' ? (
                      <IonButton slot='end' onClick={handleDisconnectPos}>
                        Disconnect
                      </IonButton>
                    ) : (
                      <IonButton slot='end' onClick={() => _handleConnectClover(rId)}>
                        Connect
                      </IonButton>
                    )}
                  </IonItem>
                  {/* <IonItem className='ion-margin-top ion-no-padding'>
                  <IonImg
                    slot='start'
                    src={'assets/images/toast-logo_color.png'}
                    style={{ marginTop: '1em', width: '5rem', borderRadius: '0.5em', backgroundColor: 'white' }}
                    alt='toast'
                  />
                  {provider === 'TOAST' ? (
                    <IonButton slot='end' onClick={handleDisconnectPos}>
                      Disconnect
                    </IonButton>
                  ) : (
                    <IonButton slot='end'>Connect</IonButton>
                  )}
                </IonItem>
                <IonItem className='ion-margin-top ion-no-padding'>
                  <IonLabel>Uber Eats</IonLabel>
                  <IonButton slot='end' onClick={_handleDisconnectUberEats}>
                    Disconnect
                  </IonButton>
                </IonItem> */}
                </div>
              </div>
              {/* <section>
                <h1>Using POS integration</h1>
                <IonGrid className='ion-margin-vertical ion-no-padding' style={{ border: '1px solid #333' }}>
                  <IonRow className='ion-align-items-center ion-padding'>
                    <IonCol>
                      <IonLabel className='vh200 bold'>Function</IonLabel>
                    </IonCol>
                    <IonCol className='ion-text-center'>
                      <IonLabel className='vh200 bold'>With POS system</IonLabel>
                    </IonCol>
                    <IonCol className='ion-text-center'>
                      <IonLabel className='vh200 bold'>Without POS system</IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-align-items-center ion-padding' style={{ border: '1px solid #333' }}>
                    <IonCol>
                      <IonLabel className='vh200 bold'>Menu sync</IonLabel>
                    </IonCol>
                    <IonCol className='ion-text-center'>
                      <IonIcon icon={ellipseOutline} size='large' />
                    </IonCol>
                    <IonCol className='ion-text-center'>
                      <IonIcon icon={ellipseOutline} size='large' />
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-align-items-center ion-padding'>
                    <IonCol>
                      <IonLabel className='vh200 bold'>Menu creation</IonLabel>
                    </IonCol>
                    <IonCol className='ion-text-center'>
                      <div>
                        <IonIcon icon={closeOutline} size='large' />
                      </div>
                      <IonLabel className='vh150'>Only import from Square. Price can be different</IonLabel>
                    </IonCol>
                    <IonCol className='ion-text-center'>
                      <div>
                        <IonIcon icon={ellipseOutline} size='large' />
                      </div>
                      <IonLabel className='vh150'>You can make menus as much as you want</IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-align-items-center ion-padding' style={{ border: '1px solid #333' }}>
                    <IonCol>
                      <IonLabel className='vh200 bold'>Order display</IonLabel>
                    </IonCol>
                    <IonCol className='ion-text-center'>
                      <IonLabel>Square</IonLabel>
                    </IonCol>
                    <IonCol className='ion-text-center'>
                      <IonLabel>ono online app, SMS, email, phone</IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-align-items-center ion-padding'>
                    <IonCol>
                      <IonLabel className='vh200 bold'>Sales report</IonLabel>
                    </IonCol>
                    <IonCol className='ion-text-center ion-padding'>
                      <IonLabel>Square POS & ono online app</IonLabel>
                    </IonCol>
                    <IonCol className='ion-text-center'>
                      <IonLabel>ono online app</IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-padding'>
                    <IonCol></IonCol>
                    <IonCol className='ion-text-center'>
                      <IonButton disabled={provider === 'SQUARE' ? true : false} onClick={() => onChangePOSProvider('SQUARE')}>
                        {provider === 'SQUARE' ? 'Selected' : 'Select'}
                      </IonButton>
                    </IonCol>
                    <IonCol className='ion-text-center'>
                      <IonButton disabled={provider === 'ONOONLINE' ? true : false} onClick={() => onChangePOSProvider('ONOONLINE')}>
                        {provider === 'ONOONLINE' ? 'Selected' : 'Select'}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>

                <div className='ion-padding ion-text-start'>
                  <h1 style={{ fontSize: '1rem' }}>Import menus from POS system</h1>
                  <div style={{ fontFamily: 'Brandon', fontSize: '0.7rem' }}>Connecting with POS means, we can push orders to your POS system.</div>
                  <IonRow className='ion-align-items-center ion-margin-vertical'>
                    <IonImg
                      onClick={() => _handleConnectSquare(user.restaurant_id ? user.restaurant_id : rId)}
                      className='ion-float-left'
                      src={'assets/images/Square_Logo_White.png'}
                      style={{ width: '6rem', borderRadius: '0.5em', marginRight: '2rem' }}
                      alt='square'
                    />
                    <IonImg
                      className='ion-float-left'
                      src={'assets/images/toast-logo_color.png'}
                      style={{ width: '4.5rem', borderRadius: '0.5em' }}
                      alt='square'
                    />
                  </IonRow>
                  <div className='ion-padding-horizontal' style={{ borderBottom: '1px solid grey' }}></div>
                  <h1 style={{ fontSize: '1rem' }}>Import menus from delivery app</h1>
                  <div style={{ fontFamily: 'Brandon', fontSize: '0.7rem' }}>
                    Import menus from delivery app means, you will check incoming order on your device.
                  </div>
                  <IonRow className='ion-align-items-center ion-margin-top'>
                    <IonImg
                      onClick={() => {
                        console.log('clicked');
                      }}
                      className='ion-float-left ion-margin-right'
                      src={'assets/images/uberEats_App_Icon.png'}
                      style={{ width: '3rem', borderRadius: '0.5em' }}
                      alt='uber'
                    />
                  </IonRow>
                  <div className='ion-padding' style={{ borderBottom: '1px solid grey' }}></div>
                </div>
              </section> */}
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={''} />
      </IonContent>
      <IonFooter>
        <IonToolbar color='light'>
          <IonButtons slot='start'>
            <IonMenuButton id='menu' />
          </IonButtons>
          <IonButtons slot='end'>
            <IonButton>
              <IonIcon slot='icon-only' icon={receipt} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default MenuSettingPage;
