import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonRadio,
  IonRadioGroup,
  IonIcon,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonButtons,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  IonModal,
  IonDatetime,
  IonSearchbar,
  IonText,
  IonInput,
  IonDatetimeButton,
  IonCheckbox,
  IonAccordionGroup,
  IonAccordion,
} from '@ionic/react';
import Parse from 'parse/dist/parse.min.js';
//import * as moment from 'moment';
import moment from 'moment-timezone';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { closeOutline, returnDownForwardSharp } from 'ionicons/icons';

import AppContext from '../../context/AppContext';

const FlashSaleSettingComponent = (props) => {
  const { categories, menus, flashSale, setFlashSale, slideSwiperNext, scrollToTop } = props;
  const { user } = useContext(AppContext);

  const [flashSaleSegment, setFlashSaleSegment] = useState('NOW');
  const [flashSaleCopy, setFlashSaleCopy] = useState({
    title: 'Flash Sale',
    sale_start: '',
    sale_end: '',
    discount: { type: '%', amount: 20, min: 20, max: 10 },
    items: { type: 'ALL', ids: [] },
    target: 'ALL',
  });
  const [flashSaleItemType, setFlashSaleItemType] = useState('ALL');
  const [showMenuModal, setShowMenuModal] = useState(false);
  const [flashSaleTimeChangeFlag, setFlashSaleTimeChangeFlag] = useState(false);
  const [newFlashSaleTime, setNewFlashSaleTime] = useState({ start_time: null, end_time: null });
  const [localTime, setLocalTime] = useState();
  const [saleStart, setSaleStart] = useState({ day: '', time: '' });
  const [saleEnd, setSaleEnd] = useState({ day: '', time: '' });
  const [saleEndSelectOptions, setSaleEndSelectOptions] = useState([]);
  const [timeListFormatted, setTimeListFormatted] = useState([]);
  const [relatedMenuToggle, setRelatedMenuToggle] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [showDateTime, setShowDateTime] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showSelectItemModal, setShowSelectItemModal] = useState(false);
  const [searchText, setSearchText] = useState('');

  const today = moment().tz('Pacific/Honolulu').format('llll');

  // const handleRelatedMenu = async (checked) => {
  //   if (checked) {
  //     setRelatedMenuToggle(!relatedMenuToggle);
  //     setShowSelectItemModal(true);
  //     await Parse.Cloud.run('getMenusHome', { id: user.restaurant_id }).then(async (results) => {
  //       if (results.length) setMenus([...results]);
  //     });
  //   } else {
  //     setRelatedMenuToggle(!relatedMenuToggle);
  //     setSelectedMenu(null);
  //   }
  // };

  const filteredMenus = useMemo(() => {
    return menus.filter((item) => {
      return item.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [menus, searchText]);

  const filteredCategories = useMemo(() => {
    return categories.filter((item) => {
      return item.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [menus, searchText]);

  const handleSetSaleStartDate = (value) => {
    if (value !== 'Now') {
      let selectedDay = new Date(value + ' ' + saleStart.time);
      let endSelectOptions = [];
      if (moment().format('YYYY-MM-DD') === value) {
        for (let i = 0; i < 7; i++) {
          endSelectOptions.push(moment(selectedDay).add(i, 'days').format('YYYY-MM-DD'));
        }
      } else {
        for (let i = 0; i < 7; i++) {
          if (i === 0) {
            endSelectOptions.push(moment(selectedDay).format('YYYY-MM-DD'));
          } else {
            endSelectOptions.push(moment(selectedDay).add(i, 'days').format('YYYY-MM-DD'));
          }
        }
      }
      setSaleEndSelectOptions(endSelectOptions);
      let copiedSaleStart = saleStart;
      setSaleStart({ ...saleStart, day: value, time: copiedSaleStart.time });
      setSaleEnd({ ...saleEnd, day: value, time: copiedSaleStart.time });
    } else {
      setSaleStart({ ...saleStart, day: 'Now', time: moment(localTime).format('h:mm A') });
      //setSaleEnd({ ...saleEnd, day: '', time: '' });
    }
  };

  const handleSetSaleStartTime = (value) => {
    let copiedSaleStart = saleStart;
    setSaleStart({ ...saleStart, day: copiedSaleStart.day, time: value });
  };

  const handleSetSaleEndDate = (value) => {
    console.log(value);
    let copiedSaleEnd = saleEnd;
    setSaleEnd({ ...saleEnd, day: value, time: copiedSaleEnd.time });
  };

  const handleShowPopover = (boolean) => {
    setShowDateTime(boolean);
  };

  const handleSetSaleEndTime = (value) => {
    const start = new Date(saleStart.day + ' ' + saleStart.time);
    const end = new Date(saleEnd.day + ' ' + value);
    const isAfter = moment(end).isAfter(start);
    if (isAfter) {
      let copiedSaleEnd = saleEnd;
      setSaleEnd({ ...saleEnd, day: copiedSaleEnd.day, time: value });
    } else {
      return alert('End time have to be later than start time');
    }
  };

  const handleSaleTimeChange = async (newTimeObj) => {
    let newStartHour, newStartMin, newEndHour, newEndMin;
    newStartMin = newTimeObj.start_time.slice(-2);
    newEndMin = newTimeObj.end_time.slice(-2);
    if (newTimeObj.start_time.length === 3) {
      newStartHour = newTimeObj.start_time.substr(0, 1);
    } else {
      newStartHour = newTimeObj.start_time.substr(0, 2);
    }
    if (newTimeObj.end_time.length === 3) {
      newEndHour = newTimeObj.end_time.substr(0, 1);
    } else {
      newEndHour = newTimeObj.end_time.substr(0, 2);
    }
    const startTime = moment()
      .set({ hour: Number(newStartHour), minute: Number(newStartMin), second: 0, millisecond: 0 })
      .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');

    const endTime = moment()
      .set({ hour: Number(newEndHour), minute: Number(newEndMin), second: 0, millisecond: 0 })
      .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
    await Parse.Cloud.run('flashSaleTimeChange', { rId: user.restaurant_id, startTime, endTime }).then((result) => {
      if (result) {
        setFlashSale(result);
        setFlashSaleTimeChangeFlag(!flashSaleTimeChangeFlag);
      } else {
        console.log(result);
      }
    });
  };

  const onPressSaleTimeChangeButton = () => {
    setFlashSaleTimeChangeFlag(!flashSaleTimeChangeFlag);
    setNewFlashSaleTime({ ...newFlashSaleTime, start_time: moment().format('HHMM') });
  };

  const TimeSelectComponent = () => (
    <IonGrid>
      <IonRow>
        <IonCol size='6'>
          <IonText>Sale start date & time</IonText>
        </IonCol>
        <IonCol size='6'>
          <IonText>Sale End date & time</IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size='6'>
          <IonSelect
            className='saleTimeSelect'
            interface='popover'
            value={saleStart.day}
            placeholder='Now'
            onIonInput={(e) => handleSetSaleStartDate(e.detail.value)}>
            <IonSelectOption value='Now'>Now</IonSelectOption>
            <IonSelectOption value={moment().format('YYYY-MM-DD')}>Today</IonSelectOption>
            <IonSelectOption value={moment().add(1, 'days').format('YYYY-MM-DD')}>Tomorrow</IonSelectOption>
            <IonSelectOption value={moment().add(2, 'days').format('YYYY-MM-DD')}>{moment().add(2, 'days').format('M/D/YYYY')}</IonSelectOption>
          </IonSelect>
          <IonSelect
            className='saleTimeSelect'
            interface='popover'
            value={saleStart.time}
            placeholder={saleStart.time}
            onIonInput={(e) => handleSetSaleStartTime(e.detail.value)}
            disabled={saleStart.day === 'Now'}>
            {timeListFormatted.map((time, index) => (
              <IonSelectOption key={index} value={time}>
                {time}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonCol>
        <IonCol size='6'>
          {saleStart.day === 'Now' ? null : (
            <IonSelect
              className='saleTimeSelect'
              interface='popover'
              position='bottom'
              value={saleEnd.day}
              placeholder='Now'
              onIonInput={(e) => handleSetSaleEndDate(e.detail.value)}>
              {saleEndSelectOptions.map((date, index) => (
                <IonSelectOption key={index} value={date}>
                  {date}
                </IonSelectOption>
              ))}
            </IonSelect>
          )}
          {saleStart.day === 'Now' ? null : (
            <IonSelect
              className='saleTimeSelect'
              interface='popover'
              position='bottom'
              value={saleEnd.time}
              placeholder={saleEnd.time}
              onIonInput={(e) => handleSetSaleEndTime(e.detail.value)}
              disabled={saleStart.day === 'Now'}>
              {timeListFormatted.map((time, index) => (
                <IonSelectOption key={index} value={time}>
                  {time}
                </IonSelectOption>
              ))}
            </IonSelect>
          )}
        </IonCol>
      </IonRow>
    </IonGrid>
  );

  const handleChangeSaleObj = useCallback(
    (val) => {
      setFlashSaleSegment(val);
    },
    [flashSaleSegment]
  );

  const handleFlashSaleItemTypeChanged = (val) => {
    console.log(flashSale.status, flashSale.items.type);
    if (val === 'ALL') {
      setFlashSaleItemType('ALL');
    }
    if (flashSale.status === 'NEW') {
      setFlashSaleCopy((prev) => {
        return { ...prev, items: { ...prev.items, type: val, ids: [] } };
      });
      setShowMenuModal(true);
    }
    if (flashSale.items.type === 'CATEGORY' && val === 'ITEMS') {
      let menuObjArray = [];
      for (let i = 0; i < menus.length; i++) {
        menuObjArray = menuObjArray.concat(menus[i]);
      }
      const menuIdArray = menuObjArray.map((item) => item.objectId);
      setFlashSaleCopy((prev) => {
        return { ...prev, items: { ...prev.items, type: val, ids: menuIdArray } };
      });
      setShowMenuModal(true);
    }
    if (flashSale.items.type === 'CATEGORY' && val === 'CATEGORY') {
      const menuIdArray = categories?.map((item) => item.objectId);
      setFlashSaleCopy((prev) => {
        return { ...prev, items: { ...prev.items, type: val, ids: menuIdArray } };
      });
      setShowMenuModal(true);
    }
    if (flashSale.items.type === 'ITEMS' && val === 'ITEMS') {
      const menuIdArray = menus?.map((item) => item.objectId);
      setFlashSaleCopy((prev) => {
        return { ...prev, items: { ...prev.items, type: val, ids: menuIdArray } };
      });
      setShowMenuModal(true);
    }
    if (flashSale.items.type === 'ITEMS' && val === 'CATEGORY') {
      setFlashSaleCopy((prev) => {
        return { ...prev, items: { ...prev.items, type: val, ids: [] } };
      });
      setShowMenuModal(true);
    }
    if (flashSale.items.type === 'ALL' && val === 'CATEGORY') {
      const menuIdArray = categories?.map((item) => item.objectId);
      setFlashSaleCopy((prev) => {
        return { ...prev, items: { ...prev.items, type: val, ids: menuIdArray } };
      });
      setShowMenuModal(true);
    }
    if (flashSale.items.type === 'ALL' && val === 'ITEMS') {
      let menuObjArray = [];
      for (let i = 0; i < menus?.length; i++) {
        menuObjArray = menuObjArray.concat(menus[i]);
      }
      const menuIdArray = menuObjArray.map((item) => item.objectId);
      setFlashSaleCopy((prev) => {
        return { ...prev, items: { ...prev.items, type: val, ids: menuIdArray } };
      });
      setShowMenuModal(true);
    }
  };

  const handleCategoryCheckbox = (menuIndex, categoryId, checked) => {
    const exist = flashSaleCopy.items.ids.includes(categoryId);
    if (checked && !exist) {
      setFlashSaleCopy((prev) => {
        return { ...prev, items: { ...prev.items, ids: [...prev.items.ids, categoryId] } };
      });
    }
    if (!checked && exist) {
      setFlashSaleCopy((prev) => {
        const filter = prev.items.ids.filter((item) => {
          return item !== categoryId;
        });
        return { ...prev, items: { ...prev.items, ids: filter } };
      });
    }
  };

  const handleIndividualItemCheckbox = useCallback(
    (checked, menuId) => {
      if (checked) {
        setFlashSaleCopy((prev) => {
          return { ...prev, items: { ...prev.items, ids: [...prev.items.ids, menuId] } };
        });
      } else {
        setFlashSaleCopy((prev) => {
          const filter = prev.items.ids.filter((item) => {
            return item !== menuId;
          });
          console.log(filter);
          return { ...prev, items: { ...prev.items, ids: filter } };
        });
      }
    },
    [flashSaleCopy.items.ids]
  );

  const onSubmitFlashSaleMenus = () => {
    setFlashSaleItemType(flashSaleCopy.items.type);
    setFlashSale((prev) => {
      return { ...prev, items: { ...prev.items, type: flashSaleCopy.items.type, ids: flashSaleCopy.items.ids } };
    });
    setShowMenuModal(false);
  };

  const submitFlashSale = async () => {
    setShowLoading(true);
    let data = flashSale;
    console.log(data);
    data.status = '';
    if (!data.sale_start) {
      data.sale_start = moment().tz('Pacific/Honolulu').format('yyyy-MM-DDTHH:mm');
    }

    if (!data.sale_end) {
      setShowLoading(false);
      alert('Please select sale end data and time');
      return;
    }
    if (data.discount.amount < 1) {
      setShowLoading(false);
      alert('Please select discount amount');
      return;
    }
    if (flashSaleItemType === 'ALL') {
      data.items = { type: 'ALL', ids: [] };
      data.status = 'EDITED';
      setFlashSale(data);
      setShowLoading(false);
      // await Parse.Cloud.run('handleUpdateFlashSale', { rId: user.restaurant_id, data: data }).then((result) => {
      //   console.log(result);
      //   data.status = 'EDITED';
      //   setFlashSale(data);
      //   setShowLoading(false);
      // });
    } else {
      data.status = 'EDITED';
      setFlashSale(data);
      setShowLoading(false);
      // await Parse.Cloud.run('handleUpdateFlashSale', { rId: user.restaurant_id, data: flashSale }).then((result) => {
      //   console.log(result);
      //   data.status = 'EDITED';
      //   setFlashSale(data);
      //   setShowLoading(false);
      // });
    }
    slideSwiperNext();
    scrollToTop();
  };

  const FlashSaleMenuModal = (props) => {
    const { type } = props;
    return (
      <>
        {type === 'ITEMS'
          ? filteredMenus.map((menu, index) => {
              return (
                <React.Fragment key={menu.objectId}>
                  <IonItem className='ion-no-padding'>
                    <IonCheckbox
                      slot='start'
                      checked={flashSaleCopy?.items.ids.includes(menu.objectId)}
                      onIonInput={(e) => handleCategoryCheckbox(index, menu.objectId, e.target.checked)}
                    />
                    <IonLabel>{menu.name}</IonLabel>
                  </IonItem>
                  {/* {category.menus.map((menu, idx) => {
                    return (
                      <IonItem key={menu.objectId}>
                        <IonCheckbox
                          slot='start'
                          disabled={flashSaleCopy?.items.type === 'CATEGORY' ? true : false}
                          checked={flashSaleCopy?.items.ids.includes(menu.objectId)}
                          value={menu.objectId}
                          onIonInput={(e) => handleIndividualItemCheckbox(e.target.checked, e.target.value)}
                        />
                        <IonLabel>{menu.name}</IonLabel>
                      </IonItem>
                    );
                  })} */}
                </React.Fragment>
              );
            })
          : filteredCategories.map((category, index) => {
              return (
                <IonAccordionGroup key={category.objectId}>
                  <IonAccordion value='first'>
                    <IonItem slot='header' className='ion-no-padding'>
                      <IonCheckbox
                        slot='start'
                        checked={flashSaleCopy?.items.ids.includes(category.objectId)}
                        onIonInput={(e) => handleCategoryCheckbox(index, category.objectId, e.target.checked)}
                      />
                      <IonLabel>{category.name}</IonLabel>
                      <IonLabel>{category.menus.length} item</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                      {category.menus.map((menu, idx) => {
                        return (
                          <IonItem key={menu.objectId}>
                            <IonCheckbox
                              slot='start'
                              disabled={flashSaleCopy?.items.type === 'CATEGORY' ? true : false}
                              checked={flashSaleCopy?.items.ids.includes(category.objectId)}
                            />
                            <IonLabel>{menu.name}</IonLabel>
                          </IonItem>
                        );
                      })}
                    </div>
                  </IonAccordion>
                </IonAccordionGroup>
              );
            })}
      </>
    );
  };

  return (
    <div className='ion-text-start'>
      {/* <IonSegment style={{ marginTop: '1em' }} value={flashSaleSegment} onIonInput={(e) => handleChangeSaleObj(e.detail.value)}>
        <IonSegmentButton value='NOW'>
          <IonLabel>Start Now</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value='TODAY'>
          <IonLabel>Today</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value='OTHER'>
          <IonLabel>Other</IonLabel>
        </IonSegmentButton>
      </IonSegment> */}

      <IonRow className='ion-margin-top'>
        <div style={{ width: '100%' }}>
          <h5 style={{ fontFamily: 'BrandonBold' }}>Sale start time</h5>
          <IonDatetimeButton className='ion-float-start' datetime='datetime1' />
        </div>
        <div className='ion-margin-top' style={{ width: '100%' }}>
          <h5 style={{ fontFamily: 'BrandonBold' }}>Sale end time</h5>
          <IonDatetimeButton className='ion-float-start' datetime='datetime2' />
        </div>
        <IonModal keepContentsMounted={true}>
          <IonDatetime
            id='datetime1'
            minuteValues='0,15,30,45'
            hourCycle='h12'
            value={
              flashSale?.sale_start
                ? moment.utc(flashSale?.sale_start).format('yyyy-MM-DDTHH:mm')
                : moment().tz('Pacific/Honolulu').format('yyyy-MM-DDTHH:mm')
            }
            min={moment().tz('Pacific/Honolulu').format('yyyy-MM-DDTHH:mm')}
            onIonInput={(e) =>
              setFlashSale((prev) => {
                return { ...prev, sale_start: e.target.value };
              })
            }
          />
        </IonModal>
        <IonModal keepContentsMounted={true}>
          <IonDatetime
            id='datetime2'
            minuteValues='0,15,30,45'
            hourCycle='h12'
            value={
              flashSale?.sale_end
                ? moment.utc(flashSale?.sale_end).format('yyyy-MM-DDTHH:mm')
                : moment().tz('Pacific/Honolulu').format('yyyy-MM-DDTHH:mm')
            }
            min={
              flashSale?.sale_start
                ? moment.utc(flashSale.sale_start).format('yyyy-MM-DDTHH:mm')
                : moment().tz('Pacific/Honolulu').format('yyyy-MM-DDTHH:mm')
            }
            onIonInput={(e) =>
              setFlashSale((prev) => {
                return { ...prev, sale_end: e.target.value };
              })
            }
          />
        </IonModal>
      </IonRow>

      <div style={{ border: '0.3px solid grey', margin: '2em 0' }} />

      <div style={{ margin: '2em 0' }}>
        <h5 style={{ fontFamily: 'BrandonBold' }}>Discount type and amount</h5>
        <IonRow className='ion-align-items-center'>
          <div className='ion-float-left ion-margin-end'>
            <IonInput
              value={flashSale?.discount.amount}
              className='discountAmountInput ion-text-center'
              inputmode='numeric'
              placeholder='10'
              style={{
                border: '1px solid grey',
                borderRadius: '5px',
                fontFamily: 'BrandonBold',
                fontSize: '1.5em',
                height: '2em',
                maxHeight: '3em',
                width: '4em',
                padding: 0,
              }}
              onIonInput={(e) =>
                setFlashSale((prev) => {
                  return { ...prev, discount: { ...prev.discount, amount: e.target.value } };
                })
              }
            />
          </div>
          <div className='ion-float-left'>
            <IonSegment
              mode='ios'
              value={flashSale.discount.type}
              style={{ height: '3.25em' }}
              onIonInput={(e) =>
                setFlashSale((prev) => {
                  return { ...prev, discount: { ...prev.discount, type: e.target.value } };
                })
              }>
              <IonSegmentButton className='segmentButton' value='%'>
                %
              </IonSegmentButton>
              <IonSegmentButton className='segmentButton' value='$'>
                $
              </IonSegmentButton>
            </IonSegment>
          </div>
          <div className='ion-margin-start'>
            <IonLabel>OFF</IonLabel>
          </div>
        </IonRow>
        <IonRow className='ion-align-items-center ion-margin-top'>
          <div className='ion-float-left ion-margin-end'>
            <IonIcon icon={returnDownForwardSharp} />
          </div>
          <div className='ion-float-left'>
            <IonLabel>Minimum purchase $:</IonLabel>
            <IonInput
              className='discountAmountInput ion-text-center ion-margin-top'
              value={flashSale?.discount.min}
              inputmode='numeric'
              placeholder='10'
              style={{ border: '1px solid grey', borderRadius: '5px', height: '3em', width: '5em' }}
              onIonInput={(e) =>
                setFlashSale((prev) => {
                  return { ...prev, discount: { ...prev.discount, min: e.target.value } };
                })
              }
            />
          </div>
        </IonRow>
        <IonRow className='ion-align-items-center ion-margin-top'>
          <div className='ion-float-left ion-margin-end'>
            <IonIcon icon={returnDownForwardSharp} />
          </div>
          <div className='ion-float-left'>
            <IonLabel>Maximum discount amount $:</IonLabel>
            <IonInput
              className='discountAmountInput ion-text-center ion-margin-top'
              value={flashSale?.discount.max}
              inputmode='numeric'
              placeholder='10'
              style={{ border: '1px solid grey', borderRadius: '5px', height: '3em', width: '5em' }}
              onIonInput={(e) =>
                setFlashSale((prev) => {
                  return { ...prev, discount: { ...prev.discount, max: e.target.value } };
                })
              }
            />
          </div>
        </IonRow>
      </div>
      {/* )} */}
      <h5 style={{ fontFamily: 'BrandonBold' }}>Discount items</h5>
      <IonRadioGroup value={flashSaleItemType} onClick={(e) => handleFlashSaleItemTypeChanged(e.target.value)}>
        <IonItem className='transparent'>
          <IonRadio slot='start' value={'ALL'} />
          <IonLabel>All items</IonLabel>
        </IonItem>
        <IonItem className='transparent'>
          <IonRadio slot='start' value={'CATEGORY'} />
          <IonLabel>Categories</IonLabel>
        </IonItem>
        <IonItem className='transparent'>
          <IonRadio slot='start' value='ITEMS' />
          <IonLabel>Selected items</IonLabel>
        </IonItem>
      </IonRadioGroup>
      <div className='ion-no-pading'>
        <h5 style={{ color: '#FC0FC0', fontFamily: 'BrandonBold', fontSize: '0.8em' }}>
          {flashSaleItemType === 'CATEGORY' ? 'Selected category' : flashSaleItemType === 'ITEMS' ? 'Selected Item' : 'All items'}
        </h5>

        {flashSaleItemType === 'CATEGORY' ? (
          menus.map((item, index) => {
            if (flashSale.items.ids.includes(item.objectId)) {
              return (
                <IonLabel key={index} style={{ marginRight: '1em', marginTop: '8px' }}>
                  {item.name}
                  {menus.length !== index + 1 && ','}
                </IonLabel>
              );
            } else {
              return null;
            }
          })
        ) : flashSaleItemType === 'ALL' ? (
          <IonLabel>All items</IonLabel>
        ) : (
          <IonLabel>{flashSale.items.ids.length} items</IonLabel>
        )}
        <IonButton style={{ marginTop: '3em' }} expand='block' onClick={submitFlashSale}>
          Next (Not saved yet)
        </IonButton>
      </div>
      <IonModal isOpen={showMenuModal} onDidDismiss={() => setShowMenuModal(false)}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonButton onClick={() => setShowMenuModal(false)}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
            <IonButtons slot='end'>
              <IonButton onClick={onSubmitFlashSaleMenus}>Submit</IonButton>
            </IonButtons>
            <IonSearchbar
              class='customSearchBar'
              debounce={400}
              value={searchText}
              onIonInput={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </IonToolbar>
        </IonHeader>
        <IonContent className='ion-padding'>
          <FlashSaleMenuModal type={flashSaleCopy.items.type} searchText={searchText} />
        </IonContent>
      </IonModal>
    </div>
  );
};

export default FlashSaleSettingComponent;
