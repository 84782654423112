import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonButton,
  IonContent,
  IonHeader,
  IonTitle,
  IonPage,
  IonToolbar,
  IonButtons,
  IonCard,
  IonCardContent,
  IonIcon,
  IonLabel,
  IonModal,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonRow,
  IonCol,
  IonGrid,
  IonSearchbar,
  IonInput,
  IonChip,
  IonCheckbox,
  useIonAlert,
  IonLoading,
  IonPopover,
  IonFooter,
  IonMenuButton,
} from '@ionic/react';
import { closeOutline, copyOutline, trashOutline } from 'ionicons/icons';
import Parse from 'parse/dist/parse.min.js';
import { nanoid } from 'nanoid';
import ModifierItemModal from '../../../components/ModifierItemModal/ModifierItemModal';
import AppContext from '../../../context/AppContext';

import './ModifierItemsPage.css';

const roundToTwo = (num) => {
  return +(Math.round(num + 'e+2') + 'e-2');
};

const ModifierItemsPage = (props) => {
  const { deviceWidth, user, updateUser, updateIsOnboarding } = useContext(AppContext);
  const history = useHistory();

  const [flag, setFlag] = useState('');
  const [isFilledTitle, setIsFilledTitle] = useState(false);
  const [menus, setMenus] = useState(null);
  const [menuStockArray, setMenuStockArray] = useState([]);
  const [modCategories, setModCategories] = useState('Additional');
  const [modFlag, setModFlag] = useState('');
  const [modifierItems, setModifierItems] = useState([]);
  const [modifierGroups, setModifierGroups] = useState([]);
  const [modifierStockArray, setModifierStockArray] = useState([]);
  const [modId, setModId] = useState('');
  const [modIndex, setModIndex] = useState();
  const [modItemsTag, setModItemsTag] = useState('Additional');
  const [modMin, setModMin] = useState(0);
  const [modMax, setModMax] = useState(100);
  const [modifierItemsCheckedIdArray, setModifierItemsCheckedIdArray] = useState([]);
  const [modRequired, setModRequired] = useState(false);
  const [modTitle, setModTitle] = useState('');
  const [modType, setModType] = useState(false);
  const [newSection, setNewSection] = useState({
    title: null,
    categories: [
      {
        title: '',
        description: '',
        menuUuids: [],
      },
    ],
    sectionIndex: null,
    menuUuids: [],
  });
  const [newModifierItemName, setNewModifierItemName] = useState('');
  const [newModifierItemPrice, setNewModifierItemPrice] = useState();
  const [originalMenus, setOriginalMenus] = useState([]);
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [restaurantHours, setRestaurantHours] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [sections, setSections] = useState([]);
  const [sectionFlag, setSectionFlag] = useState();
  const [sectionIndex, setSectionIndex] = useState(null);
  const [select, setSelect] = useState('All');
  const [showAddSectionModal, setShowAddSectionModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showMenuEditModal, setShowMenuEditModal] = useState(false);
  const [showModifierModal, setShowModifierModal] = useState(false);
  const [showModifierItemSelectModal, setShowModifierItemSelectModal] = useState(false);
  const [sectionNumArray, setSectionNumArray] = useState();
  const [title, setTitle] = useState('');
  const [posProvider, setPosProvider] = useState(null);
  const [newModifierItem, setNewModifierItem] = useState({ name: '', price: null });
  const [selectedModifierItem, setSelectedModifierItem] = useState(null);
  const [selectedModifierItemIndex, setSelectedModifierItemIndex] = useState(null);
  const [showEditModifierItemModal, setShowEditModifierItemModal] = useState(false);
  const [presentAlert] = useIonAlert();
  const [handlerMessage, setHandlerMessage] = useState('');
  const [roleMessage, setRoleMessage] = useState('');

  const modifierItemStockInput = useRef([]);

  const rId = props.match.params.id;

  useEffect(() => {
    console.log('menuPage rendered');
    const currentUser = Parse.User.current();
    if (!currentUser) {
      updateIsOnboarding(true);
      return history.replace('/');
    }
    const getRestaurant = async () => {
      await Parse.Cloud.run('getRestaurantFromApp', { rId: rId ? rId : user.restaurant_id }).then((result) => {
        if (!result) {
          alert('Please check internet connection');
        } else {
          updateUser({ ...user, restaurant_id: rId ? rId : user.restaurant_id, restaurant_name: result.name });
        }
      });
    };
    if (!user.restaurant_name) {
      getRestaurant();
    }

    const getMenuData = async () => {
      await Parse.Cloud.run('getMenuPageData', { rId: rId ? rId : user.restaurant_id })
        .then((results) => {
          if (results) {
            setRestaurantHours(results.restaurantHours);
            setSections(results.sections);
            setMenuStockArray(results.stock);
            setMenus(results.data);
            setOriginalMenus(results.data);
            setSectionNumArray(results.sectionNumArray);
            setPosProvider(results.provider);
          } else {
            console.log('No restaurant data');
          }
        })
        .catch((error) => {
          alert(error);
        });
    };
    getMenuData();
    getModifiers();
  }, []);

  const getModifiers = async () => {
    await Parse.Cloud.run('getModifers', { rId: rId ? rId : user.restaurant_id })
      .then((results) => {
        if (results.modifierGroups.length) {
          setModifierGroups(results.modifierGroups);
        }
        if (results.modifierItems.length) {
          setModifierItems(results.modifierItems);
          setModifierStockArray(results.stockArray);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const filteredItems = useMemo(() => {
    return modifierItems.filter((item) => {
      return item.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [modifierItems, searchText]);

  const handleShowNewMenuModal = () => {
    if (restaurantHours) {
      setFlag('new');
      setTitle('New Menu');
      setShowMenuEditModal(true);
    }
  };

  const handleActionPopover = (boolean, e, item, index) => {
    setSelectedModifierItem(item);
    setSelectedModifierItemIndex(index);
    setShowPopover({ showPopover: boolean, event: e });
  };

  const SelectComponent = () => {
    return (
      <IonSelect
        class='customSelect'
        value={select}
        selectedText={select}
        interface='popover'
        okText='Okay'
        cancelText='Dismiss'
        mode='ios'
        onIonInput={(e) => onSelectChange(e.target.value)}>
        <IonSelectOption value={'All'}>All</IonSelectOption>
        {sections &&
          sections.map((item, index) => (
            <IonSelectOption key={nanoid()} data-key={index} value={[index, item.title]}>
              {item.title}
            </IonSelectOption>
          ))}
      </IonSelect>
    );
  };

  const onSelectChange = (val) => {
    const copiedMenus = [...originalMenus];
    if (val !== 'All') {
      setSelect(val[1]);
      let menuUuids = sections[val[0]].menuUuids;
      let filteredArray = copiedMenus.filter(function (value) {
        return menuUuids.includes(value.objectId);
      });
      setMenus(filteredArray);
    } else {
      setSelect('All');
      setMenus(copiedMenus);
    }
  };

  const handleOnChangeModifierStock = (value, item) => {
    const idx = modifierItems.findIndex((menu) => menu.objectId === item.objectId);
    let copyModifierItems = modifierItems;
    copyModifierItems[idx].hasChanged = true;
    copyModifierItems[idx].stock = value;
    setModifierItems([...copyModifierItems]);
  };

  const handleOnFocusStockInput = (e) => {
    e.stopPropagation();
  };

  const handleSaveModifierItemStock = async (itemId, value) => {
    setShowLoading(true);
    await Parse.Cloud.run('saveModifierItemStock', { itemId, value }).then((result) => {
      setShowLoading(false);
      if (result) {
        const idx = modifierItems.findIndex((menu) => menu.objectId === itemId);
        let copyModifierItems = modifierItems;
        copyModifierItems[idx].hasChanged = false;
        // let copiedItems = filteredItems;
        // copiedItems[index].hasChanged = false;
        setModifierItems([...copyModifierItems]);
      } else {
        alert('ERROR');
      }
    });
  };

  const handleRefreshModifierItemStock = useCallback(async () => {
    setShowLoading(true);
    let modifierItemCopy = [...modifierItems];
    for (let i = 0; i < modifierItemCopy.length; i++) {
      modifierItemCopy[i].changeFlag = false;
    }
    setModifierItems([...modifierItemCopy]);
    await getModifiers().then(() => {
      setShowLoading(false);
    });
  }, []);

  const handleBeforeSaveModifier = async () => {
    setShowLoading(true);
    if (!modTitle.length) {
      setShowLoading(false);
      alert('Please input modifier set title');
      return;
    }
    let checkbox = document.getElementsByClassName('itemCheckBox');
    if (checkbox) {
      //let selectedOptions = [];
      let selectedIds = [];
      for (let i = 0; i < checkbox.length; i++) {
        if (checkbox[i].checked) {
          let json = JSON.parse(checkbox[i].getAttribute('data-item'));
          //selectedOptions.push(json);
          selectedIds.push(json.objectId);
        }
      }
      let query = new Parse.Query('ModifierItems');
      query.containedIn('objectId', selectedIds);
      try {
        let results = await query.find();
        if (results) {
          for (let item of results) {
            item.addUnique('group_titles', modTitle);
            item.save();
          }
          return handleSaveModifier(results);
        } else {
          return handleSaveModifier(null);
        }
      } catch (error) {
        setShowLoading(false);
        alert(error);
      }
    } else {
      return handleSaveModifier(null);
    }
  };

  const handleSaveModifier = async (modifierItems) => {
    let type;
    if (modType) {
      type = 'check';
    } else {
      type = 'radio';
    }
    if (modFlag === 'New') {
      const Modifier = new Parse.Object('Modifiers');
      Modifier.set('title', modTitle);
      Modifier.set('required', modRequired);
      Modifier.set('restaurant_id', user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id);
      Modifier.set('type', type);
      Modifier.set('min', type === 'radio' ? 1 : modMin);
      Modifier.set('max', type === 'radio' ? 1 : modMax);
      Modifier.set('category', modCategories);
      if (modifierItems) {
        let Options = Modifier.relation('items');
        Options.add(modifierItems);
      }
      try {
        Modifier.save().then(async (savedModifier) => {
          let json = JSON.stringify(savedModifier);
          json = JSON.parse(json);
          if (modifierItems) {
            let items = JSON.stringify(modifierItems);
            json.options = JSON.parse(items);
          }
          setModifierGroups([...modifierGroups, json]);
          //setModOptionIdArray([]);
          setShowModifierModal(false);
          setShowLoading(false);
        });
      } catch (error) {
        setShowLoading(false);
        alert(error);
      }
    } else {
      const query = new Parse.Query('Modifiers');
      try {
        let result = await query.get(modId);
        result.set('title', modTitle);
        result.set('required', modRequired);
        result.set('type', type);
        result.set('min', modMin);
        result.set('max', modMax);
        if (modifierItems) {
          let Options = result.relation('items');
          Options.add(modifierItems);
        }
        try {
          await result.save().then(async (savedModifier) => {
            let json = JSON.stringify(savedModifier);
            json = JSON.parse(json);
            if (modifierItems) {
              let items = JSON.stringify(modifierItems);
              json.options = JSON.parse(items);
            }
            let copiedModifierGroups = [...modifierGroups];
            copiedModifierGroups[modIndex] = json;
            setModifierGroups([...copiedModifierGroups]);
            setModifierItemsCheckedIdArray([]);
            setShowModifierModal(false);
            setShowLoading(false);
            return true;
          });
        } catch (error) {
          setShowLoading(false);
          alert(error);
        }
      } catch (error) {
        setShowLoading(false);
        alert(`AfterSave` + error);
      }
    }
  };

  const handleAddNewModOption = async (e) => {
    setShowLoading(true);
    e.preventDefault();
    if (newModifierItemName.length === 0) {
      setShowLoading(false);
      return alert('Please input name');
    }
    if (isNaN(newModifierItemPrice) || newModifierItemPrice === '' || newModifierItemPrice < 0 || newModifierItemPrice === null) {
      setShowLoading(false);
      return alert('Please input correct price');
    }
    const ModifierItems = new Parse.Object('ModifierItems');
    ModifierItems.addUnique('group_titles', modTitle);
    ModifierItems.set('name', newModifierItemName);
    ModifierItems.set('price', newModifierItemPrice);
    ModifierItems.set('tag', modItemsTag);
    ModifierItems.set('restaurant_id', user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id);
    ModifierItems.save()
      .then((savedItem) => {
        setModifierItems([
          ...modifierItems,
          {
            name: newModifierItemName,
            price: newModifierItemPrice,
            tag: modItemsTag,
            objectId: savedItem.id,
            group_titles: [modTitle],
            stockChangeFlag: false,
          },
        ]);
        setNewModifierItemName('');
        setNewModifierItemPrice(null);
        setModItemsTag('Additional');
        setModifierItemsCheckedIdArray([...modifierItemsCheckedIdArray, savedItem.id]);
        setModifierStockArray([...modifierStockArray, null]);
        setShowLoading(false);
      })
      .catch((error) => {
        setShowLoading(false);
        alert(error);
      });
  };

  const handleSaveNewModifierItem = async () => {
    if (!newModifierItem.name.length) {
      alert('Please type new item name');
      return false;
    }
    if (!newModifierItem.price || isNaN(newModifierItem.price)) {
      setNewModifierItem({ ...newModifierItem, price: 0 });
    }
    setShowLoading(true);
    await Parse.Cloud.run('saveNewModifierItem', { rId: rId ? rId : user.restaurant_id, newModifierItem }).then((result) => {
      if (result) {
        setModifierItems((prev) => {
          return [...prev, result];
        });
        setNewModifierItem({ name: '', price: null });
        setShowLoading(false);
      } else {
        setShowLoading(false);
        alert(result);
      }
    });
  };

  const handleShowModifierItemModal = (item) => {
    const idx = modifierItems.findIndex((mItem) => mItem.objectId === item.objectId);
    setSelectedModifierItem(item);
    setSelectedModifierItemIndex(idx);
    setShowEditModifierItemModal(true);
  };

  const handleDeleteModifierItem = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('deleteModifierItem', { itemId: selectedModifierItem.objectId }).then(() => {
      setShowLoading(false);
      let copiedItems = modifierItems;
      copiedItems.splice(selectedModifierItemIndex, 1);
      setModifierItems([...copiedItems]);
      setShowPopover({ showPopover: false, event: undefined });
    });
  };

  const handleCheckSoldout = async (checked, menuId) => {
    setShowLoading(true);
    const idx = modifierItems.findIndex((mItem) => mItem.objectId === menuId);
    let copyModifierItems = modifierItems;
    let value;
    if (checked) {
      value = 0;
      copyModifierItems[idx].stock = 0;
    } else {
      value = null;
      copyModifierItems[idx].stock = null;
    }
    setModifierItems([...copyModifierItems]);
    await Parse.Cloud.run('saveModifierItemStock', { itemId: menuId, value })
      .then(() => {
        copyModifierItems[idx].hasChanged = false;
        setModifierItems([...copyModifierItems]);
        setShowLoading(false);
      })
      .catch((error) => {
        alert('Please check internet connection');
      });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' size-lg='8'>
              <IonToolbar style={{ backgroundColor: 'transparent' }}>
                {deviceWidth > 500 ? (
                  <IonCol size-xs='12' size-sm='8' size-md='8' size-lg='8'>
                    <IonSearchbar
                      class='customSearchBar'
                      show-cancel-button='focus'
                      debounce={400}
                      value={searchText}
                      onIonInput={(e) => {
                        setSearchText(e.target.value);
                      }}
                      showCancelButton='focus'
                      mode='ios'
                    />
                  </IonCol>
                ) : (
                  <IonTitle>Modifier items</IonTitle>
                )}
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>

      <IonContent scrollX='true' className='ion-padding'>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' size-lg='8'>
              {/* <IonRow>
                {!posProvider ? (
                  <IonButton onClick={handleShowNewMenuModal}>Add Menu</IonButton>
                ) : (
                  posProvider === 'SQUARE' && <IonButton>Sync from Square</IonButton>
                )}
              </IonRow> */}
              <IonCard style={{ borderRadius: '10px', margin: 0 }}>
                <IonRow className='ion-align-items-end' color='light'>
                  <IonCol size='12' className='ion-padding-end'>
                    <IonLabel className='ion-margin-start' style={{ fontSize: '1rem', fontFamily: 'BrandonBold' }}>
                      Create Modifier Item
                    </IonLabel>
                    <IonInput
                      style={{ borderRadius: '20px', height: '40px' }}
                      placeholder={' New Modifier Item Name'}
                      value={newModifierItem.name}
                      onIonInput={(e) => setNewModifierItem({ ...newModifierItem, name: e.target.value })}
                    />
                  </IonCol>
                  <IonCol className='ion-padding-end ion-padding-top'>
                    <IonLabel className='ion-margin-start' style={{ fontSize: '1rem', fontFamily: 'BrandonBold' }}>
                      Price($)
                    </IonLabel>
                    <IonInput
                      className='ion-text-center'
                      style={{ borderRadius: '20px', height: '40px', width: '7em' }}
                      placeholder={'Price'}
                      type='tel'
                      value={newModifierItem.price}
                      onIonInput={(e) => setNewModifierItem({ ...newModifierItem, price: e.target.value })}
                    />
                  </IonCol>
                  <IonCol size='auto'>
                    <IonButton style={{ borderRadius: '20px', height: '40px', width: '5em' }} onClick={handleSaveNewModifierItem}>
                      Add
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCard>
              <main>
                {/* <div className='ion-align-items-end' style={{ width: '100%' }}>
                  <IonButton onClick={handleRefreshModifierItemStock}>Refresh Stocks</IonButton>
                </div> */}
                <IonRow style={{ marginTop: '2em' }}>
                  <IonCol />
                  <IonCol size='auto' className='ion-padding-end'>
                    <div style={{ fontFamily: 'BrandonBold', fontSize: '2vh', width: '3em' }}>Price</div>
                  </IonCol>
                  <IonCol size='auto'>
                    <div style={{ fontFamily: 'BrandonBold', fontSize: '2vh', width: '3em' }}>Stock</div>
                  </IonCol>
                </IonRow>
                {!filteredItems.length ? (
                  <>
                    <div>No Items Yet</div>
                    {/* <IonButton>Create Modifier Item</IonButton> */}
                  </>
                ) : (
                  <IonGrid style={{ paddingBottom: '180px' }}>
                    {filteredItems.map((item, index) => {
                      return (
                        <div key={item.objectId} className='ion-padding-bottom'>
                          <IonRow className='ion-no-padding ion-align-items-center' onClick={() => handleShowModifierItemModal(item)}>
                            <IonCol>
                              <IonLabel>
                                <h3 style={{ fontFamily: 'BrandonBold', fontSize: '2.5vh' }}>{item.name}</h3>
                                <p>
                                  {item.group_titles.map((title, idx) => (
                                    <span key={idx} className='vh175' style={{ marginRight: '5px' }}>
                                      {title},
                                    </span>
                                  ))}
                                </p>
                              </IonLabel>
                            </IonCol>
                            <IonCol size='auto' className='ion-padding-end'>
                              <div className='ion-text-center' style={{ width: '3em' }}>
                                <IonLabel className='vh200'>${roundToTwo(item.price / 100)}</IonLabel>
                              </div>
                            </IonCol>
                            <IonCol size='auto'>
                              <div style={{ width: '3em' }}>
                                <IonInput
                                  className='modifierItemStockInput'
                                  ref={(ref) => {
                                    modifierItemStockInput.current[index] = ref;
                                  }}
                                  slot='end'
                                  type='tel'
                                  value={item.stock}
                                  onClick={(e) => handleOnFocusStockInput(e)}
                                  onIonInput={(e) => handleOnChangeModifierStock(e.target.value, item)}
                                />
                              </div>
                            </IonCol>
                          </IonRow>
                          <IonRow className='ion-align-items-center' style={{ borderBottom: '1px solid #333', paddingBottom: '8px' }}>
                            <IonCol>
                              <div className='ion-float-start'>
                                <IonItem className='soldOutItem ion-no-padding'>
                                  <IonCheckbox
                                    slot='start'
                                    id='soldOutCheckbox'
                                    checked={item.stock === 0 ? true : false}
                                    style={{ marginRight: '0.5em' }}
                                    onIonInput={(e) => handleCheckSoldout(e.target.checked, item.objectId)}
                                  />
                                  <IonLabel className='vh200'>Sold out</IonLabel>
                                </IonItem>
                              </div>
                              <div className='vh200 ion-float-start' onClick={(e) => handleActionPopover(true, e, item, index)}>
                                More action
                              </div>
                            </IonCol>
                            <IonCol size='auto' className='ion-justify-content-center'>
                              <div className='ion-text-center ion-justify-content-center' style={{ width: '3em' }}>
                                {item.hasChanged && (
                                  <IonButton
                                    size='small'
                                    style={{ width: '3em' }}
                                    onClick={() => handleSaveModifierItemStock(item.objectId, item.stock, index)}>
                                    Save
                                  </IonButton>
                                )}
                              </div>
                            </IonCol>
                          </IonRow>
                        </div>
                      );
                    })}
                  </IonGrid>
                )}
              </main>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonPopover
          cssClass='my-custom-class'
          event={popoverState.event}
          isOpen={popoverState.showPopover}
          onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}>
          <IonItem button onClick={() => setShowEditModifierItemModal(true)}>
            <IonIcon slot='start' icon={copyOutline} size={'small'} />
            <IonLabel>Duplicate</IonLabel>
          </IonItem>
          <IonItem
            button
            onClick={() =>
              presentAlert({
                header: 'Are you sure you want to delete this modifier item?',
                buttons: [
                  {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => {
                      setHandlerMessage('Alert canceled');
                    },
                  },
                  {
                    text: 'OK',
                    role: 'confirm',
                    handler: () => {
                      handleDeleteModifierItem();
                      //setHandlerMessage('Alert confirmed');
                    },
                  },
                ],
                onDidDismiss: (e) => setRoleMessage(`Dismissed with role: ${e.detail.role}`),
              })
            }>
            <IonIcon slot='start' icon={trashOutline} size={'small'} />
            <IonLabel>Delete</IonLabel>
          </IonItem>
        </IonPopover>

        <ModifierItemModal
          rId={rId ? rId : user.restaurant_id}
          selectedModifierItem={selectedModifierItem}
          selectedModifierItemIndex={selectedModifierItemIndex}
          showEditModifierItemModal={showEditModifierItemModal}
          setShowEditModifierItemModal={setShowEditModifierItemModal}
          modifierGroups={modifierGroups}
          prevModifierItems={modifierItems}
          setPrevModifierItems={setModifierItems}
        />

        <IonModal
          isOpen={showAddSectionModal}
          onDidDismiss={() => {
            setNewSection({ ...newSection, title: '', description: '', categories: [], menuUuids: [] });
            setIsFilledTitle(false);
            setShowAddSectionModal(false);
            setSectionFlag('');
            setSectionIndex(null);
          }}>
          {/* <EditSectionCategoryModal handleShowAddSectionModal={handleShowAddSectionModal} /> */}
          {/* <IonHeader>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonButton onClick={() => setShowAddSectionModal(false)}>
                  <IonIcon icon={closeOutline} />
                </IonButton>
              </IonButtons>
              <IonTitle>{sectionFlag === 'edit' ? 'Edit Section' : 'Add Section'}</IonTitle>

              <IonButtons slot='end'>
                {sectionFlag === 'edit' ? (
                  <IonButton fill='solid' expand='block' color='primary' onClick={onSaveSections}>
                    Save
                  </IonButton>
                ) : (
                  <IonButton fill='solid' expand='block' color='primary' onClick={onSaveSections}>
                    Save
                  </IonButton>
                )}
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className='ion-padding'>
            <IonGrid>
              <IonRow className='ion-align-items-center'>
                <IonCol size='auto' sizeSm className='ion-text-end'>
                  <div style={{ width: '6em', fontSize: '1rem' }}>Title: </div>
                </IonCol>
                <IonCol className='ion-padding-end'>
                  <IonInput
                    className='ion-padding-start'
                    style={{ border: '1px solid grey', borderRadius: '5px' }}
                    value={sectionTitle && sectionTitle}
                    onIonInput={(e) => setNewSection({ ...newSection, title: e.target.value })}
                    disabled
                  />
                </IonCol>
                <IonCol size='auto'> */}
          {/* <IonButton
                    fill={!isFilledTitle ? 'solid' : 'clear'}
                    onClick={() => {
                      if (!newSection.title.length) return alert('Please fill title');
                      setIsFilledTitle(true);
                    }}>
                    <IonIcon icon={checkmarkOutline} />
                  </IonButton> */}
          {/* </IonCol>
              </IonRow>
              {isFilledTitle || sectionFlag === 'edit' ? (
                <>
                  <IonRow className='ion-align-items-center ion-padding-top'>
                    <IonCol size='auto' className='ion-text-end'>
                      <div style={{ width: '6em', fontSize: '1rem' }}>Categories: </div>
                    </IonCol>
                    <IonCol className='ion-padding-end'>
                      <IonInput
                        className='ion-padding-start'
                        style={{ border: '1px solid grey', borderRadius: '5px' }}
                        placeholder={' Soup & Salad'}
                        value={newSectionCategory}
                        onIonInput={(e) => setNewSectionCategory(e.target.value)}
                      />
                    </IonCol>
                    <IonCol size='auto'>
                      <IonButton fill='solid' onClick={handleNewSectionAddCategory}>
                        <IonIcon icon={addOutline} />
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-align-items-center ion-padding-top'>
                    <IonCol size='auto' className='ion-text-end'>
                      <div style={{ width: '6em', fontSize: '1rem' }}>Categories: </div>
                    </IonCol>
                    <IonCol className='ion-padding-end'>
                      <IonInput
                        className='ion-padding-start'
                        style={{ border: '1px solid grey', borderRadius: '5px' }}
                        placeholder={'Mosyly local & organic ingredients'}
                        value={newSectionCategory}
                        onIonInput={(e) => setNewSectionCategory(e.target.value)}
                      />
                    </IonCol>
                    <IonCol size='auto'>
                      <IonButton fill='solid' onClick={handleNewSectionAddCategory}>
                        <IonIcon icon={addOutline} />
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-align-items-center ion-padding-top'>
                    <IonCol size='auto' className='ion-text-end'>
                      <div style={{ width: '6em' }}>Applied:</div>
                    </IonCol>
                    <IonCol>
                      <IonReorderGroup disabled={false} onIonItemReorder={(e) => handleSectionChange(sectionIndex, e)}>
                        {newSection.categories.map((val, index) => (
                          <IonItem
                            key={nanoid()}
                            color='light'
                            style={{ borderRadius: '5px', marginBottom: '8px' }}
                            onClick={() => handleUpdateCategory(index, val)}>
                            <IonReorder slot='start' />
                            <IonLabel>{val.title}</IonLabel>
                            <IonLabel>
                              {val.menuUuids.length} {val.menuUuids.length > 1 ? 'items' : 'item'}
                            </IonLabel>
                            <IonButton slot='end' fill='solid' color='danger'>
                              <IonIcon icon={trashOutline} />
                            </IonButton>
                          </IonItem>
                        ))}
                      </IonReorderGroup>
                    </IonCol>
                  </IonRow>
                </>
              ) : null}
            </IonGrid>
          </IonContent> */}
        </IonModal>

        <IonModal className='modItemSelectModal' isOpen={showModifierItemSelectModal}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonButton onClick={() => setShowModifierItemSelectModal(false)}>
                  <IonIcon icon={closeOutline} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className='ion-padding'>
            {!modifierItems.length ? (
              <div>No Items Yet</div>
            ) : (
              <IonList>
                {modifierItems.map((item, index) => (
                  <IonItem key={index}>
                    <IonCheckbox slot='start' />
                    <IonLabel>{item.name}</IonLabel>
                    <IonChip slot='end'>
                      <IonLabel>{item.price}</IonLabel>
                    </IonChip>
                  </IonItem>
                ))}
              </IonList>
            )}
          </IonContent>
        </IonModal>
        <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />
      </IonContent>
      {deviceWidth < 501 ? (
        <IonFooter>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonMenuButton id='menu' />
            </IonButtons>
            <IonSearchbar
              class='customSearchBar'
              color='medium'
              debounce={400}
              value={searchText}
              onIonInput={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </IonToolbar>
        </IonFooter>
      ) : null}
    </IonPage>
  );
};

export default ModifierItemsPage;
