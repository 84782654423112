import { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonAlert,
  IonButton,
  IonCheckbox,
  IonContent,
  IonImg,
  IonPage,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonLabel,
  IonItem,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { Camera, CameraResultType } from '@capacitor/camera';
import Parse from 'parse/dist/parse.min.js';
import { nanoid } from 'nanoid';
import AppContext from '../../context/AppContext';
import { errorAlert } from '../../components/errorAlert';
import ReactInputVerificationCode from 'react-input-verification-code';

import './SignupPage.css';

const SignupAdminPage = ({ location }) => {
  const history = useHistory();

  const { user, updateUser, updateIsOnboarding } = useContext(AppContext);

  const [avatar, setAvatar] = useState();
  const [code, setCode] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [emailVerificationCode, setEmailVerificationCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userRegistrationFlag, setUserRegistrationFlag] = useState(true);
  const [restaurant, setRestaurant] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showExistingUserAlert, setShowExistingUserAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [termCheckbox1, setTermCheckbox1] = useState(false);
  const [termCheckbox2, setTermCheckbox2] = useState(false);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [isEmailVaridated, setIsEmailValidated] = useState(false);
  const [isPasswordValidated, setIsPasswordValidated] = useState(false);
  const [instagram, setInstagram] = useState('');
  const [webUrl, setWebUrl] = useState('');
  const [isWebUrlValid, setIsWebUrlValid] = useState(false);
  const [bookingUrl, setBookingUrl] = useState('');
  const [isBookingUrlValid, setIsBookingUrlValid] = useState(false);
  const [savedUser, setSavedUser] = useState(null);

  const swiper = useRef(null);

  // useEffect(() => {
  //   console.log(user);
  //   if (location.state) {
  //     handleSwiperIndex(location.state.swiperIndex);
  //   }
  //   return () => null;
  // }, [location]);

  const handleSwiperIndex = useCallback((num) => {
    setSwiperIndex(num);
  }, []);

  const handleCheckCurrentUser = useCallback(() => {
    const currentUser = Parse.User.current();
    if (currentUser) {
      console.log('currentuser', currentUser.get('status'));
      if (currentUser.get('type') === 'RESTAURANT') {
        if (currentUser.get('status') === 'SIGNUP' || currentUser.get('status') === 'YELP') {
          console.log(currentUser.id);
          updateUser({
            id: currentUser.id,
            first_name: currentUser.get('first_name'),
            last_name: currentUser.get('last_name'),
            email: currentUser.get('email'),
            avatar: currentUser.get('avatar'),
            role: currentUser.get('role'),
          });
          handleSwiperIndex(8);
        } else {
          handleSwiperIndex(1);
        }
      } else {
        console.log('only restaurant');
      }
    } else {
      handleSwiperIndex(1);
    }
  }, []);

  const handleAvatar = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Base64,
      width: 100,
      height: 100,
    });
    if (image) {
      let imageUrl = `data:image/heic;base64,${image.base64String}`;
      setAvatar(imageUrl);
    } else return;
  };

  const validateEmail = (text) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (text.match(mailformat)) {
      setIsEmailValidated(true);
    } else {
      setIsEmailValidated(false);
    }
  };

  const sendEmailVerification = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('checkIfEmailExist', { email }).then(async (result) => {
      if (!result) {
        await Parse.Cloud.run('sendEmailVerification', { email }).then(() => {
          handleSwiperIndex(5);
          setShowLoading(false);
        });
      } else {
        setShowLoading(false);
        setShowExistingUserAlert(true);
      }
    });
  };

  const checkEmailVerificationCode = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('checkEmailVerificationCode', { email, code: emailVerificationCode }).then((result) => {
      if (result) {
        setShowLoading(false);
        handleSwiperIndex(6);
      } else {
        setShowLoading(false);
        setErrorText('Verification code is incorrect');
        setShowErrorAlert(true);
      }
    });
  };

  const validatePassword = (text) => {
    if (password !== text && !isPasswordValidated) {
      return false;
    }
    if (password !== text && isPasswordValidated) {
      setIsPasswordValidated(false);
      return false;
    }
    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,200}$/;
    if (text.match(passw)) {
      setIsPasswordValidated(true);
      return true;
    } else {
      return false;
    }
  };

  const signUp = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('restaurantUserSignUp', { email, password, avatar, firstName, lastName, role }).then(async (result) => {
      setShowLoading(false);
      if (result.code) {
        if (result.code === 202) {
          setErrorText('Account already exists for this email');
          return setShowErrorAlert(true);
        } else if (result.code === 400) {
          setErrorText('Please try again');
          return setShowErrorAlert(true);
        } else if (result.code === 142) {
          setErrorText('Password cannot contain your username');
          return setShowErrorAlert(true);
        } else if (result.code === 125) {
          setErrorText('Email address format is invalid');
          return setShowErrorAlert(true);
        } else {
          setErrorText('Please refresh browser and try again');
          return setShowErrorAlert(true);
        }
      } else {
        await Parse.User.logIn(email, password).then((USER) => {
          if (USER) {
            setShowLoading(false);
            setSavedUser({
              id: USER.id,
            });
            handleSwiperIndex(7);
          } else {
            alert('Please check internet connection');
            return false;
          }
        });
      }
    });
  };

  const yelpSearch = async () => {
    console.log(savedUser?.id);
    setShowLoading(true);
    if (!phoneNumber || phoneNumber.length !== 10) {
      setShowLoading(false);
      setErrorText('Phone Number must be 10 numbers');
      setShowErrorAlert(true);
      return false;
    }
    await Parse.Cloud.run('yelpSearch', { phone: String(phoneNumber), userId: savedUser?.id, role: 'ADMIN' })
      .then((result) => {
        console.log(result);
        if (!result) {
          setErrorText('It seems the restaurant already has an account. Please ask the account holder to add you to the team.');
          setShowLoading(false);
          setShowErrorAlert(true);
          //errorAlert('Error', 'It seems the restaurant already have our account');
          return false;
        } else if (result === 'no restaurant') {
          setErrorText('It seems the restaurant does not exist on yelp');
          setShowLoading(false);
          setShowErrorAlert(true);
          return false;
        } else if (result === 'closed') {
          setErrorText('According to yelp information, this restaurant is closed. Please try to contact yelp.');
          setShowLoading(false);
          setShowErrorAlert(true);
        } else {
          setShowLoading(false);
          setRestaurant(result);
          handleSwiperIndex(9);
        }
      })
      .catch((error) => {
        setShowLoading(false);
        errorAlert('Error', error);
      });
  };

  const verifyPhoneNumber = async () => {
    await Parse.Cloud.run('verifyRestaurantNumber', { number: restaurant.phone }).then(() => {
      handleSwiperIndex(11);
    });
  };

  const verifyResendPhoneNumber = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('verifyRestaurantNumber', { number: restaurant.phone }).then(() => {
      setShowLoading(false);
    });
  };

  const verifyCode = async () => {
    if (code.length !== 5) {
      alert('Please input 5-digit number');
      return false;
    }
    setShowLoading(true);
    await Parse.Cloud.run('verifyCode', { code: code, userId: savedUser?.id, number: restaurant.phone })
      .then((result) => {
        if (result) {
          setShowLoading(false);
          handleSwiperIndex(12);
        } else {
          setShowLoading(false);
          return alert('Looks like you typed invalid code');
        }
      })
      .catch((error) => {
        setShowLoading(false);
        return alert(error);
      });
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      setIsWebUrlValid(true);
      return true;
    } catch (err) {
      return false;
    }
  };

  const isValidBookingUrl = (url) => {
    try {
      new URL(url);
      setIsBookingUrlValid(true);
      return true;
    } catch (err) {
      return false;
    }
  };

  const onSaveRestaurant = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('saveRestaurantFromHelp', { userId: savedUser.id, userEmail: email, data: restaurant, webUrl, instagram, bookingUrl }).then(
      (result) => {
        setShowLoading(false);
        if (result.rId) {
          updateUser({
            ...user,
            id: savedUser.id,
            email,
            first_name: firstName,
            last_name: lastName,
            avatar: '',
            role,
            type: 'RESTAURANT',
            restaurant_id: result.rId,
            restaurant_name: result.rName,
            restaurant_logo: '',
          });
          updateIsOnboarding(false);
          history.push(`/dashboard/store/${result.rId}`);
          return;
        } else {
          return errorAlert('Error', 'Something went wrong');
        }
      }
    );
  };

  return (
    <IonPage>
      {/* <IonHeader>
        <IonRow>
          <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='8' offset-md='2' size-lg='6' offset-lg='3'>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonBackButton text='HOME'></IonBackButton>
              </IonButtons>
            </IonToolbar>
          </IonCol>
        </IonRow>
      </IonHeader> */}
      <IonContent className='ion-padding'>
        {swiperIndex === 0 && (
          <main style={{ width: '100%', height: '100%' }}>
            <IonGrid style={{ height: '100%' }}>
              <IonRow className='ion-align-items-center ion-padding' style={{ height: '100%' }}>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='5' offset-lg='3.5' className='ion-no-padding'>
                  <section className='ion-text-center' style={{ marginBottom: '2em' }}>
                    <div style={{ marginBottom: '2em', width: '100%' }}>
                      <IonImg
                        src={'assets/images/ono.png'}
                        style={{ width: '10vh', height: '10vh', borderRadius: '5vh', overflow: 'hidden', margin: 'auto' }}
                      />
                    </div>
                    <h1 className='ion-margin-bottom' style={{ fontFamily: 'BrandonBold', fontSize: '2em' }}>
                      Mahalo for your interests
                    </h1>
                    <p className='subtitle' style={{ marginTop: '2em', marginBottom: '1em' }}>
                      We are here to help signup for your restaurant in our marketplace! <br />
                    </p>
                    <p>
                      During the process, the robocaller will call your store to verify the verification code, so please do this at a location where
                      you can get a phone call.
                    </p>
                    <div className='ion-text-center'>
                      <IonButton
                        style={{ fontFamily: 'BrandonBold', marginTop: '2em', color: 'white', width: '60%' }}
                        strong
                        size={'large'}
                        onClick={handleCheckCurrentUser}>
                        Continue
                      </IonButton>
                    </div>
                  </section>
                </IonCol>
              </IonRow>
            </IonGrid>

            {/* </IonContent> */}
          </main>
        )}
        {swiperIndex === 1 && (
          <main style={{ width: '100%', height: '100%', textAlign: 'center' }}>
            <IonGrid style={{ height: '100%' }}>
              <IonRow className='ion-align-items-center ion-padding' style={{ height: '100%' }}>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4' className='ion-no-padding'>
                  <section className='ion-text-center' style={{ marginBottom: '2em' }}>
                    <h1>What is your first name?</h1>
                    <IonItem className='ion-margin-vertical ion-no-padding inputItem' lines='none' style={{ width: '100%' }}>
                      <IonLabel className='text-white' position='stacked'>
                        First Name
                      </IonLabel>
                      <IonInput
                        className='register-input'
                        style={{ fontSize: '3vh' }}
                        pattern='^([a-zA-Z0-9]{3,})$'
                        placeholder=''
                        value={firstName}
                        onIonInput={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </IonItem>
                    <div style={{ marginTop: '2em' }}>
                      <IonButton
                        size='large'
                        storng
                        style={{ fontFamily: 'BrandonBold', color: 'white', width: '60%' }}
                        disabled={!firstName.length ? true : false}
                        onClick={() => handleSwiperIndex(2)}>
                        Continue
                      </IonButton>
                    </div>
                  </section>
                </IonCol>
              </IonRow>
            </IonGrid>
          </main>
        )}
        {swiperIndex === 2 && (
          <main style={{ width: '100%', height: '100%' }}>
            <IonGrid style={{ height: '100%' }}>
              <IonRow className='ion-align-items-center ion-padding' style={{ height: '100%' }}>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4' className='ion-no-padding'>
                  <section className='ion-text-center' style={{ marginBottom: '2em' }}>
                    <h1>What is your last name?</h1>
                    <IonItem className='ion-margin-vertical ion-no-padding inputItem' lines='none' style={{ width: '100%' }}>
                      <IonLabel className='text-white' position='stacked'>
                        Last Name
                      </IonLabel>
                      <IonInput
                        className='register-input'
                        style={{ fontSize: '3vh' }}
                        pattern='^([a-zA-Z0-9]{3,})$'
                        placeholder=''
                        value={lastName}
                        onIonInput={(e) => setLastName(e.target.value)}
                        required
                      />
                    </IonItem>
                    <div style={{ marginTop: '2em' }}>
                      <IonButton
                        size='large'
                        storng
                        style={{ fontFamily: 'BrandonBold', color: 'white', width: '60%' }}
                        disabled={!lastName.length ? true : false}
                        onClick={() => handleSwiperIndex(3)}>
                        Continue
                      </IonButton>
                    </div>
                  </section>
                </IonCol>
              </IonRow>
            </IonGrid>
          </main>
        )}
        {swiperIndex === 3 && (
          <main style={{ width: '100%', height: '100%' }}>
            <IonGrid style={{ height: '100%' }}>
              <IonRow className='ion-align-items-center ion-padding' style={{ height: '100%' }}>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4' className='ion-no-padding'>
                  <section className='ion-text-center' style={{ marginBottom: '2em' }}>
                    <h1>What is your role?</h1>
                    <IonItem className='ion-margin-vertical ion-no-padding inputItem' lines='none' style={{ width: '100%' }}>
                      <IonLabel className='text-white' position='stacked'>
                        Role
                      </IonLabel>
                      <IonSelect
                        className='register-input'
                        interface='action-sheet'
                        value={role}
                        style={{ fontSize: '3vh' }}
                        onIonInput={(e) => setRole(e.target.value)}>
                        <IonSelectOption className='text select-option' value='Owner'>
                          Owner
                        </IonSelectOption>
                        <IonSelectOption value='Chef'>Chef</IonSelectOption>
                        <IonSelectOption value='Kitchen Manager'>Kitchen Manager</IonSelectOption>
                        <IonSelectOption value='Manager'>Manager</IonSelectOption>
                        <IonSelectOption value='Marketing'>Marketing</IonSelectOption>
                        <IonSelectOption value='IT'>IT</IonSelectOption>
                        <IonSelectOption value='Cashier'>Cashier</IonSelectOption>
                      </IonSelect>
                    </IonItem>
                    <div style={{ marginTop: '2em' }}>
                      <IonButton
                        size='large'
                        storng
                        style={{ fontFamily: 'BrandonBold', color: 'white', width: '60%' }}
                        disabled={!role.length ? true : false}
                        onClick={() => handleSwiperIndex(4)}>
                        Continue
                      </IonButton>
                    </div>
                  </section>
                </IonCol>
              </IonRow>
            </IonGrid>
          </main>
        )}
        {swiperIndex === 4 && (
          <main style={{ width: '100%', height: '100%' }}>
            <IonGrid style={{ height: '100%' }}>
              <IonRow className='ion-align-items-center ion-padding' style={{ height: '100%' }}>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4' className='ion-no-padding'>
                  <section className='ion-text-center' style={{ marginBottom: '2em' }}>
                    <h1 className='ion-margin-bottom'>What is your email address?</h1>
                    <p style={{ marginTop: '2em' }}>We will send you a six-digit verification code when you submit your email.</p>
                    <IonItem className='ion-margin-top ion-no-padding inputItem' lines='none' style={{ width: '100%' }}>
                      <IonLabel className='text-white' position='stacked'>
                        Email
                      </IonLabel>
                      <IonInput
                        className='register-input'
                        inputmode='email'
                        style={{ fontSize: '3vh' }}
                        pattern='^([a-zA-Z0-9]{3,})$'
                        placeholder=''
                        min='3'
                        value={email}
                        onIonInput={(e) => {
                          setEmail(e.target.value);
                          validateEmail(e.target.value);
                        }}
                        required
                      />
                    </IonItem>
                    <p>
                      <IonLabel style={{ fontFamily: 'BrandonBold', fontSize: '1.5vh' }}>
                        This email address will be used for logging in and receiving details about orders when you accept orders online.
                      </IonLabel>
                    </p>
                    <IonButton
                      size='large'
                      storng
                      style={{ fontFamily: 'BrandonBold', color: 'white', width: '60%' }}
                      disabled={!isEmailVaridated}
                      onClick={sendEmailVerification}>
                      Submit
                    </IonButton>
                    {/* <p style={{ marginTop: '2em' }}>
                      <IonButton fill='clear' onClick={() => handleSwiperIndex(1)}>
                        <IonIcon slot='start' icon={arrowBackOutline} color='white' />
                        BACK
                      </IonButton>
                    </p> */}
                  </section>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonAlert
              isOpen={showExistingUserAlert}
              onDidDismiss={() => setShowExistingUserAlert(false)}
              cssClass='alert'
              header={'Looks like'}
              subHeader={''}
              message={'This email address has already been registered on ono online. Please use a different email address to register.'}
              buttons={['OK']}
            />
          </main>
        )}
        {swiperIndex === 5 ? (
          <main style={{ width: '100%', height: '100%' }}>
            <IonGrid style={{ height: '100%' }}>
              <IonRow className='ion-align-items-center ion-padding' style={{ height: '100%' }}>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4' className='ion-no-padding'>
                  <section className='ion-text-center' style={{ marginBottom: '2em' }}>
                    <h5 style={{ color: '#FC0FC0' }}>We have sent a code to your email address</h5>
                    <h1>Please enter 6 digit number</h1>
                    <p style={{ marginTop: '2em' }}>Enter Code below</p>
                    <IonRow className='ion-justify-content-center' style={{ marginTop: '2em', width: '100%' }}>
                      {/* <IonCol size='12'> */}
                      <ReactInputVerificationCode
                        style={{ width: '90%', maxWidth: '90%', minWidth: '90%' }}
                        autoFocus={true}
                        length={6}
                        value={emailVerificationCode}
                        onChange={(e) => setEmailVerificationCode(e)}
                      />
                      {/* </IonCol> */}
                    </IonRow>

                    <div style={{ marginTop: '2em', marginBottom: '2em' }}>
                      <IonButton
                        size='large'
                        storng
                        style={{ fontFamily: 'BrandonBold', color: 'white', width: '60%' }}
                        disabled={emailVerificationCode.length !== 6}
                        fill='solid'
                        onClick={checkEmailVerificationCode}>
                        Submit
                      </IonButton>
                      {/* <IonButton fill='clear' style={{ color: 'white' }} onClick={() => setSwiperIndex(4)}>
                      <IonIcon slot='start' icon={arrowBackOutline} color='white' />
                      BACK
                    </IonButton> */}
                    </div>
                    <IonLabel color='danger' onClick={sendEmailVerification}>
                      Resend Code
                    </IonLabel>
                  </section>
                </IonCol>
              </IonRow>
            </IonGrid>
          </main>
        ) : null}
        {swiperIndex === 6 && (
          <main style={{ width: '100%', height: '100%' }}>
            <IonGrid style={{ height: '100%' }}>
              <IonRow className='ion-align-items-center ion-padding' style={{ height: '100%' }}>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4' className='ion-no-padding'>
                  <section className='ion-text-center' style={{ marginBottom: '2em' }}>
                    <h1 className='ion-margin-bottom'>
                      Great!
                      <br />
                      Please enter your new password
                    </h1>
                    <p style={{ marginTop: '2em' }}>Your login credentials use email and this password</p>
                    <p style={{ color: '#FC0FC0', fontFamily: 'BrandonBold' }}>
                      At lease 8 characters which contain at least one numeric digit, one uppercase and one lowercase letter
                    </p>
                    <IonItem className='ion-margin-vertical ion-no-padding inputItem' lines='none' style={{ width: '100%' }}>
                      <IonLabel className='text-white' position='stacked'>
                        Password
                      </IonLabel>
                      <IonInput
                        className='register-input'
                        type='password'
                        minlength={8}
                        placeholder=''
                        value={password}
                        onIonInput={(e) => setPassword(e.target.value)}
                        required
                      />
                    </IonItem>
                    <IonItem className='ion-margin-vertical ion-no-padding inputItem' lines='none' style={{ width: '100%' }}>
                      <IonLabel className='text-white' position='stacked'>
                        Confirm password
                      </IonLabel>
                      <IonInput
                        className='register-input'
                        type='password'
                        minlength={8}
                        placeholder=''
                        value={confirmPassword}
                        onIonInput={(e) => {
                          setConfirmPassword(e.target.value);
                          validatePassword(e.target.value);
                        }}
                        required
                      />
                    </IonItem>

                    <IonButton
                      size='large'
                      storng
                      style={{ fontFamily: 'BrandonBold', color: 'white', width: '60%' }}
                      disabled={!isPasswordValidated}
                      onClick={signUp}>
                      Submit
                    </IonButton>
                    {/* <p style={{ marginTop: '2em' }}>
                      <IonButton fill='clear' onClick={() => handleSwiperIndex(5)}>
                        <IonIcon slot='start' icon={arrowBackOutline} color='white' />
                        BACK
                      </IonButton>
                    </p> */}
                  </section>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonAlert
              isOpen={showExistingUserAlert}
              onDidDismiss={() => setShowExistingUserAlert(false)}
              cssClass='alert'
              header={'Looks like'}
              subHeader={''}
              message={'This email address has already been registered on ono online. Please use a different email address to register.'}
              buttons={['OK']}
            />
          </main>
        )}
        {swiperIndex === 7 && (
          <main style={{ width: '100%', height: '100%' }}>
            <IonGrid style={{ height: '100%' }}>
              <IonRow className='ion-align-items-center ion-padding' style={{ height: '100%' }}>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4' className='ion-no-padding'>
                  <section className='ion-text-center' style={{ marginBottom: '2em' }}>
                    <h1>
                      Nice work!
                      <br />
                      We have created your account
                    </h1>
                    <h3>Let's move on to venue info</h3>
                    <p style={{ marginTop: '2em' }}>
                      Please do this at a location where you can get a phone call. This is for protecting your account.
                    </p>

                    <div style={{ marginTop: '2em' }}>
                      <IonButton
                        size='large'
                        storng
                        style={{ fontFamily: 'BrandonBold', color: 'white', width: '60%' }}
                        onClick={() => handleSwiperIndex(8)}>
                        Continue
                      </IonButton>
                    </div>
                  </section>
                </IonCol>
              </IonRow>
            </IonGrid>
          </main>
        )}
        {swiperIndex === 8 ? (
          <main style={{ width: '100%', height: '100%' }}>
            <IonGrid style={{ height: '100%' }}>
              <IonRow className='ion-align-items-center' style={{ height: '100%' }}>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4'>
                  <section className='ion-text-center' style={{ marginBottom: '2em' }}>
                    <h1>
                      Please enter your
                      <br />
                      venue phone number
                    </h1>
                    <p>Please note that only 10-digit numbers are acceptable.</p>
                    <IonItem className='ion-no-padding' style={{ marginTop: '2em' }}>
                      <IonInput
                        className='ion-text-center'
                        style={{ borderColor: 'grey', borderWidth: '1px', fontSize: '4.5vh' }}
                        value={phoneNumber}
                        placeholder='Start from 808'
                        onIonInput={(e) => setPhoneNumber(e.target.value)}
                        type='tel'
                      />
                    </IonItem>
                    <div style={{ marginTop: '2em' }}>
                      <IonButton
                        size='large'
                        storng
                        style={{ fontFamily: 'BrandonBold', color: 'white', width: '60%' }}
                        disabled={phoneNumber.length !== 10}
                        onClick={yelpSearch}>
                        Continue
                      </IonButton>
                    </div>
                    <p style={{ marginTop: '2em', textAlign: 'center' }}>
                      <IonLabel className='vh200'>
                        Phone Number Search by{'  '}
                        <span style={{ backgroundColor: 'red', paddingLeft: '4px', paddingRight: '4px' }}>
                          <img style={{ width: '6vh' }} src={'assets/images/yelp_logo_dark_bg.png'} alt='yelp' />
                        </span>
                      </IonLabel>
                    </p>
                  </section>
                </IonCol>
              </IonRow>
            </IonGrid>
          </main>
        ) : null}
        {swiperIndex === 9 ? (
          <div style={{ height: '100%' }}>
            {!restaurant ? null : (
              <IonGrid style={{ height: '100%' }}>
                <IonRow className='ion-align-items-center' style={{ height: '100%' }}>
                  <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='8' offset-md='2' size-lg='6' offset-lg='3' className='ion-no-padding'>
                    <section className='ion-text-center' style={{ marginBottom: '2em' }}>
                      <h1>Is this your venue?</h1>
                      <p>You will be able to change categories later.</p>
                      <div className='card-container'>
                        <IonItem className='ion-no-padding ion-text-center'>
                          <IonLabel style={{ fontSize: '3.5vh', fontFamily: 'BrandonBold' }}>{restaurant?.name}</IonLabel>
                          {/* <IonInput
                          className='bold'
                          style={{ color: 'black', fontSize: '3vh', fontFamily: 'BrandonBold' }}
                          value={restaurant?.name}
                          onIonInput={(e) => setRestaurant({ ...restaurant, name: e.target.value })}
                        /> */}
                        </IonItem>
                        <div style={{ marginTop: '1em' }}>
                          {!restaurant?.categories
                            ? null
                            : restaurant.categories.map((item) => (
                                <IonLabel key={nanoid()} className='ion-margin-end 250vh'>
                                  {item.title}
                                </IonLabel>
                              ))}
                        </div>
                        <div style={{ marginTop: '0.5em' }}>
                          <IonLabel className='300vh'>{restaurant?.location.address1} </IonLabel>
                          {!restaurant?.location.address2 ? null : <IonLabel className='300vh'>{restaurant?.location.address2} </IonLabel>}
                          <IonLabel className='300vh'>{restaurant?.location.address3}, </IonLabel>
                          <IonLabel className='300vh'>{restaurant?.location.city}, </IonLabel>
                          <IonLabel className='300vh'>{restaurant?.location.state} </IonLabel>
                          <IonLabel className='300vh'>{restaurant?.location.zip_code}</IonLabel>
                        </div>
                        <div style={{ marginTop: '0.5em' }}>
                          <IonLabel className='350vh'>{restaurant?.display_phone}</IonLabel>
                        </div>
                        <IonItem className='ion-no-padding ion-margin-top ion-justify-content-center ion-align-items-center'>
                          <IonCheckbox slot='start' className='checkbox' value={termCheckbox1} onIonInput={() => setTermCheckbox1(!termCheckbox1)} />
                          <div className='ion-wrap text-white vh175' style={{ wordWrap: 'normal' }}>
                            "ono online" is only for food services. I confirm that I will use this app for restaurant or food services.
                          </div>
                        </IonItem>
                        <IonItem className='ion-no-padding ion-margin-top ion-justify-content-center ion-align-items-center'>
                          <IonCheckbox slot='start' className='checkbox' value={termCheckbox2} onIonInput={() => setTermCheckbox2(!termCheckbox2)} />
                          <IonLabel className='ion-wrap text-white vh175'>
                            I agree to the{' '}
                            <a href='/termsofuse' target='_blank' rel='noreferrer'>
                              Terms of Use
                            </a>
                          </IonLabel>
                        </IonItem>
                        <div style={{ marginTop: '2em' }}>
                          {/* <IonButton className='ion-margin-start ion-margin-end' fill='outline' onClick={() => history.replace('/')}>
                          Cancel
                        </IonButton> */}
                          <IonButton
                            size='large'
                            storng
                            style={{ fontFamily: 'BrandonBold', color: 'white', width: '60%' }}
                            onClick={() => handleSwiperIndex(10)}
                            disabled={termCheckbox1 && termCheckbox2 ? false : true}>
                            Confirm
                          </IonButton>
                        </div>
                      </div>
                    </section>
                  </IonCol>
                </IonRow>
              </IonGrid>
            )}
          </div>
        ) : null}
        {swiperIndex === 10 ? (
          <main style={{ height: '100%' }}>
            <IonGrid style={{ height: '100%' }}>
              <IonRow className='ion-align-items-center ion-padding' style={{ height: '100%' }}>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4' className='ion-no-padding'>
                  <section className='ion-text-center' style={{ marginBottom: '2em' }}>
                    <h1>
                      Are you ready
                      <br />
                      to take a phone call?
                    </h1>
                    <p style={{ marginTop: '2em' }}>
                      We will call for verification right after you click or tap "I AM READY".
                      <br />
                      Please input the 5-digit code on the next screen.
                    </p>
                    <IonLabel className='bold vh300' style={{ margin: '1em 0' }}>
                      Your number:
                      <br />
                      {restaurant ? restaurant.display_phone : ''}
                    </IonLabel>

                    <div style={{ marginTop: '2em' }}>
                      <IonButton size='large' storng style={{ fontFamily: 'BrandonBold', color: 'white', width: '60%' }} onClick={verifyPhoneNumber}>
                        I am ready
                      </IonButton>
                    </div>
                  </section>
                </IonCol>
              </IonRow>
            </IonGrid>
          </main>
        ) : null}
        {swiperIndex === 11 ? (
          <main style={{ width: '100%', height: '100%' }}>
            <IonGrid style={{ height: '100%' }}>
              <IonRow className='ion-align-items-center ion-padding' style={{ height: '100%' }}>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4' className='ion-no-padding'>
                  <section className='ion-text-center' style={{ marginBottom: '2em' }}>
                    <h1>What was the 5-digit code?</h1>
                    <p style={{ marginTop: '2em' }}>Enter Code below</p>
                    <IonRow className='ion-justify-content-center' style={{ marginTop: '2em' }}>
                      <ReactInputVerificationCode autoFocus={true} length={5} value={code} onChange={(e) => setCode(e)} />
                    </IonRow>

                    <div style={{ marginTop: '2em', marginBottom: '1em' }}>
                      <IonButton
                        size='large'
                        storng
                        style={{ fontFamily: 'BrandonBold', color: 'white', width: '60%' }}
                        disabled={code.length !== 5}
                        onClick={verifyCode}>
                        Submit
                      </IonButton>
                    </div>
                    <IonLabel color='danger' onClick={verifyResendPhoneNumber}>
                      Resend Code
                    </IonLabel>
                  </section>
                </IonCol>
              </IonRow>
            </IonGrid>
          </main>
        ) : null}
        {swiperIndex === 12 && (
          <main style={{ width: '100%', height: '100%', textAlign: 'center' }}>
            <IonGrid style={{ height: '100%' }}>
              <IonRow className='ion-align-items-center ion-padding' style={{ height: '100%' }}>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4' className='ion-no-padding'>
                  <section className='ion-text-center' style={{ marginBottom: '2em' }}>
                    <h1>Do you have website?</h1>
                    <p>Please enter your website URL if you have</p>
                    <IonItem className='ion-margin-vertical ion-no-padding inputItem' lines='none' style={{ width: '100%' }}>
                      <IonLabel className='text-white' position='stacked'>
                        Website URL
                      </IonLabel>
                      <IonInput
                        className='register-input'
                        placeholder='https://your website url'
                        value={webUrl}
                        onIonInput={(e) => {
                          setWebUrl(e.target.value);
                          isValidUrl(e.target.value);
                        }}
                        required
                      />
                    </IonItem>
                    <div style={{ marginTop: '2em' }}>
                      <IonButton
                        size='large'
                        storng
                        style={{ fontFamily: 'BrandonBold', width: '60%' }}
                        disabled={!isWebUrlValid}
                        onClick={() => handleSwiperIndex(13)}>
                        Continue
                      </IonButton>
                      <br />
                      <IonButton
                        className='ion-margin-top'
                        size='large'
                        fill='outline'
                        storng
                        style={{ fontFamily: 'BrandonBold', width: '60%' }}
                        onClick={() => handleSwiperIndex(13)}>
                        No
                      </IonButton>
                    </div>
                  </section>
                </IonCol>
              </IonRow>
            </IonGrid>
          </main>
        )}
        {swiperIndex === 13 && (
          <main style={{ width: '100%', height: '100%', textAlign: 'center' }}>
            <IonGrid style={{ height: '100%' }}>
              <IonRow className='ion-align-items-center ion-padding' style={{ height: '100%' }}>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4' className='ion-no-padding'>
                  <section className='ion-text-center' style={{ marginBottom: '2em' }}>
                    <h1>Do you have Instagram account?</h1>
                    <p>Please enter Instagram username (No need @ mark)</p>
                    <IonItem className='ion-margin-vertical ion-no-padding inputItem' lines='none' style={{ width: '100%' }}>
                      <IonLabel className='text-white' position='stacked'>
                        Instagram username
                      </IonLabel>
                      <IonInput
                        className='register-input'
                        placeholder=''
                        value={instagram}
                        onIonInput={(e) => setInstagram(e.target.value)}
                        required
                      />
                    </IonItem>
                    <div style={{ marginTop: '2em' }}>
                      <IonButton
                        size='large'
                        storng
                        style={{ fontFamily: 'BrandonBold', width: '60%' }}
                        disabled={instagram.length > 1 ? false : true}
                        onClick={() => handleSwiperIndex(14)}>
                        Continue
                      </IonButton>
                      <IonButton
                        className='ion-margin-top'
                        size='large'
                        fill='outline'
                        storng
                        style={{ fontFamily: 'BrandonBold', width: '60%' }}
                        onClick={() => handleSwiperIndex(14)}>
                        No
                      </IonButton>
                    </div>
                  </section>
                </IonCol>
              </IonRow>
            </IonGrid>
          </main>
        )}
        {swiperIndex === 14 && (
          <main style={{ width: '100%', height: '100%', textAlign: 'center' }}>
            <IonGrid style={{ height: '100%' }}>
              <IonRow className='ion-align-items-center ion-padding' style={{ height: '100%' }}>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4' className='ion-no-padding'>
                  <section className='ion-text-center' style={{ marginBottom: '2em' }}>
                    <h1>Are you using 3rd party reservation service?</h1>
                    <p>Such as Open Table, RESY, TOAST, and so on</p>
                    <p>Please enter the URL if you have</p>
                    <IonItem className='ion-margin-vertical ion-no-padding inputItem' lines='none' style={{ width: '100%' }}>
                      <IonLabel className='text-white' position='stacked'>
                        URL
                      </IonLabel>
                      <IonInput
                        className='register-input'
                        placeholder='https://***********'
                        value={bookingUrl}
                        onIonInput={(e) => {
                          setBookingUrl(e.target.value);
                          isValidBookingUrl(e.target.value);
                        }}
                        required
                      />
                    </IonItem>
                    <div style={{ marginTop: '2em' }}>
                      <IonButton
                        size='large'
                        storng
                        style={{ fontFamily: 'BrandonBold', color: 'white', width: '60%' }}
                        disabled={!isBookingUrlValid}
                        onClick={() => handleSwiperIndex(15)}>
                        Continue
                      </IonButton>
                      <IonButton
                        className='ion-margin-top'
                        size='large'
                        fill='outline'
                        storng
                        style={{ fontFamily: 'BrandonBold', width: '60%' }}
                        onClick={() => handleSwiperIndex(15)}>
                        No
                      </IonButton>
                    </div>
                  </section>
                </IonCol>
              </IonRow>
            </IonGrid>
          </main>
        )}
        {swiperIndex === 15 && (
          <main style={{ width: '100%', height: '100%', textAlign: 'center' }}>
            <IonGrid style={{ height: '100%' }}>
              <IonRow className='ion-align-items-center ion-padding' style={{ height: '100%' }}>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4' className='ion-no-padding'>
                  <section className='ion-text-center' style={{ marginBottom: '2em' }}>
                    <h1>Please download ono online app</h1>
                    <p>Now, you can login with your email and password on the app</p>
                    <div style={{ marginTop: '2em' }}>
                      <IonImg
                        src={'assets/images/store_qr_pink.png'}
                        alt='Play store'
                        style={{ width: 'auto', height: '16vh' }}
                        onClick={() => window.open('https://play.google.com/store/apps/details?id=co.onoonline.android')}
                      />
                    </div>
                    <div style={{ marginTop: '2em' }}>
                      After tapping "Finish" below, we will navigate you to a store page where you can change store info. Please check your business
                      carefully.
                    </div>
                    <div style={{ marginTop: '2em' }}>
                      <IonButton size='large' storng style={{ fontFamily: 'BrandonBold', width: '60%' }} onClick={onSaveRestaurant}>
                        Finish
                      </IonButton>
                    </div>
                  </section>
                </IonCol>
              </IonRow>
            </IonGrid>
          </main>
        )}

        <IonAlert
          isOpen={showErrorAlert}
          onDidDismiss={() => setShowErrorAlert(false)}
          cssClass='errorAlert'
          header={'Error'}
          subHeader={errorText}
          message={''}
          buttons={['OK']}
        />
        <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} />
      </IonContent>
    </IonPage>
  );
};
export default SignupAdminPage;
