import { useContext, useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonAlert,
  IonButton,
  IonContent,
  IonPage,
  IonInput,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonLoading,
  IonCard,
  IonLabel,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonTitle,
} from '@ionic/react';
import Parse from 'parse/dist/parse.min.js';
import { nanoid } from 'nanoid';
import { Storage } from '@ionic/storage';
import AppContext from '../context/AppContext';
import { errorAlert } from '../components/errorAlert';
import { arrowBackOutline } from 'ionicons/icons';
import '../pages/SignupPage/SignupPage.css';

const AdminPage = () => {
  const history = useHistory();

  const { user, updateUser, updateIsAuthed, updateIsAdmin, updateAdmin, updateIsOnboarding } = useContext(AppContext);

  const [restaurant, setRestaurant] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [contentIndex, setContentIndex] = useState(1);
  const [errorText, setErrorText] = useState('');
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const slideToRegister = () => {
    setContentIndex(2);
  };

  const slideToSearch = () => {
    setContentIndex(3);
  };

  const handleLogOut = async () => {
    await Parse.User.logOut().then(() => {
      updateIsAdmin(false);
      updateIsOnboarding(true);
      return history.replace('/');
    });
  };

  const yelpSearch = async () => {
    setShowLoading(true);
    console.log(phoneNumber);
    if (!phoneNumber || phoneNumber.length !== 10) {
      setShowLoading(false);
      errorAlert('Error', 'Phone Number must be 10 numbers');
      return false;
    }
    await Parse.Cloud.run('yelpSearch', { phone: String(phoneNumber), role: user.role })
      .then((result) => {
        console.log(result);
        if (!result) {
          setShowLoading(false);
          setErrorText('It seems the restaurant already have our account');
          setShowErrorAlert(true);
        } else if (result === 'no restaurant') {
          setShowLoading(false);
          setErrorText('It seems the restaurant does not exist on yelp');
          setShowErrorAlert(true);
        } else if (result === 'closed') {
          setShowLoading(false);
          setErrorText('According to yelp information, this restaurant is closed. Please try to contact yelp.');
          setShowErrorAlert(true);
        } else {
          setShowLoading(false);
          setRestaurant(result);
          setContentIndex(4);
        }
      })
      .catch((error) => {
        setShowLoading(false);
        setErrorText(error);
        setShowErrorAlert(true);
      });
  };

  const onSaveRestaurant = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('saveRestaurantFromAdmin', { data: restaurant })
      .then(async (result) => {
        console.log(result);
        if (result.id) {
          try {
            setShowLoading(false);
            updateIsAuthed(true);
            updateUser({
              ...user,
              id: user.id,
              first_name: user.first_name,
              last_name: user.last_name,
              email: user.email,
              role: 'ADMIN',
              restaurant_id: result.id,
              restaurant_name: result.name,
            });
            const store = new Storage();
            await store.create();
            await store.set('restaurantId', result.id);
            return history.push(`/admin/${user.id}/home/${result.id}`);
          } catch (error) {
            console.log(error);
          }
        } else {
          setShowLoading(false);
          return errorAlert('Error', 'Something went wrong');
        }
      })
      .catch((error) => {
        setShowLoading(false);
        alert(error);
      });
  };

  const onSearchRestaurant = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('adminSearchRestaurant', { phone: phoneNumber }).then(async (result) => {
      console.log(result);
      if (!result) {
        setShowLoading(false);
        alert('No restaurant found');
      } else {
        try {
          updateUser({ ...user, id: user.id, role: 'ADMIN', restaurant_id: result.id, restaurant_name: result.name });
          updateAdmin({
            rId: result.id,
            rName: result.name,
          });
          setShowLoading(false);
          const store = new Storage();
          await store.create();
          await store.set('restaurantId', result.id);
          return history.push({
            pathname: `/admin/${user.id}/home/${result.id}`,
            state: { rId: result.id },
          });
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='8' offset-md='2' size-lg='6' offset-lg='3'>
              <IonToolbar>
                {contentIndex === 2 || contentIndex === 3 ? (
                  <IonButtons slot='start'>
                    <IonButton slot='icon-only' onClick={() => setContentIndex(1)}>
                      <IonIcon icon={arrowBackOutline} />
                    </IonButton>
                  </IonButtons>
                ) : null}
                <IonTitle>Admin</IonTitle>
                <IonButtons slot='end'>
                  <IonButton onClick={handleLogOut}>Log out</IonButton>
                </IonButtons>
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>
      <IonContent padding>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='8' offset-md='2' size-lg='6' offset-lg='3'>
              {contentIndex === 1 && (
                <section>
                  <IonCard className='card-container'>
                    <IonCardContent>
                      <h3>
                        <IonButton fill='solid' expand='block' onClick={slideToRegister}>
                          Register Restaurant
                        </IonButton>
                      </h3>
                      <h3 style={{ marginTop: '50px' }}>
                        <IonButton fill='solid' expand='block' onClick={slideToSearch}>
                          Modify exsiting restaurant
                        </IonButton>
                      </h3>
                    </IonCardContent>
                  </IonCard>
                </section>
              )}
              {contentIndex === 2 && (
                <section>
                  <IonCard className='card-container'>
                    <IonCardHeader>
                      <IonCardTitle>Register new restaurant</IonCardTitle>
                      <IonCardTitle>What is the</IonCardTitle>
                      <IonCardTitle>restaurant Phone Number?</IonCardTitle>
                      <IonCardSubtitle>10 digit numbers, no need () or -</IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <IonInput
                        style={{ fontSize: '1.5rem', margin: '8px 0 24px 0' }}
                        value={phoneNumber}
                        placeholder='8080000000'
                        onIonInput={(e) => setPhoneNumber(e.target.value)}
                        type='number'
                      />
                      <IonButton fill='solid' expand='block' onClick={yelpSearch}>
                        Submit
                      </IonButton>
                    </IonCardContent>
                  </IonCard>
                  <IonAlert
                    isOpen={showErrorAlert}
                    onDidDismiss={() => setShowErrorAlert(false)}
                    // cssClass='errorAlert'
                    header={'Error'}
                    subHeader={''}
                    message={errorText}
                    buttons={['OK']}
                  />
                </section>
              )}
              {contentIndex === 3 && (
                <section>
                  <IonCard className='card-container'>
                    <IonCardHeader>
                      <IonCardTitle>Restaurant search</IonCardTitle>
                      <IonCardTitle>Type Restaurant Phone Number</IonCardTitle>
                      <IonCardSubtitle>10 digit numbers, no need () or -</IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <IonInput
                        style={{ fontSize: '1.5rem', margin: '8px 0 24px 0' }}
                        value={phoneNumber}
                        placeholder='8080000000'
                        onIonInput={(e) => setPhoneNumber(e.target.value)}
                        type='number'
                      />
                      <IonButton fill='solid' expand='block' onClick={onSearchRestaurant}>
                        Search
                      </IonButton>
                    </IonCardContent>
                  </IonCard>
                </section>
              )}
              {contentIndex === 4 && (
                <section>
                  {!restaurant ? null : (
                    <IonCard className='card-container'>
                      <IonHeader>
                        <IonCardTitle style={{ fontWeight: 'bold' }}>{restaurant.name}</IonCardTitle>
                      </IonHeader>
                      <p>
                        {restaurant.categories.map((item) => (
                          <IonLabel key={nanoid()} className='ion-margin-end'>
                            {item.title}
                          </IonLabel>
                        ))}
                      </p>
                      <IonLabel>{restaurant.location.address1} </IonLabel>
                      {!restaurant.location.address2 ? null : <IonLabel>{restaurant.location.address2} </IonLabel>}
                      <IonLabel>{restaurant.location.address3}, </IonLabel>
                      <IonLabel>{restaurant.location.city}, </IonLabel>
                      <IonLabel>{restaurant.location.state} </IonLabel>
                      <IonLabel>{restaurant.location.zip_code}</IonLabel>
                      <p>
                        <IonLabel>{restaurant.display_phone}</IonLabel>
                      </p>

                      <div>
                        <IonButton onClick={() => history.replace('/')}>Cancel</IonButton>
                        <IonButton onClick={onSaveRestaurant}>Confirm</IonButton>
                      </div>
                    </IonCard>
                  )}
                </section>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={''} />
    </IonPage>
  );
};
export default AdminPage;
