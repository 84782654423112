import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const width = window?.innerWidth || 0;
  const height = window?.innerHeight || 0;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      const width = window?.innerWidth || 0;
      const height = window?.innerHeight || 0;
      setWindowDimensions({ width, height });
    };

    window.addEventListener('resize', handleResize);

    // クリーンアップ関数
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return windowDimensions;
}
