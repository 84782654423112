import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonAlert,
  IonButton,
  IonContent,
  IonPage,
  IonIcon,
  IonItem,
  IonLabel,
  IonInput,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonLoading,
  IonCard,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonTitle,
  IonRow,
  IonCol,
  useIonViewDidEnter,
  IonTextarea,
  IonGrid,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import Parse from 'parse/dist/parse.min.js';
import AppContext from '../../context/AppContext';
import { errorAlert } from '../../components/errorAlert';
import './ContactPage.css';

const ContactPage = () => {
  const { user } = useContext(AppContext);

  const history = useHistory();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ header: '', message: '' });
  const [showLoading, setShowLoading] = useState(false);

  useIonViewDidEnter(() => {
    const getUser = async () => {
      const currentUser = Parse.User.current();
      if (currentUser) {
        setEmail(currentUser.get('email'));
      } else {
        console.log('Not currentUser');
      }
    };
    getUser();
  });

  const onSubmit = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('sendContactUs', { user, message }).then(async (result) => {
      if (result) {
        alert('Thank you for your inquiry');
      }
      setMessage('');
      setShowLoading(false);
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonRow>
          <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='8' offset-md='2' size-lg='6' offset-lg='3'>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonBackButton />
              </IonButtons>
              <IonTitle>Contact us</IonTitle>
            </IonToolbar>
          </IonCol>
        </IonRow>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => {
            setShowAlert(false);
          }}
          header={alertMessage.header}
          subHeader={alertMessage.message}
          message={''}
          buttons={['OK']}
        />
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='8' offset-md='2' size-lg='6' offset-lg='3'>
              <IonCard className='ion-text-center ion-padding-vertical'>
                <IonCardContent>
                  <IonCardTitle style={{ fontFamily: 'BrandonBold', fontSize: '1.5em' }}>Thank you for using ono online</IonCardTitle>
                  <IonCardSubtitle>Please enter your email </IonCardSubtitle>
                  <IonItem className='ion-margin-bottom item-no-padding' lines='none'>
                    <IonLabel position='stacked'>Email</IonLabel>
                    <IonInput
                      className='email-input no-padding ion-no-border'
                      inputmode='email'
                      pattern='^([a-zA-Z0-9]{3,})$'
                      placeholder=''
                      min='3'
                      value={email}
                      onIonInput={(e) => setEmail(e.target.value)}
                      disabled={true}
                      style={{ borderRadius: '5px', height: '40px' }}
                    />
                  </IonItem>
                  <IonItem className='ion-margin-vertical item-no-padding' lines='none' style={{ width: '100%' }}>
                    <IonLabel position='stacked'>Comment</IonLabel>
                    <IonTextarea
                      className='comment-input no-padding'
                      autoGrow={true}
                      inputmode='text'
                      placeholder={`What would you like to tell us?`}
                      value={message}
                      onIonInput={(e) => setMessage(e.target.value)}
                      style={{ borderRadius: '5px' }}
                      required
                    />
                  </IonItem>
                  <IonItem className='ion-margin-top item-no-padding' lines='none' style={{ width: '100%' }}>
                    <IonButton className='submitButton' expand='block' disabled={!message.length} onClick={onSubmit}>
                      Submit
                    </IonButton>
                  </IonItem>
                </IonCardContent>
              </IonCard>
              <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={''} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default ContactPage;
