import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonButton,
  IonContent,
  IonPage,
  IonLabel,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonSegment,
  IonSegmentButton,
  IonModal,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonText,
  IonMenuButton,
  IonFooter,
} from '@ionic/react';
import AppContext from '../../context/AppContext';
import OrderCardComponent from '../../components/OrderCardComponent';
import OrderRefundItemComponent from '../../components/OrderRefundItemComponent';
import { closeOutline } from 'ionicons/icons';
import { menuController } from '@ionic/core';
import Parse from 'parse/dist/parse.min.js';
import { nanoid } from 'nanoid';
import * as moment from 'moment';

import './OrderPage.css';

const OrderPage = (props) => {
  const history = useHistory();
  const { updateIsOnboarding, deviceWidth, user, updateUser, orders, updateOrders, updateOrderBadge } = useContext(AppContext);

  const [firstDay, setFirstDay] = useState();
  const [endDay, setEndDay] = useState();
  const [refundAmount, setRefundAmount] = useState(0);
  const [ordersArray, setOrdersArray] = useState([]);
  const [orderBadge, setOrderBadge] = useState(null);
  const [orderCount, setOrderCount] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);
  const [orderTip, setOrderTip] = useState(0);
  const [orderDetail, setOrderDetail] = useState();
  const [qtyArray, setQtyArray] = useState([]);
  const [refundSegment, setRefundSegment] = useState('detail');
  const [segment, setSegment] = useState('active');
  const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
  const [unitPriceArray, setUnitPriceArray] = useState([]);

  const rId = props.match.params.id;

  useEffect(() => {
    const currentUser = Parse.User.current();
    if (!currentUser) {
      updateIsOnboarding(true);
      return history.replace('/');
    }

    const getRestaurant = async () => {
      console.log('getRestaurant run');
      await Parse.Cloud.run('getRestaurantFromApp', { rId: rId ? rId : user.restaurant_id }).then((result) => {
        if (!result) {
          alert('Please check internet connection');
        } else {
          updateUser({ ...user, restaurant_id: rId ? rId : user.restaurant_id, restaurant_name: result.name });
        }
      });
    };
    let today = new Date();
    setFirstDay(moment(today.setDate(1)).format('ll'));
    setEndDay(moment(new Date(today.getFullYear(), today.getMonth() + 1, 0)).format('ll'));
    if (!user.restaurant_name) {
      getRestaurant();
    }

    const getOrderBadge = async () => {
      await Parse.Cloud.run('getOrderBadge', { rId: rId ? rId : user.restaurant_id })
        .then((result) => {
          console.log(result);
          setOrderBadge(result);
        })
        .catch((error) => {
          alert(error);
        });
    };
    getOrderBadge();

    const getOrders = async () => {
      await Parse.Cloud.run('getOrders', { rId: rId ? rId : user.restaurant_id }).then((result) => {
        console.log(result);
        if (!result) {
          return;
        } else {
          //updateOrders(result.data);
          setOrdersArray(result.data);
          setOrderCount(result.count);
          setOrderTotal(result.total);
          setOrderTip(result.tip);
        }
      });
    };
    getOrders();
  }, []);

  // useEffect(() => {
  //   setOrdersArray(orders);
  // }, [orders]);

  // const time = () => {
  //   let nowTime = new Date();
  //   let nowHour = nowTime.getHours();
  //   let nowMin = nowTime.getMinutes();
  //   let nowSec = nowTime.getSeconds();
  //   if (nowSec < 10) {
  //     nowSec = `0${nowSec}`;
  //   }
  //   let msg = nowHour + ':' + nowMin + ':' + nowSec;
  //   setNow(msg);
  // };

  // setInterval(() => {
  //   time();
  // }, 1000);

  const openMenu = async () => {
    await menuController.open('order');
  };

  const handleOrderDetail = (index) => {
    console.log(orders[index]);
    setOrderDetail(orders[index]);
    let order = orders[index].order;
    let unitPrice = [];
    let qty = [];
    for (let i = 0; i < order.length; i++) {
      unitPrice.push(order[i].price / order[i].qty);
      qty.push(order[i].qty);
    }
    updateUnitPriceArray(unitPrice);
    updateQtyArray(qty);
    setShowOrderDetailModal(true);
  };

  const updateUnitPriceArray = (val) => {
    setUnitPriceArray(val);
  };

  const updateQtyArray = (val) => {
    setQtyArray(val);
  };

  const handleChangeSlide = (val) => {
    if (val === 'item') {
      setRefundSegment('item');
      //swiper.current.slideTo(0);
    } else if (val === 'detail') {
      setRefundSegment('detail');
      //swiper.current.slideTo(1);
    } else {
      setRefundSegment('amount');
      //swiper.current.slideTo(2);
    }
  };

  const allCheckboxChecked = (checked) => {
    let checkbox = document.getElementsByClassName('refundCheckbox');
    let sum = 0;
    for (let i = 0; i < checkbox.length; i++) {
      if (checked) {
        checkbox[i].checked = true;
        let number = Number(checkbox[i].value);
        sum += number;
      } else {
        checkbox[i].checked = false;
      }
    }
    updateRefundAmount(sum);
  };

  const updateRefundAmount = (val) => {
    setRefundAmount(val);
  };

  return (
    <IonPage id='orderMenu'>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {deviceWidth > 500 ? (
              <IonSegment mode='ios' onIonInput={(e) => setSegment(e.detail.value)} value={segment}>
                <IonSegmentButton value='active'>
                  <IonLabel>Active</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value='done'>
                  <IonLabel>Done</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            ) : (
              'Orders'
            )}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className='ion-padding'>
        <IonGrid>
          <IonRow>
            {!ordersArray
              ? null
              : ordersArray.map((item, index) => {
                  if (item.status !== 'done' && segment === 'active') {
                    return (
                      <IonCol key={index} size-xs='12' size-sm='6' size-md='4'>
                        <OrderCardComponent item={item} index={index} flag={'active'} />
                      </IonCol>
                    );
                  } else if (item.status === 'done' && segment === 'done') {
                    return (
                      <IonCol key={index} size-xs='12' size-sm='6' size-md='3'>
                        <OrderCardComponent key={index} item={item} index={index} flag={'done'} />
                      </IonCol>
                    );
                  } else {
                    return false;
                  }
                })}
          </IonRow>
        </IonGrid>
      </IonContent>

      <IonModal isOpen={showOrderDetailModal} onDidDismiss={() => setShowOrderDetailModal(false)}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonButton
                onClick={() => {
                  setRefundAmount(0);
                  setShowOrderDetailModal(false);
                }}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
            <IonTitle>Order for {!orderDetail ? null : orderDetail.guest_name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {!orderDetail || !unitPriceArray || !qtyArray ? null : (
            <main style={{ width: '80%', marginLeft: '10%' }}>
              <IonCard>
                <IonCardHeader className='ion-text-center'>
                  <IonCardTitle>${orderDetail.total} Payment</IonCardTitle>
                  <IonCardTitle>{moment(orderDetail.createdAt).format('LLL')}</IonCardTitle>
                  <IonCardTitle>Name: {orderDetail.guest_name}</IonCardTitle>
                </IonCardHeader>
              </IonCard>
              <IonSegment value={refundSegment} onIonInput={(e) => handleChangeSlide(e.target.value)}>
                <IonSegmentButton value='item'>
                  <IonLabel>Issue Refund Items</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value='detail'>
                  <IonLabel>Detail</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value='amount'>
                  <IonLabel>Issue Refund Amount</IonLabel>
                </IonSegmentButton>
              </IonSegment>
              {/* <IonSlides ref={swiper} options={slideOption} style={{ width: '100%' }}> */}
              {/* <IonSlides> */}
              {refundSegment === 'item' && (
                <div className='ion-padding' style={{ width: '100%' }}>
                  <IonGrid>
                    <IonRow className='ion-text-start ion-padding-bottom' style={{ borderBottom: '1px solid grey' }}>
                      <IonCol size='auto'>
                        <IonCheckbox
                          indeterminate
                          className='refundCheckbox refundTopCheckbox'
                          value={'0'}
                          onClick={(e) => allCheckboxChecked(e.target.checked, true)}
                        />
                      </IonCol>
                      <IonCol>
                        <IonLabel>Name</IonLabel>
                      </IonCol>
                      <IonCol size='1' className='ion-text-center'>
                        <IonLabel>Qty</IonLabel>
                      </IonCol>
                      <IonCol size='1.5' className='ion-text-end'>
                        <IonLabel>Price</IonLabel>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  <IonGrid>
                    {/* {orderDetail.order.map((item, index) => ( */}
                    <OrderRefundItemComponent
                      //key={index}
                      item={orderDetail.order}
                      //index={index}
                      qtyArray={qtyArray}
                      updateQtyArray={updateQtyArray}
                      refundAmount={refundAmount}
                      updateRefundAmount={updateRefundAmount}
                      unitPriceArray={unitPriceArray}
                      updateUnitPriceArray={updateUnitPriceArray}
                    />
                    {/* ))} */}
                    <IonRow className='ion-text-end'>
                      <IonCol className='ion-text-end'>
                        <IonLabel>Subtotal</IonLabel>
                      </IonCol>
                      <IonCol size='1.5' className='ion-text-end'>
                        <IonLabel>${orderDetail.subtotal}</IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow className='ion-text-end'>
                      <IonCol className='ion-text-end'>
                        <IonLabel>Tax ({orderDetail.tax_percentage}%)</IonLabel>
                      </IonCol>
                      <IonCol size='1.5' className='ion-text-end'>
                        <IonLabel>${orderDetail.tax}</IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow className='ion-text-end'>
                      <IonCol className='ion-text-end'>
                        <IonLabel>Tip</IonLabel>
                      </IonCol>
                      <IonCol size='1.5' className='ion-text-end'>
                        <IonLabel>${orderDetail.tip}</IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow className='ion-text-end'>
                      <IonCol className='ion-text-end'>
                        <IonLabel>Refund</IonLabel>
                      </IonCol>
                      <IonCol size='1.5' className='ion-text-end'>
                        <IonLabel>${refundAmount}</IonLabel>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              )}
              {/* </IonSlides>
                <IonSlides> */}
              {refundSegment === 'detail' && (
                <div className='ion-padding' style={{ width: '100%' }}>
                  <IonGrid>
                    <IonRow className='ion-text-start ion-padding-bottom' style={{ borderBottom: '1px solid grey' }}>
                      <IonCol>
                        <IonLabel>Name</IonLabel>
                      </IonCol>
                      <IonCol size='1' className='ion-text-center'>
                        <IonLabel>Qty</IonLabel>
                      </IonCol>
                      <IonCol size='1.5' className='ion-text-end'>
                        <IonLabel>Price</IonLabel>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  <IonGrid>
                    {orderDetail.order.map((item, index) => (
                      <React.Fragment key={index}>
                        <IonRow className='ion-text-start'>
                          <IonCol>
                            <IonLabel style={{ fontSize: '1.2em' }}>{item.name}</IonLabel>
                          </IonCol>
                          <IonCol size='1' className='ion-text-center'>
                            <IonLabel>{item.qty}</IonLabel>
                          </IonCol>
                          <IonCol size='1.5' className='ion-text-end'>
                            <IonLabel>$ {item.price}</IonLabel>
                          </IonCol>
                        </IonRow>
                        {item.options.length &&
                          item.options.map(
                            (option) =>
                              option.choice.length &&
                              option.choice.map((value) => (
                                <IonRow key={nanoid()}>
                                  <IonCol className='ion-text-start'>
                                    <IonLabel className='ion-margin-end' style={{ color: 'grey' }}>
                                      - {option.category}:
                                    </IonLabel>
                                    <IonLabel className='ion-margin-end' style={{ color: 'grey' }}>
                                      {value.name}
                                    </IonLabel>
                                  </IonCol>
                                  <IonCol size='1'></IonCol>
                                  <IonCol size='1.5' className='ion-text-end'>
                                    <IonLabel style={{ color: 'grey' }}>$ {value.price}</IonLabel>
                                  </IonCol>
                                </IonRow>
                              ))
                          )}
                      </React.Fragment>
                    ))}
                    <IonRow>
                      <IonCol className='ion-text-end'>
                        <IonLabel>Tip</IonLabel>
                      </IonCol>
                      <IonCol size='1.5' className='ion-text-end'>
                        <IonLabel>${orderDetail.tip}</IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className='ion-text-end'>
                        <IonLabel>Total</IonLabel>
                      </IonCol>
                      <IonCol size='1.5' className='ion-text-end'>
                        <IonLabel>${orderDetail.total}</IonLabel>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              )}
              {/* </IonSlides>
                <IonSlides> */}
              {refundSegment === 'amount' && (
                <div className='ion-padding ion-text-center' style={{ width: '100%' }}>
                  <h3>How much do you refund to this customer?</h3>
                  <div className='ion-margin-vertical'>
                    <IonLabel>Total paid: </IonLabel>
                    <IonText style={{ fontSize: '2em', fontWeight: 'bolder' }}>$ {orderDetail.total}</IonText>
                  </div>
                  <div style={{ width: '100%', justifyContent: 'center' }}>
                    <IonInput
                      style={{ width: '150px', margin: 'auto', border: '1px solid grey', borderRadius: '5px' }}
                      inputmode='number'
                      placeholder='Only number'
                      value={refundAmount}
                      onIonInput={(e) => setRefundAmount(e.target.value)}
                    />
                  </div>
                  <IonButton style={{ margin: '48px 0' }}>Proceed Refund</IonButton>
                </div>
              )}
              {/* </IonSlides>
              </IonSlides> */}
            </main>
          )}
        </IonContent>
      </IonModal>
      {deviceWidth < 501 && (
        <IonFooter>
          <IonToolbar color='secondary'>
            <IonButtons slot='start'>
              <IonMenuButton id='menu' />
            </IonButtons>
            <IonTitle>
              <IonSegment mode='ios' onIonInput={(e) => setSegment(e.detail.value)} value={segment}>
                <IonSegmentButton value='active'>
                  <IonLabel className='bold'>Active</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value='done'>
                  <IonLabel lassName='bold'>Done</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonTitle>
          </IonToolbar>
        </IonFooter>
      )}
    </IonPage>
  );
};
export default OrderPage;
