import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonTitle,
  IonIcon,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonButtons,
  IonToolbar,
  IonImg,
  IonList,
  IonThumbnail,
  IonSearchbar,
  IonTextarea,
  IonAvatar,
  IonInput,
  IonLoading,
  IonCheckbox,
  IonText,
  IonFooter,
  IonMenuButton,
} from '@ionic/react';
import Parse from 'parse/dist/parse.min.js';
import * as moment from 'moment';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Swiper, SwiperSlide } from 'swiper/react';
import { notifications, closeOutline, arrowBackOutline, receipt, images } from 'ionicons/icons';

import AppContext from '../../context/AppContext';
import CloudinaryUploadPostPhoto from '../../components/CloudinaryUploadPostPhoto';
import FlashSaleSettingComponent from '../../components/FlashSaleSettingComponent/FlashSaleSettingComponent';

import './PostPage.css';

import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';

const PostPage = (props) => {
  const { deviceWidth, user } = useContext(AppContext);

  const history = useHistory();

  const [discount, setDiscount] = useState({ type: '', amount: null, minimum: null });
  const [itemListModalFlag, setItemListModalFlag] = useState();
  const [today, setToday] = useState();
  const [menus, setMenus] = useState([]);
  const [categories, setCategories] = useState([]);
  const [localTime, setLocalTime] = useState();
  const [saleStart, setSaleStart] = useState({ day: '', time: '' });
  const [saleEnd, setSaleEnd] = useState({ day: '', time: '' });
  const [timeListFormatted, setTimeListFormatted] = useState([]);
  const [posts, setPosts] = useState([]);
  const [showPostModal, setShowPostModal] = useState(false);
  const [relatedMenuToggle, setRelatedMenuToggle] = useState(false);
  const [restaurantHours, setRestaurantHours] = useState([]);
  const [originalMenus, setOriginalMenus] = useState([]);
  const [saleType, setSaleType] = useState('');
  const [saleDetailObj, setSaleDetailObj] = useState({
    saleStart: '',
    saleEnd: '',
    target: '',
    discount: {
      type: '',
      amount: null,
      minimum: null,
    },
    saleItemUuids: [],
    is_sale_now: false,
  });
  const [saleItemUuids, setSaleItemUuids] = useState([]);
  const [sectionNumArray, setSectionNumArray] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [showSelectItemModal, setShowSelectItemModal] = useState(false);
  const [showTimesaleSection, setShowTimesaleSection] = useState(false);
  const [sections, setSections] = useState([]);
  const [tags, setTags] = useState([]);
  const [target, setTarget] = useState('');
  const [flashSale, setFlashSale] = useState({
    title: 'Flash Sale',
    sale_start: '',
    sale_end: '',
    discount: { type: '%', amount: 20, min: 20, max: 10 },
    items: { type: 'ALL', ids: [] },
    target: 'ALL',
    status: 'NEW',
  });
  const [newPost, setNewPost] = useState({
    target: '',
    type: '',
    images: [],
    isFlashSale: false,
    linkedMenus: [],
  });
  const [searchText, setSearchText] = useState('');
  const [swiperIndex, setSwiperIndex] = useState(0);

  const rId = props.match.params.id;

  const swiperRef = useRef();
  const menuCheckBoxRef = useRef([]);
  const captionInputRef = useRef('');

  useEffect(() => {
    let day = new Date();
    let utc = moment.utc().format();

    setToday(moment(day).format('MMM Do YYYY'));
    setLocalTime(moment.utc(utc).local().format());
    let date = moment.utc();
    let hour = moment.utc(date).local().get('hour');
    let ampm = 'AM';
    let minute = moment.utc(date).local().get('minute');
    if (minute >= 0 && minute < 15) minute = 15;
    if (minute > 15 && minute < 30) minute = 30;
    if (minute > 30 && minute < 45) minute = 45;
    if (minute > 45 && minute < 60) {
      minute = `00`;
      hour = hour + 1;
    }
    if (hour > 12) {
      hour = hour - 12;
      ampm = 'PM';
    }
    if (hour === 12) {
      ampm = 'PM';
    }
    setSaleStart({
      day: 'Now',
      time: `${hour}:${minute} ${ampm}`,
    });
    setSaleEnd({
      day: moment.utc(date).local().format('YYYY-MM-DD'),
      time: `${hour}:${minute} ${ampm}`,
    });

    let timeBegin = moment.utc(date).local().set({ hour: 6, minute: 0 });
    let timeList = [];
    let timeListFormat = [];
    for (let i = 0; i < 96; i++) {
      if (timeList.length) {
        let time = timeList[i - 1];
        timeList.push(moment(time).add(15, 'minute'));
        timeListFormat.push(moment(time).add(15, 'minute').format('h:mm A'));
      } else {
        timeList.push(moment(timeBegin));
        timeListFormat.push(moment(timeBegin).format('h:mm A'));
      }
    }
    setTimeListFormatted(timeListFormat);
  }, []);

  useEffect(() => {
    const initPostPage = async () => {
      console.log('getMenuData run');
      await Parse.Cloud.run('getMenuPageData', { rId: rId ? rId : user.restaurant_id })
        .then((results) => {
          if (results) {
            setRestaurantHours(results.restaurantHours);
            setSections(results.sections);
            //setMenuStockArray(results.stock);
            if (results.data) {
              setMenus(results.data.sort((a, b) => b.name - a.name));
            } else {
              setMenus([]);
            }
            setOriginalMenus(results.data);
            setSectionNumArray(results.sectionNumArray);
            // setPosProvider(results.provider);
            setCategories(results.categories);
          } else {
            console.log('No restaurant data');
          }
        })
        .catch((error) => {
          alert(error);
        });
    };
    initPostPage();
  }, []);

  // useLayoutEffect(() => {
  //   if (!isAuthed) return history.replace('/');
  //   Parse.Cloud.run('getPostPageData', { rId: user.restaurant_id })
  //     .then((results) => {
  //       if (results) {
  //         setRestaurantHours(results.restaurantHours);
  //         setSections(results.sections);
  //         setMenus(results.data);
  //         setOriginalMenus(results.data);
  //         setSectionNumArray(results.sectionNumArray);
  //       } else {
  //         return false;
  //       }
  //     })
  //     .catch((error) => {
  //       alert(error);
  //     });
  // }, []);

  const filteredMenus = useMemo(() => {
    return menus.filter((item) => {
      return item.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [menus, searchText]);

  const slideSwiperNext = () => {
    scrollToTop();
    swiperRef.current.swiper.slideNext();
  };

  const handleTargetRadio = (value) => {
    setTarget(value);
    setNewPost({ ...newPost, target: value });
    setFlashSale({ ...flashSale, target: value });
    slideSwiperNext();
  };

  const handlePostTypeRadio = (value) => {
    setNewPost({ ...newPost, type: value });
    if (value === 'FLASHSALE') {
      setShowTimesaleSection(true);
      // let endDayOptions = [];
      // for (let i = 0; i < 7; i++) {
      //   endDayOptions.push(moment().add(i, 'days').format('YYYY-MM-DD'));
      // }
      // setSaleEndSelectOptions(endDayOptions);
      // if (!restaurantHours.length) {
      //   setShowLoading(true);
      //   setTimeout(() => {
      //     handlePostTypeRadio();
      //   }, 1000);
      // } else {
      //   setShowLoading(false);
      // }
    } else {
      setShowTimesaleSection(false);
      // setSelectedDate(null);
      // setSelectedTime(null);
    }
    slideSwiperNext();
  };

  const handleShowPostModal = () => {
    setShowPostModal(true);
  };

  const handleDeleteImage = (index) => {
    let copyImages = newPost.images;
    copyImages.splice(index, 1);
    setNewPost({ ...newPost, images: copyImages });
  };

  const handleRelatedMenu = async (checked) => {
    if (checked) {
      setRelatedMenuToggle(!relatedMenuToggle);
      setItemListModalFlag('related');
      setShowSelectItemModal(true);
    } else {
      setRelatedMenuToggle(!relatedMenuToggle);
      setSelectedMenu(null);
    }
  };

  const handleSelectMenu = (data, checked) => {
    if (checked) {
      setNewPost({ ...newPost, linkedMenus: [...newPost.linkedMenus, { id: data.objectId, name: data.name }] });
    } else {
      let items = newPost.linkedMenus;
      const filteredItems = items.filter((item) => {
        return item.id !== data.objectId;
      });
      setNewPost({ ...newPost, linkedMenus: [...filteredItems] });
    }
  };

  const updatePosts = (data) => {
    let copy = posts;
    copy.unshift(data);
    setPosts([...copy]);
  };

  const handleSaleRegister = () => {
    if (!discount.type || !discount.amount) return alert('Please input discount');
    let allMenuObjectId = [];
    if (saleType === 'All') {
      for (let i = 0; i < menus.length; i++) {
        allMenuObjectId.push(menus[i].objectId);
      }
      setSaleItemUuids(allMenuObjectId);
    }
    let startDay;
    if (saleStart.day === 'Now') {
      startDay = moment().format('YYYY-MM-DD') + ' ' + moment().format('h:mm A');
    } else {
      startDay = saleStart.day + ' ' + saleStart.time;
    }
    console.log(startDay);
    setSaleDetailObj({ ...saleDetailObj, saleStart: startDay, saleEnd: saleEnd.day + ' ' + saleEnd.time, target, discount, saleItemUuids });
    slideSwiperNext();
    scrollToTop();
  };

  const onSavePost = async () => {
    if (!newPost.images.length) {
      alert('Post needs to have pictures');
      return;
    }
    setShowLoading(true);
    const menuIds = newPost.linkedMenus.map((item) => item.id);
    await Parse.Cloud.run('saveRestaurantPost', {
      data: newPost,
      caption: captionInputRef.current.value,
      rId: rId ? rId : user.restaurant_id,
      menuIds,
      tags,
      event: newPost.type === 'FLASHSALE' ? flashSale : null,
    })
      .then((result) => {
        setNewPost({
          target: '',
          type: '',
          images: [],
          caption: '',
          isFlashSale: false,
          linkedMenus: [],
        });
        setTags([]);
        //setSelectedMenu(null);
        updatePosts(result);
        setShowPostModal(false);
        //swiperRef.current.swiper.slideTo(0);
        setShowLoading(false);
        history.push(`/dashboard/mypost/${user.restaurant_id}`);
      })
      .catch((error) => {
        setShowLoading(false);
        alert(error);
      });
  };

  const contentRef = useRef();
  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonGrid>
          <IonRow className='ion-align-items-center'>
            <IonCol size-xs='12' size-sm='8' offset-sm='1' size-md='8' size-lg='6'>
              <IonToolbar>
                <IonButtons slot='end'>
                  {(swiperIndex === 2 && newPost.images.length > 0) || swiperIndex === 3 ? (
                    <IonButton style={{ backgroundColor: 'lightgreen' }} strong color='light' onClick={slideSwiperNext}>
                      Next
                    </IonButton>
                  ) : null}
                </IonButtons>
                <IonTitle>Post</IonTitle>
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>
      <IonContent ref={contentRef} scrollEvents={true} className='ion-padding'>
        <IonGrid>
          <IonRow className='ion-align-items-center'>
            <IonCol size-xs='12' size-sm='8' offset-sm='1' size-md='8' size-lg='6'>
              <Swiper
                ref={swiperRef}
                style={{ paddingBottom: '120px' }}
                allowTouchMove={false}
                onSlideChange={(index) => {
                  setSwiperIndex(index.snapIndex);
                }}>
                <SwiperSlide>
                  <div>
                    <IonItem
                      className='downloadItem'
                      detail
                      lines='none'
                      style={{ borderRadius: '10px' }}
                      onClick={() => window.open('https://apps.apple.com/bf/app/ono-online/id1615732357', '_blank')}>
                      <IonThumbnail slot='start' style={{ width: '5vh', height: '5vh', borderRadius: '8px' }} alt='logo'>
                        <IonImg src={'assets/images/ono.png'} style={{ width: '5vh', height: '5vh', borderRadius: '8px' }} />
                      </IonThumbnail>
                      <IonLabel className='vh175 bold onoPink ion-text-wrap'>
                        Please download "ono online" app for making posts easier. Just log in with your current account.
                      </IonLabel>
                    </IonItem>
                    <h3 className='ion-text-start vh300 bold' style={{ margin: '2em 1em 1em' }}>
                      Target audience
                    </h3>
                    <IonRadioGroup value={target} onClick={(e) => handleTargetRadio(e.target.value)}>
                      <IonItem lines='none'>
                        <IonRadio slot='start' style={{ fonstSize: '0.5em' }} value='ALL' />
                        <IonLabel className='vh250 ion-text-wrap'>All users around your restaurant</IonLabel>
                      </IonItem>
                      <IonItem lines='none'>
                        <IonRadio slot='start' style={{ fonstSize: '0.5em' }} value='FOLLOWERS' />
                        <IonLabel className='vh250 ion-text-wrap'>Followers</IonLabel>
                      </IonItem>
                      <IonItem lines='none'>
                        <IonRadio slot='start' style={{ fonstSize: '0.5em' }} value='ORDERED' />
                        <IonLabel className='vh250 ion-text-wrap'>Customers who have ordered before</IonLabel>
                      </IonItem>
                    </IonRadioGroup>
                  </div>
                </SwiperSlide>
                <SwiperSlide className='ion-margin-bottom'>
                  <div>
                    <IonToolbar>
                      <IonButtons slot='start'>
                        <IonButton onClick={() => swiperRef.current.swiper.slidePrev()}>
                          <IonIcon className='ion-margin-end' icon={arrowBackOutline} />
                          Back
                        </IonButton>
                      </IonButtons>
                    </IonToolbar>
                    <h3 className='ion-text-start vh300 bold' style={{ margin: '2em 1em 1em' }}>
                      What kind of posting is this?
                    </h3>
                    <IonRadioGroup value={newPost.type} onClick={(e) => handlePostTypeRadio(e.target.value)}>
                      <IonItem lines='none'>
                        <IonRadio slot='start' style={{ fonstSize: '0.5em' }} value='ANNOUNCE' />
                        <IonLabel className='vh250 ion-text-wrap'>Announcement</IonLabel>
                      </IonItem>

                      <IonItem lines='none'>
                        <IonRadio slot='start' style={{ fonstSize: '0.5em' }} value='COUPON' />
                        <IonLabel className='vh250 ion-text-wrap'>Coupon</IonLabel>
                      </IonItem>
                      <IonItem lines='none'>
                        <IonRadio slot='start' style={{ fonstSize: '0.5em' }} value='FLASHSALE' />
                        <IonLabel className='vh250 ion-text-wrap'>Flash Sale</IonLabel>
                      </IonItem>
                      <IonItem lines='none'>
                        <IonRadio slot='start' style={{ fonstSize: '0.5em' }} value='PREORDER' />
                        <IonLabel className='vh250 ion-text-wrap'>Pre Order</IonLabel>
                      </IonItem>
                    </IonRadioGroup>
                  </div>
                </SwiperSlide>
                {!showTimesaleSection ? null : (
                  <SwiperSlide className='ion-margin-bottom'>
                    <IonToolbar>
                      <IonButtons slot='start'>
                        <IonButton onClick={() => swiperRef.current.swiper.slidePrev()}>
                          <IonIcon className='ion-margin-end' icon={arrowBackOutline} />
                          Back
                        </IonButton>
                      </IonButtons>
                    </IonToolbar>
                    <h2 className='ion-text-start' style={{ margin: '0 1em' }}>
                      Set up Flash Sale
                    </h2>
                    <FlashSaleSettingComponent
                      target={target}
                      menus={menus}
                      categories={categories}
                      searchText={searchText}
                      flashSale={flashSale}
                      setFlashSale={setFlashSale}
                      slideSwiperNext={slideSwiperNext}
                      scrollToTop={scrollToTop}
                    />
                  </SwiperSlide>
                )}
                <SwiperSlide className='ion-margin-bottom'>
                  <div className='ion-text-start'>
                    <IonToolbar>
                      <IonButtons slot='start'>
                        <IonButton onClick={() => swiperRef.current.swiper.slidePrev()}>
                          <IonIcon className='ion-margin-end' icon={arrowBackOutline} />
                          Back
                        </IonButton>
                      </IonButtons>
                    </IonToolbar>
                    <h2 className='vh300 bold'>Choose pictures</h2>
                    <div className='ion-text-start ion-margin-bottom' style={{ flex: 1, height: 'auto' }}>
                      <CloudinaryUploadPostPhoto rId={rId ? rId : user.restaurant_id} post={newPost} setPost={setNewPost} />

                      {!newPost?.images.length
                        ? null
                        : newPost.images.map((image, index) => {
                            if (image.type === 'VIDEO') {
                              return (
                                <div
                                  key={index}
                                  className='ion-float-start ion-margin-end'
                                  style={{ position: 'relative', width: '20%', borderRadius: '5px', overflow: 'hidden' }}>
                                  <video src={image.uri} style={{ width: '100%' }} controls />
                                  <IonButton
                                    className='ion-no-padding'
                                    style={{
                                      position: 'absolute',
                                      top: '-7px',
                                      right: '-7px',
                                      backgroundColor: 'rgba(0,0,0,0.5)',
                                      fontWeight: 'bold',
                                      color: 'white',
                                    }}
                                    fill='clear'
                                    size='small'
                                    onClick={() => handleDeleteImage(index)}>
                                    <IonIcon slot='icon-only' icon={closeOutline} />
                                  </IonButton>
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  key={index}
                                  className='ion-float-start ion-margin-end'
                                  style={{ position: 'relative', width: '20%', borderRadius: '5px', overflow: 'hidden' }}>
                                  <IonImg src={image.uri} />
                                  <IonButton
                                    className='ion-no-padding'
                                    style={{
                                      position: 'absolute',
                                      top: '-7px',
                                      right: '-7px',
                                      backgroundColor: 'rgba(0,0,0,0.5)',
                                      fontWeight: 'bold',
                                      color: 'white',
                                    }}
                                    fill='clear'
                                    size='small'
                                    onClick={() => handleDeleteImage(index)}>
                                    <IonIcon slot='icon-only' icon={closeOutline} />
                                  </IonButton>
                                </div>
                              );
                            }
                          })}
                      {/* <IonButton style={{ width: '15vw', height: '6rem', backgroundColor: 'transparent' }} onClick={() => widgetApi.current.openDialog()}>
                  <IonIcon icon={cameraOutline} size='large' />
                </IonButton> */}
                      {/* </div> */}
                    </div>
                    <div style={{ clear: 'both' }}></div>
                    <div style={{ marginTop: '3em' }}>
                      <h3 className='vh300 bold'>Caption</h3>
                      <IonTextarea
                        ref={captionInputRef}
                        className='ion-margin-top'
                        style={{ border: '1px solid grey', borderRadius: '5px', paddingLeft: '1em' }}
                        mode='ios'
                        // value={newPost.caption}
                        // onIonInput={(e) => setNewPost({ ...newPost, caption: e.target.value })}
                        rows={3}
                        autoGrow
                        inputmode='text'
                        placeholder='Caption for the pictures here'
                      />
                    </div>
                    <IonRow className='ion-align-items-end ion-margin-top' style={{ width: '100%' }}>
                      <IonCol size='12'>
                        <h3 className='vh300 bold'>Tags</h3>
                        <IonLabel className='vh200'>Tags will not be shown on your post. For customer search purpose. No hashtag(#) needed</IonLabel>
                        <IonInput
                          className='ion-margin-top ion-padding-start'
                          style={{ border: '1px solid grey', borderRadius: '5px' }}
                          value={tags[0]}
                          placeholder=' Steak'
                          type='string'
                          onIonInput={(e) => {
                            let copy = tags;
                            copy[0] = e.target.value;
                            setTags([...tags]);
                          }}
                        />
                      </IonCol>
                      <IonCol size='12'>
                        <IonLabel position='stacked'></IonLabel>
                        <IonInput
                          className='ion-margin-top ion-padding-start'
                          style={{ border: '1px solid grey', borderRadius: '5px' }}
                          value={tags[1]}
                          placeholder=' Salad'
                          type='string'
                          onIonInput={(e) => {
                            let copy = tags;
                            copy[1] = e.target.value;
                            setTags([...tags]);
                          }}
                        />
                      </IonCol>
                      <IonCol size='12'>
                        <IonLabel position='stacked'></IonLabel>
                        <IonInput
                          className='ion-margin-top ion-padding-start'
                          style={{ border: '1px solid grey', borderRadius: '5px' }}
                          value={tags[2]}
                          placeholder=' Breakfast'
                          type='string'
                          onIonInput={(e) => {
                            let copy = tags;
                            copy[2] = e.target.value;
                            setTags([...tags]);
                          }}
                        />
                      </IonCol>
                    </IonRow>
                    {/* <IonButton
                      className='ion-margin-top'
                      expand='block'
                      onClick={() => {
                        scrollToTop();
                        swiperRef.current.swiper.slideNext();
                      }}>
                      Next
                    </IonButton> */}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className='ion-text-start'>
                    <IonToolbar>
                      <IonButtons slot='start'>
                        <IonButton onClick={() => swiperRef.current.swiper.slidePrev()}>
                          <IonIcon className='ion-margin-end' icon={arrowBackOutline} />
                          Back
                        </IonButton>
                      </IonButtons>
                    </IonToolbar>

                    <h3 className='vh300 bold'>Optional</h3>
                    <IonLabel className='vh200'>Items will appear on your post and when user tap it, direct link to the menu.</IonLabel>
                    {/* <IonItem>
                        <IonToggle slot='start' value={erasePostToggle} onIonInput={() => setErasePostToggle(!erasePostToggle)} />
                        <IonText>You want to erase this post at specific time?</IonText>
                      </IonItem>
                      {erasePostToggle && (
                        <div className='ion-padding'>
                          <div className='ion-margin-end ion-float-start'>
                            <IonLabel position='stacked'>Date</IonLabel>
                            <IonDatetime
                              style={{ width: '250px', border: '1px solid grey', borderRadius: '5px' }}
                              displayFormat='MMM DD YYYY'
                              placeholder='Select Date'
                              value={selectedDate}
                              onIonInput={(e) => setSelectedDate(e.target.value)}
                            />
                          </div>
                          <div style={{ display: 'inline' }}>
                            <IonLabel position='stacked'>Time</IonLabel>
                            <IonDatetime
                              presentation='date'
                              onIonInput={(e) => {
                                console.log(e.target.value);
                                setSelectedTime(e.target.value);
                              }}></IonDatetime>
                          </div>
                        </div>
                      )} */}
                    <IonSearchbar
                      class='customSearchBar ion-margin-top ion-margin-bottom'
                      debounce={400}
                      value={searchText}
                      onIonInput={(e) => {
                        setSearchText(e.target.value);
                      }}
                    />
                    <IonList>
                      {!filteredMenus.length ? (
                        <div>You do not have menu yet</div>
                      ) : (
                        filteredMenus.map((menu, index) => (
                          <IonItem key={menu.objectId}>
                            <IonCheckbox
                              ref={(ref) => (menuCheckBoxRef.current[index] = ref)}
                              className='menuCheckBox'
                              slot='start'
                              value={menu.objectId}
                              data-name={menu.name}
                              checked={newPost.linkedMenus?.findIndex((item) => item.id === menu.objectId) > -1}
                              onIonInput={(e) => handleSelectMenu(menu, e.target.checked)}
                            />

                            {!menu.image ? null : (
                              <IonThumbnail slot='start'>
                                <IonImg src={menu.image} />
                              </IonThumbnail>
                            )}
                            <IonLabel>
                              <h3>{menu.name}</h3>
                              <p>{menu.description}</p>
                            </IonLabel>
                          </IonItem>
                        ))
                      )}
                    </IonList>
                    {/* <IonItem className='ion-margin-top'>
                        <IonToggle
                          slot='start'
                          ref={relatedMenuToggleRef}
                          value={relatedMenuToggle}
                          onIonInput={(e) => handleRelatedMenu(e.target.checked)}
                        />
                        <IonText>Is this post related to your menu?</IonText>
                      </IonItem>
                      {relatedMenuToggle && selectedMenu && (
                        <IonRadioGroup value={selectedMenu.objectId}>
                          <IonItem
                            onClick={() => {
                              setItemListModalFlag('related');
                              setShowSelectItemModal(true);
                            }}>
                            <div slot='start'></div>
                            <IonIcon slot='start' icon={returnDownForwardOutline} />
                            {selectedMenu.image && (
                              <IonAvatar slot='start'>
                                <IonImg src={selectedMenu.image} />
                              </IonAvatar>
                            )}
                            <IonLabel>
                              <h3>{selectedMenu.name}</h3>
                              <p>{selectedMenu.description}</p>
                            </IonLabel>
                          </IonItem>
                        </IonRadioGroup>
                      )} */}
                    {/* <IonItem>
                        <IonToggle slot='start' checked={addMenuToggle} onIonInput={(e) => handleAddMenu(e.target.checked)} />
                        <IonText>You want to add item to your menu list as a new menu?</IonText>
                      </IonItem>
                      {addMenuToggle && selectedMenu ? (
                        <IonRadioGroup value={selectedMenu.objectId}>
                          <IonItem
                            onClick={() => {
                              setItemListModalFlag('related');
                              setShowSelectItemModal(true);
                            }}>
                            <div slot='start'></div>
                            <IonRadio slot='start' checked value={selectedMenu.objectId} />
                            {selectedMenu.img && (
                              <IonThumbnail slot='start'>
                                <IonImg src={selectedMenu.img} />
                              </IonThumbnail>
                            )}
                            <IonLabel>
                              <h3>{selectedMenu.name}</h3>
                              <p>{selectedMenu.description}</p>
                            </IonLabel>
                          </IonItem>
                        </IonRadioGroup>
                      ) : null} */}
                  </div>
                  {/* <IonButton onClick={slideSwiperNext}>Go to preview</IonButton> */}
                </SwiperSlide>
                <SwiperSlide>
                  <div className='ion-text-start'>
                    <IonToolbar>
                      <IonButtons slot='start'>
                        <IonButton onClick={() => swiperRef.current.swiper.slidePrev()}>
                          <IonIcon className='ion-margin-end' icon={arrowBackOutline} />
                          Back
                        </IonButton>
                      </IonButtons>
                    </IonToolbar>
                    <h3 className='vh300 bold'>Preview</h3>
                    <IonItem className='ion-no-padding'>
                      <IonAvatar slot='start' style={{ height: '30px', width: '30px' }}>
                        <IonImg src={user.restaurant_logo} />
                      </IonAvatar>
                      <IonLabel>{user.restaurant_name}</IonLabel>
                    </IonItem>
                    <div>
                      <Swiper
                        className='AnimatedSlides'
                        observer
                        observeParents
                        initialSlide={0}
                        slidesPerView={1}
                        spaceBetween={0}
                        centeredSlides={true}
                        watchSlidesProgress={true}
                        effect={'coverflow'}
                        coverflowEffect={{
                          rotate: 0,
                          stretch: 0,
                          depth: 250,
                          modifier: 1,
                          slideShadows: false,
                        }}>
                        {newPost.images.map((image, index) => {
                          return (
                            <SwiperSlide key={index} style={{ width: '100%' }}>
                              {image.type === 'IMAGE' ? (
                                <IonImg src={image.uri} style={{ width: '100%' }} />
                              ) : (
                                <video src={image.uri} style={{ width: '100%' }} controls />
                              )}
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                    {newPost.linkedMenus.length ? (
                      <IonItem className='ion-no-padding' detail='true'>
                        <IonLabel>Try this restaurant</IonLabel>
                      </IonItem>
                    ) : null}
                    <div className='ion-text-start ion-margin-top'>
                      <IonText style={{ fontSize: '0.9em' }}>{captionInputRef.current.value}</IonText>
                    </div>
                    <div className='ion-text-start ion-margin-vertical'>
                      <IonText className='ion-margin-end' style={{ color: '#FC0FC0', fontFamily: 'BrandonBold', fontSize: '0.8em' }}>
                        @{user.restaurant_name}
                      </IonText>
                      {newPost.linkedMenus.map((item, index) => (
                        <IonText key={item.id} className='ion-margin-end' style={{ color: 'white', fontFamily: 'Brandon', fontSize: '0.8em' }}>
                          #{item.name}
                        </IonText>
                      ))}
                    </div>
                  </div>
                  <IonButton strong expand='block' onClick={onSavePost} style={{ marginTop: '36px' }}>
                    Submit this post
                  </IonButton>
                </SwiperSlide>

                {/* <IonModal isOpen={showSelectItemModal} onDidDismiss={() => setShowSelectItemModal(false)}>
                  <IonHeader>
                    <IonToolbar>
                      <IonButtons slot='start'>
                        <IonButton onClick={() => setShowSelectItemModal(false)}>
                          <IonIcon icon={closeOutline} />
                        </IonButton>
                      </IonButtons>
                      <IonTitle>{itemListModalFlag === 'related' ? 'Select Related Item' : 'Select sale items'}</IonTitle>

                      {itemListModalFlag === 'check' && (
                        <IonButtons slot='end'>
                          <IonButton onClick={handleSaleItemsRegister}>
                            <IonLabel>Select</IonLabel>
                          </IonButton>
                        </IonButtons>
                      )}
                    </IonToolbar>
                  </IonHeader>
                  <IonContent className='ion-padding'>
                    <IonSearchbar value={searchText} onIonInput={(e) => setSearchText(e.target.value)} />
                    <IonList>
                      {!filteredMenus.length ? (
                        <div>You do not have menu yet</div>
                      ) : (
                        filteredMenus.map((menu, index) => (
                          <IonItem key={index} onClick={() => itemListModalFlag === 'related' && handleSelectMenu(menu)}>
                            {itemListModalFlag === 'check' ? (
                              <IonCheckbox
                                ref={menuCheckBoxRef}
                                className='menuCheckBox'
                                slot='start'
                                value={menu.objectId}
                                data-name={menu.name}
                                checked={saleDetailObj.saleItemUuids.includes(menu.objectId)}
                              />
                            ) : (
                              <IonRadio slot='start' checked={selectedMenu === menu} value={menu} />
                            )}
                            {!menu.image ? null : (
                              <IonThumbnail slot='start'>
                                <IonImg src={menu.image} />
                              </IonThumbnail>
                            )}
                            <IonLabel>
                              <h3>{menu.name}</h3>
                              <p>{menu.description}</p>
                            </IonLabel>
                          </IonItem>
                        ))
                      )}
                    </IonList>
                  </IonContent>
                </IonModal> */}
              </Swiper>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />
      </IonContent>
      {deviceWidth < 501 ? (
        <IonFooter>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonMenuButton id='menu' />
            </IonButtons>
            <IonButtons slot='end'>
              <IonButton>
                <IonIcon slot='icon-only' icon={receipt} size='small' />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      ) : null}
    </IonPage>
  );
};

export default PostPage;
