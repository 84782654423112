import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { IonButton, IonPage, IonContent, IonSpinner, IonGrid, IonRow, IonCol, useIonViewDidEnter } from '@ionic/react';
import { App } from '@capacitor/app';
import Parse from 'parse/dist/parse.min.js';
import AppContext from '../context/AppContext';

const OauthPage = (props) => {
  const { updateIsOnboarding } = useContext(AppContext);
  const [provider, setProvider] = useState('');
  const [merchantId, setMerchantId] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const [done, setDone] = useState(false);
  const [code, setCode] = useState('');
  const history = useHistory();

  // const rId = new URLSearchParams(props.location.search).get('state');
  // console.log(axios.isCancel('something'));

  useEffect(() => {
    if (window.location.href.includes('clover')) {
      setProvider('CLOVER');
      setMerchantId(new URLSearchParams(props.location.search).get('merchant_id'));
    } else if (window.location.href.includes('ubereats')) {
      setProvider('UBEREATS');
      setCode(new URLSearchParams(props.location.search).get('code'));
    } else {
      setProvider('SQUARE');
    }
  }, []);

  useIonViewDidEnter(() => {
    App.addListener('appUrlOpen', (data) => {
      console.log(`url: ${data}`);
    });
    // history.push({
    //   pathname: `/onboarding/${new URLSearchParams(props.location.search).get('state')}`,
    //   state: { code: new URLSearchParams(props.location.search).get('code'), provider: 'SQUARE' },
    // });
    //_handleGetAccessTokenSquare(new URLSearchParams(props.location.search).get('code'));

    //     if (code && provider === 'SQUARE') {
    //
    //     }
    //   }
  });

  const getPaginations = async (catalog) => {
    for (let i = 0; i < 100; i++) {
      if (!catalog.cursor) {
        break;
      } else {
        await Parse.Cloud.run('squareSaveCatalog', { data: catalog.data }).then((res) => {
          console.log(res);
        });
      }
    }
  };

  const _handleReConnectSquare = async (rId) => {
    await Parse.Cloud.run('getSquareEnvironmentVariable').then(async (result) => {
      const SQUARE_CLIENT_ID = result;
      window.location = `https://connect.squareup.com/oauth2/authorize?client_id=${SQUARE_CLIENT_ID}&scope=ITEMS_READ+ITEMS_WRITE+INVENTORY_READ+ORDERS_WRITE+ORDERS_READ+MERCHANT_PROFILE_READ+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_WRITE+PAYMENTS_READ&session=true&state=${rId}`;
    });
  };

  const _handleConnectSquare = async () => {
    setShowSpinner(true);
    let state;
    let stateArray = [];
    let rId;
    let redirectPage;

    if (new URLSearchParams(props.location.search).get('state').includes('__')) {
      state = new URLSearchParams(props.location.search).get('state');
      stateArray = state.split('__');
      rId = stateArray[0];
      redirectPage = stateArray[1];
    }

    console.log(state, rId, redirectPage);

    // await Parse.Cloud.run('squareGetAccessToken', { code: new URLSearchParams(props.location.search).get('code') })
    //   .then(async (data) => {
    //     if (data.accessToken) {
    //       await Parse.Cloud.run('squareSaveAccessToken', {
    //         rId: stateArray.length > 0 ? rId : new URLSearchParams(props.location.search).get('state'),
    //         data,
    //       }).then(async (result) => {
    //         if (result) {
    //           await Parse.Cloud.run('squareGetCatalog', {
    //             rId: stateArray.length > 0 ? rId : new URLSearchParams(props.location.search).get('state'),
    //             accessToken: data.accessToken,
    //           }).then(() => {
    //             setDone(true);
    //             updateIsOnboarding(false);

    //             setTimeout(() => {
    //               // if (stateArray.length > 0 && stateArray[1] === 'app') {
    //               //   window.open('onoonlinemanager://', '_blank');
    //               // } else {

    //               // }
    //               if (redirectPage === 'dashboard') {
    //                 history.replace({
    //                   pathname: `/dashboard/home/${rId}`,
    //                 });
    //               } else {
    //                 history.replace({
    //                   pathname: `/onboarding/${rId}`,
    //                 });
    //               }
    //             }, 1000);
    //             setShowSpinner(false);
    //           });
    //         } else {
    //           alert('error');
    //         }
    //       });
    //     }
    //   })
    //   .catch(async (error) => {
    //     alert(error);
    //     _handleReConnectSquare(stateArray[0]);
    //   });
  };

  const _handleConnectClover = async () => {
    setShowSpinner(true);
    let state;
    let stateArray = [];
    let rId;
    let redirectPage;
    if (new URLSearchParams(props.location.search).get('state').includes('__')) {
      state = new URLSearchParams(props.location.search).get('state');
      stateArray = state.split('__');
      rId = stateArray[0];
      redirectPage = stateArray[1];
    }
    setShowSpinner(false);

    await Parse.Cloud.run('cloverGetAccessToken', { code: new URLSearchParams(props.location.search).get('code') })
      .then(async (AccessToken) => {
        if (AccessToken) {
          await Parse.Cloud.run('cloverSaveAccessToken', {
            rId: stateArray.length > 0 ? rId : new URLSearchParams(props.location.search).get('state'),
            data: AccessToken,
            merchantId,
          }).then(async (result) => {
            if (result) {
              await Parse.Cloud.run('cloverGetCatalog', {
                rId: stateArray.length > 0 ? rId : new URLSearchParams(props.location.search).get('state'),
                accessToken: AccessToken,
                merchantId,
              }).then((results) => {
                setDone(true);
                updateIsOnboarding(false);

                setTimeout(() => {
                  if (redirectPage === 'dashboard') {
                    history.replace({
                      pathname: `/dashboard/menu/setting/${rId}`,
                    });
                  } else {
                    history.replace({
                      pathname: `/onboarding/${rId}`,
                    });
                  }
                }, 1000);
                setShowSpinner(false);
              });
            } else {
              alert('error');
            }
          });
        }
      })
      .catch(async (error) => {
        alert(error);
      });
  };

  const _handleDisconnectUber = async () => {
    try {
      const authorizationCode = await Parse.Cloud.run('getUberAccessToken', { code });
      console.log(authorizationCode.access_token);
      try {
        // const results = await Parse.Cloud.run('uberEatsGetStores', { access_token: authorizationCode.access_token });
        // console.log(results);
        const result = await Parse.Cloud.run('disconnectUberEats', { access_token: authorizationCode.access_token });
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const backToApp = () => {
    window.open('onoonline://', '_blank');
  };

  return (
    <IonPage>
      <IonContent>
        <IonGrid style={{ height: '100%' }}>
          <IonRow className='ion-align-items-center' style={{ height: '100%' }}>
            <IonCol
              className='ion-text-center ion-align-items-center'
              size-xs='12'
              size-sm='10'
              offset-sm='1'
              size-md='8'
              offset-md='2'
              size-lg='6'
              offset-lg='3'>
              {provider === 'SQUARE' ? (
                <section>
                  <img src={'assets/images/ono.png'} style={{ width: '5rem', height: '5rem', borderRadius: '0.5em' }} alt='logo' />
                  {done ? (
                    <div>
                      <h1>All done! redirecting</h1>
                      {new URLSearchParams(props.location.search).get('state').includes('__') && (
                        <IonButton onClick={backToApp}>Back to App</IonButton>
                      )}
                    </div>
                  ) : showSpinner ? (
                    <div style={{ marginTop: '3rem' }}>
                      <IonSpinner color='secondary'></IonSpinner>
                      <h1>Creating your menus</h1>
                      <h3>It may take a white ...</h3>
                      <h3>Please do not refresh or leave</h3>
                    </div>
                  ) : (
                    <div style={{ marginTop: '3rem' }}>
                      <h1 style={{ fontFamily: 'BrandonBold' }}>Thank you for submitting the integration</h1>
                      <h3>
                        Note: Any menu details or inventory modified on your POS will not sync automatically. If you make changes to the menu, please
                        press the "Sync" button on the menu page of ono online's Merchant portal.
                      </h3>

                      <IonButton style={{ fontFamily: 'BrandonBold', marginTop: '3rem', width: '70%' }} onClick={_handleConnectSquare}>
                        Confirm
                      </IonButton>
                    </div>
                  )}
                </section>
              ) : (
                <section>
                  <img src={'assets/images/ono.png'} style={{ width: '5rem', height: '5rem', borderRadius: '0.5em' }} alt='logo' />
                  {done ? (
                    <div>
                      <h1>All done! redirecting</h1>
                      {window.location.href.includes('__') && <IonButton onClick={backToApp}>Back to App</IonButton>}
                    </div>
                  ) : showSpinner ? (
                    <div style={{ marginTop: '3rem' }}>
                      <IonSpinner color='secondary'></IonSpinner>
                      <h1>Creating your menus</h1>
                      <h3>It may take a white ...</h3>
                      <h3>Please do not refresh or leave</h3>
                    </div>
                  ) : (
                    <div style={{ marginTop: '3rem' }}>
                      <h1 style={{ fontFamily: 'BrandonBold' }}>Thank you for submitting the Square integration</h1>
                      <h3>
                        Note: Any menu details or inventory modified on your POS will not sync automatically. If you make changes to the menu, please
                        press the "Sync" button on the menu page of ono online's Merchant portal.
                      </h3>

                      <IonButton style={{ fontFamily: 'BrandonBold', marginTop: '3rem', width: '70%' }} onClick={_handleConnectClover}>
                        Clover Confirm
                      </IonButton>
                    </div>
                  )}
                </section>
              )}
              {provider === 'UBEREATS' && (
                <div>
                  <IonButton onClick={_handleDisconnectUber}>Disconnect uber</IonButton>
                </div>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default OauthPage;
