import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  IonButton,
  IonCard,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonIcon,
  IonLabel,
  IonModal,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonThumbnail,
  IonImg,
  IonRow,
  IonCol,
  IonGrid,
  IonSearchbar,
  IonReorderGroup,
  IonReorder,
  IonInput,
  IonMenuButton,
  IonText,
  IonPopover,
  IonChip,
  IonCheckbox,
  IonAlert,
  IonLoading,
  IonFooter,
  IonSpinner,
} from '@ionic/react';
import {
  addCircleOutline,
  closeOutline,
  copyOutline,
  ellipsisHorizontalOutline,
  notifications,
  receipt,
  removeCircleOutline,
  trashOutline,
} from 'ionicons/icons';
import Parse from 'parse/dist/parse.min.js';
import { nanoid } from 'nanoid';
import AppContext from '../../context/AppContext';
import MenuEditorModal from '../../components/MenuEditorModal';

import './MenuPage.css';

const MenuPage = (props) => {
  const { deviceWidth, user, updateUser, updateIsOnboarding } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();

  const [categoryIndex, setCategoryIndex] = useState(null);
  const [categoryObj, setCategoryObj] = useState({
    section: '',
    title: '',
    description: '',
    menuUuids: [],
  });
  const [categorySegment, setCategorySegment] = useState({ section: null, category: null });
  const [copiedRestaurantHours, setCopiedRestaurantHours] = useState([]);
  const [filteredModItems, setFilteredModItems] = useState([]);
  const [flag, setFlag] = useState('');
  const [isFilledTitle, setIsFilledTitle] = useState(false);
  const [menu, setMenu] = useState(null);
  const [menus, setMenus] = useState([]);
  const [categories, setCategories] = useState([]);
  const [menuIndex, setMenuIndex] = useState();
  const [menuStockArray, setMenuStockArray] = useState([]);
  const [modCategories, setModCategories] = useState('Additional');
  const [modFlag, setModFlag] = useState('');
  const [modifierItems, setModifierItems] = useState([]);
  const [modifierGroups, setModifierGroups] = useState([]);
  const [modifierStockArray, setModifierStockArray] = useState([]);
  const [modId, setModId] = useState('');
  const [modIndex, setModIndex] = useState();
  const [modMin, setModMin] = useState(0);
  const [modMax, setModMax] = useState(100);
  const [modOptions, setModOptions] = useState([]);
  const [modifierItemsCheckedIdArray, setModifierItemsCheckedIdArray] = useState([]);
  const [modRequired, setModRequired] = useState(false);
  const [modTitle, setModTitle] = useState('');
  const [modType, setModType] = useState(false);
  const [newBusinessHour, setNewBusinessHour] = useState({ start: '', end: '', is_overnight: false });
  const [newSection, setNewSection] = useState({
    title: null,
    category_ids: [],
    sectionIndex: null,
  });
  const [newSectionCategory, setNewSectionCategory] = useState('');
  const [originalMenus, setOriginalMenus] = useState([]);
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [restaurantHours, setRestaurantHours] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [sections, setSections] = useState([]);
  const [sectionFlag, setSectionFlag] = useState();
  const [sectionIndex, setSectionIndex] = useState(null);
  const [sectionTitle, setSectionTitle] = useState('');
  const [segment, setSegment] = useState('0');
  const [sectionFilter, setSectionFilter] = useState({ title: 'All' });
  const [categoryFilter, setCategoryFilter] = useState({ name: 'All' });
  const [showAddSectionModal, setShowAddSectionModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [showEditSectionModal, setShowEditSectionModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showMenuEditModal, setShowMenuEditModal] = useState(false);
  const [showModifierModal, setShowModifierModal] = useState(false);
  const [showModifierItemSelectModal, setShowModifierItemSelectModal] = useState(false);
  const [sectionNumArray, setSectionNumArray] = useState();
  const [title, setTitle] = useState('');
  const [uuids, setUuids] = useState([]);
  const [shownAddHourObj, setShownAddHourObj] = useState({});
  const [posProvider, setPosProvider] = useState(null);
  const [menuProvider, setMenuProvider] = useState(null);

  const newCategoryInputRef = React.useRef([]);
  const stockInputRef = React.useRef([]);

  const rId = props.match.params.id;

  const getMenuData = async () => {
    console.log('getMenuData run');
    await Parse.Cloud.run('getMenuPageData', { rId: rId ? rId : user.restaurant_id })
      .then((results) => {
        if (results) {
          setRestaurantHours(results.restaurantHours);
          setSections(results.sections);
          setMenuStockArray(results.stock);
          if (results.data) {
            setMenus(results.data);
          } else {
            setMenus([]);
          }
          setOriginalMenus(results.data);
          setSectionNumArray(results.sectionNumArray);
          setPosProvider(results.posProvider);
          setMenuProvider(results.menuProvider);
          setCategories(results.categories);
        } else {
          console.log('No restaurant data');
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  useEffect(() => {
    let subscribed = true;
    console.log('menuPage rendered');

    const currentUser = Parse.User.current();
    if (!currentUser) {
      updateIsOnboarding(true);
      return history.replace('/');
    }

    const getRestaurant = async () => {
      console.log('getRestaurant run');
      await Parse.Cloud.run('getRestaurantFromApp', { rId: rId ? rId : user.restaurant_id }).then((result) => {
        if (!result) {
          alert('Please check internet connection');
        } else {
          updateUser({ ...user, restaurant_id: rId ? rId : user.restaurant_id, restaurant_name: result.name });
        }
      });
    };
    if (!user.restaurant_name) {
      getRestaurant();
    }

    const getModifiers = async () => {
      console.log('getModifers run');
      await Parse.Cloud.run('getModifers', { rId: rId ? rId : user.restaurant_id })
        .then((results) => {
          if (results.modifierGroups.length) {
            setModifierGroups(results.modifierGroups);
          }
          if (results.modifierItems.length) {
            setModifierItems(results.modifierItems);
            setModifierStockArray(results.stockArray);
          }
        })
        .catch((error) => {
          alert(error);
        });
    };
    if (subscribed) {
      getMenuData();
      getModifiers();
    }
    return () => (subscribed = false);
  }, []);

  const filteredMenus = useMemo(() => {
    if (sectionFilter.title !== 'All') {
      let menuIds = [];
      let categories = sectionFilter.active_categories;
      for (let i = 0; i < categories.length; i++) {
        menuIds = [...menuIds, ...categories[i].data.menus.map((menu) => menu.objectId)];
      }
      return menus.filter((item) => {
        return menuIds.includes(item.objectId);
      });
    }
    if (categoryFilter.name !== 'All') {
      let menuIds = categoryFilter.menus.map((item) => item.objectId);
      console.log(menuIds);
      return menus.filter((item) => {
        return menuIds.includes(item.objectId);
      });
    }
    return menus.filter((item) => {
      return item.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [menus, searchText, sectionFilter.title, categoryFilter.name]);

  const handleShowNewMenuModal = () => {
    if (restaurantHours) {
      setFlag('NEW');
      setTitle('New Menu');
      setShowMenuEditModal(true);
    }
  };

  const handleActionPopover = (boolean, e, item, index) => {
    setMenu(item);
    setMenuIndex(index);
    setShowPopover({ showPopover: boolean, event: e });
  };

  const onDeleteMenu = async () => {
    setShowPopover({ showPopover: false });
    setShowLoading(true);
    await Parse.Cloud.run('deleteMenu', { menuId: menu.objectId })
      .then((result) => {
        if (result) {
          let newMenus = menus.filter((val) => {
            return val.objectId !== menu.objectId;
          });
          setMenus([...newMenus]);
          let newOriginalMenus = originalMenus.filter((val) => {
            return val.objectId !== originalMenus.objectId;
          });
          setOriginalMenus([...newOriginalMenus]);
          setShowLoading(false);
        } else {
          setShowLoading(false);
        }
      })
      .catch((error) => {
        setShowLoading(false);
        alert(error);
      });
    setShowDeleteAlert(false);
  };

  const handleSectionChange = (index, event) => {
    let copy = [...sections];
    const template = copy[index].categories[event.detail.from];
    copy[index].categories[event.detail.from] = copy[index].categories[event.detail.to];
    copy[index].categories[event.detail.to] = template;
    event.detail.complete();
    setSections([...copy]);
  };

  // const onSaveSections = () => {
  //   if (!newSection.title.length) {
  //     alert('Please input new section title');
  //     return;
  //   }
  //   setShowLoading(true);
  //   let copiedSection = [...sections];
  //   if (sectionFlag === 'edit') {
  //     copiedSection[sectionIndex] = newSection;
  //     Parse.Cloud.run('saveSections', { id: user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id, data: copiedSection }).then((result) => {
  //       if (result) {
  //         setSections([...copiedSection]);
  //         setShowLoading(false);
  //         setShowAddSectionModal(false);
  //       } else {
  //         setShowLoading(false);
  //         alert(result);
  //       }
  //     });
  //   } else {
  //     copiedSection.push(newSection);
  //     Parse.Cloud.run('saveSections', { id: user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id, data: copiedSection }).then((result) => {
  //       if (result) {
  //         setSections([...sections, newSection]);
  //         setShowLoading(false);
  //         setShowAddSectionModal(false);
  //       } else {
  //         setShowLoading(false);
  //         alert(result);
  //       }
  //     });
  //   }
  // };

  // const onDeleteSection = () => {
  //   let copiedSection = sections;
  //   copiedSection.splice(sectionIndex, 1);
  //   Parse.Cloud.run('saveSections', { id: user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id, data: copiedSection }).then((result) => {
  //     if (result) {
  //       setSections(copiedSection);
  //       setShowAddSectionModal(false);
  //     } else {
  //       alert(result);
  //     }
  //   });
  // };

  const SelectComponent = () => {
    const onSelectChange = (val) => {
      setSectionFilter(val);
    };
    return (
      <IonItem>
        <IonLabel position='stacked'>Hours</IonLabel>
        <IonSelect
          class='customSelect'
          selectedText={sectionFilter.title}
          placeholder='Menu hour'
          interface='popover'
          okText='Okay'
          cancelText='Dismiss'
          mode='ios'
          onIonInput={(e) => onSelectChange(e.target.value)}>
          <IonSelectOption value={{ title: 'All' }}>All</IonSelectOption>
          {sections &&
            sections.map((item, index) => (
              <IonSelectOption key={index} value={item}>
                {item.title}
              </IonSelectOption>
            ))}
        </IonSelect>
      </IonItem>
    );
  };

  const CategoryFilterComponent = () => {
    const onCategoryFilterChange = (val) => {
      setCategoryFilter(val);
    };
    return (
      <IonItem>
        <IonLabel position='floating'>Category</IonLabel>
        <IonSelect
          class='customSelect'
          selectedText={categoryFilter ? categoryFilter.name : null}
          placeholder='Category'
          interface='popover'
          okText='Okay'
          cancelText='Dismiss'
          mode='ios'
          onIonInput={(e) => onCategoryFilterChange(e.target.value)}>
          <IonSelectOption value={{ name: 'All' }}>All</IonSelectOption>
          {categories.map((item, index) => (
            <IonSelectOption key={index} value={item}>
              {item.name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    );
  };

  const handleSaveCategory = async (value, index) => {
    setShowLoading(true);
    const newCategory = {
      title: value,
      description: '',
      menuUuids: [],
    };
    let copiedSection = [...sections];
    copiedSection[index].categories.push(newCategory);
    newCategoryInputRef.current[index].value = '';
    Parse.Cloud.run('saveSections', { id: user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id, data: copiedSection }).then((result) => {
      if (result) {
        setSections([...copiedSection]);
        setShowLoading(false);
      } else {
        setShowLoading(false);
        alert(result);
      }
    });
  };

  const handleEditCategory = (section, title, description, array, index, i) => {
    setSectionIndex(index);
    setCategoryObj({ ...categoryObj, section, title, description });
    setShowEditCategoryModal(true);
    setUuids(array);
    setCategorySegment({ section: index, category: i });
  };

  const onSaveCategory = async () => {
    let copiedSection = sections;
    let checkboxes = document.getElementsByClassName('categoryModalCheckBox');
    let uuids = [];
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        uuids.push(checkboxes[i].value);
        let index = copiedSection[sectionIndex].menuUuids.indexOf(checkboxes[i].value);
        if (index === -1) {
          copiedSection[sectionIndex].menuUuids.push(checkboxes[i].value);
        }
      } else {
        let index = copiedSection[sectionIndex].menuUuids.indexOf(checkboxes[i].value);
        if (index > -1) {
          copiedSection[sectionIndex].menuUuids.splice(index, 1);
        }
      }
    }
    let targetObj = categoryObj;
    targetObj.menuUuids = uuids;
    copiedSection[sectionIndex].categories[categoryIndex] = targetObj;
    //copiedSection[sectionIndex].menuUuids = [...new Set([...copiedSection[sectionIndex].menuUuids, ...uuids])];
    Parse.Cloud.run('saveSections', { id: user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id, data: copiedSection }).then((result) => {
      if (result) {
        setSections([...copiedSection]);
        setShowEditCategoryModal(false);
      } else {
        alert(result);
      }
    });
  };

  const onDeleteCategory = async (event, index, i) => {
    event.stopPropagation();
    let copy = [...sections];
    copy[index].categories.splice(i, 1);
    setSections([...copy]);
    let query = new Parse.Query('Restaurants');
    try {
      let result = await query.get(user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id);
      result.set('sections', copy);
      result.save();
    } catch (error) {
      alert(error);
    }
  };

  const handleStatusChange = async (value, menuId) => {
    let menuCopy = menus;
    //let menuStock = menuStockArray;
    for (let i = 0; i < menuCopy.length; i++) {
      if (menuCopy[i].objectId === menuId) {
        menuCopy[i].status = value;
        if (value === 'AVAILABLE') {
          menuCopy[i].stock = null;
          //menuStock[i].stock = null;
        }
        if (value === 'SOLDOUT') {
          menuCopy[i].stock = 0;
          //menuStock[i].stock = 0;
        }
      }
    }
    setMenus([...menuCopy]);
    //setMenuStockArray([...menuStock]);
    await Parse.Cloud.run('updateMenuStatus', { menuId, status: value });
  };

  const handleCloseModal = () => {
    setShowMenuEditModal(!showMenuEditModal);
  };

  const handleShowNewModifierModal = () => {
    setModFlag('New');
    setFilteredModItems([...modifierItems]);
    setShowModifierModal(true);
  };

  const handleUpdateModifierGroups = (array) => {
    setModifierGroups([...array]);
  };

  const handleShowEditModifierModal = (item, index) => {
    setModFlag('Edit');
    setModId(item.objectId);
    setModIndex(index);
    setModTitle(item.title);
    setModCategories(item.category);
    setModOptions(item.options);
    const idsArray = item.options.map((val) => val.objectId);
    setModifierItemsCheckedIdArray(idsArray);
    setModRequired(item.required);
    if (item.type === 'radio') setModType(false);
    setModMin(item.min);
    setModMax(item.max);
    setFilteredModItems([...modifierItems]);
    setShowModifierModal(true);
  };

  const handleOnChangeModifierStock = (value, index) => {
    let stockArrayCopy = modifierStockArray;
    stockArrayCopy[index] = Number(value);
    setModifierStockArray([...stockArrayCopy]);
    let modifierItemCopy = [...modifierItems];
    modifierItemCopy[index].stockChangeFlag = true;
    setModifierItems([...modifierItemCopy]);
    return;
  };

  // const handleRefreshModifierItemStock = useCallback(async () => {
  //   setShowLoading(true);
  //   let modifierItemCopy = [...modifierItems];
  //   for (let i = 0; i < modifierItemCopy.length; i++) {
  //     modifierItemCopy[i].changeFlag = false;
  //   }
  //   setModifierItems([...modifierItemCopy]);
  //   await getModifiers().then(() => {
  //     setShowLoading(false);
  //   });
  // }, []);

  const onDeleteModifierSet = (e, id) => {
    e.stopPropagation();
    setShowLoading(true);
    Parse.Cloud.run('deleteModifierSet', { id }).then((result) => {
      if (result) {
        //getModifiers();
        setShowLoading(false);
      } else {
        setShowLoading(false);
        alert(result);
      }
    });
  };

  const handleOnSaveModifierItem = async (id, index) => {
    setShowLoading(true);
    const query = new Parse.Query('ModifierItems');
    try {
      const result = await query.get(id);
      result.set('stock', modifierStockArray[index]);
      result.save().then(() => {
        let modifierItemsCopy = modifierItems;
        modifierItemsCopy[index].stockChangeFlag = false;
        setModifierItems([...modifierItemsCopy]);
        setShowLoading(false);
      });
    } catch (error) {
      setShowLoading(false);
      alert(error);
    }
  };

  const handleOnDeleteModifierItem = async (modifierItemId) => {
    setShowLoading(true);
    Parse.Cloud.run('deleteModifierItem', { id: modifierItemId }).then(async (result) => {
      if (result) {
        //getModifiers();
        setShowLoading(false);
      } else {
        setShowLoading(false);
        alert(result);
      }
    });
  };

  const handleBeforeSaveModifier = async () => {
    setShowLoading(true);
    if (!modTitle.length) {
      setShowLoading(false);
      alert('Please input modifier set title');
      return;
    }
    let checkbox = document.getElementsByClassName('itemCheckBox');
    if (checkbox) {
      //let selectedOptions = [];
      let selectedIds = [];
      for (let i = 0; i < checkbox.length; i++) {
        if (checkbox[i].checked) {
          let json = JSON.parse(checkbox[i].getAttribute('data-item'));
          //selectedOptions.push(json);
          selectedIds.push(json.objectId);
        }
      }
      let query = new Parse.Query('ModifierItems');
      query.containedIn('objectId', selectedIds);
      try {
        let results = await query.find();
        if (results) {
          for (let item of results) {
            item.addUnique('group_titles', modTitle);
            item.save();
          }
          return handleSaveModifier(results);
        } else {
          return handleSaveModifier(null);
        }
      } catch (error) {
        setShowLoading(false);
        alert(error);
      }
    } else {
      return handleSaveModifier(null);
    }
  };

  const handleSaveModifier = async (modifierItems) => {
    let type;
    if (modType) {
      type = 'check';
    } else {
      type = 'radio';
    }
    if (modFlag === 'New') {
      const Modifier = new Parse.Object('Modifiers');
      Modifier.set('title', modTitle);
      Modifier.set('required', modRequired);
      Modifier.set('restaurant_id', user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id);
      Modifier.set('type', type);
      Modifier.set('min', type === 'radio' ? 1 : modMin);
      Modifier.set('max', type === 'radio' ? 1 : modMax);
      Modifier.set('category', modCategories);
      if (modifierItems) {
        let Options = Modifier.relation('items');
        Options.add(modifierItems);
      }
      try {
        Modifier.save().then(async (savedModifier) => {
          let json = JSON.stringify(savedModifier);
          json = JSON.parse(json);
          if (modifierItems) {
            let items = JSON.stringify(modifierItems);
            json.options = JSON.parse(items);
          }
          setModifierGroups([...modifierGroups, json]);
          //setModOptionIdArray([]);
          setShowModifierModal(false);
          setShowLoading(false);
        });
      } catch (error) {
        setShowLoading(false);
        alert(error);
      }
    } else {
      const query = new Parse.Query('Modifiers');
      try {
        let result = await query.get(modId);
        result.set('title', modTitle);
        result.set('required', modRequired);
        result.set('type', type);
        result.set('min', modMin);
        result.set('max', modMax);
        if (modifierItems) {
          let Options = result.relation('items');
          Options.add(modifierItems);
        }
        try {
          await result.save().then(async (savedModifier) => {
            let json = JSON.stringify(savedModifier);
            json = JSON.parse(json);
            if (modifierItems) {
              let items = JSON.stringify(modifierItems);
              json.options = JSON.parse(items);
            }
            let copiedModifierGroups = [...modifierGroups];
            copiedModifierGroups[modIndex] = json;
            setModifierGroups([...copiedModifierGroups]);
            setModifierItemsCheckedIdArray([]);
            setShowModifierModal(false);
            setShowLoading(false);
            return true;
          });
        } catch (error) {
          setShowLoading(false);
          alert(error);
        }
      } catch (error) {
        setShowLoading(false);
        alert(`AfterSave` + error);
      }
    }
  };

  const handleNewModDelete = (i) => {
    let options = modOptions;
    let filteredArray = options.filter(function (value, index) {
      return index !== i;
    });
    setModOptions(filteredArray);
  };

  const handleShowEditMenuModal = useCallback((item, flag, title, index) => {
    setFlag(flag);
    setTitle(title);
    setMenu(item);
    setMenuIndex(index);
    setShowPopover({ showPopover: false, event: undefined });
    setShowMenuEditModal(true);
  }, []);

  const handleUpdateSections = (value) => {
    setSections([...value]);
  };

  const handleUpdateEditedMenu = (item) => {
    const idx = menus.findIndex((menu) => menu.objectId === item.objectId);
    let copyMenus = menus;
    copyMenus[idx] = item;
    setMenus([...copyMenus]);
    setOriginalMenus([...copyMenus]);
  };

  const handleFocusMenuStock = (itemId) => {
    const idx = menus.findIndex((menu) => menu.objectId === itemId);
    console.log(idx);
    setMenuIndex(idx);
    // let copyStock = menuStockArray;
    // copyStock[idx].changeFlag = true;
    // setMenuStockArray([...copyStock]);
  };

  const handleChangeMenuStock = (index) => {
    console.log(stockInputRef.current[index].value, menus[menuIndex].stock);
    if (stockInputRef.current[index].value !== menus[menuIndex].stock) {
      setMenus((prev) => {
        let copied = [...prev];
        copied[menuIndex].stock_flag = true;
        return copied;
      });
    }
  };

  const handleSaveMenuStock = async (e, index, menuId, newStock) => {
    if (newStock < 0) {
      return alert('Only numbers acceptable');
    }
    let stock = newStock;
    if (!stock) {
      stock = null;
    }
    e.preventDefault();
    setShowLoading(true);
    await Parse.Cloud.run('updateMenuStock', { menuId, stock }).then((result) => {
      if (result) {
        let newMenus = [...menus];
        newMenus[menuIndex].stock = stock;
        newMenus[menuIndex].stock_flag = false;
        setMenus(newMenus);
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    });
  };

  const onSaveSectionInHours = async (index, value) => {
    let copiedRestaurantHour = restaurantHours;
    copiedRestaurantHour[0].open[index].section = value;
    setRestaurantHours(copiedRestaurantHour);
    await Parse.Cloud.run('onSaveHours', { data: copiedRestaurantHour, id: user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id })
      .then((result) => {
        return true;
      })
      .catch((error) => alert(error));
  };

  const handleNewSectionAddCategory = () => {
    if (!newSectionCategory.length) return;
    let copiedNewSectionCategory = newSection.categories;
    copiedNewSectionCategory.push({ title: newSectionCategory, menuUuids: [] });
    setNewSection({ ...newSection, categories: copiedNewSectionCategory });
    setNewSectionCategory('');
  };

  const handleEditSection = (index) => {
    setSectionIndex(index);
    setNewSection({ ...newSection, title: sections[index].title, categories: sections[index].categories, menuUuids: sections[index].menuUuids });
    setSectionFlag('edit');
    setShowAddSectionModal(true);
  };

  const showEditBusinessHourModal = () => {
    setCopiedRestaurantHours([...restaurantHours]);
    setShowEditSectionModal(true);
  };

  const toggleAddHour = (index, val) => {
    setNewBusinessHour({ ...newBusinessHour, start: val });
    setShownAddHourObj({ [index]: true });
  };

  const handleAddBusinessHours = (index, day) => {
    if (Number(newBusinessHour.start) >= Number(newBusinessHour.end)) {
      alert('Start-time must be earlier than end-time');
      return false;
    }
    if (Number(newBusinessHour.start) < Number(copiedRestaurantHours[0].open[index].end)) {
      alert('New start-time must be earlier than previous end-time');
      return false;
    }
    if (
      copiedRestaurantHours[0].open[index + 1].day === day &&
      Number(newBusinessHour.end) > Number(copiedRestaurantHours[0].open[index + 1].start)
    ) {
      alert('New end-time must be earlier than next start-time');
      return false;
    }
    let copy = copiedRestaurantHours;
    const obj = {
      day,
      start: newBusinessHour.start,
      end: newBusinessHour.end,
      is_overnight: newBusinessHour.is_overnight,
      is_open: true,
      section: 0,
    };
    copy[0].open.splice(index + 1, 0, obj);
    setShownAddHourObj({ [index]: false });
    setCopiedRestaurantHours([...copy]);
  };

  const onSaveBusinessHour = async () => {
    await Parse.Cloud.run('onSaveHours', {
      id: user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id,
      data: copiedRestaurantHours,
    }).then((result) => {
      if (result) {
        setRestaurantHours([...copiedRestaurantHours]);
        setShowEditSectionModal(false);
      } else {
        alert(result);
      }
    });
  };

  const handleChangeBusinessHourCheck = (dayNumber, checked) => {
    let copiedBusinessHour = restaurantHours;
    let open = copiedBusinessHour[0].open;
    for (let i = 0; i < open.length; i++) {
      if (open[i].day === Number(dayNumber)) open[i].is_open = checked;
    }
    copiedBusinessHour[0].open = open;
    setRestaurantHours([...copiedBusinessHour]);
  };

  const handleRemoveBusinessHour = (index) => {
    let copy = [...copiedRestaurantHours];
    copy[0].open.splice(index, 1);
    setCopiedRestaurantHours([...copy]);
  };

  const handleShowAddSectionModal = (flag, title) => {
    setSectionFlag(flag);
    setSectionTitle(title);
    setShowAddSectionModal(true);
  };

  const handleModItemsFilterCheckbox = (value) => {
    let checkboxes = document.getElementsByClassName('modItemsFilterCheckbox');
    let searchWordArr = [];
    if (checkboxes[0].checked) {
      searchWordArr.push('Additional');
    }
    if (checkboxes[1].checked) {
      searchWordArr.push('Choice');
    }
    if (checkboxes[2].checked) {
      searchWordArr.push('Exclude');
    }
    let newArr = [];
    for (let i = 0; i < modifierItems.length; i++) {
      if (searchWordArr.includes(modifierItems[i].tag)) {
        newArr.push(modifierItems[i]);
      }
    }
    if (newArr.length) {
      setFilteredModItems([...newArr]);
    } else {
      setFilteredModItems([...modifierItems]);
    }
  };

  const handleRenderNewMenu = (value) => {
    setMenuStockArray([...menuStockArray, { stock: null, changeFlag: false }]);
    setMenus((prev) => {
      return [...prev, value];
    });
    setOriginalMenus((prev) => {
      return [...prev, value];
    });
  };

  const handleCheckDineInOnly = async (checked, menuId) => {
    let menuCopy = menus;
    for (let i = 0; i < menuCopy.length; i++) {
      if (menuCopy[i].objectId === menuId) {
        menuCopy[i].dine_in_only = checked;
      }
    }
    setMenus([...menuCopy]);
    await Parse.Cloud.run('saveDineInOnly', { checked, menuId }).then((result) => {
      if (!result) alert('Error');
    });
  };

  const handleSquareSync = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('squareSyncCatalog', { rId: rId ? rId : user.restaurant_id }).then((result) => {
      if (result) {
        getMenuData();
      }
      setShowLoading(false);
    });
  };

  const orderTest = async () => {
    await Parse.Cloud.run('squareOrderTest').then((result) => {
      console.log(result);
    });
  };

  const _handleConnectSquare = async (rId) => {
    setShowLoading(true);
    await Parse.Cloud.run('getSquareEnvironmentVariable').then(async (result) => {
      setShowLoading(false);
      const SQUARE_CLIENT_ID = result;
      window.location = `https://connect.squareup.com/oauth2/authorize?client_id=${SQUARE_CLIENT_ID}&scope=ITEMS_READ+ITEMS_WRITE+INVENTORY_READ+ORDERS_WRITE+ORDERS_READ+MERCHANT_PROFILE_READ+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_WRITE+PAYMENTS_READ&session=true&state=${rId}`;
      // await Browser.open({
      //   //Production
      //   url: `https://connect.squareup.com/oauth2/authorize?client_id=${SQUARE_CLIENT_ID}&scope=ITEMS_READ+ITEMS_WRITE+INVENTORY_READ+ORDERS_WRITE+ORDERS_READ+MERCHANT_PROFILE_READ+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_WRITE+PAYMENTS_READ&session=true&state=${rId}`,

      //   //Sandobox
      //   //url: `https://connect.squareupsandbox.com/oauth2/authorize?client_id=sandbox-sq0idb--29oEjx4F2GZFl4j8bZwYg&scope=ITEMS_READ+ITEMS_WRITE+INVENTORY_READ+ORDERS_WRITE+ORDERS_READ+MERCHANT_PROFILE_READ+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_WRITE+PAYMENTS_READ&session=false&state=${rId}`,
      // });
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar style={{ backgroundColor: 'transparent' }}>
          <IonGrid>
            <IonRow>
              <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' size-lg='10'>
                <IonRow className='ion-align-items-center'>
                  {deviceWidth > 500 ? (
                    <IonCol size-xs='12' size-sm='12' size-md='6' size-lg='6'>
                      <IonSearchbar
                        class='customSearchBar'
                        debounce={400}
                        value={searchText}
                        onIonInput={(e) => {
                          setSearchText(e.target.value);
                        }}
                        showClearButton={'always'}
                        mode='ios'
                        color='light'
                      />
                    </IonCol>
                  ) : null}
                  <IonCol size-xs='6' size-sm='6' size-md='3' size-lg='3'>
                    <SelectComponent />
                  </IonCol>
                  <IonCol size-xs='6' size-sm='6' size-md='3' size-lg='3'>
                    <CategoryFilterComponent />
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonButtons slot='end'>
            <IonButton>
              <IonIcon slot='icon-only' icon={notifications} size='small' />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent scrollX='true' className='ion-padding'>
        {/* <IonButton onClick={_handleConnectSquare}>Square test</IonButton> */}
        <IonGrid className='ion-no-padding'>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' size-lg='10'>
              <IonRow className='ion-text-end'>
                {menuProvider === 'SQUARE' && (
                  <IonButton className='ion-margin-right' onClick={handleSquareSync}>
                    Sync from Square
                  </IonButton>
                )}
                <IonButton strong className='ion-float-end' color='tertiary' onClick={handleShowNewMenuModal}>
                  Create New Menu
                </IonButton>
              </IonRow>

              {/* <IonRow className='ion-padding' style={{ backgroundColor: 'beige', borderRadius: '10px' }}>
                <IonCol className='ion-text-center'>
                  {posProvider === 'ONOONLINE' ? (
                    <div className='ion-padding-horizontal' style={{ borderRight: '1px solid white', width: '100%' }}>
                      <div className='vh200 bold onoPink' style={{ width: '100%' }}>
                        ono online POS integration
                      </div>
                      <IonButton className='ion-margin-top' onClick={handleShowNewMenuModal}>
                        Add Menu
                      </IonButton>
                    </div>
                  ) : (
                    <div className='ion-padding-horizontal' style={{ borderRight: '1px solid white', width: '100%' }}>
                      <IonLabel className='vh200 bold onoPink'>SQUARE POS integration</IonLabel>
                      <div style={{ marginTop: '8px' }} />
                      <IonText className='vh175 black'>You can edit number of stock, price, tags, and availabilities</IonText>
                    </div>
                  )}
                </IonCol>
                <IonCol className='ion-text-center'>
                  {menuProvider === 'ONOONLINE' ? (
                    <IonLabel className='vh200 bold'>Menus are controled by ono online</IonLabel>
                  ) : (
                    <div className='ion-padding-horizontal'>
                      <div className='vh200 bold onoPink' style={{ width: '100%' }}>
                        Menus are synced with SQUARE
                      </div>
                    </div>
                  )}
                </IonCol>
              </IonRow> */}
              <IonGrid className='ion-margin-top'>
                {!filteredMenus.length && !restaurantHours.length ? (
                  <p>
                    <IonSpinner name='dots' />
                  </p>
                ) : (
                  <IonList>
                    {filteredMenus.map((item, index) => (
                      <IonCard
                        key={item.objectId}
                        color='light'
                        className='ion-margin-bottom ion-padding-horizontal'
                        style={{ marginLeft: '0px', marginRight: '0px' }}>
                        <IonRow className='ion-align-items-start ion-padding-top scroll-item ion-nowrap'>
                          {/* <IonCol>
                            <IonRow className='ion-align-items-start scroll-item ion-nowrap'> */}
                          <IonCol size='auto' className='ion-padding-end'>
                            <div className='ion-no-padding' onClick={() => handleShowEditMenuModal(item, 'EDIT', 'Edit Menu', index)}>
                              {!item.images.length > 0 ? (
                                <IonThumbnail style={{ width: '6vh', height: '6vh' }}>
                                  {/* <IonImg style={{ borderRadius: '4px', overflow: 'hidden' }} /> */}
                                </IonThumbnail>
                              ) : (
                                <IonThumbnail style={{ width: '6vh', height: '6vh' }}>
                                  <IonImg src={item.images[0].uri} style={{ borderRadius: '4px', overflow: 'hidden', width: '6vh', height: '6vh' }} />
                                </IonThumbnail>
                              )}
                            </div>
                          </IonCol>
                          <IonCol className='ion-padding-end'>
                            <div style={{ width: '100%' }} onClick={() => handleShowEditMenuModal(item, 'EDIT', 'Edit Menu', index)}>
                              <div style={{ marginTop: '-4px' }}>
                                <IonText className='vh225 bold'>{item.name}</IonText>
                              </div>
                              <div style={{ overflow: 'hidden', lineHeight: '15px' }}>
                                <p className='vh175' color='medium'>
                                  {item.description}
                                </p>
                              </div>
                            </div>
                          </IonCol>
                          {/* </IonRow> */}

                          {/* <IonCol size='auto' className='ion-align-self-start'>
                            <IonInput
                              ref={(ref) => {
                                stockInputRef.current[index] = ref;
                              }}
                              className='stockInput ion-text-center ion-no-padding vh225 bold'
                              style={{ border: '1px solid grey', width: '3em' }}
                              //value={menuStockArray[index].stock}
                              placeholder={item.stock && item.stock}
                              type='number'
                              onIonFocus={() => handleFocusMenuStock(item.objectId, index)}
                              onIonInput={() => handleChangeMenuStock(index)}
                            />

                            
                            {!item.stock_flag ? (
                              <div className='ion-text-center vh175'>Stock</div>
                            ) : (
                              <IonButton
                                style={{ width: '3em' }}
                                fill='clear'
                                size={'small'}
                                disabled={!item.stock_flag}
                                onClick={(e) => {
                                  handleSaveMenuStock(e, index, item.objectId, parseInt(stockInputRef.current[index].value));
                                }}>
                                Save
                              </IonButton>
                            )}
                          </IonCol> */}
                          {/* </IonCol> */}
                          <IonCol size='auto' className='ion-text-right ion-align-items-center ion-align-self-center'>
                            <div style={{ width: '4em', justifyContent: 'flex-end' }}>
                              <div style={{ width: '4em' }}>
                                <IonText className='vh200 bold'>{item.currency === 'USD' ? `$ ${item.price / 100}` : `$ ${item.price}`}</IonText>
                              </div>
                              {/* {menuStockArray.length && ( */}
                              <input
                                ref={(ref) => {
                                  stockInputRef.current[index] = ref;
                                }}
                                className='stockInput ion-text-center ion-no-padding vh225 bold'
                                color='tertiary'
                                fill='outline'
                                style={{ border: '1px solid #999', width: '3em', height: '30px', marginTop: '0.5em' }}
                                //value={menuStockArray[index].stock}
                                placeholder={item.stock && item.stock}
                                // type='number'
                                inputmode='numeric'
                                onFocus={() => handleFocusMenuStock(item.objectId, index)}
                                onChange={() => handleChangeMenuStock(index)}
                              />
                              {!item.stock_flag ? (
                                <div className='ion-text-center vh175' style={{ marginLeft: '1em' }}>
                                  Stock
                                </div>
                              ) : (
                                <IonButton
                                  style={{ width: '3em' }}
                                  fill='clear'
                                  size={'small'}
                                  disabled={!item.stock_flag}
                                  onClick={(e) => {
                                    handleSaveMenuStock(e, index, item.objectId, parseInt(stockInputRef.current[index].value));
                                  }}>
                                  Save
                                </IonButton>
                              )}
                              {/* )} */}
                              {/* {!menuStockArray[index].changeFlag ? ( */}
                            </div>
                          </IonCol>
                        </IonRow>
                        <IonRow className='ion-align-items-center'>
                          <div className='ion-float-left'>
                            <IonSelect
                              id='customStatusSelect'
                              className='popover vh175'
                              color='medium'
                              style={{ color: (item.status === 'SOLDOUT' && 'red') || (item.status === 'HIDDEN' && 'red') }}
                              interface='popover'
                              // mode='ios'
                              value={item.status}
                              onIonInput={(e) => handleStatusChange(e.target.value, item.objectId)}>
                              <IonSelectOption value='AVAILABLE'>
                                <IonLabel>Available</IonLabel>
                              </IonSelectOption>
                              <IonSelectOption value='SOLDOUT'>
                                <IonLabel>Sold Out</IonLabel>
                              </IonSelectOption>
                              <IonSelectOption value='HIDDEN'>
                                <IonLabel>Hidden</IonLabel>
                              </IonSelectOption>
                            </IonSelect>
                          </div>
                          <div className='ion-float-left'>
                            <IonItem className='dineInOnlyItem ion-no-padding' color='medium'>
                              <IonCheckbox
                                slot='start'
                                id='dineInOnlyCheckbox'
                                checked={item.dine_in_only}
                                style={{ marginRight: '0.5em' }}
                                onIonInput={(e) => handleCheckDineInOnly(e.target.checked, item.objectId)}
                              />
                              <IonLabel className='vh175'>Dine-in only</IonLabel>
                            </IonItem>
                          </div>
                          <div className='ion-float-left'>
                            <IonButton
                              fill='clear'
                              className='ion-no-padding'
                              style={{ width: '3em' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.persist();
                                handleActionPopover(true, e, item, index);
                              }}>
                              <IonIcon icon={ellipsisHorizontalOutline} />
                            </IonButton>
                          </div>
                        </IonRow>
                      </IonCard>
                    ))}
                  </IonList>
                )}
              </IonGrid>

              <IonPopover
                cssClass='my-custom-class'
                event={popoverState.event}
                isOpen={popoverState.showPopover}
                onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}>
                <IonItem button onClick={() => handleShowEditMenuModal(menu, 'COPY', 'New Menu (duplicate)')}>
                  <IonIcon slot='start' icon={copyOutline} size={'small'} />
                  <IonLabel>Duplicate</IonLabel>
                </IonItem>
                <IonItem button onClick={() => setShowDeleteAlert(true)}>
                  <IonIcon slot='start' icon={trashOutline} size={'small'} />
                  <IonLabel>Delete</IonLabel>
                </IonItem>
              </IonPopover>

              <IonAlert
                isOpen={showDeleteAlert}
                onDidDismiss={() => setShowDeleteAlert(false)}
                cssClass='my-custom-class'
                header={'Delete Item!'}
                message={'Are you sure you want to delete this item?'}
                buttons={[
                  {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                      setShowDeleteAlert(false);
                    },
                  },
                  {
                    text: 'Okay',
                    handler: () => {
                      onDeleteMenu();
                    },
                  },
                ]}
              />
            </IonCol>
          </IonRow>
        </IonGrid>

        {segment === '1' && (
          <main>
            <IonLabel>Your restaurant business hours</IonLabel>
            <IonButton onClick={showEditBusinessHourModal}>Edit</IonButton>
            <IonGrid className='ion-padding-bottom' style={{ borderBottom: '1px solid grey' }}>
              <IonRow>
                <IonCol size='auto' className='ion-text-start'>
                  <div style={{ width: '7em', fontSize: '1rem' }}>Days</div>
                </IonCol>
                <IonCol size='auto'>
                  <div style={{ width: '10em', fontSize: '1rem' }}>Hours</div>
                </IonCol>
                <IonCol>
                  <IonLabel style={{ fontSize: '1rem' }}>Applied Menu</IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonGrid className='ion-margin-top ion-float-start' style={{ width: '65%' }}>
              {!restaurantHours.length && !sections.length
                ? null
                : restaurantHours[0].open.map((hour, index) => {
                    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
                    let start = String(hour.start);
                    let end = String(hour.end);
                    let startHour;
                    let startMin = start.substr(-2);
                    let endHour;
                    let endMin = end.substr(-2);
                    if (start.length === 4) {
                      startHour = start.substring(0, 2);
                    } else {
                      startHour = start.substring(0, 1);
                    }
                    if (end.length === 4) {
                      endHour = end.substring(0, 2);
                    } else {
                      endHour = end.substring(0, 1);
                    }
                    let prevState = 0;
                    if (index > 0) {
                      prevState = restaurantHours[0].open[index - 1];
                    }

                    return (
                      <IonRow key={nanoid()} className='ion-item-align-start'>
                        <IonCol size='auto'>
                          {!prevState !== 0 && prevState.day === hour.day ? (
                            <div style={{ fontSize: '1rem', width: '7em' }}></div>
                          ) : (
                            <div style={{ fontSize: '1rem', width: '7em' }}>{days[hour.day]}</div>
                          )}
                        </IonCol>
                        {hour.is_open ? (
                          <>
                            <IonCol size='auto' className='ion-align-self-center'>
                              <div style={{ width: '10em', fontSize: '1rem' }}>
                                {startHour}:{startMin} - {endHour}:{endMin}
                              </div>
                            </IonCol>
                            <IonCol>
                              <IonChip>
                                <IonSelect
                                  className='ion-no-padding'
                                  style={{ width: 'auto', fontSize: '1rem' }}
                                  value={hour.section}
                                  onIonInput={(e) => onSaveSectionInHours(index, e.detail.value)}>
                                  {sections.map((item, i) => (
                                    <IonSelectOption key={nanoid()} style={{ width: 'auto' }} value={i}>
                                      {item.title}
                                    </IonSelectOption>
                                  ))}
                                </IonSelect>
                              </IonChip>
                            </IonCol>
                          </>
                        ) : (
                          <>
                            <IonCol>{!prevState !== 0 && prevState.day === hour.day && !hour.is_open ? null : <IonLabel>Close</IonLabel>}</IonCol>
                            <IonCol></IonCol>
                          </>
                        )}
                      </IonRow>
                    );
                  })}
            </IonGrid>
            {/* <div className='ion-margin-top ion-float-end' style={{ width: '30%' }}>
              <IonButton fill='outline' expand='block' onClick={() => handleShowAddSectionModal('add')}>
                Add Section
              </IonButton>
              {!sections
                ? null
                : sections.map((item, index) => (
                    <IonItem
                      key={index}
                      className='ion-margin-top'
                      type='button'
                      style={{ borderRadius: '5px' }}
                      onClick={() => handleEditSection(index)}>
                      <h3 slot='start'>{item.title}</h3>
                      <div className='ion-wrap'>
                        {item.categories.length ? (
                          item.categories.map((category) => (
                            <IonText key={nanoid()} className='ion-margin-end'>
                              {category.title}
                            </IonText>
                          ))
                        ) : (
                          <IonChip>No Catgory yet</IonChip>
                        )}
                      </div>
                    </IonItem>
                  ))}
            </div> */}
          </main>
        )}

        {segment === '2' && (
          <main>
            {sections &&
              sections.map((item, index) => (
                <div key={index}>
                  <IonItem>
                    <h3>{item.title}</h3>
                    <IonButton slot='end' onClick={() => handleEditSection(index)}>
                      Edit
                    </IonButton>
                  </IonItem>
                  <IonReorderGroup disabled={false} onIonItemReorder={(e) => handleSectionChange(index, e)}>
                    {Object.values(item.categories).map((value, i) => (
                      <IonItem
                        key={nanoid()}
                        button
                        data-uuids={value.menuUuids}
                        onClick={() => handleEditCategory(item.title, value.title, value.description, value.menuUuids, index, i)}>
                        <IonReorder slot='start' />
                        <IonLabel>
                          <h3>{value.title}</h3>
                          <p>{value.description}</p>
                        </IonLabel>

                        {value.menuUuids.length > 1 ? (
                          <IonLabel slot='end'>{value.menuUuids.length} items</IonLabel>
                        ) : (
                          <IonLabel slot='end'>{value.menuUuids.length} item</IonLabel>
                        )}
                        <IonButton slot='end' onClick={(e) => onDeleteCategory(e, index, i)}>
                          <IonIcon icon={trashOutline} />
                        </IonButton>
                      </IonItem>
                    ))}
                  </IonReorderGroup>
                  <IonItem>
                    <IonLabel position='stacked'>New Category Name</IonLabel>
                    <IonInput
                      ref={(ref) => {
                        newCategoryInputRef.current[index] = ref;
                      }}
                      value={''}
                      onIonInput={(e) => e.target.value}
                    />
                    <IonButton slot='end' onClick={(e) => handleSaveCategory(e.target.previousSibling.value, index)}>
                      Add
                    </IonButton>
                  </IonItem>
                </div>
              ))}
          </main>
        )}

        {segment === '3' && (
          <main>
            <IonGrid>
              <IonRow className='ion-text-start' style={{ borderBottom: '1px solid grey' }}>
                <IonCol size='2.5'>
                  <h5>Name</h5>
                </IonCol>
                <IonCol size='8'>
                  <h5>Modifiers</h5>
                </IonCol>
                <IonCol size='1.5'>
                  <h5>Action</h5>
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonGrid>
              {!modifierGroups
                ? null
                : modifierGroups.map((item, index) => (
                    <IonRow key={index} className='ion-text-start ion-padding-bottom' onClick={() => handleShowEditModifierModal(item, index)}>
                      <IonCol size='2.5'>
                        <IonLabel style={{ fontSize: '1em', fontWeight: 'bolder' }}>{item.title}</IonLabel>
                      </IonCol>
                      <IonCol size='8' className='ion-align-self-stretch'>
                        {item.options.map((val, i) => (
                          <IonLabel key={val.objectId} style={{ color: 'grey' }}>
                            {' '}
                            {val.name},
                          </IonLabel>
                        ))}
                      </IonCol>
                      <IonCol size='1.5' className='ion-text-center'>
                        <IonButton onClick={(e) => onDeleteModifierSet(e, item.objectId)}>
                          <IonIcon icon={trashOutline} />
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  ))}
            </IonGrid>
          </main>
        )}

        {segment === '4' && (
          <main>
            {/* <div className='ion-align-items-end' style={{ width: '100%' }}>
              <IonButton onClick={handleRefreshModifierItemStock}>Refresh Stocks</IonButton>
            </div> */}
            <IonGrid className='ion-margin-bottom' style={{ borderBottom: '1px solid grey' }}>
              <IonRow>
                <IonCol size='3'>
                  <IonLabel>Item Name</IonLabel>
                </IonCol>
                <IonCol size='1.5'>
                  <IonLabel>Price</IonLabel>
                </IonCol>
                <IonCol size='5'>
                  <IonLabel>Set</IonLabel>
                </IonCol>
                <IonCol size='1'>
                  <IonLabel>Stock</IonLabel>
                </IonCol>
                <IonCol size='1.5'>
                  <IonLabel>Action</IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
            {!modifierItems.length ? (
              <>
                <div>No Items Yet</div>
                <IonButton>Create Modifier Item</IonButton>
              </>
            ) : (
              <IonGrid>
                {modifierItems.map((item, index) => (
                  <IonRow key={index}>
                    <IonCol size='3'>
                      <IonLabel>{item.name}</IonLabel>
                    </IonCol>
                    <IonCol size='1.5'>
                      <IonLabel>${item.price}</IonLabel>
                    </IonCol>
                    <IonCol size='5'>
                      {!modifierGroups.length
                        ? null
                        : modifierGroups.map((val) => (
                            <div key={val.objectId}>
                              {val.options.map((value) => (
                                <div key={value.objectId}>
                                  {value.objectId.indexOf(item.objectId) > -1 ? (
                                    <IonLabel className='ion-float-start' style={{ color: 'grey', fontSize: '0.9em', marginRight: '8px' }}>
                                      {val.title},{' '}
                                    </IonLabel>
                                  ) : null}
                                </div>
                              ))}
                            </div>
                          ))}
                    </IonCol>
                    <IonCol size='1'>
                      {modifierStockArray.length && (
                        <IonInput
                          style={{ border: '1px solid grey', borderRadius: '5px', height: '40px' }}
                          type='tel'
                          value={modifierStockArray[index]}
                          onIonInput={(e) => handleOnChangeModifierStock(e.target.value, index)}
                        />
                      )}
                    </IonCol>
                    <IonCol size='1.5'>
                      {!item.stockChangeFlag ? (
                        <IonButton onClick={() => handleOnDeleteModifierItem(item.objectId)}>
                          <IonIcon icon={trashOutline} />
                        </IonButton>
                      ) : (
                        <IonButton onClick={(e) => handleOnSaveModifierItem(item.objectId, index)}>Save</IonButton>
                      )}
                    </IonCol>
                  </IonRow>
                ))}
              </IonGrid>
            )}
            {/* </IonContent>
          </IonSlides>
        </IonSlides> */}
          </main>
        )}

        <IonModal id='editorModal' className='editorModal' isOpen={showMenuEditModal} onDidDismiss={() => setShowMenuEditModal(false)}>
          <MenuEditorModal
            rId={rId ? rId : user.restaurant_id}
            flag={flag}
            title={title}
            item={menu}
            categories={categories}
            businessHours={restaurantHours}
            menuIndex={menuIndex}
            handleUpdateEditedMenu={handleUpdateEditedMenu}
            modifierGroups={modifierGroups}
            setModifierGroups={setModifierGroups}
            modifierItems={modifierItems}
            setModifierItems={setModifierItems}
            handleUpdateModifierGroups={handleUpdateModifierGroups}
            sections={sections}
            handleUpdateSections={handleUpdateSections}
            handleEditSection={handleEditSection}
            showMenuEditModal={showMenuEditModal}
            setShowMenuEditModal={setShowMenuEditModal}
            handleShowNewModifierModal={handleShowNewModifierModal}
            handleShowEditModifierModal={handleShowEditModifierModal}
            handleShowAddSectionModal={handleShowAddSectionModal}
            handleRenderNewMenu={handleRenderNewMenu}
            restaurantId={user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id}
            restaurantName={user.restaurant_name}
            posProvider={posProvider}
          />
        </IonModal>

        <IonModal
          isOpen={showEditSectionModal}
          onDidDismiss={() => {
            setShowEditSectionModal(false);
            setCopiedRestaurantHours([]);
            setShownAddHourObj({});
            setNewBusinessHour({ start: '', end: '', is_overnight: false });
          }}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonButton onClick={() => setShowEditSectionModal(false)}>
                  <IonIcon icon={closeOutline} />
                </IonButton>
              </IonButtons>
              <IonTitle>Add or Edit Business Hours</IonTitle>
              <IonButtons slot='end'>
                <IonButton fill='solid' expand='block' color='primary' onClick={onSaveBusinessHour}>
                  <IonLabel>SAVE</IonLabel>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className='ion-padding'>
            <IonText>
              If you edit exist hours, type as 24h and three of four letters.
              <br />
              Ex: 100 = 1:00 am, 1300 = 1:00 pm
            </IonText>
            <IonGrid>
              {!copiedRestaurantHours.length
                ? null
                : copiedRestaurantHours[0].open.map((val, index) => {
                    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
                    let prevState = 0;
                    if (index > 0) {
                      prevState = copiedRestaurantHours[0].open[index - 1];
                    }
                    return (
                      <React.Fragment key={nanoid()}>
                        <IonRow className='ion-align-items-center'>
                          <IonCol size='auto'>
                            {prevState !== 0 && prevState.day === val.day ? (
                              <div style={{ width: '1em' }}></div>
                            ) : (
                              <div style={{ width: '1em' }}>
                                <IonCheckbox
                                  checked={val.is_open}
                                  value={val.day}
                                  onIonInput={(e) => handleChangeBusinessHourCheck(e.target.value, e.target.checked)}
                                />
                              </div>
                            )}
                          </IonCol>
                          <IonCol size='auto'>
                            {prevState && prevState.day === val.day ? (
                              <div style={{ width: '6em' }}></div>
                            ) : (
                              <div style={{ width: '6em' }}>{days[val.day]}</div>
                            )}
                          </IonCol>
                          <IonCol size='auto' className='ion-padding-end'>
                            <div>
                              <IonInput
                                className='ion-float-start ion-text-center'
                                type='tel'
                                style={{ border: '1px solid grey', borderRadius: '5px', width: '5em' }}
                                placeholder={val.start}
                                value={val.start}
                                disabled={!val.is_open}
                                onIonInput={(e) => (val.start = e.target.value)}
                              />
                              <span className='ion-float-start ion-margin-horizontal'>to</span>
                              <IonInput
                                className='ion-float-start ion-text-center'
                                type='tel'
                                style={{ border: '1px solid grey', borderRadius: '5px', width: '5em' }}
                                placeholder={val.end}
                                value={val.end}
                                disabled={!val.is_open}
                                onIonInput={(e) => (val.end = e.target.value)}
                              />
                            </div>
                          </IonCol>
                          <IonCol size='auto'>
                            <IonButton fill='clear' color='secondary' disabled={!val.is_open} onClick={() => toggleAddHour(index, val.end)}>
                              <IonIcon icon={addCircleOutline} />
                            </IonButton>
                          </IonCol>
                          {prevState !== 0 && prevState.day === val.day ? (
                            <IonCol>
                              <IonButton fill='clear' color='tertiary' disabled={!val.is_open} onClick={() => handleRemoveBusinessHour(index)}>
                                <IonIcon icon={removeCircleOutline} />
                              </IonButton>
                            </IonCol>
                          ) : null}
                        </IonRow>
                        {shownAddHourObj[[index]] && val.is_open ? (
                          <IonRow className='ion-align-items-center'>
                            <IonCol size='auto'>
                              <div style={{ width: '1em' }}></div>
                            </IonCol>
                            <IonCol size='auto'>
                              <div style={{ width: '6em' }}></div>
                            </IonCol>
                            <IonCol size='auto' className='ion-padding-end'>
                              <div>
                                <IonInput
                                  type='tel'
                                  className='ion-float-start ion-text-center'
                                  style={{ border: '1px solid grey', borderRadius: '5px', width: '5em' }}
                                  placeholder={val.end}
                                  value={newBusinessHour.start}
                                  onIonInput={(e) => setNewBusinessHour({ ...newBusinessHour, start: e.target.value })}
                                />
                                <span className='ion-float-start ion-margin-horizontal'>to</span>
                                <IonInput
                                  type='tel'
                                  className='ion-float-start ion-text-center'
                                  style={{ border: '1px solid grey', borderRadius: '5px', width: '5em' }}
                                  placeholder='end'
                                  value={newBusinessHour.end}
                                  onIonInput={(e) => setNewBusinessHour({ ...newBusinessHour, end: e.target.value })}
                                />
                              </div>
                            </IonCol>
                            <IonCol>
                              <IonButton onClick={() => handleAddBusinessHours(index, val.day)}>
                                <IonLabel>Submit</IonLabel>
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
            </IonGrid>
          </IonContent>
        </IonModal>

        <IonModal
          isOpen={showAddSectionModal}
          onDidDismiss={() => {
            setNewSection({ ...newSection, title: '', description: '', categories: [], menuUuids: [] });
            setIsFilledTitle(false);
            setShowAddSectionModal(false);
            setSectionFlag('');
            setSectionIndex(null);
          }}>
          {/* <EditSectionCategoryModal handleShowAddSectionModal={handleShowAddSectionModal} /> */}
          {/* <IonHeader>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonButton onClick={() => setShowAddSectionModal(false)}>
                  <IonIcon icon={closeOutline} />
                </IonButton>
              </IonButtons>
              <IonTitle>{sectionFlag === 'edit' ? 'Edit Section' : 'Add Section'}</IonTitle>

              <IonButtons slot='end'>
                {sectionFlag === 'edit' ? (
                  <IonButton fill='solid' expand='block' color='primary' onClick={onSaveSections}>
                    Save
                  </IonButton>
                ) : (
                  <IonButton fill='solid' expand='block' color='primary' onClick={onSaveSections}>
                    Save
                  </IonButton>
                )}
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className='ion-padding'>
            <IonGrid>
              <IonRow className='ion-align-items-center'>
                <IonCol size='auto' sizeSm className='ion-text-end'>
                  <div style={{ width: '6em', fontSize: '1rem' }}>Title: </div>
                </IonCol>
                <IonCol className='ion-padding-end'>
                  <IonInput
                    className='ion-padding-start'
                    style={{ border: '1px solid grey', borderRadius: '5px' }}
                    value={sectionTitle && sectionTitle}
                    onIonInput={(e) => setNewSection({ ...newSection, title: e.target.value })}
                    disabled
                  />
                </IonCol>
                <IonCol size='auto'> */}
          {/* <IonButton
                    fill={!isFilledTitle ? 'solid' : 'clear'}
                    onClick={() => {
                      if (!newSection.title.length) return alert('Please fill title');
                      setIsFilledTitle(true);
                    }}>
                    <IonIcon icon={checkmarkOutline} />
                  </IonButton> */}
          {/* </IonCol>
              </IonRow>
              {isFilledTitle || sectionFlag === 'edit' ? (
                <>
                  <IonRow className='ion-align-items-center ion-padding-top'>
                    <IonCol size='auto' className='ion-text-end'>
                      <div style={{ width: '6em', fontSize: '1rem' }}>Categories: </div>
                    </IonCol>
                    <IonCol className='ion-padding-end'>
                      <IonInput
                        className='ion-padding-start'
                        style={{ border: '1px solid grey', borderRadius: '5px' }}
                        placeholder={' Soup & Salad'}
                        value={newSectionCategory}
                        onIonInput={(e) => setNewSectionCategory(e.target.value)}
                      />
                    </IonCol>
                    <IonCol size='auto'>
                      <IonButton fill='solid' onClick={handleNewSectionAddCategory}>
                        <IonIcon icon={addOutline} />
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-align-items-center ion-padding-top'>
                    <IonCol size='auto' className='ion-text-end'>
                      <div style={{ width: '6em', fontSize: '1rem' }}>Categories: </div>
                    </IonCol>
                    <IonCol className='ion-padding-end'>
                      <IonInput
                        className='ion-padding-start'
                        style={{ border: '1px solid grey', borderRadius: '5px' }}
                        placeholder={'Mosyly local & organic ingredients'}
                        value={newSectionCategory}
                        onIonInput={(e) => setNewSectionCategory(e.target.value)}
                      />
                    </IonCol>
                    <IonCol size='auto'>
                      <IonButton fill='solid' onClick={handleNewSectionAddCategory}>
                        <IonIcon icon={addOutline} />
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-align-items-center ion-padding-top'>
                    <IonCol size='auto' className='ion-text-end'>
                      <div style={{ width: '6em' }}>Applied:</div>
                    </IonCol>
                    <IonCol>
                      <IonReorderGroup disabled={false} onIonItemReorder={(e) => handleSectionChange(sectionIndex, e)}>
                        {newSection.categories.map((val, index) => (
                          <IonItem
                            key={nanoid()}
                            color='light'
                            style={{ borderRadius: '5px', marginBottom: '8px' }}
                            onClick={() => handleUpdateCategory(index, val)}>
                            <IonReorder slot='start' />
                            <IonLabel>{val.title}</IonLabel>
                            <IonLabel>
                              {val.menuUuids.length} {val.menuUuids.length > 1 ? 'items' : 'item'}
                            </IonLabel>
                            <IonButton slot='end' fill='solid' color='danger'>
                              <IonIcon icon={trashOutline} />
                            </IonButton>
                          </IonItem>
                        ))}
                      </IonReorderGroup>
                    </IonCol>
                  </IonRow>
                </>
              ) : null}
            </IonGrid>
          </IonContent> */}
        </IonModal>

        {/* <IonModal className='categoryModal' isOpen={showEditCategoryModal}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonButton onClick={() => setShowEditCategoryModal(false)}>
                  <IonIcon icon={closeOutline} />
                </IonButton>
              </IonButtons>
              <IonTitle>Edit Category in "{categoryObj.section}"</IonTitle>
              <IonButtons slot='end'>
                <IonButton fill='solid' expand='block' color='primary' onClick={onSaveCategory}>
                  Save
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className='ion-padding'>
            <IonItem>
              <IonLabel position='stacked'>Title</IonLabel>
              <IonInput value={categoryObj.title} onIonInput={(e) => setCategoryObj({ ...categoryObj, title: e.target.value })} />
            </IonItem>
            <IonItem>
              <IonLabel position='stacked'>Description</IonLabel>
              <IonTextarea value={categoryObj.description} onIonInput={(e) => setCategoryObj({ ...categoryObj, description: e.target.value })} />
            </IonItem>
            <IonSearchbar />
            <IonList>
              {originalMenus.length ? (
                originalMenus.map((item, index) => (
                  <IonItem key={index}>
                    <IonCheckbox
                      slot='start'
                      className='categoryModalCheckBox'
                      checked={categoryObj.menuUuids.includes(item.objectId)}
                      value={item.objectId}
                    />
                    {!item.images.length > 0 ? null : (
                      <IonThumbnail slot='start'>
                        <IonImg src={item.images[0].uri} />
                      </IonThumbnail>
                    )}
                    <IonLabel className='ion-text-wrap'>
                      <h3>{item.name}</h3>
                      <p>{item.description}</p>
                      <p>${item.price}</p>
                    </IonLabel>
                  </IonItem>
                ))
              ) : (
                <IonLabel>No Item Yet</IonLabel>
              )}
            </IonList>
          </IonContent>
        </IonModal> */}

        <IonModal className='modItemSelectModal' isOpen={showModifierItemSelectModal}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonButton onClick={() => setShowModifierItemSelectModal(false)}>
                  <IonIcon icon={closeOutline} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className='ion-padding'>
            {!modifierItems.length ? (
              <div>No Items Yet</div>
            ) : (
              <IonList>
                {modifierItems.map((item, index) => (
                  <IonItem key={index}>
                    <IonCheckbox slot='start' />
                    <IonLabel>{item.name}</IonLabel>
                    <IonChip slot='end'>
                      <IonLabel>{item.price}</IonLabel>
                    </IonChip>
                  </IonItem>
                ))}
              </IonList>
            )}
          </IonContent>
        </IonModal>
        <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />
      </IonContent>
      {deviceWidth < 501 ? (
        <IonFooter>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonMenuButton id='menu' />
            </IonButtons>
            <IonSearchbar
              class='customSearchBar'
              color='medium'
              debounce={400}
              value={searchText}
              onIonInput={(e) => {
                setSearchText(e.target.value);
              }}
              // color='medium'
            />
            <IonButtons slot='end'>
              <IonButton>
                <IonIcon slot='icon-only' icon={receipt} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      ) : null}
    </IonPage>
  );
};

export default MenuPage;
