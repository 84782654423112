import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonButton,
  IonHeader,
  IonContent,
  IonPage,
  IonAvatar,
  IonLabel,
  IonToolbar,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonTitle,
  IonItem,
  IonButtons,
  IonIcon,
  IonLoading,
  IonModal,
  IonSpinner,
  IonList,
  IonBackButton,
  IonPopover,
} from '@ionic/react';
import { closeOutline, imageOutline } from 'ionicons/icons';
import Parse from 'parse/dist/parse.min.js';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { FilePicker } from '@capawesome/capacitor-file-picker';

import './MyAccountPage.css';

import AppContext from '../../context/AppContext';

const MyAccountPage = (props) => {
  const { platform, user, updateUser, updateIsOnboarding } = useContext(AppContext);
  const history = useHistory();

  const [showLoading, setShowLoading] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [restaurants, setRestaurants] = useState([]);

  const deleteAccountModal = useRef(null);

  const userId = props.match.params.id;

  const handleLogOut = async () => {
    await Parse.User.logOut().then(() => {
      updateIsOnboarding(true);
      history.replace('/');
    });
  };

  useEffect(() => {
    const currentUser = Parse.User.current();
    if (!currentUser) {
      handleLogOut();
      return false;
    }
    const getUser = async () => {
      await Parse.Cloud.run('getUserFromManager', { userId }).then((User) => {
        if (User) {
          setRestaurants(User.restaurants_data);
        }
      });
    };
    getUser();
  }, []);

  const takePicture = async () => {
    const result = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Camera,
    });
    setShowSpinner(true);
    const base64 = `${result.dataUrl}`;
    try {
      await Parse.Cloud.run('updateUserAvatar', { base64, userId: user.id })
        .then((uploadedFile) => {
          updateUser({
            ...user,
            avatar: uploadedFile,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
    setShowSpinner(false);
  };

  const pickImage = async () => {
    const result = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Photos,
    });
    setShowSpinner(true);
    const base64 = `${result.dataUrl}`;
    try {
      await Parse.Cloud.run('updateUserAvatar', { base64, userId: user.id })
        .then((uploadedFile) => {
          updateUser({
            ...user,
            avatar: uploadedFile,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
    setShowSpinner(false);
  };

  const pickAvatar = async () => {
    const result = await FilePicker.pickFiles({
      types: ['image/png', 'image/jpg', 'image/heic'],
      multiple: false,
      readData: true,
    });
    const file = result.files[0];
    setShowSpinner(true);
    try {
      const base64 = `data:${file.mimeType};base64,${file.data}`;
      await Parse.Cloud.run('updateUserAvatar', { base64, userId: user.id })
        .then((uploadedFile) => {
          console.log(uploadedFile);
          updateUser({
            ...user,
            avatar: uploadedFile,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
    setShowSpinner(false);
  };

  // const deleteImage = async (index) => {
  //   setShowLoading(true);
  //   let copiedImages = restaurant?.images;
  //   copiedImages.splice(index, 1);
  //   setRestaurant({ ...restaurant, images: [...copiedImages] });
  //   await Parse.Cloud.run('saveRestaurantImages', { rId, images: copiedImages }).then(() => {
  //     setShowLoading(false);
  //   });
  // };

  const handleDeleteAccount = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('deleteUser');
    handleLogOut();
  };

  if (showLoading) {
    return <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={''} />;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' offset-md='1' size-lg='6' offset-lg='1'>
              <IonToolbar>
                <IonButtons slot='start'>
                  <IonBackButton />
                </IonButtons>
                <IonTitle>My Account</IonTitle>
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='8' offset-md='2' size-lg='6' offset-lg='3'>
              <section className='ion-padding ion-text-center'>
                <IonRow className='ion-justify-content-center'>
                  <IonCol size='auto'>
                    <div style={{ margin: 'auto', position: 'relative', width: '12vh', height: '12vh' }}>
                      {!user ? null : user.avatar ? (
                        <>
                          <IonAvatar
                            id={platform.includes('desktop') || platform.includes('mobileweb') ? 'popover-button1' : ''}
                            style={{ width: '12vh', height: '12vh' }}
                            onClick={platform.includes('desktop') || (platform.includes('mobileweb') ? pickAvatar : null)}>
                            <IonImg src={user.avatar} />
                          </IonAvatar>
                          {platform.includes('desktop') || platform.includes('mobileweb') ? null : (
                            <IonPopover trigger='popover-button1' dismissOnSelect={true}>
                              <IonContent>
                                <IonList className='ion-no-padding'>
                                  <IonItem button={true} detail={false} onClick={takePicture}>
                                    Take photo
                                  </IonItem>
                                  <IonItem button={true} detail={false} onClick={pickImage}>
                                    Choose from library
                                  </IonItem>
                                  <IonItem button={true} detail={false} onClick={pickAvatar}>
                                    Browse files
                                  </IonItem>
                                </IonList>
                              </IonContent>
                            </IonPopover>
                          )}
                        </>
                      ) : (
                        <>
                          <IonAvatar
                            id={platform.includes('desktop') || platform.includes('mobileweb') ? 'popover-button2' : ''}
                            style={{ width: '12vh', height: '12vh' }}
                            onClick={platform.includes('desktop') || (platform.includes('mobileweb') ? pickAvatar : null)}>
                            <IonImg
                              src='https://fakeimg.pl/150x150/?retina=1&text=Logo&font=Montserrat'
                              style={{ width: '100px', height: '100px' }}
                            />
                          </IonAvatar>
                          {platform.includes('desktop') || platform.includes('mobileweb') ? null : (
                            <IonPopover trigger='popover-button2' dismissOnSelect={true}>
                              <IonContent>
                                <IonList className='ion-no-padding'>
                                  <IonItem button={true} detail={false} onClick={takePicture}>
                                    Take photo
                                  </IonItem>
                                  <IonItem button={true} detail={false} onClick={pickImage}>
                                    Choose from library
                                  </IonItem>
                                  <IonItem button={true} detail={false} onClick={pickAvatar}>
                                    Browse files
                                  </IonItem>
                                </IonList>
                              </IonContent>
                            </IonPopover>
                          )}
                        </>
                      )}
                      {showSpinner ? (
                        <IonSpinner style={{ position: 'absolute', top: 0, right: '-5vh' }} name='crescent' color='danger'></IonSpinner>
                      ) : (
                        <>
                          <IonButton
                            id={platform.includes('desktop') || platform.includes('mobileweb') ? 'popover-button3' : ''}
                            style={{ position: 'absolute', top: 0, right: '-5vh' }}
                            size='small'
                            shape='round'
                            fill='clear'
                            onClick={platform.includes('desktop') || (platform.includes('mobileweb') ? pickAvatar : null)}>
                            <IonIcon slot='icon-only' icon={imageOutline} color='dark' />
                          </IonButton>
                          {platform.includes('desktop') || platform.includes('mobileweb') ? null : (
                            <IonPopover trigger='popover-button3' dismissOnSelect={true}>
                              <IonContent>
                                <IonList className='ion-no-padding'>
                                  <IonItem button={true} detail={false} onClick={takePicture}>
                                    Take photo
                                  </IonItem>
                                  <IonItem button={true} detail={false} onClick={pickImage}>
                                    Choose from library
                                  </IonItem>
                                  <IonItem button={true} detail={false} onClick={pickAvatar}>
                                    Browse files
                                  </IonItem>
                                </IonList>
                              </IonContent>
                            </IonPopover>
                          )}
                        </>
                      )}
                    </div>
                  </IonCol>
                </IonRow>
                <div style={{ margin: '2em 0' }}>
                  <div style={{ fontFamily: 'BrandonBold', fontSize: '1.3rem' }}>
                    {user.first_name} {user.last_name}
                  </div>
                  {!user ? null : (
                    <>
                      <div style={{ fontSize: '0.9rem', marginTop: '8px' }}>
                        <IonLabel>{user.email}</IonLabel>
                      </div>
                    </>
                  )}
                </div>
              </section>
              <section>
                <h1 className='vh225'>Restaurant you manage</h1>
                <IonList>
                  {restaurants.map((item, index) => (
                    <IonItem key={index} className='ion-no-padding'>
                      <IonLabel>
                        <h3 className='bold vh200'>{item.name}</h3>
                        <p className='bold vh200' style={{ marginTop: '0.5em' }}>
                          {item.role}
                        </p>
                      </IonLabel>
                    </IonItem>
                  ))}
                </IonList>
              </section>
              <section className='ion-text-center' style={{ marginTop: '2em' }}>
                <IonButton id='openDeleteAccountModal' color={'danger'} size='small'>
                  Delete my account
                </IonButton>
              </section>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />
      </IonContent>
      <IonModal ref={deleteAccountModal} trigger='openDeleteAccountModal'>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonButton fill='clear' onClick={() => deleteAccountModal.current?.dismiss()}>
                <IonIcon slot='icon-only' icon={closeOutline} />
              </IonButton>
            </IonButtons>
            <IonTitle>Warning</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className='ion-padding'>
          <section>
            <h3 className='vh225 bold'>
              Once you delete your account, it cannot be restored. If there are no other team members managing your restaurant, we recommend adding
              team members before proceeding with the deletion.
            </h3>
            <br />
            <h3 className='vh225 bold' style={{ color: 'red' }}>
              Are you sure you want to delete your account?
            </h3>
            <div style={{ marginTop: '3em' }}>
              <IonButton onClick={handleDeleteAccount}>Delete my account</IonButton>
            </div>
          </section>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};
export default MyAccountPage;
