import React, { useContext, useEffect, useState } from 'react';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonIcon,
  IonLabel,
  IonModal,
  IonRow,
  IonCol,
  IonGrid,
  IonInput,
  IonCheckbox,
  IonLoading,
} from '@ionic/react';
import { closeOutline, addCircleOutline, removeCircleOutline } from 'ionicons/icons';
import Parse from 'parse/dist/parse.min.js';
import AppContext from '../../context/AppContext';

import './EditBusinessHourModal.css';

const EditBusinessHourModal = (props) => {
  const { rId, showBusinessHourModal, setShowBusinessHourModal, restaurantHours, setRestaurantHours } = props;
  const { user } = useContext(AppContext);

  const [showLoading, setShowLoading] = useState(false);
  const [copiedRestaurantHours, setCopiedRestaurantHours] = useState([]);
  const [newBusinessHour, setNewBusinessHour] = useState({ start: '', end: '', is_overnight: false });
  const [shownAddHourObj, setShownAddHourObj] = useState({});

  useEffect(() => {
    setCopiedRestaurantHours(restaurantHours);
  }, [restaurantHours]);

  const toggleAddHour = (index, val) => {
    setNewBusinessHour({ ...newBusinessHour, start: val });
    setShownAddHourObj({ [index]: true });
  };

  const handleAddBusinessHours = (index, day) => {
    if (Number(newBusinessHour.start) >= Number(newBusinessHour.end)) {
      alert('Start-time must be earlier than end-time');
      return false;
    }
    if (Number(newBusinessHour.start) < Number(copiedRestaurantHours[0].open[index].end)) {
      alert('New start-time must be earlier than previous end-time');
      return false;
    }
    if (
      copiedRestaurantHours[0].open[index + 1].day === day &&
      Number(newBusinessHour.end) > Number(copiedRestaurantHours[0].open[index + 1].start)
    ) {
      alert('New end-time must be earlier than next start-time');
      return false;
    }
    let copy = copiedRestaurantHours;
    const obj = {
      day,
      start: String(newBusinessHour.start),
      end: String(newBusinessHour.end),
      is_overnight: newBusinessHour.is_overnight,
      is_open: true,
      section: 0,
    };
    copy[0].open.splice(index + 1, 0, obj);
    setShownAddHourObj({ [index]: false });
    setCopiedRestaurantHours([...copy]);
  };

  const handleRemoveBusinessHour = (index) => {
    let copy = [...copiedRestaurantHours];
    copy[0].open.splice(index, 1);
    setCopiedRestaurantHours([...copy]);
  };

  const onSaveBusinessHour = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('onSaveHours', {
      rId: user.role === 'ADMIN' ? rId : rId ? rId : user.restaurant_id,
      data: copiedRestaurantHours,
    }).then((result) => {
      setShowLoading(false);
      if (result) {
        setRestaurantHours([...copiedRestaurantHours]);
        setShowBusinessHourModal(false);
      } else {
        alert(result);
      }
    });
  };

  const handleChangeBusinessHourCheck = (dayNumber, checked) => {
    let copiedBusinessHour = restaurantHours;
    let open = copiedBusinessHour[0].open;
    for (let i = 0; i < open.length; i++) {
      if (open[i].day === Number(dayNumber)) open[i].is_open = checked;
    }
    copiedBusinessHour[0].open = open;
    setRestaurantHours([...copiedBusinessHour]);
  };

  return (
    <IonModal
      id='businessHourModal'
      isOpen={showBusinessHourModal}
      onDidDismiss={() => {
        setShowBusinessHourModal(false);
        setShownAddHourObj({});
        setNewBusinessHour({ start: '', end: '', is_overnight: false });
      }}>
      <IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' offset-md='1' size-lg='6' offset-lg='3'>
              <IonToolbar>
                <IonButtons slot='start'>
                  <IonButton onClick={() => setShowBusinessHourModal(false)}>
                    <IonIcon icon={closeOutline} />
                  </IonButton>
                </IonButtons>
                <IonTitle>Add or Edit Business Hours</IonTitle>
                <IonButtons slot='end'>
                  <IonButton fill='solid' expand='block' color='primary' onClick={onSaveBusinessHour}>
                    <IonLabel>SAVE</IonLabel>
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonGrid>
          <IonRow className='ion-justify-content-center'>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' offset-md='1' size-lg='6' offset-lg='2'>
              <div style={{ marginBottom: '36px' }}>
                <h3 style={{ fontFamily: 'BrandonBold' }}>If you edit exist hours, type as 24h and three of four letters.</h3>
                <h3 className='ion-margin-top' style={{ fontFamily: 'BrandonBold' }}>
                  Ex: 100 = 1:00 am, 1300 = 1:00 pm
                </h3>
              </div>
              {copiedRestaurantHours[0]?.open.map((hour, index) => {
                const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
                let prevState = 0;
                if (index > 0) {
                  prevState = copiedRestaurantHours[0].open[index - 1];
                }
                if (hour.start[0] === '0') {
                  hour.start = hour.start.slice(1);
                }
                if (hour.end[0] === '0') {
                  hour.end = hour.end.slice(1);
                }
                return (
                  <React.Fragment key={index}>
                    <IonRow className='ion-align-items-center'>
                      <IonCol size='1'>
                        {prevState !== 0 && prevState.day === hour.day ? (
                          <div style={{ width: '1em' }}></div>
                        ) : (
                          <div style={{ width: '1em' }}>
                            <IonCheckbox
                              checked={hour.is_open}
                              value={hour.day}
                              onIonInput={(e) => handleChangeBusinessHourCheck(e.target.value, e.target.checked)}
                            />
                          </div>
                        )}
                      </IonCol>
                      <IonCol size='auto'>
                        {prevState && prevState.day === hour.day ? (
                          <div style={{ width: '6em' }}></div>
                        ) : (
                          <div style={{ fontFamily: 'BrandonBold', fontSize: '0.9em', width: '6em' }}>{days[hour.day]}</div>
                        )}
                      </IonCol>
                      <IonCol size='auto' className='ion-padding-end'>
                        <div>
                          <IonInput
                            className='ion-float-start ion-text-center ion-no-padding timeInput'
                            type='tel'
                            style={{ border: '1px solid grey', borderRadius: '5px', width: '5em' }}
                            placeholder={hour.start}
                            value={hour.start}
                            disabled={!hour.is_open}
                            onIonInput={(e) => (hour.start = e.target.value)}
                          />
                          <span className='ion-float-start ion-margin-horizontal'>to</span>
                          <IonInput
                            className='ion-float-start ion-text-center ion-no-padding timeInput'
                            type='tel'
                            style={{ border: '1px solid grey', borderRadius: '5px', width: '5em' }}
                            placeholder={hour.end}
                            value={hour.end}
                            disabled={!hour.is_open}
                            onIonInput={(e) => (hour.end = e.target.value)}
                          />
                        </div>
                      </IonCol>
                      <IonCol size='2'>
                        <IonButton fill='clear' color='secondary' disabled={!hour.is_open} onClick={() => toggleAddHour(index, hour.end)}>
                          <IonIcon icon={addCircleOutline} />
                        </IonButton>
                      </IonCol>
                      {prevState !== 0 && prevState.day === hour.day ? (
                        <IonCol>
                          <IonButton fill='clear' color='tertiary' disabled={!hour.is_open} onClick={() => handleRemoveBusinessHour(index)}>
                            <IonIcon icon={removeCircleOutline} />
                          </IonButton>
                        </IonCol>
                      ) : null}
                    </IonRow>
                    {shownAddHourObj[[index]] && hour.is_open ? (
                      <IonRow className='ion-align-items-center'>
                        <IonCol size='auto'>
                          <div style={{ width: '7em' }}></div>
                        </IonCol>
                        <IonCol size='auto' className='ion-padding-end'>
                          <div>
                            <IonInput
                              type='tel'
                              className='ion-float-start ion-text-center ion-no-padding timeInput'
                              style={{ border: '1px solid grey', borderRadius: '5px', width: '5em' }}
                              placeholder={hour.end}
                              value={newBusinessHour.start}
                              onIonInput={(e) => setNewBusinessHour({ ...newBusinessHour, start: e.target.value })}
                            />
                            <span className='ion-float-start ion-margin-horizontal'>to</span>
                            <IonInput
                              type='tel'
                              className='ion-float-start ion-text-center ion-no-padding timeInput'
                              style={{ border: '1px solid grey', borderRadius: '5px', width: '5em' }}
                              placeholder='End'
                              value={newBusinessHour.end}
                              onIonInput={(e) => setNewBusinessHour({ ...newBusinessHour, end: e.target.value })}
                            />
                          </div>
                        </IonCol>
                        <IonCol>
                          <IonButton size='small' onClick={() => handleAddBusinessHours(index, hour.day)}>
                            Looks good
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />
    </IonModal>
  );
};

export default EditBusinessHourModal;
