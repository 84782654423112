import { IonButton } from '@ionic/react';

export const CloudinaryUploadPostPhoto = (props) => {
  const { rId, post, setPost } = props;
  const cloudName = 'dn2gcd1cf'; // replace with your own cloud name
  const uploadPreset = 'aoexdljp'; // replace with your own upload preset

  const myWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: cloudName,
      uploadPreset: uploadPreset,
      cropping: true, //add a cropping step
      showAdvancedOptions: true, //add advanced options (public_id and tag)
      sources: ['local', 'camera', 'url', 'camera', 'dropbox', 'instagram', 'google_drive', 'facebook'], // restrict the upload sources to URL and local files
      multiple: true, //restrict upload to a single file
      folder: 'restaurant', //upload files to the specified folder
      tags: ['menu', rId], //add the given tags to the uploaded files
      // context: {alt: "user_uploaded"}, //add the given context data to the uploaded files
      //clientAllowedFormats: ['images'], //restrict uploading to image files only
      //resourceType: 'auto',
      // maxImageFileSize: 2000000,  //restrict file size to less than 2MB
      maxImageWidth: 1000, //Scales the image down to a width of 2000 pixels before uploading
      theme: 'white', //change to a purple theme
    },
    (error, result) => {
      if (!error && result && result.event === 'success') {
        console.log(result);
        const prevImages = post.images;
        if (result.info.resource_type === 'video') {
          const data = {
            uri: result.info.secure_url,
            media_type: 'VIDEO',
          };
          setPost({ ...post, images: [...prevImages, data] });
        } else {
          const data = {
            uri: result.info.secure_url,
            media_type: 'IMAGE',
          };
          setPost({ ...post, images: [...prevImages, data] });
        }
      }
    }
  );
  // document.getElementById("upload_widget").addEventListener(
  //   "click",
  //   function () {
  //     myWidget.open();
  //   },
  //   false
  // );

  return (
    <div
      className='cloudinary-button'
      onClick={() => {
        myWidget.open();
      }}>
      Upload
    </div>
  );
};

export default CloudinaryUploadPostPhoto;
