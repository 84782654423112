import { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonAlert,
  IonAvatar,
  IonButton,
  IonContent,
  IonPage,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonLoading,
  IonCol,
  IonRow,
  IonGrid,
  IonText,
  IonInput,
  IonSelect,
  IonSelectOption,
  getPlatforms,
} from '@ionic/react';
import './InvitedUserPage.css';
import Parse from 'parse/dist/parse.min.js';
import AppContext from '../../context/AppContext';

const InvitedUserPage = ({ match }) => {
  const history = useHistory();
  const { user, updateUser } = useContext(AppContext);
  const [showLoading, setShowLoading] = useState(false);
  const [restaurant, setRestaurant] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const [avatar, setAvatar] = useState();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userRegistrationFlag, setUserRegistrationFlag] = useState(true);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorText, setErrorText] = useState('');

  // localhost:8100/invite/restaurant?rId=hh0ONtH3CP
  // localhost:8100/invite?email=kishi@marion-crepes.com&rId=hh0ONtH3CP

  const path = window.location.pathname;

  useEffect(() => {
    setShowLoading(true);
    const launchFetch = async () => {
      await Parse.Cloud.run('getJustRestaurant', { rId: user.restaurant_id }).then((result) => {
        setShowLoading(false);
        if (result) {
          setRestaurant(result);
        } else {
          console.log(result);
        }
      });
    };
    launchFetch();
  }, []);

  useEffect(() => {
    if (firstName.length > 1 && lastName.length > 1 && role.length > 1 && email.length > 4 && password.length > 7 && password === confirmPassword) {
      setUserRegistrationFlag(false);
    }
  }, [firstName, lastName, role, email, password, confirmPassword]);

  const signUp = async () => {
    if (password.toString().length < 8) {
      setErrorText('Error', 'Password Error');
      setShowErrorAlert(true);
      return;
    }
    if (password.toString() !== confirmPassword.toString()) {
      setErrorText('Error', 'Password does not match');
      setShowErrorAlert(true);
      return;
    }
    setShowLoading(true);
    await Parse.Cloud.run('invitedRestaurantUserSignUp', { email, password, avatar, firstName, lastName, role, rId: user.restaurant_id }).then(
      async (result) => {
        setShowLoading(false);
        if (result.code) {
          if (result.code === 202) {
            setErrorText('Account already exists for this email');
            return setShowErrorAlert(true);
          } else if (result.code === 400) {
            setErrorText('Please try again');
            return setShowErrorAlert(true);
          } else if (result.code === 142) {
            setErrorText('Password cannot contain your username');
            return setShowErrorAlert(true);
          } else if (result.code === 125) {
            setErrorText('Email address format is invalid');
            return setShowErrorAlert(true);
          } else {
            setErrorText('Please refresh browser and try again');
            return setShowErrorAlert(true);
          }
        } else {
          await Parse.User.logIn(email, password)
            .then((USER) => {
              updateUser({
                ...user,
                id: USER.id,
                avatar: avatar,
                first_name: firstName,
                last_name: lastName,
                email,
                role,
                restaurant_name: restaurant.name,
                restaurant_logo: restaurant.logo,
              });
              history.push('/signup/invite/restaurant/instagram', { state: { instagram: restaurant.instagram } });
              return true;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    );
  };

  return (
    <IonPage>
      <IonHeader>
        <IonRow>
          <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='8' offset-md='2' size-lg='6' offset-lg='3'>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonBackButton text=''></IonBackButton>
              </IonButtons>
            </IonToolbar>
          </IonCol>
        </IonRow>
      </IonHeader>
      <IonContent className='container'>
        {!restaurant ? null : (
          <IonGrid style={{ height: '100%' }}>
            <IonRow className='ion-justify-content-center' style={{ height: '100%' }}>
              <IonCol size-xs='12' size-sm='10' size-md='6' size-lg='5'>
                {pageIndex === 0 && (
                  <>
                    <IonListHeader style={{ marginTop: '5em' }}>
                      <h1 style={{ fontFamily: 'BrandonBold', marginBottom: '24px' }}>Aloha {restaurant.name} team!</h1>
                    </IonListHeader>
                    <IonList lines='none'>
                      {!restaurant ? null : (
                        <IonItem className='detail' lines='none'>
                          {restaurant.logo ? (
                            <IonAvatar slot='start'>
                              <img src={restaurant.logo} alt='logo' />
                            </IonAvatar>
                          ) : null}
                          <IonLabel>
                            <h3 className='vh250 bold'>{restaurant.name}</h3>
                            <p>
                              {restaurant.location.address1}, {restaurant.location.address2}, {restaurant.location.address3}
                              {restaurant.location.city}, {restaurant.location.state} {restaurant.location.zip_code}
                            </p>
                          </IonLabel>
                        </IonItem>
                      )}
                    </IonList>
                    <div className='ion-padding-horizontal' style={{ marginTop: 20 }}>
                      <IonLabel className='ion-margin-vertical'>
                        If the restaurant you are trying to sign up for is correct, <br />
                        please CONFIRM.
                      </IonLabel>
                    </div>

                    <IonRow style={{ marginTop: 20 }}>
                      <IonCol size='6' column>
                        <IonButton
                          expand='block'
                          size='large'
                          onClick={
                            () => setPageIndex(1)
                            // handleRestaurant(restaurant.objectId, restaurant.name, restaurant.logo, restaurant.onboarding_status.is_onboarding)
                          }>
                          Confirm
                        </IonButton>
                      </IonCol>
                      <IonCol size='6'>
                        <IonButton size='large' expand='block' fill='none' onClick={() => history.replace('/')}>
                          Incorrect
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </>
                )}
                {pageIndex === 1 && (
                  <div className='ion-padding'>
                    {/* <IonContent className='ion-padding'> */}
                    <h1 className='ion-text-start'>Create new account</h1>
                    <h5 className='ion-text-start'>
                      <IonText className='subtitle'>Please let us know who you are</IonText>
                    </h5>

                    {/* <div className='ion-text-center' style={{ width: '100%' }}>
                      {avatar ? (
                        <IonAvatar style={{ margin: '2rem auto' }} onClick={handleAvatar}>
                          <IonImg src={avatar} />
                        </IonAvatar>
                      ) : (
                        <IonAvatar style={{ margin: 'auto' }} onClick={handleAvatar}>
                          <IonIcon icon={cameraOutline} size='large' />
                        </IonAvatar>
                      )}
                    </div> */}
                    <IonItem className='ion-margin-vertical ion-no-padding inputItem' lines='none' style={{ width: '100%' }}>
                      <IonLabel className='text-white' position='stacked'>
                        First Name
                      </IonLabel>
                      <IonInput
                        className='register-input'
                        pattern='^([a-zA-Z0-9]{3,})$'
                        placeholder=''
                        value={firstName}
                        onIonInput={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </IonItem>
                    <IonItem className='ion-margin-vertical ion-no-padding inputItem' lines='none' style={{ width: '100%' }}>
                      <IonLabel className='text-white' position='stacked'>
                        Last Name
                      </IonLabel>
                      <IonInput
                        className='register-input'
                        pattern='^([a-zA-Z0-9]{3,})$'
                        placeholder=''
                        value={lastName}
                        onIonInput={(e) => setLastName(e.target.value)}
                        required
                        style={{ width: '100%' }}
                      />
                    </IonItem>

                    <IonItem className='ion-margin-vertical ion-no-padding inputItem' style={{ width: '100%' }}>
                      <IonLabel className='text-white' position='stacked'>
                        Role
                      </IonLabel>
                      <IonSelect id='popOver' interface='action-sheet' value={role} onIonInput={(e) => setRole(e.target.value)}>
                        <IonSelectOption className='text select-option' style={{ color: 'white' }} value='Owner'>
                          Owner
                        </IonSelectOption>
                        <IonSelectOption value='Chef'>Chef</IonSelectOption>
                        <IonSelectOption value='Kitchen Manager'>Kitchen Manager</IonSelectOption>
                        <IonSelectOption value='Manager'>Manager</IonSelectOption>
                        <IonSelectOption value='Marketing'>Marketing</IonSelectOption>
                        <IonSelectOption value='IT'>IT</IonSelectOption>
                        <IonSelectOption value='Cashier'>Cashier</IonSelectOption>
                      </IonSelect>
                    </IonItem>
                    <IonItem className='ion-margin-top ion-no-padding inputItem' lines='none' style={{ width: '100%' }}>
                      <IonLabel className='text-white' position='stacked'>
                        Email
                      </IonLabel>
                      <IonInput
                        className='register-input'
                        inputmode='email'
                        pattern='^([a-zA-Z0-9]{3,})$'
                        placeholder=''
                        min='3'
                        value={email}
                        onIonInput={(e) => setEmail(e.target.value)}
                        style={{ width: '100%' }}
                      />
                    </IonItem>

                    <IonItem className='ion-margin-vertical ion-no-padding inputItem' lines='none' style={{ width: '100%' }}>
                      <IonLabel className='text-white' position='stacked'>
                        Password - At least 8
                      </IonLabel>
                      <IonInput
                        className='register-input'
                        type='password'
                        minlength={8}
                        placeholder=''
                        value={password}
                        onIonInput={(e) => setPassword(e.target.value)}
                        required
                      />
                    </IonItem>
                    <IonItem className='ion-margin-vertical ion-no-padding inputItem' lines='none' style={{ width: '100%' }}>
                      <IonLabel className='text-white' position='stacked'>
                        Confirm password
                      </IonLabel>
                      <IonInput
                        className='register-input'
                        type='password'
                        minlength={8}
                        placeholder=''
                        value={confirmPassword}
                        onIonInput={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                    </IonItem>
                    <IonButton
                      disabled={userRegistrationFlag}
                      fill='solid'
                      expand='block'
                      style={{ fontFamily: 'BrandonBold', height: '4em', marginTop: '2em' }}
                      onClick={signUp}>
                      Submit
                    </IonButton>
                  </div>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
        <IonAlert
          color={'light'}
          isOpen={showErrorAlert}
          onDidDismiss={() => setShowErrorAlert(false)}
          // cssClass='errorAlert'
          header={'Error'}
          subHeader={''}
          message={errorText}
          buttons={['OK']}
        />
        <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={''} />
      </IonContent>
    </IonPage>
  );
};
export default InvitedUserPage;
