import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonAlert,
  IonButton,
  IonContent,
  IonPage,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonInput,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonLoading,
  IonRow,
  IonCol,
} from '@ionic/react';
import { close, logoFacebook, logoApple } from 'ionicons/icons';
import Parse from 'parse/dist/parse.min.js';
import { FacebookLogin, FacebookLoginResponse } from '@capacitor-community/facebook-login';
import AppContext from '../../context/AppContext';
import { errorAlert } from '../../components/errorAlert';
import './LoginPage.css';

// Keyboard.setResizeMode({ mode: 'ionic' });

const LoginPage = (props) => {
  const history = useHistory();
  const { platform, user, updateUser, updateIsAuthed, updateIsAdmin, updateIsOnboarding } = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ header: '', message: '' });
  const [showLoading, setShowLoading] = useState(true);
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);

  const handleFacebookLogin = async () => {
    const FACEBOOK_PERMISSIONS = ['email', 'public_profile'];

    const result = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
    if (result.accessToken) {
      console.log(`Facebook access token is ${(result.accessToken.token, result)}`);
    }
  };

  useEffect(() => {
    const initFacebookLogin = async () => {
      await FacebookLogin.initialize({ appId: '775759307400802' });
    };
    initFacebookLogin();

    const getUser = async () => {
      const currentUser = Parse.User.current();
      if (currentUser) {
        await Parse.Cloud.run('getCurrentUser', { userId: currentUser.id }).then(async (User) => {
          if (User.type === 'ADMIN') {
            // setAdminId(currentUser.id);
            updateUser({ ...user, id: currentUser.id, role: 'ADMIN' });
            updateIsAdmin(true);
          } else if (User.type === 'RESTAURANT') {
            if (User.status === 'SIGNUP' || User.status === 'YELP') {
              setShowLoading(false);
              return history.push({ pathname: '/signup', state: { swiperIndex: 5 } });
            } else {
              setShowLoading(false);
              return history.push(`/launch/${currentUser.id}`);
            }
          } else {
            setShowLoading(false);
            console.log('Not restaurant');
          }
        });
        //updateIsAuthed(true);
      } else {
        setShowLoading(false);
        console.log('Not currentUser');
      }
    };
    getUser();
  }, []);

  const handleLogOut = async () => {
    await Parse.User.logOut().then(() => {
      updateIsAuthed(false);
      updateIsAdmin(false);
      history.goBack();
    });
  };

  const logIn = async () => {
    if (email.length < 1) {
      errorAlert('Error', 'Invalid Email');
      return false;
    }
    if (password.toString().length < 8) {
      errorAlert('Error', 'Password Error');
      return false;
    }
    setShowLoading(true);
    await Parse.User.logIn(email, password)
      .then(async (User) => {
        setShowLoading(false);
        if (User.get('type') === 'ADMIN') {
          try {
            updateIsOnboarding(false);
            updateIsAdmin(true);
            updateUser({
              id: User.id,
              role: 'ADMIN',
            });
            setShowLoading(false);
            return history.push(`/admin/${User.id}`);
          } catch (error) {
            console.log(error);
          }
        } else if (User.get('type') === 'RESTAURANT') {
          if (User.get('status') === 'SIGNUP') {
            updateUser({
              ...user,
              id: User.id,
              first_name: User.get('first_name'),
              last_name: User.get('last_name'),
              email: User.get('email'),
              avatar: User.get('avatar'),
              role: User.get('role'),
              restaurant_id: '',
              restaurant_name: '',
              restaurant_logo: '',
            });
            return history.push({ pathname: '/signup', state: { swiperIndex: 5 } });
          }
          if (User.get('status') === 'YELP') {
            updateUser({
              ...user,
              id: User.id,
              first_name: User.get('first_name'),
              last_name: User.get('last_name'),
              email: User.get('email'),
              avatar: User.get('avatar'),
              role: User.get('role'),
              restaurant_id: '',
              restaurant_name: '',
              restaurant_logo: '',
            });
            return history.push({ pathname: '/signup', state: { swiperIndex: 5 } });
          }
          updateUser({
            ...user,
            id: User.id,
            first_name: User.get('first_name'),
            last_name: User.get('last_name'),
            email: User.get('email'),
            avatar: User.get('avatar'),
            role: User.get('role'),
            restaurant_id: User.get('restaurant_ids'),
            restaurant_name: '',
            restaurant_logo: '',
          });
          setShowLoading(false);
          history.push(`/launch/${User.id}`);
          return;
        } else {
          setShowLoading(false);
          setAlertMessage({ ...alertMessage, header: 'Login Error', message: 'Your account is not for merchant' });
          return setShowAlert(true);
        }
      })
      .catch((error) => {
        console.log(error.code);
        setShowLoading(false);
        if (error.code === 100) {
          setAlertMessage({ ...alertMessage, header: 'Login Error', message: 'Please check your internet connection' });
          return setShowAlert(true);
        }
        if (error.code === 101) {
          setAlertMessage({
            ...alertMessage,
            header: 'Login Error',
            message: 'Invalid Username or Password',
          });
          return setShowAlert(true);
        }
        if (error.code === 400 || error.code === 404) {
          setAlertMessage({ ...alertMessage, header: 'Login Error', message: 'Invalid Username or Password' });
          return setShowAlert(true);
        }
      });
  };

  const doRequestPasswordReset = async function () {
    const emailValue = resetEmail;
    try {
      await Parse.User.requestPasswordReset(emailValue);
      setShowPasswordResetModal(false);
      alert(`Success! Please check ${resetEmail} to proceed with password reset.`);
      return true;
    } catch (error) {
      setShowPasswordResetModal(false);
      alert(`Error! ${error}`);
      return false;
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonRow>
          <IonCol size-xs='10' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4'>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonBackButton></IonBackButton>
              </IonButtons>
            </IonToolbar>
          </IonCol>
        </IonRow>
      </IonHeader>
      {showLoading ? null : (
        <IonContent>
          <IonRow className='ion-justify-content-center'>
            <IonCol className='ion-align-items-center ion-text-center' size-xs='10' size-sm='8' size-md='6' size-lg='4'>
              <h3 style={{ fontFamily: 'BrandonBold', fontSize: '1.5em', lineHeight: '1.5em' }}>Welcome back</h3>
              <IonLabel>Please enter Email and password</IonLabel>
              <IonItem className='ion-margin-bottom item-no-padding' lines='none' style={{ width: '100%' }}>
                <IonLabel position='stacked'>Email</IonLabel>
                <IonInput
                  className='login-input no-padding'
                  type='email'
                  pattern='^([a-zA-Z0-9]{3,})$'
                  placeholder=''
                  min='3'
                  value={email}
                  onIonInput={(e) => setEmail(e.target.value)}
                  style={{ width: '100%' }}
                />
              </IonItem>
              <IonItem className='ion-margin-vertical item-no-padding' lines='none' style={{ width: '100%' }}>
                <IonLabel position='stacked'>Password</IonLabel>
                <IonInput
                  className='login-input no-padding'
                  type='password'
                  pattern='password'
                  placeholder=''
                  value={password}
                  onIonInput={(e) => setPassword(e.target.value)}
                />
              </IonItem>
              <IonItem className='ion-margin-vertical item-no-padding' lines='none' style={{ width: '100%' }}>
                <IonButton className='submitButton' expand='block' onClick={() => logIn()}>
                  Submit
                </IonButton>
              </IonItem>
              <IonLabel onClick={() => setShowPasswordResetModal(true)}>Forgot password?</IonLabel>
              {/* <div style={{ margin: '3em 0' }}>---------- or ---------</div> */}
              {/* {platform.includes('ios') || !platform.includes('desktop') ? (
                <IonButton fill='clear' style={{ marginBottom: '2em' }} onClick={handleFacebookLogin}>
                  <IonIcon icon={logoApple} className='ion-floart-start ion-margin-end' />
                  <IonLabel>Login with Apple</IonLabel>
                </IonButton>
              ) : null} */}
              {/* <IonButton fill='clear' onClick={handleFacebookLogin}>
                <IonIcon icon={logoFacebook} className='ion-floart-start ion-margin-end' />
                <IonLabel>Login with Facebook</IonLabel>
              </IonButton> */}
            </IonCol>
          </IonRow>
          <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => {
              setShowAlert(false);
            }}
            header={alertMessage.header}
            subHeader={alertMessage.message}
            message={''}
            buttons={['OK']}
          />
        </IonContent>
      )}

      <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={''} />

      <IonModal isOpen={showPasswordResetModal} onDidDismiss={() => setShowPasswordResetModal(false)}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonIcon className='ion-margin-start' icon={close} size='large' color='white' onClick={() => setShowPasswordResetModal(false)} />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className='ion-text-center' style={{ width: '80%', margin: 'auto' }}>
          <IonLabel style={{ fontSize: '4vh' }}>Reset your password</IonLabel>
          <IonItem style={{ marginBottom: '2em' }} lines='none'>
            <IonLabel position='stacked'>Email</IonLabel>
            <IonInput
              className='login-input no-padding'
              inputmode='email'
              pattern='^([a-zA-Z0-9]{3,})$'
              placeholder=''
              min='3'
              value={resetEmail}
              onIonInput={(e) => setResetEmail(e.target.value)}
              required
              style={{ width: '100%' }}
            />
          </IonItem>
          <IonButton onClick={doRequestPasswordReset}>Submit</IonButton>
        </div>
      </IonModal>
    </IonPage>
  );
};
export default LoginPage;
