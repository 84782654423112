import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import AppContext from '../context/AppContext';
import Parse from 'parse/dist/parse.min.js';

const GetParamsPage = (props) => {
  const { user, updateUser, updateIsOnboarding } = useContext(AppContext);

  const path = window.location.pathname;
  console.log(path);

  const id = props.match.params.id;
  const rId = props.match.params.rId;
  const email = props.match.params.email;
  const action = props.match.params.action;
  const where = props.match.params.where;

  const history = useHistory();

  if (path.includes('/invite/restaurant')) {
    updateUser({
      ...user,
      email,
      restaurant_id: rId,
      type: 'RESTAURANT',
    });
    history.replace('/signup/invite/restaurant/introduction');
  }

  // if (rId && email) {
  //   updateUser({
  //     ...user,
  //     email,
  //     restaurant_id: rId,
  //     type: 'RESTAURANT',
  //   });
  //   history.replace('/signup/invite/restaurant/introduction');
  // }
};

export default GetParamsPage;
