import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { IonButton, IonPage, IonContent, IonSpinner, IonGrid, IonRow, IonCol, useIonViewDidEnter } from '@ionic/react';
import { App } from '@capacitor/app';
import axios, { isCancel, AxiosError } from 'axios';
import Parse from 'parse/dist/parse.min.js';

const WebhookPage = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [done, setDone] = useState(false);
  const history = useHistory();

  // const rId = new URLSearchParams(props.location.search).get('state');
  // console.log(axios.isCancel('something'));

  useIonViewDidEnter(() => {
    App.addListener('appUrlOpen', (data) => {
      console.log(`url: ${data}`);
    });
    console.log(new URLSearchParams(props.location.search).get('code'));
    // history.push({
    //   pathname: `/onboarding/${new URLSearchParams(props.location.search).get('state')}`,
    //   state: { code: new URLSearchParams(props.location.search).get('code'), provider: 'SQUARE' },
    // });
    //_handleGetAccessTokenSquare(new URLSearchParams(props.location.search).get('code'));

    //     if (code && provider === 'SQUARE') {
    //
    //     }
    //   }
  });

  const _handleGetCatalogSquare = async () => {
    if (new URLSearchParams(props.location.search).get('code')) {
      await Parse.Cloud.run('squareGetCatalog', { rId: new URLSearchParams(props.location.search).get('state') }).then(async (result) => {
        if (result) {
          await Parse.Cloud.run('updateWaitListCompleteSquareMenu', { rId: new URLSearchParams(props.location.search).get('state') }).then(() => {
            setDone(true);
            setTimeout(() => {
              history.push({
                pathname: `/onboarding/${new URLSearchParams(props.location.search).get('state')}`,
              });
            }, 3000);
          });
        }
      });
    }
  };

  const _handleGetAccessTokenSquare = async (token) => {
    // axios({
    //   method: 'post',
    //   url: 'https://connect.squareupsandbox.com/oauth2/token',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Square-Version': '2022-11-16',
    //   },
    //   params: {
    //     client_id: 'sandbox-sq0idb--29oEjx4F2GZFl4j8bZwYg',
    //     client_secret: 'sandbox-sq0csb-XVKab-lBc5tx11F1eVkcm_Tlu2yi64kuMSCQRLx-7tw',
    //     grant_type: 'authorization_code',
    //     code: token,
    //   },
    // }).then(function (response) {
    //   console.log(response.data);
    // });
    // await Parse.Cloud.run('squareGetAccessToken', { token }).then(async (data) => {
    //   console.log(data);
    //   if (data.accessToken) {
    //     await Parse.Cloud.run('squareSaveAccessToken', { rId, accessToken:data.accessToken, merchantId:data.merchantId }).then((result) => {
    //       if (result) {
    //         history.replace(`/onboarding/${rId}`);
    //       } else {
    //       }
    //     });
    //   }
    // });
  };

  return (
    <IonPage>
      <IonContent>
        <IonGrid style={{ height: '100%' }}>
          <IonRow className='ion-align-items-center' style={{ height: '100%' }}>
            <IonCol
              className='ion-text-center ion-align-items-center'
              size-xs='12'
              size-sm='10'
              offset-sm='1'
              size-md='8'
              offset-md='2'
              size-lg='6'
              offset-lg='3'>
              <img src={'assets/images/ono.png'} style={{ width: '5rem', height: '5rem', borderRadius: '0.5em' }} alt='logo' />
              {done ? (
                <div>
                  <h1>All done! redirecting onboarding page</h1>
                </div>
              ) : showSpinner ? (
                <div style={{ marginTop: '3rem' }}>
                  <IonSpinner color='secondary'></IonSpinner>
                  <h1>Creating your menus</h1>

                  <h3>It may take a white ...</h3>
                  <h3>Please do not refresh or leave</h3>
                </div>
              ) : (
                <div style={{ marginTop: '3rem' }}>
                  <h1>Thank you for submitting Square integration</h1>
                  <h2>If you import menus from Square POS,</h2>
                  <h3>Online order on ono online will pop up on your Square POS.</h3>
                  <h3>However, every time you modify or add menus on Square, you will have to sync with ono online.</h3>
                  <IonButton style={{ fontFamily: 'BrandonBold', marginTop: '3rem', width: '70%' }} onClick={_handleGetCatalogSquare}>
                    Confirm
                  </IonButton>
                </div>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default WebhookPage;
