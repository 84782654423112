import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonIcon,
  IonLabel,
  IonModal,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonRow,
  IonCol,
  IonGrid,
  IonSearchbar,
  IonInput,
  IonToggle,
  IonChip,
  IonCheckbox,
  IonLoading,
  IonCard,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import Parse from 'parse/dist/parse.min.js';
import AppContext from '../../context/AppContext';
import './ModifierGroupsModal.css';

const ModifierGroupsModal = (props) => {
  const {
    rId,
    selectedGroup,
    setSelectedModifier,
    selectedGroupIndex,
    modifierItemsList,
    setModifierItemsList,
    showModifierModal,
    setShowModifierModal,
    modifierGroups,
    setModifierGroups,
  } = props;

  const { user } = useContext(AppContext);

  const [searchText, setSearchText] = useState('');
  const [modifierItems, setModifierItems] = useState(modifierItemsList ? modifierItemsList : []);
  const [newModifierItem, setNewModifierItem] = useState({ name: '', price: 0 });
  const [showLoading, setShowLoading] = useState(false);
  const [selectedModifierGroup, setSelectedModifierGroup] = useState(
    !selectedGroup
      ? {
          is_required: false,
          max: 1,
          min: 1,
          type: 'RADIO',
          objectId: '',
          restaurant_id: user.restaurant_id,
          title: '',
          options: [],
        }
      : selectedGroup
  );

  useEffect(() => {
    const defaultGroup = {
      is_required: false,
      max: 1,
      min: 1,
      type: 'RADIO',
      objectId: '',
      restaurant_id: user.restaurant_id,
      title: '',
      options: [],
    };

    const selectedIds = selectedGroup?.options.map((item) => item.objectId) || [];

    const selectedItems = modifierItemsList.filter((item) => selectedIds.includes(item.objectId));

    const notSelectedItems = modifierItemsList.filter((item) => !selectedIds.includes(item.objectId));

    setModifierItems([...selectedItems, ...notSelectedItems]);
    setSelectedModifierGroup(selectedGroup || defaultGroup);
  }, [selectedGroup]);

  const filteredModifierItems = useMemo(() => {
    return modifierItems.filter((item) => {
      return item.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [modifierItems, searchText]);

  const handleModifierGroupType = (checked) => {
    if (checked) {
      setSelectedModifierGroup({ ...selectedModifierGroup, type: 'CHECK' });
    } else {
      setSelectedModifierGroup({ ...selectedModifierGroup, type: 'RADIO' });
    }
  };

  const handleCheckModifierItems = (checked, id, item, index) => {
    let copiedModifierItems = modifierItems;
    if (checked) {
      setSelectedModifierGroup((prev) => {
        return { ...prev, options: [...prev.options, item] };
      });
      copiedModifierItems[index].group_titles.push(selectedModifierGroup.title);
      setModifierItems([...copiedModifierItems]);
    } else {
      const groupOptionIndex = selectedModifierGroup.options.findIndex((item) => item.objectId === id);
      setSelectedModifierGroup((prev) => {
        let options = prev.options;
        options.splice(groupOptionIndex, 1);
        return { ...prev, options: prev.options };
      });
      let titleIndex = item.group_titles.indexOf(selectedModifierGroup.title);
      copiedModifierItems[index].group_titles.splice(titleIndex, 1);
      setModifierItems([...copiedModifierItems]);
    }
  };

  const handleBeforeSaveModifier = async () => {
    setShowLoading(true);
    if (!selectedModifierGroup.title.length) {
      setShowLoading(false);
      alert('Please input modifier set title');
      return;
    }
    let selectedItemIds = selectedModifierGroup.options.map((item) => item.objectId);
    await Parse.Cloud.run('beforeSaveModifierGroup', { rId, modifierItemIds: selectedItemIds, selectedModifierGroup })
      .then((savedModifier) => {
        if (!selectedModifierGroup.objectId) {
          setModifierGroups([...modifierGroups, savedModifier]);
          setShowModifierModal(false);
          setShowLoading(false);
        } else {
          let copiedModifierGroups = [...modifierGroups];
          copiedModifierGroups[selectedGroupIndex] = selectedModifierGroup;
          setModifierGroups([...copiedModifierGroups]);
          setShowModifierModal(false);
          setShowLoading(false);
        }
      })
      .catch((error) => {
        setShowLoading(false);
        alert(error);
      });
  };

  const handleSaveNewModifier = async () => {
    setShowLoading(true);
    if (!selectedModifierGroup.title.length) {
      setShowLoading(false);
      alert('Please input modifier group title');
      return false;
    }
    await Parse.Cloud.run('saveNewModifierGroup', { rId, selectedModifierGroup })
      .then((savedModifier) => {
        setSelectedModifierGroup(savedModifier);
        setModifierGroups([savedModifier, ...modifierGroups]);
        setShowLoading(false);
      })
      .catch((error) => {
        setShowLoading(false);
        alert(error);
      });
  };

  const handleAddNewModOption = async (e) => {
    setShowLoading(true);
    e.preventDefault();
    let price;
    if (newModifierItem.name.length === 0) {
      setShowLoading(false);
      return alert('Please input name');
    }
    if (!newModifierItem.price) {
      setShowLoading(false);
      return alert('Please input correct price');
    }
    if (isNaN(newModifierItem.price)) {
      price = Number(newModifierItem.price) * 100;
      setNewModifierItem({ ...newModifierItem, price: Number(newModifierItem.price) * 100 });
    }
    await Parse.Cloud.run('createModifierItem', {
      rId,
      name: newModifierItem.name,
      price,
      groupTitle: selectedModifierGroup.title,
    })
      .then((savedItem) => {
        setModifierItems([savedItem, ...modifierItemsList]);
        setModifierItemsList([...modifierItemsList, savedItem]);
        setSelectedModifierGroup((prev) => {
          return { ...prev, options: [...prev.options, savedItem] };
        });
        setNewModifierItem({ name: '', price: '' });
        // setModifierItemsCheckedIdArray([...modifierItemsCheckedIdArray, savedItem.id]);
        // setModifierStockArray([...modifierStockArray, null]);
        setShowLoading(false);
      })
      .catch((error) => {
        setShowLoading(false);
        alert(error);
      });
  };

  const roundToTwo = (num) => {
    return +(Math.round(num + 'e+2') + 'e-2');
  };

  const closeModal = () => {
    setSelectedModifier(null);
    setShowModifierModal(false);
  };

  return (
    <IonModal
      id='modifierModal'
      isOpen={showModifierModal}
      onDidDismiss={() => {
        setShowModifierModal(false);
      }}>
      <IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='8' offset-md='2' size-lg='6' offset-lg='3'>
              <IonToolbar>
                <IonButtons slot='start'>
                  <IonButton onClick={closeModal}>
                    <IonIcon icon={closeOutline} />
                  </IonButton>
                </IonButtons>
                <IonTitle>{selectedGroup?.objectId ? 'Edit modifier group' : 'New modifier group'}</IonTitle>
                {!selectedModifierGroup?.objectId ? null : (
                  <IonButtons slot='end'>
                    <IonButton fill='solid' expand='block' color='primary' onClick={handleBeforeSaveModifier}>
                      Save
                    </IonButton>
                  </IonButtons>
                )}
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>
      <IonContent className='ion-padding' style={{ marginBottom: '300px' }}>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='8' offset-md='2' size-lg='6' offset-lg='3'>
              <IonLabel style={{ fontFamily: 'BrandonBold', fontSize: '2.75vh' }}>Modifier Group Title</IonLabel>
              <IonItem className='ion-margin-top' fill='outline'>
                <IonInput
                  style={{ borderRadius: '5px', height: '40px' }}
                  // color={'light'}
                  className='ion-padding-start'
                  value={selectedModifierGroup?.title}
                  onIonInput={(e) =>
                    setSelectedModifierGroup((prev) => {
                      return { ...prev, title: e.target.value };
                    })
                  }
                  fill='solid'
                  placeholder='Example: Side Choice'
                />
              </IonItem>

              {/* <div style={{ width: '100%' }}>Category</div>
              <IonSelect
                style={{ border: '1px solid grey', borderRadius: '5px', height: '40px', marginTop: '8px', width: '180px' }}
                value={modCategories}
                interface='popover'
                multiple={false}
                placeholder='Select One'
                onIonInput={(e) => setModCategories(e.target.value)}>
                <IonSelectOption value='Additional'>Additional</IonSelectOption>
                <IonSelectOption value='Extra'>Extra</IonSelectOption>
                <IonSelectOption value='Choice'>Choice</IonSelectOption>
                <IonSelectOption value='Exclude'>Exclude</IonSelectOption>
              </IonSelect> */}
              <div style={{ marginTop: '30px' }}>
                <IonItem className='ion-no-padding'>
                  <IonToggle
                    slot='start'
                    checked={selectedModifierGroup?.is_required}
                    onIonInput={(e) =>
                      setSelectedModifierGroup((prev) => {
                        return { ...prev, is_required: e.target.checked };
                      })
                    }
                  />
                  <IonLabel style={{ fontFamily: 'BrandonBold', fontSize: '1.1rem' }}>Required?</IonLabel>
                </IonItem>
                <IonItem className='ion-no-padding'>
                  <IonToggle
                    slot='start'
                    checked={selectedModifierGroup?.type === 'RADIO' ? false : true}
                    onIonInput={(e) => handleModifierGroupType(e.target.checked)}
                  />
                  <IonLabel style={{ fontFamily: 'BrandonBold', fontSize: '1.1rem' }}>Multiple Choice?</IonLabel>
                </IonItem>
              </div>
              {selectedModifierGroup?.type === 'RADIO' ? null : (
                <IonCard lassName='ion-padding' style={{ margin: '12px 0' }} color='light'>
                  <IonRow color='light' className='ion-align-items-center ion-padding-horizontal' style={{ borderRadius: '10px' }}>
                    <IonCol size='auto'>
                      <IonLabel>Minimum</IonLabel>
                    </IonCol>
                    <IonCol size='auto'>
                      <IonSelect
                        interface='popover'
                        style={{ color: 'red', width: '100px' }}
                        value={selectedModifierGroup?.min}
                        placeholder='None'
                        onIonInput={(e) => setSelectedModifierGroup({ ...selectedModifierGroup, min: e.detail.value })}>
                        <IonSelectOption value={0}>None</IonSelectOption>
                        <IonSelectOption value={1}>1</IonSelectOption>
                        <IonSelectOption value={2}>2</IonSelectOption>
                        <IonSelectOption value={3}>3</IonSelectOption>
                        <IonSelectOption value={4}>4</IonSelectOption>
                        <IonSelectOption value={5}>5</IonSelectOption>
                        <IonSelectOption value={6}>6</IonSelectOption>
                        <IonSelectOption value={7}>7</IonSelectOption>
                        <IonSelectOption value={8}>8</IonSelectOption>
                        <IonSelectOption value={9}>9</IonSelectOption>
                        <IonSelectOption value={10}>10</IonSelectOption>
                      </IonSelect>
                    </IonCol>
                    <IonCol size='auto' className='ion-padding-start'>
                      <IonLabel>Maximum</IonLabel>
                    </IonCol>
                    <IonCol size='auto'>
                      <IonSelect
                        interface='popover'
                        style={{ color: 'red', width: '120px' }}
                        value={selectedModifierGroup?.max}
                        placeholder='Unlimited'
                        onIonInput={(e) => setSelectedModifierGroup({ ...selectedModifierGroup, max: e.detail.value })}>
                        <IonSelectOption value={100}>Unlimited</IonSelectOption>
                        <IonSelectOption value={1}>1</IonSelectOption>
                        <IonSelectOption value={2}>2</IonSelectOption>
                        <IonSelectOption value={3}>3</IonSelectOption>
                        <IonSelectOption value={4}>4</IonSelectOption>
                        <IonSelectOption value={5}>5</IonSelectOption>
                        <IonSelectOption value={6}>6</IonSelectOption>
                        <IonSelectOption value={7}>7</IonSelectOption>
                        <IonSelectOption value={8}>8</IonSelectOption>
                        <IonSelectOption value={9}>9</IonSelectOption>
                        <IonSelectOption value={10}>10</IonSelectOption>
                      </IonSelect>
                    </IonCol>
                  </IonRow>
                </IonCard>
              )}

              {!selectedModifierGroup?.objectId ? (
                <IonButton className='ion-margin-top' onClick={handleSaveNewModifier}>
                  Save new modifier group
                </IonButton>
              ) : (
                <div style={{ marginBottom: '240px', marginTop: '45px' }}>
                  <h3 style={{ fontFamily: 'BrandonBold', fontSize: '2.75vh' }}>New modifier items</h3>
                  <IonGrid className='ion-margin-bottom'>
                    <IonRow className='ion-align-items-end'>
                      <IonCol className='ion-padding-end'>
                        <div style={{ fonstSize: '0.8em', width: '100%' }}>New Name</div>
                        <IonInput
                          clearInput={true}
                          style={{ borderBottom: '1px solid grey', height: '50px' }}
                          placeholder='French Fry'
                          value={newModifierItem.name}
                          type='text'
                          onIonInput={(e) => setNewModifierItem({ ...newModifierItem, name: e.target.value })}
                        />
                      </IonCol>
                      <IonCol size='auto' className='ion-padding-end'>
                        <div style={{ fonstSize: '0.8em' }}>Price ( $ )</div>
                        <IonInput
                          style={{ borderBottom: '1px solid grey', height: '50px', width: '4em' }}
                          placeholder='1.5'
                          value={newModifierItem.price}
                          type='text'
                          onIonInput={(e) => setNewModifierItem({ ...newModifierItem, price: e.target.value })}
                        />
                      </IonCol>
                      <IonCol size='auto' className='ion-text-end'>
                        <IonButton onClick={(e) => handleAddNewModOption(e)}>Add</IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  <h3 style={{ fontFamily: 'BrandonBold', fontSize: '1em', marginTop: '45px' }}>Assign modifier items</h3>
                  <IonSearchbar
                    id='modifierItemSearchInput'
                    clearInput={true}
                    style={{ borderRadius: '20px', height: '40px', marginTop: '8px' }}
                    placeholder='Search Items'
                    value={searchText}
                    type='text'
                    color={'light'}
                    onIonInput={(e) => setSearchText(e.target.value)}
                  />
                  <IonList className='modifierItemsList'>
                    {filteredModifierItems.map((item, index) => (
                      <IonItem key={item.objectId} className='ion-no-padding'>
                        <IonCheckbox
                          className='itemCheckBox'
                          slot='start'
                          data-item={JSON.stringify(item)}
                          checked={selectedModifierGroup?.options.findIndex(({ objectId }) => objectId === item.objectId) > -1}
                          onClick={(e) => handleCheckModifierItems(e.target.checked, item.objectId, item, index)}
                        />
                        <IonLabel>
                          <h3 style={{ fontFamily: 'BrandonBold', fontSize: '1rem' }}>{item.name}</h3>
                          <p>
                            {item.group_titles.length &&
                              item.group_titles.map((title, i) => (
                                <span key={`${title} + ${i}`} className='vh150' style={{ marginRight: '5px' }}>
                                  {title},
                                </span>
                              ))}
                          </p>
                        </IonLabel>
                        <IonChip slot='end'>$ {roundToTwo(item.price / 100)}</IonChip>
                        {/* <IonButton slot='end'>Edit</IonButton> */}
                      </IonItem>
                    ))}
                  </IonList>
                </div>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />
      </IonContent>
    </IonModal>
  );
};

export default ModifierGroupsModal;
