import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonIcon,
  IonLabel,
  IonModal,
  IonList,
  IonItem,
  IonRow,
  IonCol,
  IonGrid,
  IonSearchbar,
  IonInput,
  IonChip,
  IonCheckbox,
  IonAlert,
  IonLoading,
  IonFooter,
  IonMenuButton,
  IonNote,
} from '@ionic/react';
import { closeOutline, ellipsisHorizontalOutline, trashOutline } from 'ionicons/icons';
import Parse from 'parse/dist/parse.min.js';
import AppContext from '../../context/AppContext';
import ModifierGroupsModal from '../../components/ModifierGroupsModal/ModifierGroupsModal';

import './MenuPage.css';

const ModifierGroupPage = (props) => {
  const { deviceWidth, user, updateUser, updateIsOnboarding } = useContext(AppContext);
  const history = useHistory();

  const [menus, setMenus] = useState(null);
  const [modifierItems, setModifierItems] = useState([]);
  const [modifierGroups, setModifierGroups] = useState([]);
  const [modifierStockArray, setModifierStockArray] = useState([]);
  const [originalMenus, setOriginalMenus] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [segment, setSegment] = useState('0');
  const [showLoading, setShowLoading] = useState(false);
  const [showModifierModal, setShowModifierModal] = useState(false);
  const [showModifierItemSelectModal, setShowModifierItemSelectModal] = useState(false);
  const [posProvider, setPosProvider] = useState(null);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(null);
  const [selectedModifier, setSelectedModifier] = useState(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const rId = props.match.params.id;

  useEffect(() => {
    console.log('modifierPage rendered');
    const currentUser = Parse.User.current();
    if (!currentUser) {
      updateIsOnboarding(true);
      return history.replace('/');
    }
    const getRestaurant = async () => {
      await Parse.Cloud.run('getRestaurantFromApp', { rId: rId ? rId : user.restaurant_id }).then((result) => {
        if (!result) {
          alert('Please check internet connection');
        } else {
          updateUser({ ...user, restaurant_id: rId ? rId : user.restaurant_id, restaurant_name: result.name });
        }
      });
    };
    if (!user.restaurant_name) {
      getRestaurant();
    }
    getModifiers();
  }, []);

  const getModifiers = async () => {
    await Parse.Cloud.run('getModifers', { rId: rId ? rId : user.restaurant_id })
      .then((results) => {
        if (results.modifierGroups.length) {
          setModifierGroups(results.modifierGroups);
        }
        if (results.modifierItems.length) {
          setModifierItems(results.modifierItems);
          setModifierStockArray(results.stockArray);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const filteredGroup = useMemo(() => {
    return modifierGroups.filter((item) => {
      return item.title.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [modifierGroups, searchText]);

  const handleShowEditModifierModal = useCallback(
    (item) => {
      if (item) {
        const idx = modifierGroups.findIndex((group) => group.objectId === item.objectId);
        setSelectedGroupIndex(idx);
      } else {
        setSelectedGroupIndex(0);
      }
      setSelectedModifier(item);
      setShowModifierModal(true);
    },
    [selectedModifier]
  );

  const handleOnChangeModifierStock = (value, index) => {
    let stockArrayCopy = modifierStockArray;
    stockArrayCopy[index] = Number(value);
    setModifierStockArray([...stockArrayCopy]);
    let modifierItemCopy = [...modifierItems];
    modifierItemCopy[index].stockChangeFlag = true;
    setModifierItems([...modifierItemCopy]);
    return;
  };

  const handleRefreshModifierItemStock = useCallback(async () => {
    setShowLoading(true);
    let modifierItemCopy = [...modifierItems];
    for (let i = 0; i < modifierItemCopy.length; i++) {
      modifierItemCopy[i].changeFlag = false;
    }
    setModifierItems([...modifierItemCopy]);
    await getModifiers().then(() => {
      setShowLoading(false);
    });
  }, []);

  const onDeleteModifierGroup = async () => {
    console.log(selectedModifier.objectId, selectedModifier.title);
    setShowLoading(true);
    await Parse.Cloud.run('deleteModifierGroup', { groupId: selectedModifier.objectId, groupTitle: selectedModifier.title }).then((result) => {
      if (result) {
        getModifiers();
        setShowLoading(false);
      } else {
        setShowLoading(false);
        alert(result);
      }
    });
  };

  const handleOnSaveModifierItem = async (id, index) => {
    setShowLoading(true);
    const query = new Parse.Query('ModifierItems');
    try {
      const result = await query.get(id);
      result.set('stock', modifierStockArray[index]);
      result.save().then(() => {
        let modifierItemsCopy = modifierItems;
        modifierItemsCopy[index].stockChangeFlag = false;
        setModifierItems([...modifierItemsCopy]);
        setShowLoading(false);
      });
    } catch (error) {
      setShowLoading(false);
      alert(error);
    }
  };

  const handleOnDeleteModifierItem = async (modifierItemId) => {
    setShowLoading(true);
    Parse.Cloud.run('deleteModifierItem', { id: modifierItemId }).then(async (result) => {
      if (result) {
        getModifiers();
        setShowLoading(false);
      } else {
        setShowLoading(false);
        alert(result);
      }
    });
  };

  const handleShowDeleteAlert = (e, group) => {
    e.stopPropagation();
    setSelectedModifier(group);
    setShowDeleteAlert(true);
  };

  const roundToTwo = (num) => {
    return +(Math.round(num + 'e+2') + 'e-2');
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar style={{ backgroundColor: 'transparent' }}>
          {deviceWidth > 500 ? (
            <IonGrid>
              <IonRow className='ion-align-items-center'>
                <IonCol size-xs='8' size-sm='8' offset-sm='1' size-md='8' size-lg='8' offset-lg='1'>
                  <IonSearchbar
                    class='customSearchBar'
                    show-cancel-button='focus'
                    debounce={400}
                    value={searchText}
                    onIonInput={(e) => {
                      setSearchText(e.target.value);
                    }}
                    showCancelButton='focus'
                    mode='ios'
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          ) : (
            <IonTitle>Modifier group</IonTitle>
          )}
        </IonToolbar>
      </IonHeader>

      <IonContent scrollX='true' className='ion-padding'>
        <IonGrid className='ion-no-padding'>
          <IonRow className='ion-no-padding'>
            <IonCol className='ion-no-padding' size-xs='12' size-sm='10' offset-sm='1' size-md='10' size-lg='8'>
              <IonRow>
                {!posProvider ? (
                  <IonButton onClick={() => handleShowEditModifierModal(null)}>Create modifier group</IonButton>
                ) : (
                  posProvider === 'SQUARE' && <IonButton>Sync from Square</IonButton>
                )}
              </IonRow>
              <main>
                <IonGrid>
                  <IonRow className='ion-text-start ion-align-items-center' style={{ borderBottom: '1px solid grey' }}>
                    <IonCol>
                      <h5 style={{ fontFamily: 'BrandonBold', fontSize: '1em' }}>Group name & items</h5>
                    </IonCol>
                    <IonCol size='auto'>
                      <h5 style={{ fontFamily: 'BrandonBold', fontSize: '1em', width: '3em' }}>Action</h5>
                    </IonCol>
                  </IonRow>
                </IonGrid>

                <IonGrid>
                  {!filteredGroup
                    ? null
                    : filteredGroup.map((item, index) => (
                        <IonRow
                          key={index}
                          className='ion-text-start ion-padding-bottom ion-align-items-center'
                          style={{ borderBottom: '1px solid #333' }}
                          onClick={() => handleShowEditModifierModal(item)}>
                          <IonCol>
                            <h5 style={{ fontFamily: 'BrandonBold', fontSize: '1em', lineHeight: '0.2em' }}>{item.title}</h5>
                            {item.options.map((val, i) => (
                              <IonNote key={val.objectId} className='vh175'>
                                {val.name},{' '}
                              </IonNote>
                            ))}
                          </IonCol>

                          <IonCol size='auto' className='ion-text-center'>
                            <div style={{ width: '3em' }}>
                              <IonButton size='small' shape='round' fill='clear' color='medium' onClick={(e) => handleShowDeleteAlert(e, item)}>
                                <IonIcon icon={ellipsisHorizontalOutline} />
                              </IonButton>
                            </div>
                          </IonCol>
                        </IonRow>
                      ))}
                </IonGrid>
              </main>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonAlert
          isOpen={showDeleteAlert}
          onDidDismiss={() => setShowDeleteAlert(false)}
          cssClass='my-custom-class'
          header={'Delete Item!'}
          message={'Are you sure you want to delete this item?'}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                setShowDeleteAlert(false);
              },
            },
            {
              text: 'Yes',
              handler: () => {
                onDeleteModifierGroup();
              },
            },
          ]}
        />

        {segment === '4' && (
          <main>
            <div className='ion-align-items-end' style={{ width: '100%' }}>
              <IonButton onClick={handleRefreshModifierItemStock}>Refresh Stocks</IonButton>
            </div>
            <IonGrid className='ion-margin-bottom' style={{ borderBottom: '1px solid grey' }}>
              <IonRow>
                <IonCol size='3'>
                  <IonLabel>Item Name</IonLabel>
                </IonCol>
                <IonCol size='1.5'>
                  <IonLabel>Price</IonLabel>
                </IonCol>
                <IonCol size='5'>
                  <IonLabel>Set</IonLabel>
                </IonCol>
                <IonCol size='1'>
                  <IonLabel>Stock</IonLabel>
                </IonCol>
                <IonCol size='1.5'>
                  <IonLabel>Action</IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
            {!modifierItems.length ? (
              <>
                <div>No Items Yet</div>
                <IonButton>Create Modifier Item</IonButton>
              </>
            ) : (
              <IonGrid>
                {modifierItems.map((item, index) => (
                  <IonRow key={index}>
                    <IonCol size='3'>
                      <IonLabel>{item.name}</IonLabel>
                    </IonCol>
                    <IonCol size='1.5'>
                      <IonLabel>${item.price}</IonLabel>
                    </IonCol>
                    <IonCol size='5'>
                      {!modifierGroups.length
                        ? null
                        : modifierGroups.map((val) => (
                            <div key={val.objectId}>
                              {val.options.map((value) => (
                                <div key={value.objectId}>
                                  {value.objectId.indexOf(item.objectId) > -1 ? (
                                    <IonLabel className='ion-float-start' style={{ color: 'grey', fontSize: '0.9em', marginRight: '8px' }}>
                                      {val.title},{' '}
                                    </IonLabel>
                                  ) : null}
                                </div>
                              ))}
                            </div>
                          ))}
                    </IonCol>
                    <IonCol size='1'>
                      {modifierStockArray.length && (
                        <IonInput
                          style={{ border: '1px solid grey', borderRadius: '5px', height: '40px' }}
                          type='tel'
                          value={modifierStockArray[index]}
                          onIonInput={(e) => handleOnChangeModifierStock(e.target.value, index)}
                        />
                      )}
                    </IonCol>
                    <IonCol size='1.5'>
                      {!item.stockChangeFlag ? (
                        <IonButton onClick={() => handleOnDeleteModifierItem(item.objectId)}>
                          <IonIcon icon={trashOutline} />
                        </IonButton>
                      ) : (
                        <IonButton onClick={(e) => handleOnSaveModifierItem(item.objectId, index)}>Save</IonButton>
                      )}
                    </IonCol>
                  </IonRow>
                ))}
              </IonGrid>
            )}
            {/* </IonContent>
          </IonSlides>
        </IonSlides> */}
          </main>
        )}

        <ModifierGroupsModal
          rId={rId ? rId : user.restaurant_id}
          selectedGroup={selectedModifier}
          setSelectedModifier={setSelectedModifier}
          selectedGroupIndex={selectedGroupIndex}
          modifierItemsList={modifierItems}
          setModifierItemsList={setModifierItems}
          modifierGroups={modifierGroups}
          setModifierGroups={setModifierGroups}
          showModifierModal={showModifierModal}
          setShowModifierModal={setShowModifierModal}
        />

        <IonModal className='modItemSelectModal' isOpen={showModifierItemSelectModal}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonButton onClick={() => setShowModifierItemSelectModal(false)}>
                  <IonIcon icon={closeOutline} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className='ion-padding'>
            {!modifierItems.length ? (
              <div>No Items Yet</div>
            ) : (
              <IonList>
                {modifierItems.map((item, index) => (
                  <IonItem key={index}>
                    <IonCheckbox slot='start' />
                    <IonLabel>{item.name}</IonLabel>
                    <IonChip slot='end'>
                      <IonLabel>{roundToTwo(item.price)}</IonLabel>
                    </IonChip>
                  </IonItem>
                ))}
              </IonList>
            )}
          </IonContent>
        </IonModal>
        <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />
      </IonContent>
      {deviceWidth < 501 ? (
        <IonFooter>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonMenuButton id='menu' />
            </IonButtons>
            <IonSearchbar
              class='customSearchBar'
              color='medium'
              show-cancel-button='focus'
              debounce={400}
              value={searchText}
              onIonInput={(e) => {
                setSearchText(e.target.value);
              }}
              showCancelButton='focus'
              modde='ios'
            />
          </IonToolbar>
        </IonFooter>
      ) : null}
    </IonPage>
  );
};

export default ModifierGroupPage;
