import { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonButton,
  IonContent,
  IonImg,
  IonPage,
  IonLoading,
  IonCol,
  IonRow,
  IonGrid,
  IonText,
  IonModal,
  isPlatform,
  IonCard,
  IonItem,
  IonLabel,
} from '@ionic/react';
import Parse from 'parse/dist/parse.min.js';
import './InvitedUserInstagramPage.css';
import AppContext from '../../context/AppContext';
import { Browser } from '@capacitor/browser';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';

const InvitedUserInstagramPage = ({ location }) => {
  const history = useHistory();
  const { user, updateIsOnboarding } = useContext(AppContext);
  const [showLoading, setShowLoading] = useState(false);

  const [showLastModal, setShowLastModal] = useState(false);
  const [instagram, setInstagram] = useState('');

  const slideOpts = {
    slidesPerView: isPlatform('desktop') || (isPlatform('tablet') && 6),
  };

  useEffect(() => {
    if (location.state) {
      setInstagram(location.state.state.instagram);
    }
  }, [location.state]);

  // localhost:8100/invite/restaurant?rId=hh0ONtH3CP

  const handleSubmit = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('updateOnboardingStatus', { rId: user.restaurant_id })
      .then(() => {
        setShowLastModal(false);
        updateIsOnboarding(false);
        setShowLoading(false);
        history.push(`/dashboard/home/${user.restaurant_id}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleJumpInstagram = async () => {
    await Browser.open({ url: `https://instagram.com/${instagram}` });
    return true;
  };

  return (
    <IonPage>
      <IonContent className='container'>
        <IonGrid style={{ height: '100%' }}>
          <IonRow className='ion-justify-content-center'>
            <IonCol size-xs='12' size-sm='8' size-md='6' size-lg='4'>
              <main
                style={{
                  textAlign: 'center',
                }}>
                <img src={'assets/images/ono.png'} style={{ width: '10vh', height: '10vh', borderRadius: '5px' }} alt={'logo'} />
                <h1>Mahalo for joining us {user.first_name}!</h1>
                <br />
                <h1>Last thing</h1>

                <h1>Please open Instagram on web browser and approve our request</h1>
                <h3>From this action, we will never get your login crediential informations.</h3>
                <div style={{ width: '100%', textAlign: 'center', marginTop: '4em' }}>
                  <img src={'assets/images/Ig/ig-1.png'} style={{ width: '40vh', height: 'auto' }} alt={'1'} />
                  <img src={'assets/images/Ig/ig-2.png'} style={{ width: '40vh', height: 'auto' }} alt={'2'} />
                  <img src={'assets/images/Ig/ig-3.png'} style={{ width: '40vh', height: 'auto' }} alt={'3'} />
                  {/* <img src={'assets/ig/ig4.png'} style={{ width: '40vh', height: '80vh' }} alt={'4'} />
                  <img src={'assets/ig/ig5.png'} style={{ width: '40vh', height: '80vh' }} alt={'5'} /> */}
                </div>

                <div style={{ marginTop: '2em' }}>
                  <IonText className='300vh'>Please click below to approve our request</IonText>
                  <IonItem button className='ion-no-padding ion-text-center ion-margin-top' onClick={handleJumpInstagram}>
                    <IonLabel className='300vh bold' style={{ color: 'blue' }}>
                      https://instagram.com/{instagram}
                    </IonLabel>
                  </IonItem>
                </div>
                <div className='ion-margin-top'>
                  <IonLabel className='300vh'>Only web browser version of Instagram can handle Apps and website.</IonLabel>
                  <IonLabel className='300vh'>Safari browser might take you to instagram app automatically.</IonLabel>
                  <IonLabel className='300vh'>If so, please type the url above on search input.</IonLabel>
                </div>

                <div style={{ marginTop: 30, marginBottom: 50 }}>
                  <IonButton size='large' strong expand='block' onClick={() => setShowLastModal(true)}>
                    Finish
                  </IonButton>
                </div>
              </main>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      <IonModal isOpen={showLastModal} onIonModalDidDismiss={() => setShowLastModal(false)}>
        <IonContent className='ion-padding ion-text-center'>
          <section>
            <IonRow className='ion-text-center ion-justify-content-center' style={{ width: '100%' }}>
              <IonImg src={'assets/images/ono.png'} style={{ width: '10vh', height: '10vh', borderRadius: '5px' }} />
            </IonRow>
            <h1 style={{ fontSize: '1.75em', fontFamily: 'RetroFunk' }}>Please download ono online app.</h1>
            <h1 style={{ fontSize: '1.75em', fontFamily: 'RetroFunk' }}>Using app makes much easier to post.</h1>
            <h3 style={{ fontSize: '1em' }}>You can login with your email and password on the app</h3>

            <IonCard>
              <section>
                {isPlatform('desktop') || isPlatform('tablet') ? (
                  <Swiper pager={false} options={slideOpts}>
                    <div>
                      <SwiperSlide>
                        <IonImg
                          className='ion-margin-end ion-float-start'
                          style={{ height: '300px' }}
                          src={
                            'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/ac83fefad83dc1817eac1ef14fce67d8_Screenshot0.png'
                          }
                        />
                        <IonImg
                          className='ion-margin-end  ion-float-start'
                          style={{ height: '300px' }}
                          src={
                            'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/0a1bf260d6f66f4d4af408af3ff5df99_Screenshot1.png'
                          }
                        />
                        <IonImg
                          className='ion-margin-end ion-float-start'
                          style={{ height: '300px' }}
                          src={
                            'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/34dc5285db2a031663630210027c343b_Screenshot2.png'
                          }
                        />
                        <IonImg
                          className='ion-margin-end ion-float-start'
                          style={{ height: '300px' }}
                          src={
                            'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/6f91ee537b91c91e453440af4a4abe16_Screenshot3.png'
                          }
                        />
                        <IonImg
                          className='ion-margin-end ion-float-start'
                          style={{ height: '300px' }}
                          src={
                            'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/a53d984be1c2f4dbc5d47e7520c397ff_Screenshot4.png'
                          }
                        />
                        <IonImg
                          className='ion-float-start'
                          style={{ height: '300px' }}
                          src={
                            'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/1202e1ae20f1d36a7eb941a0c3c83b08_Screenshot5.png'
                          }
                        />
                      </SwiperSlide>
                    </div>
                  </Swiper>
                ) : (
                  <Swiper pager={true} options={slideOpts}>
                    <SwiperSlide>
                      <IonImg
                        className='ion-margin-end'
                        style={{ height: '300px' }}
                        src={
                          'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/ac83fefad83dc1817eac1ef14fce67d8_Screenshot0.png'
                        }
                      />

                      <IonImg
                        className='ion-margin-end'
                        style={{ height: '300px' }}
                        src={
                          'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/0a1bf260d6f66f4d4af408af3ff5df99_Screenshot1.png'
                        }
                      />

                      <IonImg
                        style={{ height: '300px' }}
                        src={
                          'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/34dc5285db2a031663630210027c343b_Screenshot2.png'
                        }
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <IonImg
                        className='ion-margin-end'
                        style={{ height: '300px' }}
                        src={
                          'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/6f91ee537b91c91e453440af4a4abe16_Screenshot3.png'
                        }
                      />

                      <IonImg
                        className='ion-margin-end'
                        style={{ height: '300px' }}
                        src={
                          'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/a53d984be1c2f4dbc5d47e7520c397ff_Screenshot4.png'
                        }
                      />

                      <IonImg
                        style={{ height: '300px' }}
                        src={
                          'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/1202e1ae20f1d36a7eb941a0c3c83b08_Screenshot5.png'
                        }
                      />
                    </SwiperSlide>
                  </Swiper>
                )}
              </section>
              <IonText className='ion-margin-vertical' style={{ fontSize: '3vh' }}>
                App store or Play store links
              </IonText>
              <section className='ion-text-center ion-margin-top'>
                <IonImg
                  className='ion-margin-bottom'
                  src={'assets/images/App_Store_Badge_US.png'}
                  alt='App store'
                  style={{ height: '6vh' }}
                  onClick={() => window.open('https://apps.apple.com/us/app/ono-online/id1615732357')}
                />
                <IonImg
                  src={'assets/images/google-play-badge.png'}
                  alt='Play store'
                  style={{ width: 'auto', height: '8vh' }}
                  onClick={() => window.open('https://play.google.com/store/apps/details?id=co.onoonline.android')}
                />
                <IonImg
                  src={'assets/images/app_store_qr.png'}
                  alt='Play store'
                  style={{ width: 'auto', height: '20vh' }}
                  onClick={() => window.open('https://play.google.com/store/apps/details?id=co.onoonline.android')}
                />
              </section>
            </IonCard>
          </section>
          <div style={{ marginTop: 30, marginBottom: 50 }}>
            <IonButton size='large' strong style={{ width: '50%' }} onClick={handleSubmit}>
              Start
            </IonButton>
          </div>
        </IonContent>
      </IonModal>

      <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={''} />
    </IonPage>
  );
};
export default InvitedUserInstagramPage;
