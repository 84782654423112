import { useContext, useRef, useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonHeader,
  IonContent,
  IonImg,
  IonList,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonInput,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonText,
  useIonViewDidEnter,
  IonSpinner,
  getPlatforms,
  IonPopover,
  IonThumbnail,
} from '@ionic/react';
import Lottie from 'lottie-react';
import Parse from 'parse/dist/parse.min.js';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import './OnboardingPage.css';

import AppContext from '../../context/AppContext';

const OnboardingPage = (props) => {
  const { user, updateIsOnboarding } = useContext(AppContext);
  const history = useHistory();

  const [menuImages, setMenuImages] = useState([]);
  const [files, setFiles] = useState([]);
  const [url, setUrl] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [status, setStatus] = useState({
    is_bank_ready: false,
    is_menu_ready: false,
    is_store_ready: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [platform] = useState(() => getPlatforms());

  const contentRef = useRef();
  const accordionGroup = useRef(null);

  const welcomeAnimation = require('../../assets/lottie/welcomeAnimation.json');

  const rId = props.match.params.id;
  console.log(platform);

  useIonViewDidEnter(() => {
    console.log('Onboarding Rendered');
    let currentStatus = {
      is_bank_ready: false,
      is_menu_ready: false,
      is_store_ready: false,
    };
    const getWaitlist = async () => {
      await Parse.Cloud.run('getRestaurant', { rId: rId ? rId : user.restaurant_id })
        .then(async (result) => {
          if (!result) {
            console.log('no restaurant');
            return;
          } else {
            currentStatus = { ...result.data.onboarding_status };
            if (!currentStatus.is_bank_ready) {
              await Parse.Cloud.run('getStripeAccount', { rId: rId ? rId : user.restaurant_id })
                .then(async (account) => {
                  console.log(account);
                  if (!account) {
                    currentStatus.is_bank_ready = 'Need to register';
                    console.log('Stripe account error');
                  } else {
                    if (account.account.capabilities.card_payments === 'inactive' || account.account.capabilities.transfers === 'inactive') {
                      currentStatus.is_bank_ready = 'Need to register';
                    } else {
                      currentStatus.is_bank_ready = 'COMPLETED';
                    }
                  }
                  setStatus({ ...currentStatus });
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              currentStatus.is_bank_ready = 'COMPLETED';
              setStatus({ ...currentStatus });
            }
          }
        })
        .catch((error) => console.log(error));
    };
    getWaitlist();
  });

  useEffect(() => {
    if (status.is_bank_ready === 'COMPLETED' && status.is_menu_ready !== false && status.is_store_ready) {
      setShowModal(true);
      Parse.Cloud.run('completeOnboardingTask', { rId })
        .then((result) => {
          if (result) {
            setTimeout(() => {
              setShowModal(false);
              updateIsOnboarding(false);
              history.replace(`/dashboard/home/${user.restaurant_id ? user.restaurant_id : rId}`);
            }, 5000);
          } else {
            console.log('Save error');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (status.is_store_ready) {
      if (!accordionGroup.current) {
        return;
      }
      const nativeEl = accordionGroup.current;
      if (nativeEl.value === 'first') {
        nativeEl.value = undefined;
      }
    }
  }, [status.is_bank_ready, status.is_menu_ready, status.is_store_ready]);

  const cloudName = 'dn2gcd1cf'; // replace with your own cloud name
  const uploadPreset = 'aoexdljp'; // replace with your own upload preset

  const myWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: cloudName,
      uploadPreset: uploadPreset,
      cropping: true, //add a cropping step
      showAdvancedOptions: true, //add advanced options (public_id and tag)
      sources: ['local', 'url', 'camera', 'dropbox', 'instagram', 'google_drive', 'facebook'], // restrict the upload sources to URL and local files
      multiple: true, //restrict upload to a single file
      folder: 'restaurant', //upload files to the specified folder
      tags: ['menu', rId], //add the given tags to the uploaded files
      maxImageWidth: 2000, //Scales the image down to a width of 2000 pixels before uploading
      // theme: "purple", //change to a purple theme
    },
    (error, result) => {
      if (!error && result && result.event === 'success') {
        console.log(result);
        const prevImages = menuImages;
        const data = {
          name: result.info.original_filename,
          uri: result.info.secure_url,
          type: result.info.format.toUpperCase(),
        };
        setMenuImages([...prevImages, data]);
      }
    }
  );

  const handleLogOut = async () => {
    await Parse.User.logOut().then(() => {
      history.replace('/');
    });
  };

  const updateBankStatus = async () => {
    await Parse.Cloud.run('handleUseWithoutBankSetting', { rId: rId ? rId : user.restaurant_id }).then((result) => {
      if (result) {
        setStatus({ ...status, is_bank_ready: 'COMPLETED' });
      }
    });
  };

  const onSubmitUrl = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('updateWaitListUrl', { rId: rId ? rId : user.restaurant_id, url })
      .then(() => {
        setShowLoading(false);
        setStatus({ ...status, is_menu_ready: 'PENDING' });
        if (!accordionGroup.current) {
          return;
        }
        const nativeEl = accordionGroup.current;
        if (nativeEl.value === 'second') {
          nativeEl.value = undefined;
        } else {
          nativeEl.value = 'second';
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const takePicture = async () => {
    const result = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Camera,
    });
    const base64 = `${result.dataUrl}`;
    setMenuImages([...menuImages, { data: base64, type: 'IMAGE', name: '' }]);
  };

  const pickImages = async () => {
    const result = await FilePicker.pickImages({
      multiple: true,
      readData: true,
    });

    if (result.files.length === 1) {
      setShowLoading(true);
      const base64 = `data:${result.files[0].mimeType};base64,${result.files[0].data}`;
      setMenuImages([...menuImages, { data: base64, type: 'IMAGE', name: result.files[0].name }]);
    } else {
      setShowLoading(true);
      let data = [];
      for (let i = 0; i < result.files.length; i++) {
        if (result.files[i].mimeType.includes('video') || result.files[i].mimeType.includes('mp')) {
          alert('You can not upload video at this time');
        }
        const base64 = `data:${result.files[i].mimeType};base64,${result.files[i].data}`;
        data.push({ data: base64, type: 'IMAGE', name: result.files[i].name });
      }
      setMenuImages([...menuImages, ...data]);
    }
    setShowLoading(false);
  };

  const pickFiles = async () => {
    const result = await FilePicker.pickFiles({
      types: ['image/png', 'application/pdf', 'image/jpg', 'image/heic'],
      multiple: true,
      readData: true,
    });
    if (!result) {
      return false;
    }
    if (result.files.length === 1) {
      const base64 = `data:${result.files[0].mimeType};base64,${result.files[0].data}`;
      setMenuImages([
        ...menuImages,
        { data: base64, type: result.files[0].mimeType === 'application/pdf' ? 'PDF' : 'IMAGE', name: result.files[0].name },
      ]);
    } else {
      let fileData = [];
      for (let i = 0; i < result.files.length; i++) {
        const base64 = `data:${result.files[i].mimeType};base64,${result.files[i].data}`;
        fileData.push({ data: base64, type: result.files[i].mimeType === 'application/pdf' ? 'PDF' : 'IMAGE', name: result.files[i].name });
      }
      setMenuImages([...menuImages, ...fileData]);
    }
  };

  const deleteFile = (index) => {
    let prev = menuImages;
    if (prev.length === 1) {
      prev = [];
    } else {
      prev.splice(index, 1);
    }
    setMenuImages([...prev]);
  };

  const onSubmitFiles = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('updateWaitListFiles', { rId: rId ? rId : user.restaurant_id, files: menuImages, user })
      .then(() => {
        setShowLoading(false);
        setStatus({ ...status, is_menu_ready: 'PENDING' });
        if (!accordionGroup.current) {
          return;
        }
        const nativeEl = accordionGroup.current;
        if (nativeEl.value === 'second') {
          nativeEl.value = undefined;
        } else {
          nativeEl.value = 'second';
        }
      })
      .catch((error) => {
        setShowLoading(false);
        console.log(error);
      });
  };

  const refuseMenuSubmit = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('updateIsMenuReady', { rId: rId ? rId : user.restaurant_id })
      .then(() => {
        setStatus({ ...status, is_menu_ready: 'REFUSED' });
      })
      .catch((error) => {
        console.log(error);
      });
    setShowLoading(false);
    if (!accordionGroup.current) {
      return;
    }
    const nativeEl = accordionGroup.current;
    if (nativeEl.value === 'second') {
      nativeEl.value = undefined;
    } else {
      nativeEl.value = 'second';
    }
  };

  const _handleConnectSquare = async () => {
    await Parse.Cloud.run('getSquareEnvironmentVariable').then(async (result) => {
      const SQUARE_CLIENT_ID = result;
      window.location.href = `https://connect.squareup.com/oauth2/authorize?client_id=${SQUARE_CLIENT_ID}&scope=ITEMS_READ+ITEMS_WRITE+INVENTORY_READ+ORDERS_WRITE+ORDERS_READ+MERCHANT_PROFILE_READ+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_WRITE+PAYMENTS_READ&session=true&state=${rId}__onboarding`;
    });
  };

  const _handleConnectUberEats = async (rId) => {
    setShowLoading(true);
    await Parse.Cloud.run('getUberEatsEnvironmentVariable').then(async (result) => {
      setShowLoading(false);
      console.log(result);
      window.location = `https://login.uber.com/oauth/v2/token?client_id=${result.UBER_CLIENT_ID}&client_secret=${result.UBER_CLIENT_SECRET}&grant_type=client_credentials&scope=eats.store`;

      // const REDIRECT_URI = `http://localhost:8100/oauth/ubereats`;
      // window.location = `https://login.uber.com/oauth/v2/token?client_id=${UBER_CLIENT_ID}&grant_type=client_credentials&scope=eats.store`;
      // await Browser.open({
      //   //Production
      //   url: `https://connect.squareup.com/oauth2/authorize?client_id=${SQUARE_CLIENT_ID}&scope=ITEMS_READ+ITEMS_WRITE+INVENTORY_READ+ORDERS_WRITE+ORDERS_READ+MERCHANT_PROFILE_READ+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_WRITE+PAYMENTS_READ&session=true&state=${rId}`,

      //   //Sandobox
      //   //url: `https://connect.squareupsandbox.com/oauth2/authorize?client_id=sandbox-sq0idb--29oEjx4F2GZFl4j8bZwYg&scope=ITEMS_READ+ITEMS_WRITE+INVENTORY_READ+ORDERS_WRITE+ORDERS_READ+MERCHANT_PROFILE_READ+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_WRITE+PAYMENTS_READ&session=false&state=${rId}`,
      // });
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonRow>
          <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='8' offset-md='2' size-lg='6' offset-lg='3'>
            <IonToolbar>
              <IonTitle style={{ fontFamily: 'BrandonBold' }}>{user.restaurant_name}</IonTitle>
              <IonButtons slot='end'>
                <IonButton onClick={handleLogOut} href={'/'}>
                  Log out
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonCol>
        </IonRow>
      </IonHeader>

      <IonContent ref={contentRef} className='ion-padding'>
        <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={''} />

        <IonGrid style={{ marginBottom: 200 }}>
          <IonRow className='ion-align-items-center' style={{ height: '100%' }}>
            <IonCol className='ion-text-center' size-xs='12' size-sm='10' offset-sm='1' size-md='8' offset-md='2' size-lg='6' offset-lg='3'>
              <img src={'assets/images/ono.png'} style={{ width: '4rem', height: '4rem', borderRadius: '0.5em' }} alt='logo' />

              <h1 className='300vh bold'>Mahalo for joining ono online.</h1>
              <h5 className='225vh'>
                ono online is an All-in-One restaurant marketing tool. Its most important feature is the 'Call to Action,' which connects news
                delivery, similar to Instagram, with online ordering. Even if you are not interested in online ordering, you can still use it as a
                free marketing tool.
              </h5>
              <h5 className='225vh' style={{ marginTop: '2em', marginBottom: '2em' }}>
                Let's get started right away by completing the following three tasks and giving it a try.
              </h5>

              <IonAccordionGroup ref={accordionGroup}>
                <IonAccordion value='first' className='ion-margin-top'>
                  <IonItem slot='header' style={{ borderRadius: '10px' }}>
                    <IonLabel>
                      <h1 className='vh225 ion-text-wrap'>1. Complete store informations</h1>
                      <p className='ion-text-wrap'>Build your store page contents</p>
                      {!status.is_store_ready ? (
                        <p style={{ color: '#FC0FC0', fontFamily: 'BrandonBold', fontSize: '1em', marginTop: '1em' }}>Need to confirm</p>
                      ) : (
                        <p style={{ color: 'lightgreen', fontFamily: 'BrandonBold', fontSize: '1em', marginTop: '1em' }}>Completed</p>
                      )}
                    </IonLabel>
                  </IonItem>
                  <div className='ion-padding' slot='content' style={{ borderBottom: 'solid #999 0.5px' }}>
                    <IonButton style={{ fontFamily: 'BrandonBold' }} strong onClick={() => history.push(`/onboarding/store/${rId}`)}>
                      Check information page
                    </IonButton>
                  </div>
                </IonAccordion>

                <IonAccordion value='second' className='ion-margin-top'>
                  <IonItem slot='header' style={{ borderRadius: '10px' }}>
                    <IonLabel>
                      <h1 className='vh225'>2. Create food menus</h1>
                      <p className='ion-text-wrap'>
                        You can import menu data from your POS system or upload menu pictures. We will take care of rest.
                      </p>
                      <p style={{ color: !status.is_menu_ready ? '#FC0FC0' : 'red', fontFamily: 'BrandonBold', fontSize: '1em', marginTop: '1em' }}>
                        {!status.is_menu_ready
                          ? 'Need to register'
                          : status.is_menu_ready === 'PENDING'
                          ? 'Pending'
                          : status.is_menu_ready === 'REFUSED'
                          ? 'No need online order'
                          : 'COMPLETED'}
                      </p>
                      {status.is_menu_ready === 'PENDING' && (
                        <IonLabel className='vh150 bold ion-text-wrap' style={{ color: 'red' }}>
                          As soon as our input is complete, we will contact you via email.
                        </IonLabel>
                      )}
                    </IonLabel>
                  </IonItem>
                  <div className='ion-padding ion-text-start' slot='content' style={{ borderBottom: 'solid #999 0.5px' }}>
                    <h1 className='vh250'>If you are using POS system (Square)</h1>
                    <div className='vh200'>Import menus from POS means we can push orders to your POS system.</div>
                    <IonRow className='ion-align-items-center ion-margin-vertical'>
                      <div className='ion-float-left'>
                        <IonImg
                          onClick={() => _handleConnectSquare(user.restaurant_id ? user.restaurant_id : rId)}
                          className='ion-float-left'
                          src={'assets/images/Square_LogoLockup_Black.png'}
                          style={{ width: '7rem', borderRadius: '0.5em', marginRight: '2rem', backgroundColor: 'white' }}
                        />
                      </div>
                      <div className='ion-float-left'>
                        <IonLabel className='vh150'>Coming soon</IonLabel>
                        <IonImg src={'assets/images/toast-logo_color.png'} style={{ width: '4.5rem', borderRadius: '0.5em' }} alt='toast' />
                      </div>
                    </IonRow>
                    <div className='ion-padding-horizontal' style={{ borderBottom: '1px solid grey' }}></div>
                    <div>
                      <h1 className='vh250'>Import menus from your assets</h1>
                      <div className='vh200'>
                        Ask us to create menus from your website or menu pictures. You will check incoming orders on your device.
                      </div>
                      <h3 className='vh225 bold onoPink'>Please give us your online order URL below</h3>
                      <IonItem className='ion-margin-vertical' fill='outline'>
                        <IonLabel position='stack'>URL:</IonLabel>
                        <IonInput value={url} type='url' placeholder='https://your-restaurant-menu-page' onIonInput={(e) => setUrl(e.target.value)} />
                        <IonButton slot='end' disabled={url.length === 0} onClick={onSubmitUrl}>
                          Submit
                        </IonButton>
                      </IonItem>
                      <IonText style={{ fontSize: '0.9rem', marginTop: '2em', marginBottom: '2em' }}>
                        Alternatively, you can upload your menu via any file type, such as PDF, JPEG, or photo. Please ensure the images are not
                        blurry and contain item pricing.{' '}
                      </IonText>

                      {!menuImages.length ? (
                        <IonItem fill='outline' lines='none' className='ion-margin-vertical'>
                          <IonLabel>{menuImages.length} file</IonLabel>
                          {platform.includes('desktop') || platform.includes('mobileweb') ? (
                            <IonButton slot='end' onClick={pickFiles}>
                              Upload
                            </IonButton>
                          ) : (
                            <>
                              <IonButton id='popover-button' slot='end'>
                                Upload
                              </IonButton>
                              <IonPopover trigger='popover-button' dismissOnSelect={true}>
                                <IonContent>
                                  <IonList className='ion-no-padding'>
                                    <IonItem button={true} detail={false} onClick={takePicture}>
                                      Take photo
                                    </IonItem>
                                    <IonItem button={true} detail={false} onClick={pickImages}>
                                      Choose from library
                                    </IonItem>
                                    <IonItem button={true} detail={false} onClick={pickFiles}>
                                      Browse files
                                    </IonItem>
                                  </IonList>
                                </IonContent>
                              </IonPopover>
                            </>
                          )}
                        </IonItem>
                      ) : (
                        <>
                          {menuImages.map((image, index) => (
                            <IonItem key={index} fill='outline' lines='none' className='ion-margin-vertical'>
                              {image.type === 'IMAGE' ? (
                                <IonThumbnail slot='start'>
                                  <IonImg src={image.data} />
                                </IonThumbnail>
                              ) : null}
                              <IonLabel slot='start'>
                                <p cloudName='ion-text-wrap'>{image.name}</p>
                              </IonLabel>
                              <IonButton slot='end' onClick={() => deleteFile(index)}>
                                Delete
                              </IonButton>
                            </IonItem>
                          ))}
                          <div>
                            {platform.includes('desktop') || platform.includes('mobileweb') ? (
                              <IonButton slot='end' onClick={pickFiles}>
                                Add more files
                              </IonButton>
                            ) : (
                              <>
                                <IonButton id='popover-button2' slot='end'>
                                  Add more files
                                </IonButton>
                                <IonPopover trigger='popover-button2' dismissOnSelect={true}>
                                  <IonContent>
                                    <IonList className='ion-no-padding'>
                                      <IonItem button={true} detail={false} onClick={takePicture}>
                                        Take photo
                                      </IonItem>
                                      <IonItem button={true} detail={false} onClick={pickImages}>
                                        Choose from library
                                      </IonItem>
                                      <IonItem button={true} detail={false} onClick={pickFiles}>
                                        Browse files
                                      </IonItem>
                                    </IonList>
                                  </IonContent>
                                </IonPopover>
                              </>
                            )}

                            <IonButton
                              style={{ marginTop: '24px' }}
                              expand='block'
                              color='dark'
                              disabled={!menuImages.length}
                              onClick={onSubmitFiles}>
                              Submit file
                            </IonButton>
                          </div>
                        </>
                      )}
                    </div>
                    <IonButton className='ion-margin-top' fill='clear' onClick={refuseMenuSubmit}>
                      Use App without online order
                    </IonButton>
                  </div>
                </IonAccordion>

                <IonAccordion value='third' className='ion-margin-top' disabled={status.is_bank_ready === 'COMPLETED'}>
                  <IonItem slot='header' style={{ borderRadius: '10px' }}>
                    <IonLabel>
                      <h1 className='vh225'>3. Bank account</h1>
                      <p className='ion-text-wrap'>We use 'STRIPE' as our payment system.</p>
                      <p
                        style={{
                          color: status.is_bank_ready === 'COMPLETED' ? 'lightgreen' : '#FC0FC0',
                          fontFamily: 'BrandonBold',
                          fontSize: '1em',
                          marginTop: '1em',
                        }}>
                        {status.is_bank_ready}
                      </p>
                    </IonLabel>
                  </IonItem>
                  <div className='ion-padding' slot='content' style={{ borderBottom: 'solid #999 0.5px' }}>
                    <IonRow className='ion-align-items-center ion-justify-content-between'>
                      <IonButton style={{ fontFamily: 'BrandonBold' }} color='danger' onClick={() => history.push(`/onboarding/bank/${rId}`)}>
                        Set up bank account
                      </IonButton>
                      <IonButton
                        className='ion-margin-top'
                        style={{ fontFamily: 'BrandonBold' }}
                        fill='outline'
                        color='danger'
                        onClick={updateBankStatus}>
                        Use without online order now
                      </IonButton>
                    </IonRow>
                  </div>
                </IonAccordion>
              </IonAccordionGroup>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonModal id='completed-modal' cssClass='completed-modal' isOpen={showModal}>
          <IonContent fullscreen className='ion-padding'>
            <div style={{ height: '54px' }}>
              <Lottie animationData={welcomeAnimation} loop={true} />
            </div>
            <IonRow className='ion-align-items-center' style={{ height: '100%' }}>
              <IonCol className='ion-text-center'>
                <div>
                  <h1 className='test'>Thank you for completing tasks</h1>
                  <h1>Redirecting to your dashboard...</h1>
                  <IonSpinner style={{ marginTop: '4em' }} />
                </div>
              </IonCol>
            </IonRow>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};
export default OnboardingPage;
