import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonButton,
  IonCard,
  IonContent,
  IonHeader,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonTitle,
  IonIcon,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonChip,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonButtons,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  IonModal,
  IonImg,
  IonToggle,
  IonDatetime,
  IonList,
  IonThumbnail,
  IonSearchbar,
  IonText,
  IonTextarea,
  IonInput,
  IonLoading,
  IonCheckbox,
  IonAccordionGroup,
  IonAccordion,
  IonMenuButton,
  IonFooter,
  useIonAlert,
  IonAvatar,
  IonCardContent,
  IonPopover,
  getPlatforms,
} from '@ionic/react';
import Parse from 'parse/dist/parse.min.js';
import { Camera, CameraResultType } from '@capacitor/camera';
import { FilePicker } from '@capawesome/capacitor-file-picker';
//import * as momentTZ from 'momentTZ';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { closeOutline, notifications, receipt, restaurant, returnDownForwardSharp } from 'ionicons/icons';
import AppContext from '../../context/AppContext';
import MenuEditorModal from '../../components/MenuEditorModal';

import './HomePage.css';

const localTime = () => {
  return momentTZ().tz('Pacific/Honolulu').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
};

const HomePage = (props) => {
  const { deviceWidth, user, admin, isOnboarding, updateIsOnboarding } = useContext(AppContext);

  const [platform] = useState(getPlatforms());
  const [acceptOnlineOrder, setAcceptOnlineOrder] = useState(false);
  const [acceptTableOrder, setAcceptTableOrder] = useState(false);
  const [acceptDeliveryOrder, setAcceptDeliveryOrder] = useState(false);
  const [provider, setProvider] = useState('');
  const [onboardingStatus, setOnboardingStatus] = useState({ is_menu_ready: true, is_bank_ready: true });
  const [todaysBusinessHour, setTodaysBusinessHour] = useState([]);
  const [isFlashSale, setIsFlashSale] = useState(false);
  const [prepTime, setPrepTime] = useState('');
  const [flashSale, setFlashSale] = useState({
    title: 'Flash Sale',
    sale_start: '',
    sale_end: '',
    discount: { type: '%', amount: 20, min: 20, max: 10 },
    items: { type: 'ALL', ids: [] },
    target: 'ALL',
    status: 'NEW',
  });
  const [flashSaleSegment, setFlashSaleSegment] = useState('NOW');
  const [flashSaleCopy, setFlashSaleCopy] = useState({
    title: 'Flash Sale',
    sale_start: '',
    sale_end: '',
    discount: { type: '%', amount: 20, min: 20, max: 10 },
    items: { type: 'ALL', ids: [] },
    target: 'ALL',
  });
  const [flashSaleItemType, setFlashSaleItemType] = useState('ALL');
  const [showMenuModal, setShowMenuModal] = useState(false);
  const [flashSaleTimeChangeFlag, setFlashSaleTimeChangeFlag] = useState(false);
  const [newFlashSaleTime, setNewFlashSaleTime] = useState({ start_time: null, end_time: null });
  const [showFlashSaleSetting, setShowFlashSaleSetting] = useState(false);
  const [saleStart, setSaleStart] = useState({ day: '', time: '' });
  const [saleEnd, setSaleEnd] = useState({ day: '', time: '' });
  const [saleEndSelectOptions, setSaleEndSelectOptions] = useState([]);
  const [timeListFormatted, setTimeListFormatted] = useState([]);
  const [images, setImages] = useState([]);
  const [selected, setSelected] = useState('0');
  const [showPostModal, setShowPostModal] = useState(false);
  const [erasePostToggle, setErasePostToggle] = useState(false);
  const [relatedMenuToggle, setRelatedMenuToggle] = useState(false);
  const [addMenuToggle, setAddMenuToggle] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [menus, setMenus] = useState([]);
  const [modifierSets, setModifierSets] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [showDateTime, setShowDateTime] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showSelectItemModal, setShowSelectItemModal] = useState(false);
  const [postComment, setPostComment] = useState('');
  const [postType, setPostType] = useState('Announcement');
  const [posts, setPosts] = useState(null);
  const [showCouponSection, setShowCouponSection] = useState(false);
  const [showMenuEditorModal, setShowMenuEditorModal] = useState(false);
  const [showTimesaleSection, setShowTimesaleSection] = useState(false);
  const [sections, setSections] = useState([]);
  const [tags, setTags] = useState([]);
  const [showSaleStartDateTime, setShowSaleStartDateTime] = useState(false);
  const [showSaleEndDateTime, setShowSaleEndDateTime] = useState(false);
  const [menuUrl, setMenuUrl] = useState('');
  const [menuImages, setMenuImages] = useState([]);

  const [presentAlert] = useIonAlert();
  // const widgetApi = useRef();
  const dateTimeRef1 = useRef();
  const dateTimeRef2 = useRef();
  const accordionGroup = useRef(null);

  const history = useHistory();

  const rId = props.match.params.id;

  // useEffect(() => {
  //   console.log('Homepage rendered 1');
  //   console.log(platform);
  //   if (isOnboarding) {
  //     updateIsOnboarding(false);
  //   }
  //   if (getPlatforms().includes('desktop') || getPlatforms().includes('web')) {
  //     window.addEventListener('popstate', (event) => {
  //       const path = event.target.location.pathname;
  //       if (path.indexOf('launch') > 0) {
  //         event.preventDefault(); // stop request to server for new html
  //         event.stopPropagation();
  //         alert('Are you sure you want to leave?');
  //         history.go(1);
  //       }
  //     });
  //     return () => window.removeEventListener('popstate');
  //   }
  // }, []);

  useEffect(() => {
    console.log('Homepage rendered 2');
    let isMounted = true;
    const initHomePage = async () => {
      setShowLoading(true);
      await Parse.Cloud.run('initHomePage', {
        rId: rId ? rId : user.restaurant_id,
        day: momentTZ().tz('Pacific/Honolulu').day(),
        localTime: localTime(),
      })
        .then(async (result) => {
          if (result.onboardingStatus.is_onboarding) {
            setShowLoading(false);
            updateIsOnboarding(true);
            history.replace(`/onboarding/${rId ? rId : user.restaurant_id}`);
            return;
          }
          setShowLoading(false);
          setAcceptOnlineOrder(result.acceptOrder.online);
          setAcceptTableOrder(result.acceptOrder.table);
          setAcceptDeliveryOrder(result.acceptOrder.delivery);
          setOnboardingStatus(result.onboardingStatus);
          setTodaysBusinessHour(result.businessHour);
          setSections(result.sections);
          setProvider(result.provider);
          setMenus(result.menus);
          setIsFlashSale(result.isFlashSale);
          setPrepTime(result.prepTime);
          if (result.flashSale) {
            setFlashSale(result.flashSale);
            setFlashSaleCopy(result.flashSale);
            setFlashSaleItemType(result.flashSale.items.type);
            setShowFlashSaleSetting(true);
          }
        })
        .catch((error) => {
          setShowLoading(false);
          console.log(error);
        });
      setShowLoading(false);
    };
    const getRestaurant = async () => {
      await Parse.Cloud.run('getRestaurantAdmin', { rId: rId ? rId : user.restaurant_id }).then((result) => {
        if (!result) {
          alert('Please check internet connection');
        } else {
          // updateUser({ ...user, restaurant_id: rId ? rId : user.restaurant_id, restaurant_name: result.name });
          initHomePage();
        }
      });
    };
    initHomePage();
  }, []);

  const onSubmitUrl = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('updateWaitListUrl', { rId: rId ? rId : user.restaurant_id, url: menuUrl })
      .then(() => {
        setShowLoading(false);
        setOnboardingStatus({ ...onboardingStatus, is_menu_ready: 'PENDING' });
        if (!accordionGroup.current) {
          return;
        }
        const nativeEl = accordionGroup.current;
        if (nativeEl.value === 'second') {
          nativeEl.value = undefined;
        } else {
          nativeEl.value = 'second';
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const takePicture = async () => {
    const result = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.DataUrl,
    });
    const base64 = `${result.dataUrl}`;
    setMenuImages([...menuImages, { data: base64, type: 'IMAGE', name: '' }]);
  };

  const pickImages = async () => {
    const result = await FilePicker.pickImages({
      multiple: true,
      readData: true,
    });

    if (result.files.length === 1) {
      setShowLoading(true);
      const base64 = `data:${result.files[0].mimeType};base64,${result.files[0].data}`;
      setMenuImages([...menuImages, { data: base64, type: 'IMAGE', name: result.files[0].name }]);
    } else {
      setShowLoading(true);
      let data = [];
      for (let i = 0; i < result.files.length; i++) {
        if (result.files[i].mimeType.includes('video') || result.files[i].mimeType.includes('mp')) {
          alert('You can not upload video at this time');
        }
        const base64 = `data:${result.files[i].mimeType};base64,${result.files[i].data}`;
        data.push({ data: base64, type: 'IMAGE', name: result.files[i].name });
      }
      setMenuImages([...menuImages, ...data]);
    }
    setShowLoading(false);
  };

  const pickFiles = async () => {
    const result = await FilePicker.pickFiles({
      types: ['image/png', 'application/pdf', 'image/jpg', 'image/heic'],
      multiple: true,
      readData: true,
    });
    console.log(result);
    if (!result) {
      return false;
    }
    if (result.files.length === 1) {
      const base64 = `data:${result.files[0].mimeType};base64,${result.files[0].data}`;
      setMenuImages([
        ...menuImages,
        { data: base64, type: result.files[0].mimeType === 'application/pdf' ? 'PDF' : 'IMAGE', name: result.files[0].name },
      ]);
    } else {
      let fileData = [];
      for (let i = 0; i < result.files.length; i++) {
        const base64 = `data:${result.files[i].mimeType};base64,${result.files[i].data}`;
        fileData.push({ data: base64, type: result.files[i].mimeType === 'application/pdf' ? 'PDF' : 'IMAGE', name: result.files[i].name });
      }
      setMenuImages([...menuImages, ...fileData]);
    }
  };

  const deleteFile = (index) => {
    let prev = menuImages;
    if (prev.length === 1) {
      prev = [];
    } else {
      prev.splice(index, 1);
    }
    setMenuImages([...prev]);
  };

  const onSubmitFiles = async () => {
    setShowLoading(true);
    await Parse.Cloud.run('updateWaitListFiles', { rId: rId ? rId : user.restaurant_id, files: menuImages, user })
      .then(() => {
        setShowLoading(false);
        setOnboardingStatus({ ...onboardingStatus, is_menu_ready: 'PENDING' });
        if (!accordionGroup.current) {
          return;
        }
        const nativeEl = accordionGroup.current;
        if (nativeEl.value === 'second') {
          nativeEl.value = undefined;
        } else {
          nativeEl.value = 'second';
        }
      })
      .catch((error) => {
        setShowLoading(false);
        console.log(error);
      });
  };

  const _handleConnectSquare = async () => {
    await Parse.Cloud.run('getSquareEnvironmentVariable').then(async (result) => {
      // Production
      const SQUARE_CLIENT_ID = result;
      window.location.href = `https://connect.squareup.com/oauth2/authorize?client_id=${SQUARE_CLIENT_ID}&scope=ITEMS_READ+ITEMS_WRITE+INVENTORY_READ+ORDERS_WRITE+ORDERS_READ+MERCHANT_PROFILE_READ+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_WRITE+PAYMENTS_READ&session=true&state=${rId}__dashboard`;
      // Sandbox
      // const SQUARE_CLIENT_ID = 'sandbox-sq0idb--29oEjx4F2GZFl4j8bZwYg';
      // window.location = `https://connect.squareup.com/oauth2/authorize?client_id=${SQUARE_CLIENT_ID}&scope=ITEMS_READ+ITEMS_WRITE+INVENTORY_READ+ORDERS_WRITE+ORDERS_READ+MERCHANT_PROFILE_READ+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_WRITE+PAYMENTS_READ&session=true&state=${rId}&&dashboard`;
    });
  };

  const handleAcceptingOrder = async (e) => {
    setAcceptOnlineOrder(e.detail.checked);
    if (onboardingStatus.is_menu_ready === 'PENDING' && e.detail.checked) {
      alert('We are working on updating your menus. We will email you when it is ready');
      setAcceptOnlineOrder(false);
      return false;
    }
    if (onboardingStatus.is_menu_ready === 'REFUSED' && e.detail.checked) {
      alert('Please complete the menu registration steps.');
      setAcceptOnlineOrder(false);
      return false;
    }
    if (!onboardingStatus.is_bank_ready && e.detail.checked) {
      alert('Please setup bank before accepting order');
      setAcceptOnlineOrder(false);
      return false;
    }
    if (!e.detail.checked && flashSale?.status !== 'NEW') {
      alert('Flash sale will be canceled');
      setAcceptOnlineOrder(false);
      onCancelFlashSale();
    }
    await Parse.Cloud.run('updateAcceptOnlineOrder', { rId: rId ? rId : user.restaurant_id, val: e.detail.checked }).then((result) => {
      if (!result) alert(result);
    });
  };

  const handleAcceptingDeliveryOrder = async (e) => {
    setAcceptDeliveryOrder(e.detail.checked);
    if (onboardingStatus.is_menu_ready === 'PENDING' && e.detail.checked) {
      alert('We are working on updating your menus. We will email you when it is ready');
      setAcceptDeliveryOrder(false);
      return false;
    }
    if (onboardingStatus.is_menu_ready === 'REFUSED' && e.detail.checked) {
      alert('Please complete the menu registration steps.');
      setAcceptDeliveryOrder(false);
      return false;
    }
    if (!onboardingStatus.is_bank_ready && e.detail.checked) {
      alert('Please setup bank before accepting order');
      setAcceptDeliveryOrder(false);
      return false;
    }
    await Parse.Cloud.run('updateAcceptDeliveryOrder', { rId: rId ? rId : user.restaurant_id, val: e.detail.checked }).then((result) => {
      if (!result) alert(result);
    });
  };

  const handleAcceptingTableOrder = async (e) => {
    setAcceptTableOrder(e.detail.checked);
    if (onboardingStatus.is_menu_ready === 'PENDING' && e.detail.checked) {
      alert('We are working on updating your menus. We will email you when it is ready');
      setAcceptTableOrder(false);
      return false;
    }
    if (onboardingStatus.is_menu_ready === 'REFUSED' && e.detail.checked) {
      alert('Please complete the menu registration steps.');
      setAcceptTableOrder(false);
      return false;
    }
    if (!onboardingStatus.is_bank_ready && e.detail.checked) {
      alert('Please setup bank before accepting order');
      setAcceptTableOrder(false);
      return false;
    }
    setAcceptTableOrder(e.detail.checked);
    await Parse.Cloud.run('updateAcceptTableOrder', { rId: user.restaurant_id, val: e.detail.checked }).then((result) => {
      if (!result) alert(result);
    });
  };

  const handleUpdatePrepTime = async (val) => {
    setShowLoading(true);
    try {
      await Parse.Cloud.run('updatePrepTime', { rId: rId ? rId : user.restaurant_id, time: Number(val) });
      setShowLoading(false);
      setPrepTime(val);
    } catch (error) {
      setShowLoading(false);
      alert(error);
    }
  };

  const handleFlashSaleStatusChange = async (value) => {
    setIsFlashSale(value);
    await Parse.Cloud.run('updateIsFlashSale', { rId: user.restaurant_id, val: value }).then((result) => {
      if (!result) alert(result);
    });
  };

  const handleRelatedMenu = async (checked) => {
    if (checked) {
      setRelatedMenuToggle(!relatedMenuToggle);
      setShowSelectItemModal(true);
      await Parse.Cloud.run('getMenusHome', { id: user.restaurant_id }).then(async (results) => {
        if (results.length) setMenus([...results]);
      });
    } else {
      setRelatedMenuToggle(!relatedMenuToggle);
      setSelectedMenu(null);
    }
  };

  const handleSelectMenu = (data) => {
    setSelectedMenu(data);
    setShowSelectItemModal(false);
  };

  const onSavePost = async () => {
    setShowLoading(true);
    let eraseTime;
    if (erasePostToggle) {
      let date = selectedDate;
      let time = selectedTime;
      date = momentTZ(date).utc().format('YYYY-MM-DD');
      time = momentTZ(time).utc().format('HH:mm:ss');
      eraseTime = momentTZ(`${date}T${time}`).utc().valueOf();
    } else {
      eraseTime = null;
    }
    await Parse.Cloud.run('savePost', {
      restaurant_id: user.restaurant_id,
      restaurant_name: user.restaurant_name,
      restaurant_logo: user.restaurant_logo,
      author: `${user.first_name} ${user.last_name}`,
      linked_menu: selectedMenu ? selectedMenu.objectId : null,
      tags,
      type: postType,
      comment: postComment,
      images,
      time: eraseTime,
    })
      .then((result) => {
        setImages([]);
        setTags([]);
        setPostType('Announcement');
        setPostComment('');
        setSelectedMenu(null);
        updatePosts(result);
        setShowPostModal(false);
        setShowLoading(false);
      })
      .catch((error) => {
        setShowLoading(false);
        alert(error);
      });
  };

  const getModifiers = async () => {
    Parse.Cloud.run('getModifers', { restaurant_id: user.restaurant_id })
      .then((results) => {
        if (results.modifierSets.length) {
          setModifierSets(results.modifierSets);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleAddMenu = async (checked) => {
    if (checked && !sections.length) {
      setAddMenuToggle(true);
      await Parse.Cloud.run('getSection', { id: user.restaurant_id }).then(async (result) => {
        setSections(result);
        getModifiers();
        setShowMenuEditorModal(true);
      });
    } else if (checked && sections.length) {
      setAddMenuToggle(true);
      setShowMenuEditorModal(true);
    } else {
      setAddMenuToggle(false);
    }
  };

  const handleClose = () => {
    setAddMenuToggle(false);
    setShowMenuEditorModal(false);
  };

  const handleAfterAddedMenu = (data) => {
    setSelectedMenu(data);
    setShowMenuEditorModal(false);
  };

  const updatePosts = (data) => {
    let copy = posts;
    copy.unshift(data);
    setPosts([...copy]);
  };

  const handlePostType = (value) => {
    setPostType(value);
    if (value === 'Flash Sale') {
      setShowTimesaleSection(true);
    } else {
      setShowTimesaleSection(false);
      setSelectedDate(null);
      setSelectedTime(null);
    }
  };

  const handleSetSaleStartDate = (value) => {
    if (value !== 'Now') {
      let selectedDay = new Date(value + ' ' + saleStart.time);
      let endSelectOptions = [];
      if (momentTZ().tz('Pacific/Honolulu').format('YYYY-MM-DD') === value) {
        for (let i = 0; i < 7; i++) {
          endSelectOptions.push(momentTZ(selectedDay).utc().add(i, 'days').format('YYYY-MM-DD'));
        }
      } else {
        for (let i = 0; i < 7; i++) {
          if (i === 0) {
            endSelectOptions.push(momentTZ(selectedDay).utc().format('YYYY-MM-DD'));
          } else {
            endSelectOptions.push(momentTZ(selectedDay).utc().add(i, 'days').format('YYYY-MM-DD'));
          }
        }
      }
      setSaleEndSelectOptions(endSelectOptions);
      let copiedSaleStart = saleStart;
      setSaleStart({ ...saleStart, day: value, time: copiedSaleStart.time });
      setSaleEnd({ ...saleEnd, day: value, time: copiedSaleStart.time });
    } else {
      setSaleStart({ ...saleStart, day: 'Now', time: momentTZ(localTime()).utc().format('h:mm A') });
      //setSaleEnd({ ...saleEnd, day: '', time: '' });
    }
  };

  const handleSetSaleStartTime = (value) => {
    let copiedSaleStart = saleStart;
    setSaleStart({ ...saleStart, day: copiedSaleStart.day, time: value });
  };

  const handleSetSaleEndDate = (value) => {
    let copiedSaleEnd = saleEnd;
    setSaleEnd({ ...saleEnd, day: value, time: copiedSaleEnd.time });
  };

  const handleShowPopover = (boolean) => {
    setShowDateTime(boolean);
  };

  const handleSetSaleEndTime = (value) => {
    const start = new Date(saleStart.day + ' ' + saleStart.time);
    const end = new Date(saleEnd.day + ' ' + value);
    const isAfter = momentTZ(end).utc().isAfter(start);
    if (isAfter) {
      let copiedSaleEnd = saleEnd;
      setSaleEnd({ ...saleEnd, day: copiedSaleEnd.day, time: value });
    } else {
      return alert('End time have to be later than start time');
    }
  };

  const handleSaleTimeChange = async (newTimeObj) => {
    let newStartHour, newStartMin, newEndHour, newEndMin;
    newStartMin = newTimeObj.start_time.slice(-2);
    newEndMin = newTimeObj.end_time.slice(-2);
    if (newTimeObj.start_time.length === 3) {
      newStartHour = newTimeObj.start_time.substr(0, 1);
    } else {
      newStartHour = newTimeObj.start_time.substr(0, 2);
    }
    if (newTimeObj.end_time.length === 3) {
      newEndHour = newTimeObj.end_time.substr(0, 1);
    } else {
      newEndHour = newTimeObj.end_time.substr(0, 2);
    }
    const startTime = momentTZ()
      .utc()
      .set({ hour: Number(newStartHour), minute: Number(newStartMin), second: 0, millisecond: 0 })
      .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');

    const endTime = momentTZ()
      .set({ hour: Number(newEndHour), minute: Number(newEndMin), second: 0, millisecond: 0 })
      .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
    await Parse.Cloud.run('flashSaleTimeChange', { rId: rId ? rId : user.restaurant_id, startTime, endTime }).then((result) => {
      if (result) {
        setFlashSale(result);
        setFlashSaleTimeChangeFlag(!flashSaleTimeChangeFlag);
      } else {
        console.log(result);
      }
    });
  };

  const onPressSaleTimeChangeButton = () => {
    setFlashSaleTimeChangeFlag(!flashSaleTimeChangeFlag);
    setNewFlashSaleTime({ ...newFlashSaleTime, start_time: momentTZ().utc().format('HHMM') });
  };

  const TimeSelectComponent = () => (
    <IonGrid>
      <IonRow>
        <IonCol size='6'>
          <IonText>Sale start date & time</IonText>
        </IonCol>
        <IonCol size='6'>
          <IonText>Sale End date & time</IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size='6'>
          <IonSelect
            className='saleTimeSelect'
            interface='popover'
            value={saleStart.day}
            placeholder='Now'
            onIonInput={(e) => handleSetSaleStartDate(e.detail.value)}>
            <IonSelectOption value='Now'>Now</IonSelectOption>
            <IonSelectOption value={momentTZ().utc().format('YYYY-MM-DD')}>Today</IonSelectOption>
            <IonSelectOption value={momentTZ().utc().add(1, 'days').format('YYYY-MM-DD')}>Tomorrow</IonSelectOption>
            <IonSelectOption value={momentTZ().utc().add(2, 'days').format('YYYY-MM-DD')}>
              {momentTZ().utc().add(2, 'days').format('M/D/YYYY')}
            </IonSelectOption>
          </IonSelect>
          <IonSelect
            className='saleTimeSelect'
            interface='popover'
            value={saleStart.time}
            placeholder={saleStart.time}
            onIonInput={(e) => handleSetSaleStartTime(e.detail.value)}
            disabled={saleStart.day === 'Now'}>
            {timeListFormatted.map((time, index) => (
              <IonSelectOption key={index} value={time}>
                {time}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonCol>
        <IonCol size='6'>
          {saleStart.day === 'Now' ? null : (
            <IonSelect
              className='saleTimeSelect'
              interface='popover'
              position='bottom'
              value={saleEnd.day}
              placeholder='Now'
              onIonInput={(e) => handleSetSaleEndDate(e.detail.value)}>
              {saleEndSelectOptions.map((date, index) => (
                <IonSelectOption key={index} value={date}>
                  {date}
                </IonSelectOption>
              ))}
            </IonSelect>
          )}
          {saleStart.day === 'Now' ? null : (
            <IonSelect
              className='saleTimeSelect'
              interface='popover'
              position='bottom'
              value={saleEnd.time}
              placeholder={saleEnd.time}
              onIonInput={(e) => handleSetSaleEndTime(e.detail.value)}
              disabled={saleStart.day === 'Now'}>
              {timeListFormatted.map((time, index) => (
                <IonSelectOption key={index} value={time}>
                  {time}
                </IonSelectOption>
              ))}
            </IonSelect>
          )}
        </IonCol>
      </IonRow>
    </IonGrid>
  );

  const TodaysBusinessHourComponent = (props) => {
    const { businessHours, sectionArray } = props;
    const getTime = (str, idx, val) => {
      let ampm, time;
      let number = Number(str);
      if (!number) {
        ampm = 'AM';
        time = '000';
      } else if (0 < number < 1200) {
        ampm = 'AM';
        time = String(number);
      } else {
        ampm = 'PM';
        time = String(number - 1200);
      }

      let res = time.slice(0, idx) + val + str.slice(idx);
      return res + ' ' + ampm;
    };

    return (
      <IonRow className='ion-margin-top ion-justify-content-center'>
        {businessHours?.map((hour, index) => {
          return (
            <IonCol key={index} size='auto' className='ion-no-padding ion-margin-end ion-align-self-baseline'>
              <IonLabel>{getTime(hour.start, -2, ':')} - </IonLabel>
              <IonLabel className='ion-margin-end'>{getTime(hour.end, -2, ':')}</IonLabel>
              <IonLabel style={{ fontFamily: 'BrandonBold' }}>"{sectionArray[hour.section].title}" menu</IonLabel>
            </IonCol>
          );
        })}
      </IonRow>
    );
  };

  const handleChangeSaleObj = useCallback(
    (val) => {
      setFlashSaleSegment(val);
    },
    [flashSaleSegment]
  );

  const handleFlashSaleItemTypeChanged = (val) => {
    if (val === 'ALL') {
      setFlashSaleItemType('ALL');
    }
    if (flashSale.status === 'NEW') {
      setFlashSaleCopy((prev) => {
        return { ...prev, items: { ...prev.items, type: val, ids: [] } };
      });
      setShowMenuModal(true);
    }
    if (flashSale.items.type === 'CATEGORY' && val === 'ITEMS') {
      let menuObjArray = [];
      for (let i = 0; i < menus.length; i++) {
        menuObjArray = menuObjArray.concat(menus[i].menus);
      }
      const menuIdArray = menuObjArray.map((item) => item.objectId);
      setFlashSaleCopy((prev) => {
        return { ...prev, items: { ...prev.items, type: val, ids: menuIdArray } };
      });
      setShowMenuModal(true);
    }
    if (flashSale.items.type === 'CATEGORY' && val === 'CATEGORY') {
      const menuIdArray = menus.map((item) => item.objectId);
      setFlashSaleCopy((prev) => {
        return { ...prev, items: { ...prev.items, type: val, ids: menuIdArray } };
      });
      setShowMenuModal(true);
    }
    if (flashSale.items.type === 'ITEMS' && val === 'ITEMS') {
      const menuIdArray = menus.map((item) => item.objectId);
      setFlashSaleCopy((prev) => {
        return { ...prev, items: { ...prev.items, type: val, ids: menuIdArray } };
      });
      setShowMenuModal(true);
    }
    if (flashSale.items.type === 'ITEMS' && val === 'CATEGORY') {
      setFlashSaleCopy((prev) => {
        return { ...prev, items: { ...prev.items, type: val, ids: [] } };
      });
      setShowMenuModal(true);
    }
    if (flashSale.items.type === 'ALL' && val === 'CATEGORY') {
      const menuIdArray = menus.map((item) => item.objectId);
      setFlashSaleCopy((prev) => {
        return { ...prev, items: { ...prev.items, type: val, ids: menuIdArray } };
      });
      setShowMenuModal(true);
    }
    if (flashSale.items.type === 'ALL' && val === 'ITEMS') {
      let menuObjArray = [];
      for (let i = 0; i < menus.length; i++) {
        menuObjArray = menuObjArray.concat(menus[i].menus);
      }
      const menuIdArray = menuObjArray.map((item) => item.objectId);
      setFlashSaleCopy((prev) => {
        return { ...prev, items: { ...prev.items, type: val, ids: menuIdArray } };
      });
      setShowMenuModal(true);
    }
  };

  const handleCategoryCheckbox = (menuIndex, categoryId, checked) => {
    const exist = flashSaleCopy.items.ids.includes(categoryId);
    if (checked && !exist) {
      setFlashSaleCopy((prev) => {
        return { ...prev, items: { ...prev.items, ids: [...prev.items.ids, categoryId] } };
      });
    }
    if (!checked && exist) {
      setFlashSaleCopy((prev) => {
        const filter = prev.items.ids.filter((item) => {
          return item !== categoryId;
        });
        return { ...prev, items: { ...prev.items, ids: filter } };
      });
    }
  };

  const handleIndividualItemCheckbox = useCallback(
    (checked, menuId) => {
      if (checked) {
        setFlashSaleCopy((prev) => {
          return { ...prev, items: { ...prev.items, ids: [...prev.items.ids, menuId] } };
        });
      } else {
        setFlashSaleCopy((prev) => {
          const filter = prev.items.ids.filter((item) => {
            return item !== menuId;
          });
          return { ...prev, items: { ...prev.items, ids: filter } };
        });
      }
    },
    [flashSaleCopy?.items?.ids]
  );

  const onSubmitFlashSaleMenus = () => {
    setFlashSaleItemType(flashSaleCopy.items.type);
    setFlashSale((prev) => {
      return { ...prev, items: { ...prev.items, type: flashSaleCopy.items.type, ids: flashSaleCopy.items.ids } };
    });
    setShowMenuModal(false);
  };

  const submitFlashSale = async () => {
    setShowLoading(true);
    let data = flashSale;
    data.status = '';
    if (!data.sale_start) {
      data.sale_start = localTime();
    }

    if (!data.sale_end) {
      setShowLoading(false);
      alert('Please select sale end data and time');
      return;
    }
    if (data.discount.amount < 1) {
      setShowLoading(false);
      alert('Please select discount amount');
      return;
    }
    if (flashSaleItemType === 'ALL') {
      data.items = { type: 'ALL', ids: [] };
      await Parse.Cloud.run('handleUpdateFlashSale', { rId: rId ? rId : user.restaurant_id, data: data }).then((result) => {
        data.status = 'EDITED';
        setFlashSale(data);
        setShowLoading(false);
      });
    } else {
      await Parse.Cloud.run('handleUpdateFlashSale', { rId: rId ? rId : user.restaurant_id, data: flashSale }).then((result) => {
        data.status = 'EDITED';
        setFlashSale(data);
        setShowLoading(false);
      });
    }
  };

  const onCancelFlashSale = async () => {
    setShowLoading(true);
    setFlashSale({
      title: 'Flash Sale',
      sale_start: '',
      sale_end: '',
      discount: { type: '%', amount: 10, min: 20, max: 10 },
      items: { type: 'ALL', ids: [] },
      target: 'ALL',
      status: 'NEW',
    });
    setShowFlashSaleSetting(false);
    await Parse.Cloud.run('handleUpdateFlashSale', { rId: rId ? rId : user.restaurant_id, data: null }).then((result) => {
      setShowLoading(false);
    });
  };

  const FlashSaleMenuModal = (props) => {
    const { type } = props;
    return (
      <>
        {type === 'ITEMS'
          ? menus.map((category, index) => {
              return (
                <React.Fragment key={index}>
                  <IonItem className='ion-no-padding'>
                    <IonCheckbox
                      slot='start'
                      checked={flashSaleCopy?.items.ids.includes(category.objectId)}
                      onIonInput={(e) => handleCategoryCheckbox(index, category.objectId, e.target.checked)}
                    />
                    <IonLabel>{category.name}</IonLabel>
                  </IonItem>
                  {category.menus.map((menu, idx) => {
                    return (
                      <IonItem key={menu.objectId}>
                        <IonCheckbox
                          slot='start'
                          disabled={flashSaleCopy?.items.type === 'CATEGORY' ? true : false}
                          checked={flashSaleCopy?.items.ids.includes(menu.objectId)}
                          value={menu.objectId}
                          onIonInput={(e) => handleIndividualItemCheckbox(e.target.checked, e.target.value)}
                        />
                        <IonLabel>{menu.name}</IonLabel>
                      </IonItem>
                    );
                  })}
                </React.Fragment>
              );
            })
          : menus.map((category, index) => {
              return (
                <IonAccordionGroup key={index}>
                  <IonAccordion value='first'>
                    <IonItem slot='header' className='ion-no-padding'>
                      <IonCheckbox
                        slot='start'
                        checked={flashSaleCopy?.items.ids.includes(category.objectId)}
                        onIonInput={(e) => handleCategoryCheckbox(index, category.objectId, e.target.checked)}
                      />
                      <IonLabel>{category.name}</IonLabel>
                      <IonLabel>{category.menus.length} item</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                      {category.menus.map((menu, idx) => {
                        return (
                          <IonItem key={menu.objectId}>
                            <IonCheckbox
                              slot='start'
                              disabled={flashSaleCopy?.items.type === 'CATEGORY' ? true : false}
                              checked={flashSaleCopy?.items.ids.includes(category.objectId)}
                            />
                            <IonLabel>{menu.name}</IonLabel>
                          </IonItem>
                        );
                      })}
                    </div>
                  </IonAccordion>
                </IonAccordionGroup>
              );
            })}
      </>
    );
  };

  const fixLocalTime = (datetime) => {
    const dt = momentTZ(datetime).tz('Pacific/Honolulu');
    const offset = dt._tzm;
    //const offset = dt._offset;
    const hour = offset / 60;
    if (hour >= 0) {
      return moment(dt).add(hour, 'hours').toISOString();
    } else {
      return moment(dt).subtract(hour, 'hours').toISOString();
    }

    // } else {
    //   utc = new Date(dt.getTime() + dt.getTimezoneOffset() * 60000);
    // }
    // const iso = utc.toISOString();
  };

  const linkToApp = async () => {
    if (!platform.includes('desktop')) {
      window.open(`onoonline://search?rId=${rId}`);

      // window.open(`exp://wg-qka.community.app.exp.direct:80/restaurant?rid=${user.restaurant_id ? user.restaurant_id : rId}`);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonRow>
          <IonCol size-xs='12' size-sm='10' size-md='12' size-lg='10' offset-lg='1'>
            <IonToolbar>
              {/* <IonButtons slot='start'>
                <IonMenuButton id='menu' />
              </IonButtons> */}
              {/* <IonTitle style={{ fontSize: '1.25em', fontFamily: 'BrandonBold' }}>Home</IonTitle> */}
              <IonButtons slot='end'>
                <IonButton>
                  <IonIcon slot='icon-only' icon={receipt} />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ fontFamily: 'BrandonBold', fontSize: '1rem' }}>{user.restaurant_name ? user.restaurant_name : admin.rName}</IonTitle>
              <IonButtons slot='end'>
                <IonButton>
                  {user.role === 'ADMIN' && <IonButton onClick={() => history.replace(`/admin/${user.id}`)}>Next</IonButton>}
                  <IonIcon slot='icon-only' icon={notifications} size='small' />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonCol>
        </IonRow>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='12' size-md='12' size-lg='10' offset-lg='1'>
              {/* <IonLabel style={{ fontFamily: 'BrandonBold', fontSize: '0.9em' }}>{today}</IonLabel> */}
              {/* <IonButton
                onClick={() => {
                  Parse.Cloud.run('makeOrderConfirmationPhoneCall', { number: '8088001343' });
                }}>
                Create order types test
              </IonButton> */}
              <section>
                <IonRow>
                  {user.restaurant_logo ? (
                    <IonCol size='auto' className='ion-padding-end'>
                      <div className='ion-text-center'>
                        <IonAvatar style={{ width: '10vh', height: '10vh', marginBottom: '3em', margin: 'auto' }}>
                          <img src={user.restaurant_logo} alt='logo' />
                        </IonAvatar>
                      </div>
                    </IonCol>
                  ) : null}
                  <IonCol size='auto'>
                    <div style={{ alignItems: 'center' }}>
                      <IonLabel style={{ fontFamily: 'BrandonBold' }}>{`${momentTZ(localTime()).utc().format('llll')}`}</IonLabel>
                      <div className='ion-text-center'>
                        {!todaysBusinessHour.length ? (
                          <IonLabel>Close today</IonLabel>
                        ) : !sections.length ? null : (
                          <TodaysBusinessHourComponent businessHours={todaysBusinessHour} sectionArray={sections} />
                        )}
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
              </section>

              <section style={{ display: 'flex' }}>
                {provider === 'SQUARE' ? (
                  <div className='ion-margin-top ion-text-center'>
                    <div style={{ fontFamily: 'BrandonBold' }}>POS integration</div>
                    <IonImg
                      src={'assets/images/Square_LogoLockup_Black.png'}
                      style={{ margin: 'auto', marginTop: '1em', width: '7rem', borderRadius: '0.5em', backgroundColor: 'white' }}
                      alt='square'
                    />
                  </div>
                ) : null}
              </section>
              {platform.includes('desktop') ? null : (
                <section style={{ textAlign: 'center', margin: '2em 0' }}>
                  <IonButton style={{ fontFamily: 'BrandonBold' }} onClick={linkToApp}>
                    Check your page on ono online app
                  </IonButton>
                </section>
              )}
              {!onboardingStatus.is_menu_ready ||
              onboardingStatus.is_menu_ready === 'PENDING' ||
              onboardingStatus.is_menu_ready === 'REFUSED' ||
              !onboardingStatus.is_bank_ready ||
              onboardingStatus.is_bank_ready === 'PENDING' ? (
                <section style={{ margin: '2em 0' }}>
                  <div className='bold vh275 ion-text-center'>The process of accepting online orders.</div>
                  <IonCard style={{ margin: '1em 0 0', border: '1px solid lightblue' }}>
                    <IonCardContent>
                      <IonAccordionGroup ref={accordionGroup}>
                        <IonAccordion value='second'>
                          <IonItem slot='header'>
                            <IonLabel>
                              <h1 className='vh275'>Create food menus</h1>
                              <p className='ion-text-wrap'>
                                You can import menu data from your POS system or upload menu pictures. We will take care of rest.
                              </p>
                              {onboardingStatus?.is_menu_ready === 'PENDING' ? (
                                <>
                                  <p
                                    style={{
                                      color: !onboardingStatus?.is_menu_ready ? '#FC0FC0' : 'red',
                                      fontFamily: 'BrandonBold',
                                      fontSize: '1em',
                                      marginTop: '1em',
                                    }}>
                                    Status: Pending
                                  </p>

                                  <IonLabel className='vh200 ion-text-wrap'>
                                    As soon as our input is complete, we will contact you via email.
                                  </IonLabel>
                                </>
                              ) : null}
                            </IonLabel>
                          </IonItem>
                          <div className='ion-padding ion-text-start' slot='content' style={{ borderBottom: 'solid #999 0.5px' }}>
                            <h1 className='vh250'>1. If you are using POS system (Square)</h1>
                            <div className='vh200'>Import menus from POS means we can push orders to your POS system.</div>
                            <IonRow className='ion-align-items-center ion-margin-vertical'>
                              <div className='ion-float-left'>
                                <IonImg
                                  onClick={() => _handleConnectSquare(user.restaurant_id ? user.restaurant_id : rId)}
                                  className='ion-float-left'
                                  src={'assets/images/Square_LogoLockup_Black.png'}
                                  style={{ width: '7rem', borderRadius: '0.5em', marginRight: '2rem', backgroundColor: 'white' }}
                                />
                              </div>
                              <div className='ion-float-left'>
                                <IonLabel className='vh150'>Coming soon</IonLabel>
                                <IonImg src={'assets/images/toast-logo_color.png'} style={{ width: '4.5rem', borderRadius: '0.5em' }} alt='toast' />
                              </div>
                            </IonRow>
                            <div className='ion-padding-horizontal' style={{ borderBottom: '1px solid grey' }}></div>
                            <div style={{ marginTop: '2em' }}>
                              <h1 className='vh250'>2. Import menus from your assets</h1>
                              <div className='vh200'>
                                Ask us to create menus from your website or menu pictures. You will check incoming orders on your device.
                              </div>
                              <h3 className='vh225 bold onoPink'>Please give us your online order URL below</h3>
                              <IonItem className='ion-margin-vertical' fill='outline'>
                                <IonLabel position='stack'>URL:</IonLabel>
                                <IonInput
                                  value={menuUrl}
                                  type='url'
                                  placeholder='https://your-restaurant-menu-page'
                                  onIonInput={(e) => setMenuUrl(e.target.value)}
                                />
                                <IonButton slot='end' disabled={menuUrl.length === 0} onClick={onSubmitUrl}>
                                  Submit
                                </IonButton>
                              </IonItem>
                              <IonText style={{ fontSize: '0.9rem', marginTop: '2em', marginBottom: '2em' }}>
                                Alternatively, you can upload your menu via any file type, such as PDF, JPEG, or photo. Please ensure the images are
                                not blurry and contain item pricing.{' '}
                              </IonText>

                              {!menuImages.length ? (
                                <IonItem fill='outline' lines='none' className='ion-margin-vertical'>
                                  <IonLabel>{menuImages.length} file</IonLabel>
                                  {platform.includes('desktop') || platform.includes('mobileweb') ? (
                                    <IonButton slot='end' onClick={pickFiles}>
                                      Upload
                                    </IonButton>
                                  ) : (
                                    <>
                                      <IonButton id='popover-button' slot='end'>
                                        Upload
                                      </IonButton>
                                      <IonPopover trigger='popover-button' dismissOnSelect={true}>
                                        <IonContent>
                                          <IonList className='ion-no-padding'>
                                            <IonItem button={true} detail={false} onClick={takePicture}>
                                              Take photo
                                            </IonItem>
                                            <IonItem button={true} detail={false} onClick={pickImages}>
                                              Choose from library
                                            </IonItem>
                                            <IonItem button={true} detail={false} onClick={pickFiles}>
                                              Browse files
                                            </IonItem>
                                          </IonList>
                                        </IonContent>
                                      </IonPopover>
                                    </>
                                  )}
                                </IonItem>
                              ) : (
                                <>
                                  {menuImages.map((image, index) => (
                                    <IonItem key={index} fill='outline' lines='none' className='ion-margin-vertical'>
                                      {image.type === 'IMAGE' ? (
                                        <IonThumbnail slot='start'>
                                          <IonImg src={image.data} />
                                        </IonThumbnail>
                                      ) : null}
                                      <IonLabel slot='start'>
                                        <p cloudName='ion-text-wrap'>{image.name}</p>
                                      </IonLabel>
                                      <IonButton slot='end' onClick={() => deleteFile(index)}>
                                        Delete
                                      </IonButton>
                                    </IonItem>
                                  ))}
                                  <div>
                                    {platform.includes('desktop') || platform.includes('mobileweb') ? (
                                      <IonButton slot='end' onClick={pickFiles}>
                                        Add more files
                                      </IonButton>
                                    ) : (
                                      <>
                                        <IonButton id='popover-button2' slot='end'>
                                          Add more files
                                        </IonButton>
                                        <IonPopover trigger='popover-button2' dismissOnSelect={true}>
                                          <IonContent>
                                            <IonList className='ion-no-padding'>
                                              <IonItem button={true} detail={false} onClick={takePicture}>
                                                Take photo
                                              </IonItem>
                                              <IonItem button={true} detail={false} onClick={pickImages}>
                                                Choose from library
                                              </IonItem>
                                              <IonItem button={true} detail={false} onClick={pickFiles}>
                                                Browse files
                                              </IonItem>
                                            </IonList>
                                          </IonContent>
                                        </IonPopover>
                                      </>
                                    )}
                                  </div>
                                </>
                              )}
                              <IonButton
                                style={{ marginTop: '3em' }}
                                expand='block'
                                color='dark'
                                disabled={!menuImages.length}
                                onClick={onSubmitFiles}>
                                Submit file
                              </IonButton>
                            </div>
                          </div>
                        </IonAccordion>
                        {!onboardingStatus.is_bank_ready ||
                        onboardingStatus.is_bank_ready === 'PENDING' ||
                        onboardingStatus.is_bank_ready === 'REFUSED' ? (
                          <IonAccordion value='third' className='ion-margin-top' disabled={onboardingStatus.is_bank_ready}>
                            <IonItem slot='header' className='ion-no-padding' style={{ borderRadius: '10px' }}>
                              <IonLabel>
                                <h1 className='vh250'>Bank account</h1>
                                <p className='ion-text-wrap'>We use 'STRIPE' as our payment system.</p>
                                <p
                                  style={{
                                    color: onboardingStatus.is_bank_ready ? 'lightgreen' : '#FC0FC0',
                                    fontFamily: 'BrandonBold',
                                    fontSize: '0.7rem',
                                  }}>
                                  {onboardingStatus.is_bank_ready ? 'Completed' : 'Need to setup'}
                                </p>
                              </IonLabel>
                            </IonItem>
                            <div className='ion-padding' slot='content'>
                              <IonRow className='ion-align-items-center ion-justify-content-between'>
                                <IonButton
                                  style={{ fontFamily: 'BrandonBold' }}
                                  color='danger'
                                  onClick={() => {
                                    console.log(user.role);
                                    user.role === 'ADMIN'
                                      ? history.push(`/admin/${user.id}/account/bank/${rId}`)
                                      : history.push(`/dashboard/account/bank/${rId}`);
                                  }}>
                                  Set up bank account
                                </IonButton>
                              </IonRow>
                            </div>
                          </IonAccordion>
                        ) : null}
                      </IonAccordionGroup>
                    </IonCardContent>
                  </IonCard>
                </section>
              ) : null}
              <>
                <IonRow>
                  <IonCol size={platform.includes('desktop') ? '5.75' : '12'} className='ion-no-padding'>
                    <IonCard className='ion-padding ion-no-margin' style={{ marginTop: '1em', border: '1px solid lightgreen', borderRadius: '10px' }}>
                      <IonItem className='ion-no-padding' lines='none'>
                        <IonLabel>
                          <h1 className='bold'>Online order</h1>
                          <p className='ion-text-wrap' style={{ marginTop: '8px' }}>
                            Set your online order status
                          </p>
                        </IonLabel>
                      </IonItem>
                      <IonItem className='ion-no-padding' lines='full' style={{ height: '60px' }}>
                        <IonLabel style={{ fontSize: '2.5vh', fontFamily: 'BrandonBold' }}>Pickup Orders</IonLabel>
                        <IonToggle mode='ios' checked={acceptOnlineOrder} value={acceptOnlineOrder} onIonInput={(e) => handleAcceptingOrder(e)} />
                      </IonItem>
                      <IonItem className='ion-no-padding' lines='full' style={{ height: '60px' }}>
                        <IonLabel style={{ fontSize: '2.5vh', fontFamily: 'BrandonBold' }}>Delivery Orders</IonLabel>
                        <IonToggle
                          mode='ios'
                          checked={acceptDeliveryOrder}
                          value={acceptDeliveryOrder}
                          onIonInput={(e) => handleAcceptingDeliveryOrder(e)}
                        />
                      </IonItem>
                      <IonItem className='ion-no-padding' lines='full' style={{ height: '60px' }}>
                        <IonLabel style={{ fontSize: '2.5vh', fontFamily: 'BrandonBold' }}>Table Orders</IonLabel>
                        <IonToggle mode='ios' checked={acceptTableOrder} value={acceptTableOrder} onIonInput={(e) => handleAcceptingTableOrder(e)} />
                      </IonItem>
                    </IonCard>
                  </IonCol>
                  <IonCol
                    className='ion-no-padding'
                    size={platform.includes('desktop') ? '5.75' : '12'}
                    offsetLg='0.5'
                    style={{ marginTop: platform.includes('desktop') ? '0' : '12px' }}>
                    <IonCard className='ion-padding ion-no-margin' style={{ marginTop: '1em', border: '1px solid lightgreen', borderRadius: '10px' }}>
                      <IonItem className='ion-no-padding' lines='none'>
                        <IonLabel>
                          <h1 className='bold'>Prep time</h1>
                          <p className='ion-text-wrap' style={{ marginTop: '8px' }}>
                            Set preparation time for ASAP order
                          </p>
                        </IonLabel>
                      </IonItem>
                      <div>
                        <IonRadioGroup mode='md' value={String(prepTime)} onIonInput={(e) => handleUpdatePrepTime(String(e.detail.value))}>
                          <IonChip className='ion-no-padding ion-margin-end' disabled={!acceptOnlineOrder} outline style={{ border: 0 }}>
                            <IonRadio mode='md' value='10' size='small' style={{ marginRight: '5px' }} />
                            <IonLabel style={{ fontSize: '1.2em', fontFamily: 'Brandon' }}>10 min</IonLabel>
                          </IonChip>
                          <IonChip className='ion-no-padding ion-margin-end' disabled={!acceptOnlineOrder} outline style={{ border: 0 }}>
                            <IonRadio mode='md' value='20' style={{ marginRight: '5px' }} />
                            <IonLabel style={{ fontSize: '1.2em', fontFamily: 'Brandon' }}>20 min</IonLabel>
                          </IonChip>
                          <IonChip className='ion-no-padding ion-margin-end' disabled={!acceptOnlineOrder} outline style={{ border: 0 }}>
                            <IonRadio mode='md' value='30' style={{ marginRight: '5px' }} />
                            <IonLabel style={{ fontSize: '1.2em', fontFamily: 'Brandon' }}>30 min</IonLabel>
                          </IonChip>
                          <IonChip className='ion-no-padding ion-margin-end' disabled={!acceptOnlineOrder} outline style={{ border: 0 }}>
                            <IonRadio mode='md' value='40' style={{ marginRight: '5px' }} />
                            <IonLabel style={{ fontSize: '1.2em', fontFamily: 'Brandon' }}>40 min</IonLabel>
                          </IonChip>
                          <IonChip className='ion-no-padding  ion-margin-end' disabled={!acceptOnlineOrder} outline style={{ border: 0 }}>
                            <IonRadio mode='md' value='50' style={{ marginRight: '5px' }} />
                            <IonLabel style={{ fontSize: '1.2em', fontFamily: 'Brandon' }}>50 min</IonLabel>
                          </IonChip>
                          <IonChip className='ion-no-padding ion-margin-end' disabled={!acceptOnlineOrder} outline style={{ border: 0 }}>
                            <IonRadio mode='md' value='60' style={{ marginRight: '5px' }} />
                            <IonLabel style={{ fontSize: '1.2em', fontFamily: 'Brandon' }}>60 min</IonLabel>
                          </IonChip>
                        </IonRadioGroup>
                      </div>
                    </IonCard>
                  </IonCol>
                </IonRow>

                <IonCard
                  className='ion-padding'
                  style={{ marginLeft: '0', marginRight: '0', marginTop: '2em', border: '1px solid lightgreen', borderRadius: '10px' }}>
                  <IonItem className='ion-no-padding' lines='none'>
                    <IonLabel>
                      <h1 className='bold'>Review Reward</h1>
                      <p className='ion-text-wrap' style={{ marginTop: '8px' }}>
                        Only purchased customer can write a review for your restaurant
                      </p>
                      <p className='ion-text-wrap'>We always give customer 1% if they post a review.</p>
                    </IonLabel>
                  </IonItem>
                  <IonItem className='ion-no-padding' disabled={!acceptOnlineOrder}>
                    <IonRadioGroup scrollable mode='ios' value={selected} onIonInput={(e) => setSelected(e.detail.value)}>
                      <IonChip className='ion-no-padding ion-margin-end' outline style={{ border: 0 }}>
                        <IonRadio mode='md' value='0' style={{ marginRight: '5px' }} />
                        <IonLabel style={{ fontSize: '1.2em', fontFamily: 'BrandonBold' }}>None</IonLabel>
                      </IonChip>
                      <IonChip className='ion-no-padding ion-margin-end' outline style={{ border: 0 }}>
                        <IonRadio mode='md' value='1' style={{ marginRight: '5px' }} />
                        <IonLabel style={{ fontSize: '1.2em', fontFamily: 'BrandonBold' }}>1 %</IonLabel>
                      </IonChip>
                      <IonChip className='ion-no-padding ion-margin-end' outline style={{ border: 0 }}>
                        <IonRadio mode='md' value='3' style={{ marginRight: '5px' }} />
                        <IonLabel style={{ fontSize: '1.2em', fontFamily: 'BrandonBold' }}>3 %</IonLabel>
                      </IonChip>
                      <IonChip className='ion-no-padding ion-margin-end' outline style={{ border: 0 }}>
                        <IonRadio mode='md' value='5' style={{ marginRight: '5px' }} />
                        <IonLabel style={{ fontSize: '1.2em', fontFamily: 'BrandonBold' }}>5 %</IonLabel>
                      </IonChip>
                      <IonChip className='ion-no-padding ion-margin-end' outline style={{ border: 0 }}>
                        <IonRadio mode='md' value='10' style={{ marginRight: '5px' }} />
                        <IonLabel style={{ fontSize: '1.2em', fontFamily: 'BrandonBold' }}>10 %</IonLabel>
                      </IonChip>
                      <IonChip className='ion-no-padding ion-margin-end' outline style={{ border: 0 }}>
                        <IonRadio mode='md' value='other' style={{ marginRight: '5px' }} />
                        <IonLabel style={{ fontSize: '1.2em', fontFamily: 'BrandonBold' }}>Other</IonLabel>
                      </IonChip>
                    </IonRadioGroup>
                  </IonItem>
                </IonCard>
                {/* <IonCard className='ion-padding' style={{ marginLeft: '0', marginRight: '0', marginTop: '2em', border: '1px solid lightgreen' }}>
                  {flashSale.status === 'NEW' ? (
                    <IonItem className='ion-no-padding' lines='none'>
                      <IonLabel style={{ fontSize: '1.25em', fontFamily: 'BrandonBold' }}>Schedule Flash Sale</IonLabel>
                      <IonToggle
                        mode='ios'
                        disabled={!acceptOnlineOrder}
                        checked={showFlashSaleSetting}
                        onClick={() => setShowFlashSaleSetting(!showFlashSaleSetting)}
                      />
                    </IonItem>
                  ) : (
                    <IonItem className='ion-no-padding' lines='none'>
                      <IonLabel style={{ fontSize: '1.25em', fontFamily: 'BrandonBold' }}>Edit Flash Sale</IonLabel>
                    </IonItem>
                  )}

                  {!showFlashSaleSetting ? null : (
                    <>
                      <div style={{ border: '0.3px solid #FC0FC0', margin: '1em 0' }} />
                      {flashSale.status === 'NEW' ? (
                        <IonSegment
                          style={{ marginTop: '1em' }}
                          value={flashSaleSegment}
                          mode='ios'
                          onIonInput={(e) => handleChangeSaleObj(e.detail.value)}>
                          <IonSegmentButton value='NOW'>
                            <IonLabel>Start Now</IonLabel>
                          </IonSegmentButton>
                          <IonSegmentButton value='TODAY'>
                            <IonLabel>Today</IonLabel>
                          </IonSegmentButton>
                          <IonSegmentButton value='OTHER'>
                            <IonLabel>Other</IonLabel>
                          </IonSegmentButton>
                        </IonSegment>
                      ) : (
                        <>
                          <IonItem className='ion-no-padding' lines='none' color='light'>
                            <IonLabel className='ion-padding-start' style={{ fontSize: '1.25em', fontFamily: 'BrandonBold' }}>
                              Cancel
                            </IonLabel>
                            <IonButton
                              slot='end'
                              mode='ios'
                              onClick={() =>
                                presentAlert({
                                  header: 'Alert!',
                                  subHeader: 'Are you sure you want to cancel flash sale?',
                                  buttons: [
                                    {
                                      text: 'Cancel',
                                      role: 'cancel',
                                    },
                                    {
                                      text: 'OK',
                                      role: 'confirm',
                                      handler: onCancelFlashSale,
                                    },
                                  ],
                                  // onDidDismiss: (e) => setRoleMessage(`Dismissed with role: ${e.detail.role}`),
                                })
                              }>
                              Cancel
                            </IonButton>
                          </IonItem>
                        </>
                      )}

                      <IonRow className='ion-margin-top'>
                        <div style={{ width: '100%' }}>
                          <h5 style={{ fontFamily: 'BrandonBold' }}>Sale start time</h5>
                          <IonChip onClick={() => setShowSaleStartDateTime(true)}>
                            {flashSale?.sale_start
                              ? momentTZ(flashSale.sale_start).tz('Pacific/Honolulu').utc().format('ddd M/DD h:mm A')
                              : moment(localTime()).utc().format('ddd M/D h:mm A')}
                          </IonChip>
                          {!showSaleStartDateTime ? null : (
                            <IonDatetime
                              ref={dateTimeRef1}
                              minuteValues='0,15,30,45'
                              hourCycle='h12'
                              value={
                                flashSale?.sale_start ? moment(flashSale.sale_start).utc().toISOString() : moment(localTime()).utc().toISOString()
                              }
                              min={moment(localTime()).utc().toISOString()}
                              onIonInput={(e) => {
                                setFlashSale({ ...flashSale, sale_start: fixLocalTime(e.target.value) });
                              }}
                              presentation={'date-time'}
                              preferWheel={false}
                              showDefaultButtons={true}>
                              <IonButtons slot='buttons'>
                                <IonButton
                                  color='primary'
                                  onClick={() => {
                                    dateTimeRef1.current?.cancel();
                                    setShowSaleStartDateTime(false);
                                  }}>
                                  Cancel
                                </IonButton>
                                <IonButton
                                  color='primary'
                                  onClick={(e) => {
                                    dateTimeRef1.current?.confirm();
                                    setShowSaleStartDateTime(false);
                                  }}>
                                  All Set
                                </IonButton>
                              </IonButtons>
                            </IonDatetime>
                          )}
                           <IonDatetimeButton className='ion-float-start' datetime='datetime1' presentation={'date-time'} /> 
                        </div>
                        <div className='ion-margin-top' style={{ width: '100%' }}>
                          <h5 style={{ fontFamily: 'BrandonBold' }}>Sale end time</h5>
                          <IonChip onClick={() => setShowSaleEndDateTime(true)}>
                            {flashSale.sale_start && flashSale.sale_end
                              ? momentTZ(flashSale.sale_end).tz('Pacific/Honolulu').utc().format('ddd M/DD h:mm A')
                              : flashSale.sale_start && !flashSale.sale_end
                              ? momentTZ(flashSale.sale_start).tz('Pacific/Honolulu').utc().add(1, 'hour').format('ddd M/DD h:mm A')
                              : moment(localTime()).add(1, 'hours').utc().format('ddd M/D h:mm A')}
                          </IonChip>
                          <IonDatetimeButton className='ion-float-start' datetime='datetime2' /> 
                        </div>
                        {!showSaleEndDateTime ? null : (
                          <IonDatetime
                            ref={dateTimeRef2}
                            minuteValues='0,15,30,45'
                            hourCycle='h12'
                            value={
                              flashSale.sale_start && flashSale.sale_end
                                ? moment(flashSale.sale_end).utc().toISOString()
                                : flashSale.sale_start && !flashSale.sale_end
                                ? moment(flashSale.sale_start).utc().add(1, 'hour').toISOString()
                                : moment(localTime()).add(1, 'hours').utc().toISOString()
                            }
                            min={
                              flashSale.sale_start && flashSale.sale_end
                                ? moment(flashSale.sale_end).utc().toISOString()
                                : flashSale.sale_start && !flashSale.sale_end
                                ? moment(flashSale.sale_start).utc().add(1, 'hour').toISOString()
                                : moment(localTime()).add(1, 'hours').utc().toISOString()
                            }
                            onIonInput={(e) => {
                              setFlashSale({ ...flashSale, sale_end: fixLocalTime(e.target.value) });
                            }}
                            presentation={'date-time'}
                            preferWheel={false}
                            showDefaultButtons={true}>
                            <IonButtons slot='buttons'>
                              <IonButton
                                color='primary'
                                onClick={() => {
                                  dateTimeRef2.current?.cancel();
                                  setShowSaleEndDateTime(false);
                                }}>
                                Cancel
                              </IonButton>
                              <IonButton
                                color='primary'
                                onClick={() => {
                                  dateTimeRef2.current?.confirm();
                                  setShowSaleEndDateTime(false);
                                }}>
                                All Set
                              </IonButton>
                            </IonButtons>
                          </IonDatetime>
                        )}
                      </IonRow>

                      <div style={{ border: '0.3px solid grey', margin: '2em 0' }} />

                      <div style={{ margin: '2em 0' }}>
                        <h5 style={{ fontFamily: 'BrandonBold' }}>Discount type and amount</h5>
                        <IonRow className='ion-align-items-center'>
                          <div className='ion-float-left ion-margin-end'>
                            <IonInput
                              value={flashSale?.discount.amount}
                              className='discountAmountInput ion-text-center'
                              inputmode='numeric'
                              placeholder='10'
                              style={{
                                border: '1px solid grey',
                                borderRadius: '5px',
                                fontFamily: 'BrandonBold',
                                fontSize: '1.5em',
                                height: '2em',
                                maxHeight: '3em',
                                width: '4em',
                                padding: 0,
                              }}
                              onIonInput={(e) =>
                                setFlashSale((prev) => {
                                  return { ...prev, discount: { ...prev.discount, amount: e.target.value } };
                                })
                              }
                            />
                          </div>
                          <div className='ion-float-left'>
                            <IonSegment
                              mode='ios'
                              color='light'
                              value={flashSale.discount.type}
                              style={{ height: '3.25em' }}
                              onIonInput={(e) =>
                                setFlashSale((prev) => {
                                  return { ...prev, discount: { ...prev.discount, type: e.target.value } };
                                })
                              }>
                              <IonSegmentButton className='segmentButton' value='%'>
                                %
                              </IonSegmentButton>
                              <IonSegmentButton className='segmentButton' value='$'>
                                $
                              </IonSegmentButton>
                            </IonSegment>
                          </div>
                          <div className='ion-margin-start'>
                            <IonLabel>OFF</IonLabel>
                          </div>
                        </IonRow>
                        <IonRow className='ion-align-items-center ion-margin-top'>
                          <div className='ion-float-left ion-margin-end'>
                            <IonIcon icon={returnDownForwardSharp} />
                          </div>
                          <div className='ion-float-left'>
                            <IonLabel>Minimum purchase $:</IonLabel>
                            <IonInput
                              className='discountAmountInput ion-text-center ion-margin-top'
                              value={flashSale?.discount.min}
                              inputmode='numeric'
                              placeholder='10'
                              style={{ border: '1px solid grey', borderRadius: '5px', height: '3em', width: '5em' }}
                              onIonInput={(e) =>
                                setFlashSale((prev) => {
                                  return { ...prev, discount: { ...prev.discount, min: e.target.value } };
                                })
                              }
                            />
                          </div>
                        </IonRow>
                        <IonRow className='ion-align-items-center ion-margin-top'>
                          <div className='ion-float-left ion-margin-end'>
                            <IonIcon icon={returnDownForwardSharp} />
                          </div>
                          <div className='ion-float-left'>
                            <IonLabel>Maximum discount amount $:</IonLabel>
                            <IonInput
                              className='discountAmountInput ion-text-center ion-margin-top'
                              value={flashSale?.discount.max}
                              inputmode='numeric'
                              placeholder='10'
                              style={{ border: '1px solid grey', borderRadius: '5px', height: '3em', width: '5em' }}
                              onIonInput={(e) =>
                                setFlashSale((prev) => {
                                  return { ...prev, discount: { ...prev.discount, max: e.target.value } };
                                })
                              }
                            />
                          </div>
                        </IonRow>
                      </div>
                      {/* )} 
                      <h5 style={{ fontFamily: 'BrandonBold' }}>Discount items</h5>
                      <IonRadioGroup value={flashSaleItemType} onClick={(e) => handleFlashSaleItemTypeChanged(e.target.value)}>
                        <IonItem color='light'>
                          <IonRadio slot='start' value={'ALL'} />
                          <IonLabel>All items</IonLabel>
                        </IonItem>
                        <IonItem color='light'>
                          <IonRadio slot='start' value={'CATEGORY'} />
                          <IonLabel>Categories</IonLabel>
                        </IonItem>
                        <IonItem color='light'>
                          <IonRadio slot='start' value='ITEMS' />
                          <IonLabel>Selected items</IonLabel>
                        </IonItem>
                      </IonRadioGroup>
                      <div className='ion-no-pading'>
                        <h5 style={{ color: '#FC0FC0', fontFamily: 'BrandonBold', fontSize: '0.8em' }}>
                          {flashSaleItemType === 'CATEGORY' ? 'Selected category' : flashSaleItemType === 'ITEMS' ? 'Selected Item' : 'All items'}
                        </h5>

                        {flashSaleItemType === 'CATEGORY' ? (
                          menus.map((item, index) => {
                            if (flashSale.items.ids.includes(item.objectId)) {
                              return (
                                <IonLabel key={index} style={{ marginRight: '1em', marginTop: '8px' }}>
                                  {item.name}
                                  {menus.length !== index + 1 && ','}
                                </IonLabel>
                              );
                            } else {
                              return null;
                            }
                          })
                        ) : flashSaleItemType === 'ALL' ? (
                          <IonLabel>All items</IonLabel>
                        ) : (
                          <IonLabel>{flashSale.items.ids.length} items</IonLabel>
                        )}
                      </div>
                      <IonModal isOpen={showMenuModal} onDidDismiss={() => setShowMenuModal(false)}>
                        <IonHeader>
                          <IonToolbar>
                            <IonButtons slot='start'>
                              <IonButton onClick={() => setShowMenuModal(false)}>
                                <IonIcon icon={closeOutline} />
                              </IonButton>
                            </IonButtons>
                            <IonButtons slot='end'>
                              <IonButton onClick={onSubmitFlashSaleMenus}>Submit</IonButton>
                            </IonButtons>
                            <IonSearchbar />
                          </IonToolbar>
                        </IonHeader>
                        <IonContent className='ion-padding'>
                          <FlashSaleMenuModal type={flashSaleCopy.items.type} />
                        </IonContent>
                      </IonModal>

                      <IonButton style={{ marginTop: '3em' }} fill='outline' expand='block' onClick={submitFlashSale}>
                        Save
                      </IonButton>
                    </>
                  )}
                </IonCard> */}

                {/* )} */}
              </>
            </IonCol>
            {/* <IonCol className='ion-align-self-end'>
              {!posts ? null : (
                <div style={{ margin: 'auto', width: '320px' }}>
                  <IonSegment style={{ width: '320px' }} mode='md' value={segment} onIonInput={(e) => setSegment(e.target.value)}>
                    <IonSegmentButton value='timeline' icon>
                      <IonIcon icon={homeOutline} />
                    </IonSegmentButton>
                    <IonSegmentButton value='album' icon>
                      <IonIcon icon={albumsOutline} />
                    </IonSegmentButton>
                    <IonSegmentButton value='tag' icon>
                      <IonIcon icon={pricetagOutline} />
                    </IonSegmentButton>
                  </IonSegment>
                  <IonContent className='ion-padding' style={{ height: deviceHeight }}>
                    {segment === 'timeline' && (
                      <div>
                        {posts.map((post, index) => (
                          <div key={index} className='ion-margin-bottom'>
                            <IonItem className='ion-no-padding'>
                              {!post.restaurant.get('logo') ? null : (
                                <IonAvatar slot='start'>
                                  <img src={post.restaurant.get('logo')} alt='' />
                                </IonAvatar>
                              )}
                              <IonLabel>
                                <h3 style={{ fontWeight: 'bolder' }}>{post.restaurant_name}</h3>
                                <p style={{ fontSize: '0.7em' }}>
                                  <span>
                                    <IonBadge className='ion-margin-end'>
                                      <IonLabel style={{ fontSize: '0.7em' }}>{post.type} </IonLabel>
                                    </IonBadge>
                                  </span>
                                  <span>{momentTZ(post.createdAt).from()}</span>
                                </p>
                              </IonLabel>
                            </IonItem>
                            {post.images.length > 1 ? (
                              <Carousel showThumbs={false} dynamicHeight swipeable emulateTouch>
                                {post.images.map((image) => (
                                  <div key={nanoid()} style={{ borderRadius: '5px', overflow: 'hidden' }}>
                                    <IonImg src={image.uri} />
                                  </div>
                                ))}
                              </Carousel>
                            ) : (
                              <div style={{ borderRadius: '5px', overflow: 'hidden' }}>
                                <IonImg src={post.images[0].uri} />
                              </div>
                            )}
                            <IonRow>
                              <IonCol size='auto' className='ion-align-items-center'>
                                <div className='ion-padding-end'>
                                  <IonIcon icon={heartOutline} />
                                  <IonLabel>{post.likes}</IonLabel>
                                </div>
                              </IonCol>
                              <IonCol size='auto'>
                                <IonIcon icon={chatboxOutline} />
                                <IonLabel>{post.comment_count}</IonLabel>
                              </IonCol>
                            </IonRow>
                            <div className='ion-text-start'>
                              <IonLabel>{post.comment}</IonLabel>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {segment === 'album' && (
                      <div>
                        {posts.length &&
                          posts.map((post, index) => (
                            <div key={index} className='ion-float-start' style={{ width: '33%', border: '1px solid grey' }}>
                              <IonImg src={post.images[0]} />
                            </div>
                          ))}
                      </div>
                    )}
                  </IonContent>
                </div>
              )}
            </IonCol> */}
          </IonRow>
        </IonGrid>

        <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />
      </IonContent>

      <IonModal
        className='createPostModal'
        isOpen={showPostModal}
        onDidDismiss={() => {
          setImages([]);
          setTags([]);
          setShowPostModal(false);
        }}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonButton onClick={() => setShowPostModal(false)}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
            <IonTitle>Post</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <main style={{ width: '80%', marginLeft: '10%', paddingBottom: '120px' }}>
            <section className='ion-margin-bottom'>
              <h2 style={{ margin: '2em 0' }}>What kind of posting is this?</h2>
              <IonSegment value={postType} onIonInput={(e) => handlePostType(e.target.value)}>
                <IonGrid>
                  <IonRow>
                    <IonCol size='12' size-sm>
                      <IonSegmentButton style={{ fonstSize: '0.5em' }} value='Announcement'>
                        <IonLabel>Announcement</IonLabel>
                      </IonSegmentButton>
                    </IonCol>
                    <IonCol size='12' size-sm>
                      <IonSegmentButton style={{ fonstSize: '0.5em' }} value='New Menu'>
                        <IonLabel>New Menu</IonLabel>
                      </IonSegmentButton>
                    </IonCol>
                    <IonCol size='12' size-sm>
                      <IonSegmentButton style={{ fonstSize: '0.5em' }} value='Coupon'>
                        <IonLabel>Coupon</IonLabel>
                      </IonSegmentButton>
                    </IonCol>
                    <IonCol size='12' size-sm>
                      <IonSegmentButton style={{ fonstSize: '0.5em' }} value='Flash Sale'>
                        <IonLabel>Flash Sale</IonLabel>
                      </IonSegmentButton>
                    </IonCol>
                    <IonCol size='12' size-sm>
                      <IonSegmentButton style={{ fonstSize: '0.5em' }} value='Pre Order'>
                        <IonLabel>Pre Order</IonLabel>
                      </IonSegmentButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonSegment>
            </section>
            {!showCouponSection ? null : <section className='ion-margin-bottom'></section>}
            {!showTimesaleSection ? null : (
              <section className='ion-margin-bottom'>
                <TimeSelectComponent />
                <section>
                  <h3>Section</h3>
                </section>
                <section>
                  <h3>Sale Items</h3>
                  <IonRadioGroup>
                    <IonItem>
                      <IonRadio slot='start'></IonRadio>
                      <IonLabel>Section Based</IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonRadio slot='start'></IonRadio>
                      <IonLabel>Item Based</IonLabel>
                    </IonItem>
                  </IonRadioGroup>
                </section>
              </section>
            )}

            <div className='ion-margin-top'>
              <h2 style={{ margin: '2em 0' }}>
                Choose photo{'   '}
                {/* <Widget
                  ref={widgetApi}
                  publicKey='87fdf62788f55dc95e09'
                  multiple
                  previewStep
                  crop
                  imageShrink='640x480'
                  onChange={(info) => {
                    let files = [];
                    for (let i = 0; i < info.count; i++) {
                      files.push(`${info.cdnUrl}nth/${i}/`);
                    }
                    setImages([...files]);
                  }}
                /> */}
              </h2>
              <IonRow className='ion-justify-content-center'>
                {!images.length
                  ? null
                  : images.map((image, index) => {
                      return (
                        <div
                          key={index}
                          className='ion-float-start ion-margin-end'
                          style={{ position: 'relative', width: '20%', borderRadius: '5px', overflow: 'hidden' }}>
                          <IonImg src={image} />
                          {/* <IonButton
                            style={{ position: 'absolute', top: '-12px', right: '-12px', backgroundColor: 'rgba(255,255,255,0)' }}
                            onClick={() => handleDeleteImage(index)}>
                            <IonIcon slot='icon-only' icon={closeOutline} />
                          </IonButton> */}
                        </div>
                      );
                    })}
                {/* <div className='ion-float-start'> */}

                {/* <IonButton
                  className='ion-float-start'
                  style={{ width: '15vw', height: '6rem', backgroundColor: 'transparent' }}
                  onClick={() => widgetApi.current.openDialog()}>
                </IonButton> */}
                {/* </div> */}
              </IonRow>
            </div>
            <div className='ion-margin-top'>
              <IonLabel>Comment</IonLabel>
              <IonTextarea
                className='ion-margin-top'
                mode='ios'
                value={postComment}
                onIonInput={(e) => setPostComment(e.target.value)}
                rows={3}
                inputmode='text'
                placeholder='Comment for the pictures here'
              />
            </div>
            <div className='ion-margin-top'>
              <IonRow className='ion-align-items-end' style={{ width: '100%' }}>
                <IonCol size='12' size-sm>
                  <IonLabel>Tags</IonLabel>
                  <IonInput
                    className='ion-margin-top ion-padding-start'
                    style={{ border: '1px solid grey', borderRadius: '5px' }}
                    value={tags[0]}
                    placeholder=' Steak'
                    type='string'
                    onIonInput={(e) => {
                      let copy = tags;
                      copy[0] = e.target.value;
                      setTags([...tags]);
                    }}
                  />
                </IonCol>
                <IonCol size='12' size-sm>
                  <IonLabel position='stacked'></IonLabel>
                  <IonInput
                    className='ion-margin-top ion-padding-start'
                    style={{ border: '1px solid grey', borderRadius: '5px' }}
                    value={tags[1]}
                    placeholder=' Salad'
                    type='string'
                    onIonInput={(e) => {
                      let copy = tags;
                      copy[1] = e.target.value;
                      setTags([...tags]);
                    }}
                  />
                </IonCol>
                <IonCol size='12' size-sm>
                  <IonLabel position='stacked'></IonLabel>
                  <IonInput
                    className='ion-margin-top ion-padding-start'
                    style={{ border: '1px solid grey', borderRadius: '5px' }}
                    value={tags[2]}
                    placeholder=' Breakfast'
                    type='string'
                    onIonInput={(e) => {
                      let copy = tags;
                      copy[2] = e.target.value;
                      setTags([...tags]);
                    }}
                  />
                </IonCol>
              </IonRow>
            </div>

            <div className='ion-margin-top'>
              <IonLabel>Optional</IonLabel>
              <IonItem>
                <IonToggle slot='start' value={erasePostToggle} onIonInput={() => setErasePostToggle(!erasePostToggle)} />
                <IonLabel>
                  <h3>You want to erase this post at specific time?</h3>
                </IonLabel>
              </IonItem>
              {erasePostToggle && (
                <div className='ion-padding'>
                  <div className='ion-margin-end ion-float-start'>
                    <IonLabel position='stacked'>Date</IonLabel>
                    <IonDatetime
                      style={{ width: '250px', border: '1px solid grey', borderRadius: '5px' }}
                      displayFormat='MMM DD YYYY'
                      placeholder='Select Date'
                      value={selectedDate}
                      onIonInput={(e) => setSelectedDate(e.target.value)}
                    />
                  </div>
                  <div style={{ display: 'inline' }}>
                    <IonLabel position='stacked'>Time</IonLabel>
                    <IonDatetime
                      presentation='date'
                      onIonInput={(e) => {
                        setSelectedTime(e.target.value);
                      }}></IonDatetime>
                    {/* <IonDatetime
                      style={{ width: '150px', border: '1px solid grey', borderRadius: '5px' }}
                      displayFormat='HH mm'
                      placeholder='Select Time'
                      value={selectedTime}
                      onIonInput={(e) => setSelectedTime(e.target.value)}
                    /> */}
                  </div>
                </div>
              )}
              <IonItem>
                <IonToggle slot='start' value={relatedMenuToggle} onIonInput={(e) => handleRelatedMenu(e.target.checked)} />
                <IonLabel>
                  <h3>Is this post related to your menu?</h3>
                </IonLabel>
              </IonItem>
              {relatedMenuToggle && selectedMenu && (
                <IonRadioGroup value={selectedMenu.objectId}>
                  <IonItem onClick={() => setShowSelectItemModal(true)}>
                    <IonRadio slot='start' checked value={selectedMenu.objectId} />
                    {selectedMenu.image && (
                      <IonThumbnail slot='start'>
                        <IonImg src={selectedMenu.image} />
                      </IonThumbnail>
                    )}
                    <IonLabel>
                      <h3>{selectedMenu.name}</h3>
                      <p>{selectedMenu.description}</p>
                    </IonLabel>
                  </IonItem>
                </IonRadioGroup>
              )}
              <IonItem>
                <IonToggle slot='start' checked={addMenuToggle} onIonInput={(e) => handleAddMenu(e.target.checked)} />
                <IonLabel>
                  <h3>You want to add item to your menu list as a new menu?</h3>
                </IonLabel>
              </IonItem>
              {addMenuToggle && selectedMenu ? (
                <IonRadioGroup value={selectedMenu.objectId}>
                  <IonItem onClick={() => setShowSelectItemModal(true)}>
                    <div slot='start'></div>
                    <IonRadio slot='start' checked value={selectedMenu.objectId} />
                    {selectedMenu.img && (
                      <IonThumbnail slot='start'>
                        <IonImg src={selectedMenu.img} />
                      </IonThumbnail>
                    )}
                    <IonLabel>
                      <h3>{selectedMenu.name}</h3>
                      <p>{selectedMenu.description}</p>
                    </IonLabel>
                  </IonItem>
                </IonRadioGroup>
              ) : null}
            </div>
            <IonButton onClick={onSavePost}>Post</IonButton>
          </main>
        </IonContent>
      </IonModal>

      <IonModal isOpen={showMenuEditorModal} onDidDismiss={() => setShowMenuEditorModal(false)}>
        <MenuEditorModal
          flag={'post'}
          sections={sections}
          handleClose={handleClose}
          handleAfterSave={handleAfterAddedMenu}
          modifierSets={modifierSets}
        />
      </IonModal>

      <IonModal isOpen={showSelectItemModal} onDidDismiss={() => setShowSelectItemModal(false)}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonButton onClick={() => setShowSelectItemModal(false)}>
                <IonIcon icon={closeOutline} />
              </IonButton>
              <IonTitle>Select Related Item</IonTitle>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className='ion-padding'>
          <IonSearchbar></IonSearchbar>
          <IonList>
            {!menus?.length ? (
              <div>You do not have menu yet</div>
            ) : (
              menus.map((menu, index) => (
                <IonItem key={index} onClick={() => handleSelectMenu(menu)}>
                  <IonRadio slot='start' value={menu} />
                  <IonThumbnail slot='start'>
                    <IonImg src={menu.image} />
                  </IonThumbnail>
                  <IonLabel>
                    <h3>{menu.name}</h3>
                    <p>{menu.description}</p>
                  </IonLabel>
                </IonItem>
              ))
            )}
          </IonList>
        </IonContent>
      </IonModal>
      {deviceWidth < 501 ? (
        <IonFooter>
          <IonToolbar color='light'>
            <IonButtons slot='start'>
              <IonMenuButton id='menu' />
            </IonButtons>
            <IonTitle style={{ fontSize: '1.25em', fontFamily: 'BrandonBold' }}>Home</IonTitle>
            <IonButtons slot='end'>
              <IonButton>
                <IonIcon slot='icon-only' icon={receipt} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      ) : null}
    </IonPage>
  );
};

export default HomePage;
