import { useContext } from 'react';
import { IonLabel, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonItem, IonCheckbox, IonCardContent, IonText } from '@ionic/react';
import Parse from 'parse/dist/parse.min.js';
import { nanoid } from 'nanoid';

import AppContext from '../context/AppContext';
import moment from 'moment';

import '../pages/OrderPage/OrderPage.css';

export default function OrderCardComponent(props) {
  const { orders, orderBadge, updateOrderBadge } = useContext(AppContext);

  const { item, index, flag } = props;

  const handleCheckBox = async (index, i, checked, objectId) => {
    let checkboxes = document.getElementsByClassName(`orderCheckbox${index}`);
    let checkArray = [];
    for (let i = 0; i < checkboxes.length; i++) {
      if (!checkboxes[i].checked) checkArray.push(i);
    }
    if (!checkArray.length) {
      const query = new Parse.Query('Orders');
      try {
        let result = await query.get(objectId);
        result.set('status', 'done');
        result.save().then(() => {
          return true;
        });
      } catch (error) {
        alert(error);
      }
      let copyBudge = orderBadge - 1;
      updateOrderBadge(copyBudge);
    }
  };

  const handleReturnCheckBox = async (index, i, objectId) => {
    let copiedOrders = orders;
    copiedOrders[index].order[i].checked = false;
    copiedOrders[index].status = 'wait';
    const query = new Parse.Query('Orders');
    try {
      let result = await query.get(objectId);
      result.set('status', 'wait');
      result.save().then(() => {
        return true;
      });
    } catch (error) {
      alert(error);
    }
    let copyBudge = orderBadge + 1;
    updateOrderBadge(copyBudge);
  };

  return (
    <IonCard key={index} className='order-card'>
      {flag === 'active' ? (
        <IonCardHeader style={{ padding: 0 }}>
          <IonCardTitle>
            <div className='time-div' style={{ backgroundColor: item.check_in ? 'orange' : 'royalblue' }}>
              <IonText className='time-label'>{moment(item.pickup_datetime).utc().format('h:mm A')} pickup</IonText>
              {item.checked_in ? <IonText className='time-label'>Checked in</IonText> : null}
            </div>
          </IonCardTitle>
          <IonCardSubtitle className='ion-padding-start' style={{ color: 'white' }}>
            {item.guest_name}
          </IonCardSubtitle>
        </IonCardHeader>
      ) : (
        <IonCardHeader style={{ padding: 0 }}>
          <IonCardTitle>
            <div className='time-div' style={{ backgroundColor: 'crimson' }}>
              <IonText className='time-label'>{moment(item.pickup_datetime).utc().format('h:mm A')}</IonText>
            </div>
          </IonCardTitle>
          <IonCardSubtitle className='ion-padding-start' style={{ color: 'white' }}>
            {item.guest_name}
          </IonCardSubtitle>
        </IonCardHeader>
      )}
      <IonCardContent className='ion-no-padding'>
        {item.order.map((order, i) => (
          <IonItem key={nanoid()} className='ion-align-items-start' style={{ margin: '0' }}>
            <IonCheckbox
              slot='start'
              className={`orderCheckbox${index}`}
              value={item.objectId}
              style={{
                width: '1em',
                height: '1em',
                marginRight: '1em',
              }}
              checked={flag === 'done' && true}
              onIonInput={(e) => {
                flag === 'active' ? handleCheckBox(index, i, e.target.checked, item.objectId) : handleReturnCheckBox(index, i, item.objectId);
              }}
            />
            <IonLabel className='ion-text-wrap'>
              <h2>
                {order.menu.name} x {order.qty}
              </h2>
              {!order.sides.length
                ? null
                : order.sides.map((side) => (
                    <div key={nanoid()}>
                      <p>
                        <IonLabel color='secondary'>{side.category}</IonLabel>
                      </p>
                      {!side.choice
                        ? null
                        : side.choice.map((choice) => (
                            <p key={nanoid()}>
                              <IonLabel className='ion-margin-start'>{side.name}</IonLabel>
                            </p>
                          ))}
                    </div>
                  ))}
            </IonLabel>
          </IonItem>
        ))}
      </IonCardContent>
    </IonCard>
  );
}
