import { useContext, useEffect, useState } from 'react';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonIcon,
  IonLabel,
  IonItem,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonReorderGroup,
  IonReorder,
} from '@ionic/react';
import { addOutline, closeOutline, trashOutline } from 'ionicons/icons';
import Parse from 'parse/dist/parse.min.js';

import AppContext from '../context/AppContext';

export default function EditSectionCategoryModal(props) {
  const { closeSectionCategoryModal, currentSection, handleUpdateCategory, handleSaveCategory } = props;

  const { user } = useContext(AppContext);

  const [newCategory, setNewCategory] = useState({
    title: '',
    description: '',
    menuUuids: [],
  });
  const [editableSection, setEditableSection] = useState();

  useEffect(() => {
    setEditableSection(currentSection);
  }, []);

  const handleAddCategory = () => {
    if (!newCategory.title.length) {
      alert('Please type category title');
      return;
    }
    let copiedSection = editableSection;
    copiedSection.categories.push(newCategory);
    setEditableSection(copiedSection);
    setNewCategory({
      title: '',
      description: '',
      menuUuids: [],
    });
  };

  const handleReOrder = (event) => {
    console.log(event.detail);
    let copiedSection = editableSection;
    const originalCategory = editableSection.categories[event.detail.from];
    copiedSection.categories[event.detail.from] = copiedSection.categories[event.detail.to];
    copiedSection.categories[event.detail.to] = originalCategory;
    event.detail.complete();
    console.log(copiedSection);
    setEditableSection(copiedSection);
  };

  const onSaveNewCategory = () => {
    console.log(editableSection);
    handleSaveCategory(editableSection);
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton onClick={closeSectionCategoryModal}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Edit Section</IonTitle>

          <IonButtons slot='end'>
            <IonButton fill='solid' expand='block' color='primary' onClick={onSaveNewCategory}>
              Save
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonGrid>
          <IonRow>
            <IonCol className='ion-no-padding' size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='6' offset-lg='3'>
              <IonRow className='ion-align-items-center'>
                <IonCol size='auto' sizeSm className='ion-text-end'>
                  <div style={{ width: '6em', fontSize: '1rem' }}>Section: </div>
                </IonCol>
                <IonCol className='ion-padding-end'>
                  <h3 className='ion-padding-start'>{currentSection?.title ? currentSection?.title : null}</h3>
                </IonCol>
              </IonRow>
              <div className='ion-margin-top'>
                <h5 style={{ fontSize: '1rem', fontFamily: 'BrandonBold' }}>Title:</h5>
                <IonInput
                  className='ion-padding-start'
                  style={{ border: '1px solid grey', borderRadius: '5px' }}
                  placeholder={' Soup & Salad'}
                  value={newCategory.title}
                  onIonInput={(e) => setNewCategory({ ...newCategory, title: e.target.value })}
                />
                {/* <IonCol size='auto'>
                  <IonButton fill='solid' onClick={handleAddCategory}>
                    <IonIcon icon={addOutline} />
                  </IonButton>
                </IonCol> */}
              </div>
              <div className='ion-margin-top'>
                <h5 style={{ fontSize: '1rem', fontFamily: 'BrandonBold' }}>Description: </h5>
                <IonInput
                  className='ion-padding-start'
                  style={{ border: '1px solid grey', borderRadius: '5px' }}
                  placeholder={'Mostly local & organic ingredients'}
                  value={newCategory.description}
                  onIonInput={(e) => setNewCategory({ ...newCategory, description: e.target.value })}
                />
                {/* <IonCol size='auto'>
                  <IonButton fill='solid' onClick={handleAddCategory}>
                    <IonIcon icon={addOutline} />
                  </IonButton>
                </IonCol> */}
              </div>
              <IonRow>
                <IonCol size='auto' className='ion-text-end'>
                  <div style={{ width: '6em', fontSize: '1rem' }}></div>
                </IonCol>
                <IonCol className='ion-padding-end'>
                  <IonButton expand='block' onClick={handleAddCategory}>
                    <IonIcon icon={addOutline} />
                  </IonButton>
                </IonCol>
              </IonRow>

              <div className='ion-margin-top'>
                <h5 style={{ fontSize: '1rem', fontFamily: 'BrandonBold' }}>Categories:</h5>

                <IonReorderGroup disabled={false} onIonItemReorder={(e) => handleReOrder(e)}>
                  {!editableSection
                    ? null
                    : editableSection.categories.map((val, index) => (
                        <IonItem
                          key={index}
                          color='light'
                          style={{ borderRadius: '5px', marginBottom: '8px' }}
                          onClick={() => handleUpdateCategory(index, val)}>
                          <IonReorder slot='start' />
                          <IonLabel>{val.title}</IonLabel>
                          <IonLabel>
                            {val.menuUuids.length} {val.menuUuids.length > 1 ? 'items' : 'item'}
                          </IonLabel>
                          <IonButton slot='end' fill='solid' color='danger'>
                            <IonIcon icon={trashOutline} />
                          </IonButton>
                        </IonItem>
                      ))}
                </IonReorderGroup>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
}
