import React, { useContext, useEffect } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonImg,
  IonText,
  IonPage,
  isPlatform,
} from '@ionic/react';
import Parse from 'parse/dist/parse.min.js';
import AppContext from '../../context/AppContext';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';

const LandingPage = () => {
  const { updateIsAuthed, updateIsAdmin, updateIsOnboarding } = useContext(AppContext);

  const slideOpts = {
    slidesPerView: isPlatform('desktop') || (isPlatform('tablet') && 6),
  };

  useEffect(() => {
    const handleLogOut = async () => {
      await Parse.User.logOut().then(() => {
        updateIsAuthed(false);
        updateIsAdmin(false);
        updateIsOnboarding(true);
      });
    };
    handleLogOut();
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonRow>
          <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' offset-md='1' size-lg='8' offset-lg='2'>
            <IonToolbar className='ion-justify-content-center'>
              <IonButtons slot='start'>
                <IonImg src={'assets/images/ono.png'} style={{ width: '3em', height: '3em', borderRadius: '5px' }} />
              </IonButtons>
              <IonTitle style={{ fontSize: '1em', fontFamily: 'BrandonBold' }}>ono online</IonTitle>
              <IonButtons slot='end'>
                <IonButton style={{ backgroundColor: '#FF007A', color: 'white' }} onClick={() => window.open('https://join.onoonline.app/')}>
                  Restaurant portal
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonCol>
        </IonRow>
      </IonHeader>

      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' offset-md='1' size-lg='8' offset-lg='2'>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Discover the best local restaurants in Hawaii with ono online!</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonText>
                    <p>Find your favorite food, explore new cuisines, and share your dining experiences with others.</p>
                  </IonText>
                  <section style={{ margin: '2em 0' }}>
                    {isPlatform('desktop') || isPlatform('tablet') ? (
                      <Swiper pager={false} options={slideOpts}>
                        <div>
                          <SwiperSlide>
                            <IonImg
                              className='ion-margin-end ion-float-start'
                              style={{ height: '300px' }}
                              src={
                                'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/ac83fefad83dc1817eac1ef14fce67d8_Screenshot0.png'
                              }
                            />
                            <IonImg
                              className='ion-margin-end  ion-float-start'
                              style={{ height: '300px' }}
                              src={
                                'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/0a1bf260d6f66f4d4af408af3ff5df99_Screenshot1.png'
                              }
                            />
                            <IonImg
                              className='ion-margin-end ion-float-start'
                              style={{ height: '300px' }}
                              src={
                                'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/34dc5285db2a031663630210027c343b_Screenshot2.png'
                              }
                            />
                            <IonImg
                              className='ion-margin-end ion-float-start'
                              style={{ height: '300px' }}
                              src={
                                'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/6f91ee537b91c91e453440af4a4abe16_Screenshot3.png'
                              }
                            />
                            <IonImg
                              className='ion-margin-end ion-float-start'
                              style={{ height: '300px' }}
                              src={
                                'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/a53d984be1c2f4dbc5d47e7520c397ff_Screenshot4.png'
                              }
                            />
                            <IonImg
                              className='ion-float-start'
                              style={{ height: '300px' }}
                              src={
                                'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/1202e1ae20f1d36a7eb941a0c3c83b08_Screenshot5.png'
                              }
                            />
                          </SwiperSlide>
                        </div>
                      </Swiper>
                    ) : (
                      <Swiper pager={true} options={slideOpts}>
                        <SwiperSlide>
                          <IonImg
                            className='ion-margin-end'
                            style={{ height: '300px' }}
                            src={
                              'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/ac83fefad83dc1817eac1ef14fce67d8_Screenshot0.png'
                            }
                          />

                          <IonImg
                            className='ion-margin-end'
                            style={{ height: '300px' }}
                            src={
                              'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/0a1bf260d6f66f4d4af408af3ff5df99_Screenshot1.png'
                            }
                          />

                          <IonImg
                            style={{ height: '300px' }}
                            src={
                              'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/34dc5285db2a031663630210027c343b_Screenshot2.png'
                            }
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <IonImg
                            className='ion-margin-end'
                            style={{ height: '300px' }}
                            src={
                              'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/6f91ee537b91c91e453440af4a4abe16_Screenshot3.png'
                            }
                          />

                          <IonImg
                            className='ion-margin-end'
                            style={{ height: '300px' }}
                            src={
                              'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/a53d984be1c2f4dbc5d47e7520c397ff_Screenshot4.png'
                            }
                          />

                          <IonImg
                            style={{ height: '300px' }}
                            src={
                              'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/1202e1ae20f1d36a7eb941a0c3c83b08_Screenshot5.png'
                            }
                          />
                        </SwiperSlide>
                      </Swiper>
                    )}
                  </section>
                  <IonRow>
                    <IonCol>
                      <IonImg
                        className='ion-margin-bottom'
                        src={'assets/images/App_Store_Badge_US.png'}
                        alt='App store'
                        style={{ height: '6vh' }}
                        onClick={() => window.open('https://apps.apple.com/us/app/ono-online/id1615732357')}
                      />
                      <IonImg
                        src={'assets/images/google-play-badge.png'}
                        alt='Play store'
                        style={{ width: 'auto', height: '8vh' }}
                        onClick={() => window.open('https://play.google.com/store/apps/details?id=co.onoonline.android')}
                      />
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
              <div className='ion-padding' style={{ backgroundColor: '#36B7FF', color: 'white', margin: '40px 0' }}>
                <IonRow>
                  <IonCol>
                    <IonText>Company: ono online Inc</IonText>
                    <br />
                    <br />
                    <IonText>Address: 1441 Kapiolani Blvd Ste 1115, Honolulu, HI 96814</IonText>
                    <br />
                    <br />
                    <IonText>Contact: aloha@onoonline.app</IonText>
                  </IonCol>
                  <IonCol className='ion-align-self-end'>
                    <IonImg src={'assets/images/palm_tree_transparent.png'} style={{ width: 'auto', height: '16vh' }} />
                  </IonCol>
                </IonRow>
              </div>
            </IonCol>
          </IonRow>
          {/* <IonRow>
            <IonCol size='12' md='8' offsetMd='2'>
              <IonList>
                <IonItem>
                  <IonLabel>Find restaurants near you</IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>Save and share your favorite dishes</IonLabel>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow> */}
        </IonGrid>
      </IonContent>

      {/* <IonFooter>
        <IonRow>
          <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' offset-md='1' size-lg='8' offset-lg='2'>
            <IonToolbar className='ion-justify-content-center'>
              <IonTitle>ono online</IonTitle>
              <IonButtons slot='end'>
                <IonButton href='#'>Facebook</IonButton>
                <IonButton href='#'>Twitter</IonButton>
                <IonButton href='#'>Instagram</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonCol>
        </IonRow>
      </IonFooter> */}
    </IonPage>
  );
};

export default LandingPage;
