import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonLabel,
  IonModal,
  IonTitle,
  IonList,
  IonItem,
  IonRow,
  IonCol,
  IonGrid,
  IonSearchbar,
  IonInput,
  IonCheckbox,
  IonLoading,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import Parse from 'parse/dist/parse.min.js';

import './ModifierItemModal.css';

const roundToTwo = (num) => {
  return +(Math.round(num + 'e+2') + 'e-2');
};

const ModifierItemModal = (props) => {
  const {
    rId,
    selectedModifierItem,
    selectedModifierItemIndex,
    showEditModifierItemModal,
    setShowEditModifierItemModal,
    modifierGroups,
    prevModifierItems,
    setPrevModifierItems,
  } = props;
  const [searchText, setSearchText] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [modifierItem, setModifierItem] = useState(null);
  const [groups, setGroups] = useState([]);
  const [groupIdArray, setGroupIdArray] = useState([]);
  const [price, setPrice] = useState(0);

  const priceInputRef = useRef();

  useEffect(() => {
    if (selectedModifierItem) {
      setModifierItem(selectedModifierItem);
      setPrice(selectedModifierItem.price / 100);
      setGroups(modifierGroups);
      const ids = modifierGroups.map((item) => item.objectId);
      setGroupIdArray(ids);
    }
  }, [selectedModifierItem]);

  const filteredGroups = useMemo(() => {
    return groups.filter((item) => {
      return item.title.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [groups, searchText]);

  const handleItemCheckbox = (checked, group) => {
    if (checked && !modifierItem.group_ids.includes(group.objectId)) {
      setModifierItem((prev) => {
        return { ...prev, group_titles: [...prev.group_titles, group.title], group_ids: [...prev.group_ids, group.objectId] };
      });
      return;
    }
    if (!checked && modifierItem.group_ids?.includes(group.objectId)) {
      setModifierItem((prev) => {
        const filteredIds = prev.group_ids.filter((item) => {
          return item !== group.objectId;
        });
        const filteredTitles = prev.group_titles.filter((item) => {
          return item !== group.title;
        });
        return { ...prev, group_titles: filteredTitles, group_ids: filteredIds };
      });
      return;
    }
  };

  const handleSaveEditedModifierItem = async () => {
    if (!modifierItem.name.length) {
      alert('Please input item name');
      return;
    }
    if (isNaN(priceInputRef.current.value) || !priceInputRef.current.value) {
      alert('Please input price');
      return;
    }
    setShowLoading(true);
    await Parse.Cloud.run('saveEditedModifierItem', {
      rId,
      itemId: modifierItem.objectId,
      data: JSON.stringify(modifierItem),
      price: Number(priceInputRef.current.value) * 100,
      groupIds: modifierItem.group_ids,
    })
      .then((result) => {
        if (result) {
          let copiedModifierItems = prevModifierItems;
          copiedModifierItems[selectedModifierItemIndex] = { ...modifierItem, price: Number(priceInputRef.current.value) * 100 };
          setPrevModifierItems([...copiedModifierItems]);
          setSearchText('');
          setShowEditModifierItemModal(false);
          setShowLoading(false);
          return;
        } else {
          setShowLoading(false);
          alert(result);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <IonModal className='categoryModal' isOpen={showEditModifierItemModal}>
      <IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='6' offset-lg='3'>
              <IonToolbar>
                <IonButtons slot='start'>
                  <IonButton onClick={() => setShowEditModifierItemModal(false)}>
                    <IonIcon icon={closeOutline} />
                  </IonButton>
                </IonButtons>
                <IonTitle>Modifier item</IonTitle>
                <IonButtons slot='end'>
                  <IonButton fill='solid' expand='block' color='primary' onClick={handleSaveEditedModifierItem}>
                    Save
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>
      <IonContent className='ion-padding'>
        {!modifierItem ? null : (
          <IonGrid>
            <IonRow>
              <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='6' offset-lg='3'>
                <div className='ion-margin-vertical'>
                  <IonLabel style={{ fontSize: '1rem', fontFamily: 'BrandonBold' }}>Item name</IonLabel>
                  <IonItem className='ion-margin-vertical' fill={'solid'}>
                    <IonInput
                      style={{ borderRadius: '10px', height: '40px' }}
                      placeholder={' Ex: Extra French Fry'}
                      value={modifierItem.name}
                      onIonInput={(e) => setModifierItem({ ...modifierItem, name: e.target.value })}
                    />
                  </IonItem>
                  <IonLabel style={{ fontSize: '1rem', fontFamily: 'BrandonBold' }}>Price($)</IonLabel>
                  <IonItem className='ion-margin-vertical' fill={'solid'}>
                    <IonInput ref={priceInputRef} value={price} style={{ borderRadius: '10px' }} onIonInput={(e) => setPrice(e.target.value)} />
                  </IonItem>
                </div>
                <div style={{ marginTop: '30px' }}>
                  <IonLabel style={{ fontSize: '1rem', fontFamily: 'BrandonBold' }}>Assign item to modifier group</IonLabel>
                  <IonSearchbar
                    className='ion-margin-vertical'
                    style={{ minHeight: '3em' }}
                    // className='searchBar'
                    placeholder={'Search items'}
                    showClearButton={'always'}
                    value={searchText}
                    onIonInput={(e) => setSearchText(e.target.value)}
                  />
                </div>
                <IonList>
                  {filteredGroups.length ? (
                    filteredGroups.map((group) => {
                      return (
                        <IonItem key={group.objectId}>
                          <IonCheckbox
                            slot='start'
                            className='categoryModalCheckBox'
                            checked={modifierItem.group_ids?.includes(group.objectId)}
                            value={group.objectId}
                            onIonInput={(e) => handleItemCheckbox(e.target.checked, group)}
                          />
                          <IonLabel className='ion-text-wrap'>
                            <h3>{group.title}</h3>
                          </IonLabel>
                        </IonItem>
                      );
                    })
                  ) : (
                    <IonLabel>No Item</IonLabel>
                  )}
                </IonList>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
      <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />
    </IonModal>
  );
};

export default ModifierItemModal;
