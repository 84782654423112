import React, { useContext, useEffect, useState } from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonRow, IonCol, IonTitle, IonToolbar, IonGrid } from '@ionic/react';

export default function FaqPage() {
  return (
    <IonPage>
      <IonHeader>
        <IonRow>
          <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' offset-md='1' size-lg='8' offset-lg='2'>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonBackButton />
              </IonButtons>
              <IonTitle>FAQ</IonTitle>
            </IonToolbar>
          </IonCol>
        </IonRow>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' offset-md='1' size-lg='8' offset-lg='2'>
              <div className='ion-padding'>
                <h1>Coming soon</h1>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
