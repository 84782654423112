import { useEffect, useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonButton,
  IonContent,
  IonImg,
  IonPage,
  IonLoading,
  IonCol,
  IonRow,
  IonGrid,
  IonText,
  isPlatform,
  IonCard,
  getPlatforms,
} from '@ionic/react';
import Parse from 'parse/dist/parse.min.js';
import './InvitedUserPage.css';
import AppContext from '../../context/AppContext';

const InvitedUserIntroPage = ({ match }) => {
  const history = useHistory();
  const { user, updateIsOnboarding } = useContext(AppContext);
  const [showLoading, setShowLoading] = useState(false);

  const [isOnboarding, setIsOnBoarding] = useState(true);

  const videoRef = useRef(null);

  const slideOpts = {
    slidesPerView: isPlatform('desktop') || (isPlatform('tablet') && 6),
  };

  // localhost:8100/invite?email=mbong@elcielo-hawaii.com&rId=OUVvxOHZ4W
  // localhost:8100/invite?email=kishi@marion-crepes.com&rId=hh0ONtH3CP

  useEffect(() => {
    videoRef.current?.play();
  }, [videoRef.current]);

  const handleSubmit = () => {
    history.push('/signup/invite/restaurant');
  };

  return (
    <IonPage>
      <IonContent className='container'>
        <IonGrid style={{ height: '100%' }}>
          <IonRow className='ion-justify-content-center ion-padding' style={{ height: '100%' }}>
            <IonCol size-xs='12' size-sm='12' size-md='10' size-lg='8'>
              <main>
                <IonImg src={'assets/images/ono.png'} style={{ width: '10vh', height: '10vh', borderRadius: '5px' }} />

                <h1>Aloha!</h1>
                <h1>
                  Thank you for your interests {user.first_name}!<br />
                  We are honored to work with you.
                </h1>
                <section style={{ margin: '5em 0' }}>
                  <h1 style={{ fontSize: '3.5vh' }}>ono online is a "ALL-IN-ONE" marketing tool (Free)</h1>
                  <IonGrid>
                    <IonRow className='ion-justify-content-center'>
                      <IonCol size-xs='6' size-sm='3' size-md='3' className='ion-text-center' style={{ paddingTop: '2em' }}>
                        <IonImg src={'assets/images/social-media.png'} style={{ width: '50%', marginLeft: '25%' }} />
                        <h3 className='titles'>POST NEWS</h3>
                        <IonText className='subTitles'>
                          It's location-based. All users nearby will see your news and posts. Your post will be at the top of the users' feeds.
                        </IonText>
                      </IonCol>
                      <IonCol size-xs='6' size-sm='3' size-md='3' className='ion-text-center' style={{ paddingTop: '2em' }}>
                        <IonImg src={'assets/images/call-to-action.png'} style={{ width: '50%', marginLeft: '25%' }} />
                        <h3 className='titles'>CALL TO ACTION</h3>
                        <IonText className='subTitles'>
                          Order now, Book now, Pre order, Go to Flash Sale, etc. Customers can respond your post immediately.
                        </IonText>
                      </IonCol>
                      <IonCol size-xs='6' size-sm='3' size-md='3' className='ion-text-center' style={{ paddingTop: '2em' }}>
                        <IonImg src={'assets/images/sale-time.png'} style={{ width: '50%', marginLeft: '25%' }} />
                        <h3 className='titles'>FLASH SALE</h3>
                        <IonText className='subTitles'>
                          We want you to reduce food loss. Having a Flash Sale is a great way to do that! It’s sale time!
                        </IonText>
                      </IonCol>
                      <IonCol size-xs='6' size-sm='3' size-md='3' className='ion-text-center' style={{ paddingTop: '2em' }}>
                        <IonImg src={'assets/images/pre-order.png'} style={{ width: '50%', marginLeft: '25%' }} />
                        <h3 className='titles'>PRE ORDERS</h3>
                        <IonText className='subTitles'>
                          Big day, big sales! Run special food items/dishes on certain days, available on those days only.
                        </IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow className='ion-justify-content-center'>
                      <IonCol size-xs='6' size-sm='3' size-md='3' className='ion-text-center' style={{ paddingTop: '2em' }}>
                        <IonImg src={'assets/images/menu.png'} style={{ width: '50%', marginLeft: '25%' }} />
                        <h3 className='titles'>MENU MANAGEMENT</h3>
                        <IonText className='subTitles'>
                          Easy-to-use interface to manage your menus. We will take care of your initial menu settings.
                        </IonText>
                      </IonCol>
                      <IonCol size-xs='6' size-sm='3' size-md='3' className='ion-text-center' style={{ paddingTop: '2em' }}>
                        <IonImg src={'assets/images/shelf.png'} style={{ width: '50%', marginLeft: '25%' }} />
                        <h3 className='titles'>MODIFIER STOCK</h3>
                        <IonText className='subTitles'>
                          At times, side menus can be difficult to manage. You can set stock for each side menu.
                        </IonText>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </section>
                <section style={{ margin: '5em 0' }}>
                  <h1 className='ion-margin-bottom' style={{ fontSize: '3.5vh', fontFamily: 'RetroFunk' }}>
                    No need many "Followers" on your social media
                  </h1>
                  <IonGrid className='ion-no-padding' style={{ marginTop: '1em' }}>
                    <IonRow>
                      <IonCol size='auto' className='ion-padding-end'>
                        <IonImg src={'assets/images/radar.png'} style={{ width: '10vh' }} />
                      </IonCol>
                      <IonCol>
                        <IonText>
                          The app is a location-based restaurant search.
                          <br />
                          Restaurants can achieve up-sells by using the app. Users can get real-time information about restaurants around them. <br />
                          <br />
                          <span style={{ fontFamily: 'RetroFunk', fontSize: '1.25em' }}>ono online </span>makes connections between restaurants and
                          users.
                        </IonText>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </section>
                <section>
                  <IonRow style={{ margin: '2em 0' }}>
                    <IonCol size='auto' className='ion-padding-end'>
                      <IonImg src={'assets/images/free.png'} style={{ width: '50px' }} />
                    </IonCol>
                    <IonCol>
                      <IonText style={{ fontSize: '1.3em', fontFamily: 'BrandonBold' }}>Free advertisement</IonText>
                      <br />
                      <IonText style={{ fontSize: '1em', fontFamily: 'Brandon' }}>
                        If you don’t want to turn on “Accept orders,” it’s a free listing. Users can still look-up your restaurant info similar to
                        Yelp. We believe that you won’t need your website moving forward.
                      </IonText>
                    </IonCol>
                  </IonRow>
                </section>
                <section style={{ margin: '5em 0' }}>
                  <h1 style={{ fontSize: '3.5vh' }}>One of the most specialized function is "CALL TO ACTION" on your posts</h1>

                  <IonGrid>
                    <IonRow className='ion-align-items-end'>
                      <IonCol size-xs='12' size-sm='5' size-md='5' className='ion-justify-content-center'>
                        <video muted autoPlay loop ref={videoRef} width='100%'>
                          <source
                            src={'https://res.cloudinary.com/dn2gcd1cf/video/upload/v1683689395/LandingPage/Call_to_action_Ad_yhadmp.mp4'}
                            type='video/mp4'
                          />
                        </video>
                        {/* <IonImg src={'assets/images/cover.png'} style={{ width: '100%' }} /> */}
                      </IonCol>
                      <IonCol size-xs='12' size-sm='1' size-md='1' />
                      <IonCol size-xs='12' size-sm='6' size-md='6'>
                        <div>
                          <h1 style={{ fontSize: '3vh' }}>Your customer respond with one tap</h1>
                          <h1 style={{ fontSize: '3vh' }}>Order now</h1>
                          <h1 style={{ fontSize: '3vh' }}>Book a table</h1>
                          <h1 style={{ fontSize: '3vh' }}>Pre order</h1>
                          <h1 style={{ fontSize: '3vh' }}>Go to Flash Sale</h1>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </section>

                <section style={{ margin: '5em 0' }}>
                  <h1 style={{ fontSize: '1.75em', fontFamily: 'RetroFunk' }}> Our fees</h1>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <h3 className='titles'>IF ZERO SALES</h3>
                        <IonText className='subTitles'>If somehow there are ZERO sales, no worries, ZERO out of your pocket.</IonText>
                      </IonCol>
                      <IonCol>
                        <h3 className='titles'>COMMISSION</h3>
                        <IonText className='subTitles'>
                          We pride ourselves on 100% transparency. 5% of sales are for operating ono online. The credit card processing fee is 2.9%
                          and 30 cents per transaction.
                        </IonText>
                        <IonText> Nearly 1% of our commission goes to promotional fees.</IonText>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </section>
                <section style={{ margin: '5em 0' }}>
                  <h1 style={{ fontSize: '1.75em', fontFamily: 'RetroFunk' }}>Upcoming functions</h1>
                  <IonGrid>
                    <IonRow>
                      <IonCol className='ion-padding-end'>
                        <h3 className='titles ion-text-center'>POS INTEGRATION (Now Square)</h3>
                        <IonText className='subTitles'>
                          Orders placed through our ono online app will pop up on your POS system screen, which keeps things streamlined for you.
                        </IonText>
                      </IonCol>
                      <IonCol className='ion-padding-end'>
                        <h3 className='titles ion-text-center'>FLASH BOOKING (upcoming)</h3>
                        <IonText className='subTitles'>
                          Getting tired of reservations canceling? We will offer other options and solutions for you.
                        </IonText>
                      </IonCol>
                      <IonCol>
                        <h3 className='titles ion-text-center'>LOCAL FARM CONNECTIONS (upcoming)</h3>
                        <IonText className='subTitles'>
                          We believe that the relationships between restaurants and local farms/distributors are of utmost importance. We will connect
                          you with the best local farms, distributors, and purveyors.
                        </IonText>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </section>
                {/* <section>
                  <h1 style={{ fontSize: '1.75em', fontFamily: 'RetroFunk' }}>Please download ono online app.</h1>
                  <h1 style={{ fontSize: '1.75em', fontFamily: 'RetroFunk' }}>Using app makes much easier to post.</h1>
                  <IonCard>
                    <IonCardContent className='ion-no-padding'>
                      <section style={{ margin: '2em 0' }}>
                        {isPlatform('desktop') || isPlatform('tablet') ? (
                          <IonSlides pager={false} options={slideOpts}>
                            <div>
                              <IonSlides>
                                <IonImg
                                  className='ion-margin-end ion-float-start'
                                  style={{ height: '300px' }}
                                  src={
                                    'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/ac83fefad83dc1817eac1ef14fce67d8_Screenshot0.png'
                                  }
                                />
                                <IonImg
                                  className='ion-margin-end  ion-float-start'
                                  style={{ height: '300px' }}
                                  src={
                                    'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/0a1bf260d6f66f4d4af408af3ff5df99_Screenshot1.png'
                                  }
                                />
                                <IonImg
                                  className='ion-margin-end ion-float-start'
                                  style={{ height: '300px' }}
                                  src={
                                    'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/34dc5285db2a031663630210027c343b_Screenshot2.png'
                                  }
                                />
                                <IonImg
                                  className='ion-margin-end ion-float-start'
                                  style={{ height: '300px' }}
                                  src={
                                    'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/6f91ee537b91c91e453440af4a4abe16_Screenshot3.png'
                                  }
                                />
                                <IonImg
                                  className='ion-margin-end ion-float-start'
                                  style={{ height: '300px' }}
                                  src={
                                    'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/a53d984be1c2f4dbc5d47e7520c397ff_Screenshot4.png'
                                  }
                                />
                                <IonImg
                                  className='ion-float-start'
                                  style={{ height: '300px' }}
                                  src={
                                    'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/1202e1ae20f1d36a7eb941a0c3c83b08_Screenshot5.png'
                                  }
                                />
                              </IonSlides>
                            </div>
                          </IonSlides>
                        ) : (
                          <IonSlides pager={true} options={slideOpts}>
                            <IonSlides>
                              <IonImg
                                className='ion-margin-end'
                                style={{ height: '300px' }}
                                src={
                                  'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/ac83fefad83dc1817eac1ef14fce67d8_Screenshot0.png'
                                }
                              />

                              <IonImg
                                className='ion-margin-end'
                                style={{ height: '300px' }}
                                src={
                                  'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/0a1bf260d6f66f4d4af408af3ff5df99_Screenshot1.png'
                                }
                              />

                              <IonImg
                                style={{ height: '300px' }}
                                src={
                                  'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/34dc5285db2a031663630210027c343b_Screenshot2.png'
                                }
                              />
                            </IonSlides>
                            <IonSlides>
                              <IonImg
                                className='ion-margin-end'
                                style={{ height: '300px' }}
                                src={
                                  'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/6f91ee537b91c91e453440af4a4abe16_Screenshot3.png'
                                }
                              />

                              <IonImg
                                className='ion-margin-end'
                                style={{ height: '300px' }}
                                src={
                                  'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/a53d984be1c2f4dbc5d47e7520c397ff_Screenshot4.png'
                                }
                              />

                              <IonImg
                                style={{ height: '300px' }}
                                src={
                                  'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/1202e1ae20f1d36a7eb941a0c3c83b08_Screenshot5.png'
                                }
                              />
                            </IonSlides>
                          </IonSlides>
                        )}
                      </section>
                      <section className='ion-text-center'>
                        <IonImg
                          className='ion-margin-bottom'
                          src={'assets/images/App_Store_Badge_US.png'}
                          alt='App store'
                          style={{ height: '6vh' }}
                          onClick={() => window.open('https://apps.apple.com/us/app/ono-online/id1615732357')}
                        />
                        <IonImg
                          src={'assets/images/google-play-badge.png'}
                          alt='Play store'
                          style={{ width: 'auto', height: '8vh' }}
                          onClick={() => window.open('https://play.google.com/store/apps/details?id=co.onoonline.android')}
                        />
                        <IonImg
                          src={'assets/images/app_store_qr.png'}
                          alt='Play store'
                          style={{ width: 'auto', height: '20vh' }}
                          onClick={() => window.open('https://play.google.com/store/apps/details?id=co.onoonline.android')}
                        />
                        <IonText className='ion-margin-vertical' style={{ fontSize: '4vh' }}>
                          App store or Play store links
                        </IonText>
                      </section>
                    </IonCardContent>
                  </IonCard>
                </section> */}

                <section style={{ margin: '3em 0' }}>
                  <h1 style={{ fontSize: '1.75em', fontFamily: 'RetroFunk' }}> Please join us right now! Yes, it is free to start.</h1>
                  <IonCard className='ion-padding ion-no-margin' color={'secondary'}>
                    <h1 style={{ fontSize: '3vh' }}>No initial cost</h1>
                    <h1 style={{ fontSize: '3vh' }}>No monthly maintenance fee</h1>
                    <h1 style={{ fontSize: '3vh' }}>No more devices</h1>
                  </IonCard>
                </section>
                <div style={{ marginTop: 30, marginBottom: 50 }}>
                  <IonButton size='large' strong expand='block' onClick={handleSubmit}>
                    Let's begin!!
                  </IonButton>
                </div>
              </main>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={''} />
    </IonPage>
  );
};
export default InvitedUserIntroPage;
