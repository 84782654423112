import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonLoading,
  IonGrid,
  IonLabel,
  IonHeader,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import AppContext from '../../context/AppContext';
import Parse from 'parse/dist/parse.min.js';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';

import './WelcomePage.css';
import '../../theme/variables.css';

const WelcomePage = () => {
  const history = useHistory();
  const { user, updateUser, updateIsOnboarding } = useContext(AppContext);

  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      const currentUser = Parse.User.current();
      if (currentUser) {
        console.log('Current user');
        await Parse.Cloud.run('getUserEditingRestaurant', { userId: currentUser.id })
          .then((result) => {
            setShowLoading(false);
            if (!result) {
              setShowLoading(false);
              return false;
            } else {
              updateUser({
                ...user,
                id: currentUser.id,
                first_name: currentUser.get('first_name'),
                last_name: currentUser.get('last_name'),
                email: currentUser.get('email'),
                avatar: result.avatar,
                role: result.role,
                restaurant_id: result.rId,
                restaurant_name: result.rName,
                restaurant_logo: result.rLogo,
              });
              if (!result.isOnboarding) {
                updateIsOnboarding(false);
                history.push(`/dashboard/home/${result.rId}`);
                setShowLoading(false);
                return true;
              } else {
                history.push(`/onboarding/${result.rId}`);
                setShowLoading(false);
              }
            }
          })
          .catch((error) => {
            console.log(error);
            setShowLoading(false);
          });
      } else {
        console.log('Not currentuser');
        setShowLoading(false);
      }
    };
    getUser();
  }, []);

  const changePass = async () => {
    await Parse.Cloud.run('changeUserPassword', { username: 'kikiyui.hi@gmail.com', oldPassword: 'Kishi1979', newPassword: 'Kishi1979!' })
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onPresssLoginButton = async () => {
    await Parse.User.logOut().then(() => {
      const currentUser = Parse.User.current();
      if (currentUser) {
        history.push(`/launch/${currentUser.id}`);
      } else {
        history.push('/login');
      }
    });
  };

  // useEffect(() => {
  //   if (user.id) {
  //     const handleLogOut = async () => {
  //       await Parse.User.logOut().then(() => {
  //         updateIsAdmin(false);
  //         updateIsOnboarding(true);
  //       });
  //     };
  //     handleLogOut();
  //   }
  // }, []);

  if (showLoading) {
    return <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Aloha'} />;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonRow>
          <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' offset-md='1' size-lg='8' offset-lg='2'>
            <IonToolbar className='ion-justify-content-center'>
              <IonButtons slot='start'>
                <IonImg src={'assets/images/ono.png'} style={{ width: '3em', height: '3em', borderRadius: '1.5em', overflow: 'hidden' }} />
              </IonButtons>
              <IonTitle style={{ fontSize: '1em', fontFamily: 'BrandonBold' }}>Restaurant Portal</IonTitle>
              <IonButtons slot='end'>
                <IonButton style={{ backgroundColor: '#FF007A', color: 'white' }} onClick={onPresssLoginButton}>
                  Login
                </IonButton>
                <IonButton style={{ color: 'black' }} onClick={() => history.push('/signup')}>
                  Signup
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonCol>
        </IonRow>
      </IonHeader>
      <IonContent className='content'>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='10' offset-sm='1' size-md='10' offset-md='1' size-lg='8' offset-lg='2'>
              <div>
                <h1 style={{ fontFamily: 'RetroFunk', fontSize: '3vh' }}> Aloha Restaurants,</h1>
                <h3 style={{ marginTop: '2em' }}>
                  <span style={{ fontFamily: 'RetroFunk', fontSize: '1.45em' }}>"ono online" </span>is
                </h3>
                <h4 style={{ fontFamily: 'Brandon', fontSize: '3vh' }}>
                  a free restaurant search and online food ordering app for{' '}
                  <span style={{ color: '#FF007A', fontFamily: 'BrandonBold' }}>both locals and tourists in Hawaii.</span>
                </h4>
                <section style={{ margin: '3em 0' }}>
                  <IonGrid>
                    <IonRow className='ion-align-items-end'>
                      <IonCol size-xs='12' size-sm='5' size-md='5' className='ion-justify-content-center'>
                        {/* <ReactPlayer
                          url='https://res.cloudinary.com/dn2gcd1cf/video/upload/v1683689395/LandingPage/Call_to_action_Ad_yhadmp.mp4'
                          muted={true}
                          loop={true}
                          playing={true}
                          ref={videoRef}
                          width='100%'
                        /> */}
                        <video
                          autoPlay={'autoplay'}
                          muted={true}
                          loop={true}
                          src={'https://res.cloudinary.com/dn2gcd1cf/video/upload/v1683689395/LandingPage/Call_to_action_Ad_yhadmp.mp4'}
                          type='video/mp4'
                          playsInLine='playsinline'
                          width='100%'
                        />
                        {/* <IonImg src={'assets/images/cover.png'} style={{ width: '100%' }} /> */}
                      </IonCol>
                      <IonCol size-xs='12' size-sm='1' size-md='1' />
                      <IonCol size-xs='12' size-sm='6' size-md='6'>
                        <div>
                          <h3 style={{ fontSize: '3vh' }}>
                            Join <span style={{ fontSize: '4vh', fontFamily: 'RetroFunk' }}>"ono online"</span> now.
                          </h3>
                          <h1 style={{ fontSize: '3vh' }}>It is free advertisement for your restaurant.</h1>
                          <h1 style={{ fontSize: '3vh' }}>No initial cost</h1>
                          <h1 style={{ fontSize: '3vh' }}>No monthly maintenance fee</h1>
                          <h1 style={{ fontSize: '3vh' }}>No more devices</h1>
                        </div>
                        <div style={{ marginTop: '2em', marginBottom: '1em' }}>
                          <IonButton expand='block' size='large' onClick={() => history.push('/signup')} style={{ fontFamily: 'BrandonBold' }}>
                            SIGN UP NOW
                          </IonButton>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </section>
                <section style={{ margin: '5em 0' }}>
                  <h1 className='ion-margin-bottom' style={{ fontSize: '3.5vh', fontFamily: 'RetroFunk' }}>
                    No need many "Followers" on your social media
                  </h1>
                  <IonGrid className='ion-no-padding' style={{ marginTop: '1em' }}>
                    <IonRow>
                      <IonCol size='auto' className='ion-padding-end'>
                        <IonImg src={'assets/images/radar.png'} style={{ width: '60px' }} />
                      </IonCol>
                      <IonCol>
                        <IonText>
                          The app is a location-based restaurant search.
                          <br />
                          Restaurants can achieve up-sells by using the app. Users can get real-time information about restaurants around them. <br />
                          <br />
                          <span style={{ fontFamily: 'RetroFunk', fontSize: '1.25em' }}>ono online </span>makes connections between restaurants and
                          users.
                        </IonText>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </section>
                <section style={{ margin: '5em 0' }}>
                  <h1 style={{ fontSize: '3.5vh', fontFamily: 'RetroFunk' }}>All-in-one marketing tool (Free)</h1>
                  <IonGrid>
                    <IonRow className='ion-justify-content-center'>
                      <IonCol size-xs='6' size-sm='3' size-md='3' className='ion-text-center' style={{ paddingTop: '2em' }}>
                        <IonImg src={'assets/images/social-media.png'} style={{ width: '50%', marginLeft: '25%' }} />
                        <h3 className='titles'>POST NEWS</h3>
                        <IonText className='subTitles'>
                          It's location-based. All users nearby will see your news and posts. Your post will be at the top of the users' feeds.
                        </IonText>
                      </IonCol>
                      <IonCol size-xs='6' size-sm='3' size-md='3' className='ion-text-center' style={{ paddingTop: '2em' }}>
                        <IonImg src={'assets/images/hold.png'} style={{ width: '50%', marginLeft: '25%' }} />
                        <h3 className='titles'>TAP TO ORDER</h3>
                        <IonText className='subTitles'>If your post is linked to a menu, it makes it easy for users to start an order.</IonText>
                      </IonCol>
                      <IonCol size-xs='6' size-sm='3' size-md='3' className='ion-text-center' style={{ paddingTop: '2em' }}>
                        <IonImg src={'assets/images/sale-time.png'} style={{ width: '50%', marginLeft: '25%' }} />
                        <h3 className='titles'>FLASH SALE</h3>
                        <IonText className='subTitles'>
                          We want you to reduce food loss. Having a Flash Sale is a great way to do that! It’s sale time!
                        </IonText>
                      </IonCol>
                      <IonCol size-xs='6' size-sm='3' size-md='3' className='ion-text-center' style={{ paddingTop: '2em' }}>
                        <IonImg src={'assets/images/pre-order.png'} style={{ width: '50%', marginLeft: '25%' }} />
                        <h3 className='titles'>PRE ORDERS</h3>
                        <IonText className='subTitles'>
                          Big day, big sales! Run special food items/dishes on certain days, available on those days only.
                        </IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow className='ion-justify-content-center'>
                      <IonCol size-xs='6' size-sm='3' size-md='3' className='ion-text-center' style={{ paddingTop: '2em' }}>
                        <IonImg src={'assets/images/menu.png'} style={{ width: '50%', marginLeft: '25%' }} />
                        <h3 className='titles'>MENU MANAGEMENT</h3>
                        <IonText className='subTitles'>
                          Easy-to-use interface to manage your menus. We will take care of your initial menu settings.
                        </IonText>
                      </IonCol>
                      <IonCol size-xs='6' size-sm='3' size-md='3' className='ion-text-center' style={{ paddingTop: '2em' }}>
                        <IonImg src={'assets/images/shelf.png'} style={{ width: '50%', marginLeft: '25%' }} />
                        <h3 className='titles'>MODIFIER STOCK</h3>
                        <IonText className='subTitles'>
                          At times, side menus can be difficult to manage. You can set stock for each side menu.
                        </IonText>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </section>
                <section style={{ margin: '5em 0' }}>
                  <h1 className='ion-margin-bottom' style={{ fontSize: '3.5vh', fontFamily: 'RetroFunk' }}>
                    Screenshots for user's app
                  </h1>
                  {isPlatform('desktop') || isPlatform('tablet') ? (
                    <Swiper slidesPerView={6} style={{ width: '100%', height: '300px' }}>
                      <SwiperSlide>
                        <IonImg
                          className='ion-margin-end'
                          style={{ height: '300px' }}
                          src={
                            'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/ac83fefad83dc1817eac1ef14fce67d8_Screenshot0.png'
                          }
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <IonImg
                          className='ion-margin-end'
                          style={{ height: '300px' }}
                          src={
                            'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/0a1bf260d6f66f4d4af408af3ff5df99_Screenshot1.png'
                          }
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <IonImg
                          className='ion-margin-end'
                          style={{ height: '300px' }}
                          src={
                            'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/34dc5285db2a031663630210027c343b_Screenshot2.png'
                          }
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <IonImg
                          className='ion-margin-end'
                          style={{ height: '300px' }}
                          src={
                            'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/6f91ee537b91c91e453440af4a4abe16_Screenshot3.png'
                          }
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <IonImg
                          className='ion-margin-end'
                          style={{ height: '300px' }}
                          src={
                            'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/a53d984be1c2f4dbc5d47e7520c397ff_Screenshot4.png'
                          }
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <IonImg
                          className='ion-margin-end'
                          style={{ height: '300px' }}
                          src={
                            'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/1202e1ae20f1d36a7eb941a0c3c83b08_Screenshot5.png'
                          }
                        />
                      </SwiperSlide>
                    </Swiper>
                  ) : (
                    <Swiper pager={true} slidesPerView={3}>
                      <SwiperSlide>
                        <IonImg
                          className='ion-margin-end'
                          style={{ height: '300px' }}
                          src={
                            'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/ac83fefad83dc1817eac1ef14fce67d8_Screenshot0.png'
                          }
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <IonImg
                          className='ion-margin-end'
                          style={{ height: '300px' }}
                          src={
                            'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/0a1bf260d6f66f4d4af408af3ff5df99_Screenshot1.png'
                          }
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <IonImg
                          style={{ height: '300px' }}
                          src={
                            'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/34dc5285db2a031663630210027c343b_Screenshot2.png'
                          }
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <IonImg
                          className='ion-margin-end'
                          style={{ height: '300px' }}
                          src={
                            'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/6f91ee537b91c91e453440af4a4abe16_Screenshot3.png'
                          }
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <IonImg
                          className='ion-margin-end'
                          style={{ height: '300px' }}
                          src={
                            'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/a53d984be1c2f4dbc5d47e7520c397ff_Screenshot4.png'
                          }
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <IonImg
                          style={{ height: '300px' }}
                          src={
                            'https://parsefiles.back4app.com/c0nl0kPooW6sgvvBQeX2dVvBJeD9fWiM1oT2s7rY/1202e1ae20f1d36a7eb941a0c3c83b08_Screenshot5.png'
                          }
                        />
                      </SwiperSlide>
                    </Swiper>
                  )}
                </section>

                <section style={{ margin: '5em 0' }}>
                  <h3 style={{ fontFamily: 'BrandonItalic' }}>
                    “If you are struggling to gain followers on social media such as Instagram,
                    <br />
                    <span style={{ color: '#FF007A', fontFamily: 'BrandonBold' }}>do not worry</span>,
                    <br />
                    you can reach new customers using our App”
                  </h3>
                </section>
                <section style={{ margin: '5em 0' }}>
                  <h1 className='ion-margin-bottom' style={{ fontSize: '1.6em', fontFamily: 'RetroFunk' }}>
                    App positioning
                  </h1>
                  <IonGrid className='ion-margin-top'>
                    <IonRow>
                      <IonCol size-xs='4' size-sm='4' size-md='4' className='ion-text-center'>
                        <p style={{ fontSize: '1em', fontFamily: 'BrandonBold' }}>Info</p>
                        <IonImg
                          className='ion-margin-bottom'
                          src={'assets/images/IG_logo.png'}
                          alt={'IG'}
                          style={{ width: '8vw', margin: '1em auto' }}
                        />
                        <IonImg src={'assets/images/yelp_logo.png'} alt={'yelp'} style={{ width: '12vw', margin: '1em auto' }} />
                      </IonCol>
                      <IonCol
                        size-xs='4'
                        size-sm='4'
                        size-md='4'
                        className='ion-text-center ion-padding-top'
                        style={{ border: '2px solid #FF007A', borderRadius: '5px' }}>
                        <IonText style={{ fontSize: '1em', fontFamily: 'RetroFunk' }}>Combination</IonText>
                        <IonImg src={'assets/images/ono.png'} style={{ width: '15vw', margin: '1em auto' }} />
                      </IonCol>
                      <IonCol size-xs='4' size-sm='4' size-md='4' className='ion-text-center'>
                        <p style={{ fontSize: '1em', fontFamily: 'BrandonBold' }}>Order</p>

                        <IonImg
                          className='ion-margin-bottom'
                          src={'assets/images/UE_logo.png'}
                          alt={'IG'}
                          style={{ width: '12vw', margin: '1em auto' }}
                        />
                        <IonImg src={'assets/images/doordash_logo.png'} alt={'yelp'} style={{ width: '12vw', margin: '1em auto' }} />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </section>

                <section>
                  <h1 style={{ fontSize: '1.75em', fontFamily: 'RetroFunk' }}>List your restaurant</h1>
                  <IonRow style={{ margin: '2em 0' }}>
                    <IonCol size='auto' className='ion-padding-end'>
                      <IonImg src={'assets/images/free.png'} style={{ width: '50px' }} />
                    </IonCol>
                    <IonCol>
                      <IonText style={{ fontSize: '1.3em', fontFamily: 'BrandonBold' }}>Free advertisement</IonText>
                      <br />
                      <IonText style={{ fontSize: '1em', fontFamily: 'Brandon' }}>
                        If you don’t want to turn on “Accept orders,” it’s a free listing. Users can still look-up your restaurant info similar to
                        Yelp. We believe that you won’t need your website moving forward.
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ margin: '2em 0' }}>
                    <IonCol size='auto' className='ion-padding-end'>
                      <IonImg src={'assets/images/three-4.png'} style={{ width: '50px' }} />
                    </IonCol>
                    <IonCol>
                      <IonText style={{ fontSize: '1.3em', fontFamily: 'BrandonBold' }}>Only 3 minutes to register</IonText>
                      <br />
                      <IonText style={{ fontSize: '1em', fontFamily: 'Brandon' }}>
                        All you need to get started is to enter your user information and restaurant phone number. <br />
                        We will take care of the rest.
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size='auto' className='ion-padding-end'>
                      <IonImg src={'assets/images/responsive-design.png'} style={{ width: '50px' }} />
                    </IonCol>
                    <IonCol>
                      <IonText style={{ fontSize: '1.3em', fontFamily: 'BrandonBold' }}>No more devices needed</IonText>
                      <br />
                      <IonText style={{ fontSize: '1em', fontFamily: 'Brandon' }}>
                        You might have various electronic devices such as tablets for delivery services. You will not receive any more devices from
                        us.
                      </IonText>
                    </IonCol>
                  </IonRow>
                </section>
                <section style={{ margin: '5em 0' }}>
                  <h1 style={{ fontSize: '1.75em', fontFamily: 'RetroFunk' }}> Our fees</h1>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <h3 className='titles'>IF ZERO SALES</h3>
                        <IonText className='subTitles'>If somehow there are ZERO sales, no worries, ZERO out of your pocket.</IonText>
                      </IonCol>
                      <IonCol>
                        <h3 className='titles'>COMMISSION</h3>
                        <IonText className='subTitles'>
                          We pride ourselves on 100% transparency. 6% of sales are for operating ono online. The credit card processing fee is 2.9%
                          and 30 cents per transaction.
                        </IonText>
                        <IonText> Nearly 1% of our commission goes to promotional fees.</IonText>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </section>

                <section style={{ margin: '5em 0' }}>
                  <h1 style={{ fontSize: '1.75em', fontFamily: 'RetroFunk' }}>Upcoming functions</h1>
                  <IonGrid>
                    <IonRow>
                      <IonCol className='ion-padding-end'>
                        <h3 className='titles ion-text-center'>POS INTEGRATION (Now Square)</h3>
                        <IonText className='subTitles'>
                          Orders placed through our ono online app will pop up on your POS system screen, which keeps things streamlined for you.
                        </IonText>
                      </IonCol>
                      <IonCol className='ion-padding-end'>
                        <h3 className='titles ion-text-center'>FLASH BOOKING (upcoming)</h3>
                        <IonText className='subTitles'>
                          Getting tired of reservations canceling? We will offer other options and solutions for you.
                        </IonText>
                      </IonCol>
                      <IonCol>
                        <h3 className='titles ion-text-center'>LOCAL FARM CONNECTIONS (upcoming)</h3>
                        <IonText className='subTitles'>
                          We believe that the relationships between restaurants and local farms/distributors are of utmost importance. We will connect
                          you with the best local farms, distributors, and purveyors.
                        </IonText>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </section>
              </div>

              <div className='ion-padding' style={{ backgroundColor: '#36B7FF', color: 'white', margin: '40px 0' }}>
                <IonRow>
                  <IonCol>
                    <IonLabel className='bold' onClick={() => history.push('/about')}>
                      About us
                    </IonLabel>

                    <IonLabel className='bold ion-margin-start' onClick={() => history.push('/faq')}>
                      FAQ
                    </IonLabel>
                    <br />
                    <br />
                    <IonText>Company: ono online Inc</IonText>
                    <br />
                    <IonText>Address: 1441 Kapiolani Blvd Ste 1115, Honolulu, HI 96814</IonText>
                    <br />
                    <IonText>Contact: aloha@onoonline.app</IonText>
                    <br />
                    <IonLabel>Version:1.0.1</IonLabel>
                  </IonCol>
                  <IonCol className='ion-align-self-end'>
                    <IonImg src={'assets/images/palm_tree_transparent.png'} style={{ width: 'auto', height: '16vh' }} />
                  </IonCol>
                </IonRow>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default WelcomePage;
