import { useContext, useEffect, useRef, useState } from 'react';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonIcon,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonButtons,
  IonToolbar,
  IonImg,
  IonAvatar,
  IonLoading,
  IonChip,
  IonText,
  IonFooter,
  IonMenuButton,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react';
import Parse from 'parse/dist/parse.min.js';
import momentTZ from 'moment-timezone';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';
import { notifications, albumsOutline, imageOutline, receipt, chatbubbleOutline, heartOutline, shareOutline } from 'ionicons/icons';

import AppContext from '../../context/AppContext';

import './PostPage.css';

const MyPostPage = (props) => {
  const { deviceWidth, user } = useContext(AppContext);

  const [posts, setPosts] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [footerSegment, setFooterSegment] = useState('TIMELINE');

  const rId = props.match.params.id;

  const swiperRef = useRef();
  const contentRef = useRef();

  useEffect(() => {
    const initMyPostPage = async () => {
      console.log('myPostPage run');
      setShowLoading(true);
      await Parse.Cloud.run('getMyRestaurantPosts', { rId: rId ? rId : user.restaurant_id })
        .then((results) => {
          if (results.length) {
            setPosts(results);
          } else {
            console.log('No posts yet');
          }
          setShowLoading(false);
        })
        .catch((error) => {
          setShowLoading(false);
          alert(error);
        });
    };
    initMyPostPage();
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          {deviceWidth > 500 ? (
            <IonGrid>
              <IonRow className='ion-align-items-center'>
                <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='4' offset-md='4' size-lg='4' offset-lg='4'>
                  <IonSegment mode='ios' value={footerSegment} onIonInput={(e) => setFooterSegment(e.target.value)}>
                    <IonSegmentButton value='TIMELINE'>
                      <IonIcon icon={albumsOutline}></IonIcon>
                    </IonSegmentButton>
                    <IonSegmentButton value='IMAGES'>
                      <IonIcon icon={imageOutline}></IonIcon>
                    </IonSegmentButton>
                  </IonSegment>
                </IonCol>
              </IonRow>
            </IonGrid>
          ) : (
            <>
              <IonTitle>My Posts</IonTitle>
              <IonButtons slot='end'>
                <IonButton>
                  <IonIcon slot='icon-only' icon={notifications} size='small' />
                </IonButton>
              </IonButtons>
            </>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent ref={contentRef} scrollEvents={true} className='ion-padding'>
        <IonGrid>
          <IonRow className='ion-align-items-center'>
            <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='4' offset-lg='4'>
              <Swiper ref={swiperRef} style={{ paddingBottom: '120px' }}>
                <SwiperSlide>
                  {!posts.length ? (
                    <div>No posts yet</div>
                  ) : (
                    posts.map((post, index) => {
                      if (footerSegment === 'TIMELINE') {
                        return (
                          <div key={index} className='ion-margin-bottom'>
                            <IonItem className='ion-no-padding'>
                              <IonAvatar slot='start' style={{ height: '30px', width: '30px' }}>
                                <IonImg src={post.author.avatar} />
                              </IonAvatar>
                              <IonLabel>
                                <h3>{post.author.name}</h3>
                              </IonLabel>
                              <IonLabel slot='end' style={{ fontSize: '0.7em' }}>
                                {momentTZ(post.createdAt).tz('Pacific/Honolulu').fromNow(true)}
                              </IonLabel>
                            </IonItem>
                            <div>
                              <Swiper
                                className='AnimatedSlides'
                                observer
                                observeParents
                                initialSlide={0}
                                slidesPerView={1}
                                spaceBetween={0}
                                centeredSlides={true}
                                watchSlidesProgress={true}
                                effect={'coverflow'}
                                coverflowEffect={{
                                  rotate: 0,
                                  stretch: 0,
                                  depth: 250,
                                  modifier: 1,
                                  slideShadows: false,
                                }}>
                                {post.images.map((image, index) => {
                                  return (
                                    <SwiperSlide key={index} style={{ width: '100%' }}>
                                      {image.type === 'IMAGE' ? (
                                        <IonImg src={image.uri} style={{ width: '100%' }} />
                                      ) : (
                                        <video src={image.uri} style={{ width: '100%' }} controls />
                                      )}
                                    </SwiperSlide>
                                  );
                                })}
                              </Swiper>
                            </div>

                            <div style={{ marginTop: '4px' }}>
                              <IonRow className='ion-no-padding'>
                                <IonCol size='8' className='ion-no-padding'>
                                  <div className='ion-text-start'>
                                    <IonChip>
                                      <IonIcon icon={heartOutline} style={{ color: 'white' }} />
                                      <IonLabel style={{ fontSize: '0.9em', fontFamily: 'BrandonBold' }}>{post.likes}</IonLabel>
                                    </IonChip>
                                    <IonChip>
                                      <IonIcon icon={shareOutline} style={{ color: 'white' }} />
                                      <IonLabel style={{ fontSize: '0.9em', fontFamily: 'BrandonBold' }}>{post.shared_count}</IonLabel>
                                    </IonChip>
                                  </div>
                                </IonCol>
                                <IonCol className='ion-no-padding'>
                                  <div className='ion-text-end'>
                                    <IonChip>
                                      <IonIcon icon={chatbubbleOutline} style={{ color: 'white' }} />
                                      <IonLabel style={{ fontSize: '0.9em', fontFamily: 'BrandonBold' }}>{post.comment_count}</IonLabel>
                                    </IonChip>
                                  </div>
                                </IonCol>
                              </IonRow>
                            </div>
                            <div className='ion-text-start'>
                              <IonText style={{ fontSize: '0.8em' }}>{post.caption}</IonText>
                            </div>
                            <div className='ion-text-start'>
                              {post.linked_menus.length
                                ? post.linked_menus.map((menu) => <IonText style={{ fontSize: '0.7em', marginRight: '8px' }}>#{menu.name}</IonText>)
                                : null}
                            </div>
                            {/* <div className='ion-text-start ion-margin-vertical'>
                            <IonText className='ion-margin-end' style={{ color: '#FC0FC0', fontFamily: 'BrandonBold', fontSize: '0.8em' }}>
                              @{user.restaurant_name}
                            </IonText>
                            {newPost.linkedMenus.map((item, index) => (
                              <IonText key={item.id} className='ion-margin-end' style={{ color: 'white', fontFamily: 'Brandon', fontSize: '0.8em' }}>
                                #{item.name}
                              </IonText>
                            ))}
                          </div> */}
                          </div>
                        );
                      } else {
                        return (
                          <div key={index}>
                            {post.images[0].type === 'IMAGE' ? (
                              <IonImg className='ion-float-start' src={post.images[0].uri} style={{ width: '33.3%' }} />
                            ) : (
                              <video className='ion-float-start' src={post.images[0].uri} style={{ width: '33.3%' }} />
                            )}
                          </div>
                        );
                      }
                    })
                  )}
                </SwiperSlide>
              </Swiper>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />
      </IonContent>
      {deviceWidth < 501 ? (
        <IonFooter>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonMenuButton id='menu' />
            </IonButtons>
            <IonTitle>
              <IonSegment mode='ios' value={footerSegment} onIonInput={(e) => setFooterSegment(e.target.value)}>
                <IonSegmentButton value='TIMELINE'>
                  <IonIcon icon={albumsOutline} size='small' />
                </IonSegmentButton>
                <IonSegmentButton value='IMAGES'>
                  <IonIcon icon={imageOutline} size='small' />
                </IonSegmentButton>
              </IonSegment>
            </IonTitle>
            <IonButtons slot='end'>
              <IonButton>
                <IonIcon slot='icon-only' icon={receipt} size='small' />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      ) : null}
    </IonPage>
  );
};

export default MyPostPage;
