import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonIcon,
  IonLabel,
  IonModal,
  IonList,
  IonItem,
  IonThumbnail,
  IonImg,
  IonRow,
  IonCol,
  IonGrid,
  IonSearchbar,
  IonInput,
  IonTextarea,
  IonCheckbox,
  IonLoading,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import Parse from 'parse/dist/parse.min.js';

import './CategoryModal.css';

const CategoryModal = (props) => {
  const {
    flag,
    rId,
    selectedCategory,
    selectedCategoryIndex,
    showEditCategoryModal,
    setShowEditCategoryModal,
    originalMenus,
    prevCategories,
    setPrevCategories,
  } = props;
  const [searchText, setSearchText] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [category, setCategory] = useState(null);
  const [menus, setMenus] = useState([]);
  const [menuIdArray, setMenuIdArray] = useState([]);

  useEffect(() => {
    if (selectedCategory) {
      setCategory(selectedCategory);
      setMenus(originalMenus);
      const ids = originalMenus.map((item) => item.objectId);
      setMenuIdArray(ids);
    }
  }, [selectedCategory]);

  const filteredMenus = useMemo(() => {
    const filtered = menus.filter((item) => {
      return item.name.toLowerCase().includes(searchText.toLowerCase());
    });
    return filtered;
  }, [menus, searchText]);

  const handleItemCheckbox = (checked, menu) => {
    const menuIds = category?.menus.map((item) => item.objectId);
    console.log(menuIds);
    if (checked && !menuIds.includes(menu.objectId)) {
      setCategory((prev) => {
        return { ...prev, menus: [...prev.menus, menu] };
      });
      return;
    }
    if (!checked && menuIds.includes(menu.objectId)) {
      setCategory((prev) => {
        const filter = prev.menus.filter((item) => {
          return item.objectId !== menu.objectId;
        });
        console.log(filter);
        return { ...prev, menus: filter };
      });
      return;
    }
  };

  const handleSaveEditedCategory = async () => {
    if (!category.name.length) {
      alert('Please type category name');
      return;
    }
    setShowLoading(true);
    const menuIds = category.menus.map((item) => item.objectId);
    await Parse.Cloud.run('saveEditedCategory', { categoryId: category.objectId, data: JSON.stringify(category), menuIds: menuIds })
      .then((result) => {
        if (result) {
          let copiedCategories = prevCategories;
          copiedCategories[selectedCategoryIndex] = category;
          setPrevCategories([...copiedCategories]);
          setSearchText('');
          setShowEditCategoryModal(false);
          setShowLoading(false);
          return;
        } else {
          setShowLoading(false);
          alert(result);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <IonModal className='categoryModal' isOpen={showEditCategoryModal}>
      <IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='6' offset-lg='3'>
              <IonToolbar>
                <IonButtons slot='start'>
                  <IonButton onClick={() => setShowEditCategoryModal(false)}>
                    <IonIcon icon={closeOutline} />
                  </IonButton>
                </IonButtons>
                {/* <IonTitle>Edit Category in "{categoryObj.section}"</IonTitle> */}
                <IonButtons slot='end'>
                  <IonButton fill='solid' expand='block' color='primary' onClick={handleSaveEditedCategory}>
                    Save
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>
      <IonContent className='ion-padding'>
        {!category ? null : (
          <IonGrid>
            <IonRow>
              <IonCol size-xs='12' size-sm='8' offset-sm='2' size-md='6' offset-md='3' size-lg='6' offset-lg='3'>
                <div className='ion-margin-vertical'>
                  <IonLabel style={{ fontSize: '1rem', fontFamily: 'BrandonBold' }}>Category name</IonLabel>
                  <IonItem className='ion-no-padding ion-margin-vertical' fill='outline'>
                    <IonInput
                      style={{ borderRadius: '10px', height: '2em' }}
                      placeholder={' Category name'}
                      value={category.name}
                      onIonInput={(e) => setCategory({ ...category, name: e.target.value })}
                    />
                  </IonItem>
                  <IonLabel style={{ fontSize: '1rem', fontFamily: 'BrandonBold' }}>Description</IonLabel>
                  <IonItem className='ion-no-padding ion-margin-vertical' fill='outline'>
                    <IonTextarea
                      className='ion-padding-horizontal'
                      fill='outline'
                      value={category.description}
                      style={{ borderRadius: '10px' }}
                      onIonInput={(e) => setCategory({ ...category, description: e.target.value })}
                    />
                  </IonItem>
                </div>
                <div className='ion-margin-top'>
                  <IonLabel style={{ fontSize: '1rem', fontFamily: 'BrandonBold' }}>Assign items</IonLabel>
                  <IonSearchbar
                    className='searchBar ion-no-padding ion-margin-top'
                    placeholder={'Search items'}
                    showClearButton={'always'}
                    value={searchText}
                    onIonInput={(e) => setSearchText(e.target.value)}
                  />
                </div>
                <IonList>
                  {filteredMenus.length ? (
                    filteredMenus.map((menu) => {
                      const menuIds = category?.menus.map((item) => item.objectId);
                      return (
                        <IonItem key={menu.objectId} className='ion-align-items-start'>
                          <IonCheckbox
                            slot='start'
                            className='categoryModalCheckBox'
                            checked={menuIds.includes(menu.objectId)}
                            value={menu.objectId}
                            onIonInput={(e) => handleItemCheckbox(e.target.checked, menu)}
                          />
                          {!menu.images.length > 0 ? (
                            <IonThumbnail slot='start' style={{ width: '5vh', height: '5vh' }} />
                          ) : (
                            <IonThumbnail slot='start' className='ion-margin-top' style={{ width: '5vh', height: '5vh' }}>
                              <IonImg src={menu.images[0].uri} style={{ width: '5vh', height: '5vh' }} />
                            </IonThumbnail>
                          )}
                          <IonLabel className='ion-text-wrap'>
                            <IonLabel className='vh200'>{menu.name}</IonLabel>
                            <p>{menu.description}</p>
                            {/* <p>${menu.price}</p> */}
                          </IonLabel>
                        </IonItem>
                      );
                    })
                  ) : (
                    <IonLabel>No Item</IonLabel>
                  )}
                </IonList>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
      <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />
    </IonModal>
  );
};

export default CategoryModal;
