import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { AppProvider } from './context/AppContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
<link rel="apple-touch-icon" sizes="180x180" href="ono-180x180.png"></link>

ReactDOM.render(<AppProvider><App /></AppProvider>, document.getElementById('root'))
  
defineCustomElements(window)

serviceWorkerRegistration.register({
  onUpdate: async(registration: ServiceWorkerRegistration) => {
    await registration.update();
    let confirmed = window.confirm('New version available!  Ready to update?')
    if (confirmed) {
      window.location.reload();
      return;
    }
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }  
  }
});

reportWebVitals()