import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonBackButton,
  IonTitle,
  IonHeader,
  IonButton,
  IonContent,
  IonCard,
  IonPage,
  IonLabel,
  IonToolbar,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonMenuButton,
  IonList,
  IonItem,
  IonButtons,
  IonMenuToggle,
  IonIcon,
  IonToggle,
  IonModal,
  IonListHeader,
  IonBadge,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonSelect,
  IonSelectOption,
  IonAvatar,
  IonInput,
  IonLoading,
} from '@ionic/react';
import { Camera, CameraResultType } from '@capacitor/camera';
import { cameraOutline, gitMergeOutline } from 'ionicons/icons';
import Parse from 'parse/dist/parse.min.js';
import { errorAlert } from '../../components/errorAlert';
import { nanoid } from 'nanoid';

import AppContext from '../../context/AppContext';

import './TeamPage.css';

const TeamPage = (props) => {
  const { user, updateIsOnboarding, updateUser } = useContext(AppContext);
  const history = useHistory();

  const [team, setTeam] = useState([]);
  const [userInfo, setUserInfo] = useState({
    first_name: '',
    last_name: '',
    avatar: '',
    role: '',
    email: '',
    password: '',
  });
  const [showLoading, setShowLoading] = useState(false);

  const firstNameInputRef = useRef(null);
  const lastNameInputRef = useRef(null);
  const roleSelectRef = useRef(null);
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);

  const rId = props.match.params.id;

  useEffect(() => {
    const currentUser = Parse.User.current();
    if (!currentUser) {
      updateIsOnboarding(true);
      return history.replace('/');
    }

    const getRestaurant = async () => {
      console.log('getRestaurant run');
      await Parse.Cloud.run('getRestaurantFromApp', { rId: rId ? rId : user.restaurant_id }).then((result) => {
        if (!result) {
          alert('Please check internet connection');
        } else {
          updateUser({ ...user, restaurant_id: rId ? rId : user.restaurant_id, restaurant_name: result.name });
        }
      });
    };
    if (!user.restaurant_name) {
      getRestaurant();
    }

    const getTeam = async () => {
      await Parse.Cloud.run('getTeam', { rId: rId ? rId : user.restaurant_id }).then((results) => {
        if (results) {
          setTeam(results);
        } else {
          alert('Something wrong, please try again later');
        }
      });
    };
    getTeam();
  }, []);

  const handleAvatar = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Base64,
      width: 100,
      height: 100,
    }).catch((e) => console.log(e));
    if (image) {
      let imageUrl = `data:image/heic;base64,${image.base64String}`;
      setUserInfo({ ...userInfo, avatar: imageUrl });
    }
  };

  const handleAddUser = async () => {
    if (emailInputRef.current.value.length < 1) {
      errorAlert('Error', 'Invalid Email');
      return false;
    }
    if (passwordInputRef.current.value.toString().length < 8) {
      errorAlert('Error', 'Password Error');
      return false;
    }

    setShowLoading(true);
    await Parse.Cloud.run('addTeamMember', { userInfo, rId: rId ? rId : user.restaurant_id, restaurant_name: user.restaurant_name }).then(
      (result) => {
        if (result) {
          setUserInfo({ ...userInfo, first_name: '', last_name: '', avatar: '', role: '', email: '', password: '' });
          setShowLoading(false);
          errorAlert('Confirm', 'All set!');
        } else {
          setShowLoading(false);
          if (result === 202) {
            return errorAlert('Signup Error', 'Account already exists for this email');
          } else if (result === 400) {
            return errorAlert('Signup Error', 'Please try again');
          } else {
            return errorAlert('Signup Error', 'Please refresh browser and try again');
          }
        }
      }
    );
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton />
          </IonButtons>
          <IonTitle>Team</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem>
                {user.avatar ? (
                  <IonAvatar slot='start' style={{ width: '7vh', height: '7vh' }}>
                    <IonImg src={user.avatar} style={{ width: '7vh', height: '7vh' }} />
                  </IonAvatar>
                ) : (
                  <IonAvatar slot='start' style={{ width: '7vh', height: '7vh' }}>
                    <IonImg src='https://fakeimg.pl/100x100/?retina=1&text=Image&font=Montserrat' style={{ width: '7vh', height: '7vh' }} />
                  </IonAvatar>
                )}

                <IonLabel>
                  <h3>
                    {user.first_name} {user.last_name} (You)
                  </h3>
                  <p>{user.role}</p>
                  <p>{user.email}</p>
                </IonLabel>
              </IonItem>
              {!team.length
                ? null
                : team.map((member, index) => (
                    <IonItem key={index}>
                      {member.avatar ? (
                        <IonAvatar slot='start' style={{ width: '7vh', height: '7vh' }}>
                          <IonImg src={member.avatar} style={{ width: '7vh', height: '7vh' }} />
                        </IonAvatar>
                      ) : (
                        <IonAvatar slot='start' style={{ width: '7vh', height: '7vh' }}>
                          <IonImg src='https://fakeimg.pl/100x100/?retina=1&text=Image&font=Montserrat' style={{ width: '7vh', height: '7vh' }} />
                        </IonAvatar>
                      )}

                      <IonLabel>
                        <h3>
                          {member.first_name} {member.last_name}
                        </h3>
                        <p>{member.role}</p>
                        <p>{member.email}</p>
                      </IonLabel>
                    </IonItem>
                  ))}
            </IonCol>
            <IonCol size='auto'>
              <IonCard className='ion-text-left ion-padding' style={{ width: '360px' }}>
                <IonCardHeader>
                  <IonCardTitle className='ion-text-center' style={{ fontFamily: 'BrandonBold', fontSize: '1rem' }}>
                    Add New Team Member
                  </IonCardTitle>
                </IonCardHeader>
                {/* <div className='ion-text-center' style={{ width: '100%' }}>
                  {userInfo.avatar ? (
                    <IonAvatar style={{ margin: '2rem auto' }} onClick={handleAvatar}>
                      <IonImg src={userInfo.avatar} />
                    </IonAvatar>
                  ) : (
                    <IonAvatar style={{ margin: 'auto' }} onClick={handleAvatar}>
                      <IonIcon icon={cameraOutline} size='large' />
                    </IonAvatar>
                  )}
                </div> */}
                <IonItem className='ion-margin-vertical item-no-padding' lines='none'>
                  <IonLabel position='stacked'>First Name</IonLabel>
                  <IonInput
                    ref={firstNameInputRef}
                    className='register-input no-padding'
                    pattern='^([a-zA-Z0-9]{3,})$'
                    placeholder=''
                    value={userInfo.first_name}
                    onIonInput={(e) => setUserInfo({ ...userInfo, first_name: e.target.value })}
                    required
                    style={{ border: '1px solid #999', borderRadius: '5px' }}
                  />
                </IonItem>
                <IonItem className='ion-margin-vertical item-no-padding' lines='none'>
                  <IonLabel position='stacked'>Last Name</IonLabel>
                  <IonInput
                    ref={lastNameInputRef}
                    className='register-input no-padding'
                    pattern='^([a-zA-Z0-9]{3,})$'
                    placeholder=''
                    value={userInfo.last_name}
                    onIonInput={(e) => setUserInfo({ ...userInfo, last_name: e.target.value })}
                    required
                    style={{ border: '1px solid #999', borderRadius: '5px' }}
                  />
                </IonItem>

                <IonItem className='ion-margin-vertical item-no-padding' lines='none'>
                  <IonLabel position='stacked'>Role</IonLabel>
                  <IonSelect
                    ref={roleSelectRef}
                    style={{ border: '1px solid #999', borderRadius: '5px', marginTop: '7px' }}
                    value={userInfo.role}
                    onIonInput={(e) => setUserInfo({ ...userInfo, role: e.target.value })}>
                    <IonSelectOption value='Owner'>Owner</IonSelectOption>
                    <IonSelectOption value='Chef'>Chef</IonSelectOption>
                    <IonSelectOption value='Kitchen Manager'>Kitchen Manager</IonSelectOption>
                    <IonSelectOption value='Manager'>Manager</IonSelectOption>
                    <IonSelectOption value='Cashier'>Cashier</IonSelectOption>
                  </IonSelect>
                </IonItem>
                <IonItem className='ion-margin-vertical item-no-padding' lines='none'>
                  <IonLabel position='stacked'>Email</IonLabel>
                  <IonInput
                    ref={emailInputRef}
                    className='register-input no-padding'
                    inputmode='email'
                    pattern='^([a-zA-Z0-9]{3,})$'
                    placeholder=''
                    min='3'
                    value={userInfo.email}
                    onIonInput={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
                    required
                    style={{ border: '1px solid #999', borderRadius: '5px' }}
                  />
                </IonItem>
                <IonItem className='ion-margin-vertical item-no-padding' lines='none'>
                  <IonLabel position='stacked'>Password</IonLabel>
                  <IonInput
                    ref={passwordInputRef}
                    className='register-input no-padding'
                    type='password'
                    pattern='password'
                    placeholder=''
                    value={userInfo.password}
                    onIonInput={(e) => setUserInfo({ ...userInfo, password: e.target.value })}
                    required
                    style={{ border: '1px solid #999', borderRadius: '5px' }}
                  />
                </IonItem>
                <div className='ion-margin-top ion-text-center'>
                  <IonButton fill='outline' onClick={handleAddUser}>
                    Submit
                  </IonButton>
                </div>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Please wait...'} />
    </IonPage>
  );
};
export default TeamPage;
